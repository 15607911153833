import axiosInstance from "app/interceptors";

export const getRequestProjectPayments = async (params, signal) => {
  const { data } = await axiosInstance.get("/project", { params, signal });
  return data;
};

export const postRequestProjectPayment = async (params) => {
  const { data } = await axiosInstance.post("/project", params);
  return data;
};

export const putRequestProjectPayment = async (params) => {
  const { data } = await axiosInstance.put(`/project/${params.id}`, params?.payload);
  return data;
};

export const getRequestProjectPaymentDetail = async (id) => {
  const { data } = await axiosInstance.get(`/project/${id}`);
  return data;
};
