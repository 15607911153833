import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";

import {
  usePostRecon,
  useReconDetail,
  useReconDetailTrx,
  useReconciliation,
} from "hooks/reconciliation";
import {
  CustomDatepickerForm,
  InputForm,
  NumberInputForm,
  SelectAccountForm,
  SelectBankAccountForm,
} from "components/HookForm";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useNavigate, useParams, useLocation } from "react-router";
import { Tab, Tabs } from "components/Tabs";
import { render } from "@testing-library/react";
import { CgSpinner } from "react-icons/cg";
import { convertToIDR } from "helpers";
import { debounce } from "lodash";
import { useProjectAccountsQuery } from "hooks/projectAccount";
import { enqueueSnackbar } from "notistack";

const ReconciliationApprove = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const postRecon = usePostRecon();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("action");
  const { data, isLoading } = useReconDetail([
    "recon-detail",
    {
      dokumen_import_id: id,
      page: newPage,
      limit: newLimit,
      search: search,
    },
  ]);
  const { data: reconDetail, isLoading: reconDetailLoading } =
    useReconDetailTrx(["recon-detail-trx", id]);

  const methods = useForm({
    defaultValues: {
      transaction: {},
    },
  });

  useEffect(() => {
    if (reconDetail && !paramValue) {
      methods.setValue(`transaction.bank_account`, {
        value: reconDetail?.data?.project_account_rekening?.id,
        label:
          reconDetail?.data?.project_account_rekening?.number +
          " - " +
          reconDetail?.data?.project_account_rekening?.bank_name +
          " - " +
          reconDetail?.data?.project_account_rekening?.name,
      });
      methods.setValue(
        `transaction.bank_account_name`,
        reconDetail?.data?.project_account_rekening?.name
      );
    }
  }, [reconDetail, paramValue]);

  useEffect(() => {
    if (data) {
      setDataSource(
        data?.data?.data?.map((data, index) => ({
          no: index + 1,
          ...data,
          ada_di_rek:
            data?.status === "Dana Ada" ||
            data?.status === "Menunggu Rekon Akunting"
              ? true
              : false,
          isDisabled: paramValue === "recon" ? false : true,
        })) || []
      );
    }
  }, [data]);

  useEffect(() => {
    if (dataSource.length > 0) {
      const totalPokok = dataSource
        ?.filter((data) => data.ada_di_rek)
        .reduce((acc, item) => {
          return acc + item.nilai_pokok_pengembalian;
        }, 0);
      const totalBunga = dataSource
        ?.filter((data) => data.ada_di_rek)
        .reduce((acc, item) => {
          return acc + item.nilai_bunga_pengembalian;
        }, 0);
      methods.setValue(`transaction.total_transaction`, totalPokok);
      methods.setValue(`account.recon`, totalBunga);
    }
  }, [dataSource]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setSearch(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    500
  );

  const links = [
    {
      label: "Daftar Rekonsialisasi",
      path: "/reconciliation-accounting",
    },
    {
      label: "Rekonsiliasi Bendahara",
    },
  ];

  const headers = [
    {
      title: "No",
      dataIndex: "no",
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "tanggal_transaksi",
      render: (value, data, index) => {
        return (
          <div className="text-ellipsis w-full max-w-[540px]">
            {moment(value).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: "NAP",
      dataIndex: "noa",
    },
    {
      title: "Nama Debitur",
      dataIndex: "nama_debitur",
    },
    {
      title: "Nominal Setor",
      dataIndex: "nilai_pengembalian",
      render: (value, data, index) => {
        return (
          <div className="text-ellipsis w-full max-w-[540px] text-blue-500 font-bold">
            {value ? convertToIDR(value) : "-"}
          </div>
        );
      },
    },
    {
      title: "Pokok Pengembalian",
      dataIndex: "nilai_pokok_pengembalian",
      render: (value, data, index) => {
        return (
          <div className="text-ellipsis w-full max-w-[540px]">
            {value ? convertToIDR(value) : "-"}
          </div>
        );
      },
    },
    {
      title: "Bunga Pengembalian",
      dataIndex: "nilai_bunga_pengembalian",
      render: (value, data, index) => {
        return (
          <div className="text-ellipsis w-full max-w-[540px]">
            {value ? convertToIDR(value) : "Rp 0"}
          </div>
        );
      },
    },
    {
      title: "Ada di Rek Koran",
      dataIndex: "ada_di_rek",
      fixed: "right",
      render: (value, data, index) => {
        return (
          //render chekbox
          <input
            type="checkbox"
            checked={value}
            disabled={data?.isDisabled}
            onChange={() => {
              const newDataSource = dataSource.map((item, idx) => {
                if (idx === index) {
                  return {
                    ...item,
                    ada_di_rek: !item.ada_di_rek,
                  };
                }
                return item;
              });
              setDataSource(newDataSource);
            }}
          />
        );
      },
    },
    {
      title: "Tidak ada di Rek Koran",
      dataIndex: "ada_di_rek",
      fixed: "right",
      render: (value, data, index) => {
        return (
          //render chekbox dan textarea input alasan
          <div className="flex items-center gap-4 justify-start">
            <input
              type="checkbox"
              checked={value ? false : true}
              disabled={data?.isDisabled}
              onChange={() => {
                const newDataSource = dataSource.map((item, idx) => {
                  if (idx === index) {
                    return {
                      ...item,
                      ada_di_rek: !item.ada_di_rek,
                    };
                  }
                  return item;
                });
                setDataSource(newDataSource);
              }}
            />
          </div>
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    try {
      if (!data?.transaction?.bank_account) {
        enqueueSnackbar("Rekening Dana Kelolaan harus diisi", {
          variant: "error",
        });
        return;
      }
      let payload = {
        dokumen_import_id: id,
        akun_pendapatan_id: "1010df18-1eae-49aa-a477-f770b3acd68d",
        pokok_hasil_rekon: data?.transaction?.total_transaction,
        rekening_dana_id: data?.transaction?.bank_account?.id,
        bunga_hasil_rekon: data?.account?.recon,
        rekonsiliasi: dataSource.map((item) => ({
          id: item?.id,
          status: item?.ada_di_rek ? "Dana Ada" : "Dana Tidak Ada",
        })),
      };

      const res = await postRecon.mutateAsync(payload);
      enqueueSnackbar("Berhasil menyimpan data", { variant: "success" });
      navigate("/reconciliation-accounting");
    } catch (error) {
      enqueueSnackbar("Gagal menyimpan data", { variant: "error" });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Breadcrumbs items={links} />
        <div className={`md:flex block items-center my-3`}>
          <TitleText className="flex-1">{"Rekonsiliasi Bendahara"}</TitleText>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] ">
          <div className="card-body p-3">
            <div className="space-y-4 w-full">
              <div className="w-full">
                <SearchInput
                  placeholder="Cari"
                  value={search}
                  onChange={onSearch}
                />
              </div>
              <div className="flex items-center overflow-auto">
                <Table
                  bordered
                  stripped
                  layout="fixed"
                  className="w-full"
                  columns={headers}
                  dataSource={dataSource}
                  onChangePage={setNewPage}
                  onChangeRowsPerPage={setNewLimit}
                  isLoading={isLoading}
                  pagination={{
                    page: data?.data?.meta?.current_page,
                    limit: data?.data?.meta?.per_page,
                    total: data?.data?.meta?.total,
                    previous_pages: data?.data?.meta?.current_page > 1,
                    next_pages: !(
                      data?.data?.meta?.current_page ===
                      data?.data?.meta?.last_page
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-4">
            <div className="border-b-[1px] pb-6 mb-6 space-y-4">
              {/* <TitleText className="flex-1">{"Pendapatan Bunga"}</TitleText> */}
              <div className="flex flex-row items-end justify-end gap-4">
                <div className="grid grid-cols-3 gap-4 w-full">

                  <NumberInputForm
                    name={`transaction.total_transaction`}
                    label="Total Pokok Hasil Rekon"
                    className="w-full"
                    required
                    disabled
                  />
                  <NumberInputForm
                    name={`account.recon`}
                    label="Total Bunga Hasil Rekon"
                    className="w-full"
                    required
                    disabled
                  />

                  <NumberInputForm
                    name={``}
                    label="Total Hasil Rekonsiliasi"
                    className="w-full"
                    required
                    disabled
                    value={
                      methods.watch(`transaction.total_transaction`) + methods.watch(`account.recon`)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-4">
            <div className="border-b-[1px] pb-6 mb-6 space-y-4">
              <TitleText className="flex-1">{"Pilih Rekening Dana Kelolaan"}</TitleText>
              <div className="flex flex-row items-end justify-end gap-4">
                <div className="grid grid-cols-3 gap-4 w-full">
                  <SelectBankAccountForm
                    label="Rekening Dana Kelolaan"
                    name={`transaction.bank_account`}
                    placeholder="Nomor Rekening"
                    type="Dana Kelolaan"
                    required
                    onSelect={(value) => {
                      methods.setValue(
                        `transaction.bank_account_name`,
                        value?.name
                      );
                    }}
                  />
                  <InputForm
                    name={`transaction.bank_account_name`}
                    label="Nama Pemilik Rekening"
                    className="w-full"
                    disabled
                    placeholder="Pilih Nomor Rekening"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {paramValue && paramValue === "recon" && (
            <div className="flex-1 flex items-center justify-between space-x-4 px-8 py-4">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1 max-w-[100px]"
                //   disabled={isLoading}
                onClick={() => {
                  navigate("/reconciliation-accounting");
                }}
              >
                Batal
              </Button>
              <Button
                type="submit"
                isLoading={postRecon.isLoading}
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 space-x-1 max-w-[100px]"
                disabled={postRecon.isLoading}
              >
                <span>{"Submit"}</span>
                {postRecon.isLoading && <CgSpinner className="animate-spin" />}
              </Button>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ReconciliationApprove;
