import config from 'app/config';
import axiosInstance from 'app/interceptors';
import { isString } from 'lodash';

export const getAccountGroupList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/directorate`, { params, signal });
  return data;
}

export const getAccountGroupDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/directorate/${id}`);
  return { ...data, accounts: isString(data?.accounts) ? JSON.parse(data?.accounts) : data?.accounts };
}

export const createAccountGroupData = async (params) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/directorate`, params);
  return data;
}

export const updateAccountGroupData = async (params) => {
  const { data } = await axiosInstance.put(`${config.BASE_URL}/directorate/${params?.id}`, params?.payload);
  return data;
}

export const deleteAccountGroupData = async (params) => {
  const { data } = await axiosInstance.delete(`${config.BASE_URL}/permission/${params?.id}`);
  return data;
}

export const getDirectorate = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/directorate`, { params, signal });
  return data;
}
