import { Button } from "components";
import Accordion from "components/Accordion";
import { SelectAccountForm } from "components/HookForm";
import { map } from "lodash";
import { convertToIDR } from "pages/DownPayment/util";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router";
import Activity from "./activity";
import { BiTrash } from "react-icons/bi";

const Account = () => {
  const location = useLocation();
  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  const { control, watch } = useFormContext();
  const {
    fields: accounts,
    append: addAccount,
    remove: removeAccount,
  } = useFieldArray({
    control,
    name: "accounts",
  });

  return (
    <div className="space-y-4">
      {map(accounts, (account, key) => (
        <Accordion
          key={account?.id}
          label={`Akun ${key + 1}`}
          renderLeft={
            isDetail
              ? () => (
                  <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
                    Nilai:{" "}
                    {convertToIDR(
                      account?.activities?.reduce(
                        (acc, curr) => acc + curr?.value,
                        0
                      )
                    )}
                  </div>
                )
              : null
          }
          renderRight={
            !isDetail
              ? () => (
                  <Button
                    iconOnly
                    startIcon={<BiTrash size={20} />}
                    className="px-2 mr-2 bg-red-600 border-red-600"
                    onClick={() => removeAccount(key)}
                  />
                )
              : null
          }
        >
          <div className="space-y-4">
            {/* <SelectAccountForm
              label="Kegiatan"
              placeholder="Pilih Kegiatan"
              name={`accounts.${key}.activity`}
              level="3"
              isDisabled={isDetail}
            />
            <SelectAccountForm
              label="Rincian Output"
              placeholder="Pilih Rincian Output"
              name={`accounts.${key}.output`}
              level="5"
              isDisabled={isDetail}
            /> */}
            <SelectAccountForm
              label="Komponen"
              placeholder="Pilih Komponen"
              name={`accounts.${key}.component`}
              level="8"
              isDisabled={isDetail}
            />
            <SelectAccountForm
              label="Akun Belanja"
              placeholder="Pilih Akun Belanja"
              name={`accounts.${key}.account`}
              level="9"
              parentId={watch(`accounts.${key}.component`)?.id}
              isDisabled={!watch(`accounts.${key}.component`)?.id || isDetail}
            />
            <div className="space-y-4">
              <div className="font-bold text-sm text-gray-600">
                Detail Kegiatan
              </div>
              <Activity
                name={`accounts.${key}.activities`}
                parentName={`accounts.${key}.account`}
              />
            </div>
          </div>
        </Accordion>
      ))}
      {!isDetail && (
        <div className="flex items-center justify-center w-full">
          <Button
            startIcon={<FiPlus />}
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
            onClick={() =>
              addAccount({
                activity: null,
                output_detail: null,
                component: null,
                account_code: null,
                activities: [{ activity_detail: null, value: "" }],
              })
            }
          >
            Tambah Akun
          </Button>
        </div>
      )}
    </div>
  );
};

export default Account;
