import { CustomDatepickerForm, CustomSelectForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { enqueueSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiImageAdd } from "react-icons/bi";

const ImportModal = ({ open, setOpen }) => {
  const importRef = useRef();

  const post = {
    isLoading: false,
  };

  const methods = useForm({
    mode: "onChange",
  });

  const onSelectFile = (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        file.type.includes("application/vnd.ms-excel")
      ) {
        methods.resetField("file", { defaultValue: file });
      } else {
        enqueueSnackbar({
          message: "Ekstensi file tidak valid",
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Something went wrong", variant: "error" });
    }
  };

  const onSubmit = (payload) => {
    if (payload) {
    }
  };

  return (
    <FormModal
      title="Import Bank Statement"
      open={open}
      onClose={() => {
        setOpen(false);
        methods.reset({ file: null });
      }}
      onSubmit={methods.handleSubmit(onSubmit)}
      isLoading={post.isLoading}
    >
      <FormProvider {...methods}>
        <div className="space-y-4">
          <CustomSelectForm name="bank" label="Bank" placeholder="Pilih Bank" />
          <CustomDatepickerForm
            name="period"
            label="Periode Transaksi yang akan Direkonsialisasi"
            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
            selectsRange
          />
          <div className="space-y-1">
            <label className="flex gap-1 font-semibold text-[14px]">
              <span className={`label-text`}>File</span>
            </label>
            <div
              className="flex items-center justify-center flex-col border border-dashed p-10 space-y-2 hover:cursor-pointer"
              onClick={() => importRef?.current?.click()}
            >
              <BiImageAdd className="text-[#8F9BB3] text-3xl" />
              <div className="flex items-center justify-center flex-col">
                <div className="font-medium text-sm">
                  {methods?.watch("file")
                    ? methods?.watch("file")?.name
                    : "Upload"}
                </div>
                {!methods?.watch("file") ? (
                  <div className="text-[#8F9BB3] text-sm">
                    .xls, .xlsx up to 10MB
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <input
          ref={importRef}
          type="file"
          name="file"
          className="hidden"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={onSelectFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </FormProvider>
    </FormModal>
  );
};

export default ImportModal;
