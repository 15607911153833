import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs, Button } from "components";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import Form from "./form";
import {
  useApprovalSubmission,
  useSubmissionRecapQuery,
} from "hooks/directTransaction";
import RejectionModal from "pages/DownPayment/rejection-modal";
import { ConfirmationModal } from "components/Modal";
import { enqueueSnackbar } from "notistack";

const VerificationRequest = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: detail } = useSubmissionRecapQuery([
    "submission-recap-detail",
    { id: params?.id },
  ]);

  const approval = useApprovalSubmission();

  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const schema = yup.object();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    if (detail?.result) {
      let total = detail.result.accounts.reduce((acc, account) => {
        const accountTotal = account.details.reduce((sum, detail) => {
          return sum + detail.tax_total + detail.value;
        }, 0);
        return acc + accountTotal;
      }, 0);

      methods.reset({...detail?.result, total: total});
    }
  }, [detail?.result]);

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Verifikasi Pengajuan Uang Muka",
    },
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const onApproveRealization = (payload) => {
    try {
      const bodyToSend = {
        approved: !payload?.reject_reason,
        reject_reason: payload?.reject_reason ?? "",
      };

      approval.mutate(
        { id: params?.id, payload: bodyToSend },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setIsOpen(false);
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const renderModal = () => (
    <>
      <RejectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={onApproveRealization}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        onSubmit={onApproveRealization}
        title="Setujui Detail Rencana Kegiatan"
        message="Anda yakin akan menyetujui Detail Rencana Kegiatan ini?"
      />
    </>
  );

  return (
    <>
      {renderModal()}
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <Form />
        <div className="h-24"></div>
        <BottomFormAction
          onCancel={handlePrevious}
          onSubmit={handleNext}
          _submitButton={{
            label: "Selanjutnya",
          }}
          _cancelButton={{
            label: "Sebelumnya",
          }}
          renderAction={() => {
            return (
              <div className="grid grid-cols-2 gap-2">
                <Button
                  className="bg-error-600 border-error-600 gap-2"
                  startIcon={<RiCloseCircleLine size={18} />}
                  onClick={() => setIsOpen(true)}
                >
                  Tolak
                </Button>
                <Button
                  className="bg-success-500 border-success-500 gap-2"
                  startIcon={<RiCheckboxCircleLine size={18} />}
                  onClick={() => setIsOpenConfirmation(true)}
                >
                  Setujui
                </Button>
              </div>
            );
          }}
        />
      </FormProvider>
    </>
  );
};

export default VerificationRequest;
