import classNames from "classnames";
import { Button } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose, CgSpinner } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const ConfirmationModal = ({
  open,
  onClose,
  onSubmit,
  message = "Are you sure you will delete this data?",
  className = "",
  isLoading = false,
  title = "",
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          {/* <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          > */}
          <div
            key="modal-confirmation"
            className={classNames([
              "bg-white rounded-xl",
              ...className.split(" "),
            ])}
          >
            <div className="flex flex-col p-6 space-y-8">
              <div className="flex-1 flex items-center justify-between">
                <FiAlertCircle className="text-xl" />
                <CgClose className="text-xl" onClick={() => onClose(false)} />
              </div>
              <div className="flex-1">
                <span className="font-medium text-lg">{title}</span>
                <div>{message}</div>
              </div>
              <div className="flex-1 flex items-center space-x-4">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  disabled={isLoading}
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 space-x-1"
                  disabled={isLoading}
                  onClick={onSubmit}
                >
                  <span>Confirm</span>
                  {isLoading && <CgSpinner className="animate-spin" />}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationModal;
