import { useMutation, useQuery } from "react-query";
import { OA } from "services";

export const useOperationalQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return OA.getOperational({ ...params }, signal);
    },
    ...options,
  });
};

export const useOperationalDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return OA.getOperationalDetail(params);
      return null;
    },
    ...options,
  });
};

export const useCreateUMO = () => {
  return useMutation((payload) => OA.createUMO(payload));
};

export const useUpdateUMO = () => {
  return useMutation((payload) => OA.updateUMO(payload));
};

export const useDeleteUMO = () => {
  return useMutation((payload) => OA.deleteUMO(payload));
};

export const useApprovalUMO = () => {
  return useMutation((payload) => OA.approveUMO(payload));
};

export const useSubmitRealizationUMO = () => {
  return useMutation((payload) => OA.submitRealizationUMO(payload));
};

export const useRealizationUMO = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return OA.getRealizationUMO(params);
      return null;
    },
    ...options,
  });
};

export const useRealizationApprovalUMO = () => {
  return useMutation((payload) => OA.approveRealizationUMO(payload));
};
