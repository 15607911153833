import classNames from "classnames";
import { map } from "lodash";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";

import { BottomFormAction, Breadcrumbs, Button } from "components";
import { CustomDatepickerForm, InputForm, NumberInputForm, SelectProjectAccountForm } from "components/HookForm";
import { useEffect, useState } from "react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import {
  useRequestProjectPaymentDetailQuery,
  useRequestProjectPaymentPut,
} from "hooks/requestProjectPayment";
import { getStatusLabel } from "./util";
import StatusBadge from "./StatusBadge";
import moment from "moment";
import RejectionModal from "./RejectionModal";
import { ConfirmationModal } from "components/Modal";
import { enqueueSnackbar } from "notistack";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { convertToIDR } from "helpers";

const DetailItem = ({ title, value = "-", className }) => (
  <div className={classNames("flex flex-col gap-2", className)}>
    <span className="text-sm font-semibold">{title}</span>
    <span>{value}</span>
  </div>
);

const RequestProjectPaymentDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const { data: detail } = useRequestProjectPaymentDetailQuery([
    "request-payment-detail",
    id,
  ]);

  const approval = useRequestProjectPaymentPut();

  const isDetail = !location?.pathname?.includes("review") && id;

  const links = [
    {
      label: "Daftar Permohonan Pembayaran Proyek",
      path: "/request-project-payment",
    },
    {
      label: "Detail",
    },
  ];

  const methods = useForm({
    mode: "all",
  });

  const {
    fields: payments,
    append: appendPayment,
    remove: removePayment,
  } = useFieldArray({
    name: "payments",
    control: methods.control,
  });

  useEffect(() => {
    if (detail) {
      methods.reset({
        payments:
          detail?.payment?.length > 0
            ? map(detail?.payment, (payment) => ({
                project_account: {
                  value: payment?.project_account_id,
                  label: `${payment?.project_account_number} - ${payment?.project_account_owner}`,
                },
                account_name: payment?.project_account_owner,
                value: payment?.value,
              }))
            : [
                {
                  project_account: null,
                  account_name: "",
                  value: "",
                },
              ],
      });
    }
  }, [detail]);

  useEffect(() => {
    if (!isOpen && !isOpenConfirmation) {
      methods.resetField("reject_reason", { defaultValue: null });
    }
  }, [isOpen, isOpenConfirmation]);

  const handlePrevious = () => {};
  const onSubmit = () => {};

  const onApproval = (payload, status) => {
    const paramPayment = {
      payment: map(payload?.payments, (payment) => ({
        payment_date: moment(payload?.payment_date).format("YYYY-MM-DD"),
        project_account_id: payment?.project_account?.value,
        project_account_number: payment?.project_account?.account_number,
        project_account_owner: payment?.account_name,
        value: 0,
      })),
    };
    try {
      const bodyToSend = {
        review_note: payload?.note,
        reason: payload?.reject_reason ? payload?.reject_reason : "",
        status,
        ...paramPayment
      };

      approval.mutate(
        { id, payload: bodyToSend },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setIsOpen(false);
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const renderModal = () => (
    <>
      <RejectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={methods.handleSubmit((payload) => onApproval(payload, 2))}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        onSubmit={methods.handleSubmit((payload) => onApproval(payload, 1))}
        title="Setujui Detail Rencana Kegiatan"
        message="Anda yakin akan menyetujui Detail Rencana Kegiatan ini?"
      />
    </>
  );

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        {renderModal()}
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold flex flex-row items-center gap-2">
                  <span>Detail</span>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <DetailItem
                    title="Uraian pengajuan"
                    value={detail?.project_desc}
                  />
                  <DetailItem
                    title="Status"
                    // value={getStatusLabel(detail?.status)}
                    value={
                      <div className="w-fit">
                        <StatusBadge status={detail?.status} />
                      </div>
                    }
                  />
                  <DetailItem
                    title="Tanggal SPJ/ Tagihan? Transaksi"
                    value={moment(detail?.submission_date)?.format(
                      "DD MMMM YYYY"
                    )}
                  />
                  <DetailItem
                    title="Nomor Invoice"
                    value={detail?.invoice_number}
                  />
                  <div className="col-span-2">
                    <DetailItem
                      title="Dibayarkan Kepada"
                      value={detail?.paid_to}
                    />
                  </div>
                  <DetailItem title="Alamat" value={detail?.address} />
                </div>
                <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded">
                  <label>Data yang perlu dibayar</label>
                </div>
                <DetailItem
                  title="Kegiatan Utama"
                  value={detail?.main_activity_name}
                />
                <DetailItem
                  title="Detail kegiatan"
                  value={detail?.detail_activity_name}
                />
                <DetailItem
                  title="Mata Anggaran"
                  value={detail?.currency_name}
                />

                <div className="w-full h-[1px] bg-gray-400" />

                <div className="grid grid-cols-5 gap-6">
                  <DetailItem
                    title="Nilai Pengajuan (RP)"
                    value={convertToIDR(detail?.total_submission)}
                  />
                  <DetailItem title="Pajak" value={detail?.tax_name} />
                  <DetailItem
                    title="Nilai Pajak (Rp)"
                    value={convertToIDR(detail?.tax_value)}
                  />
                  <DetailItem
                    title="Kode Billing"
                    value={detail?.billing_code}
                  />
                  <DetailItem
                    title="Total Dibayarkan (Rp)"
                    value={convertToIDR(detail?.total_payment)}
                  />
                </div>
                <div className="flex items-center space-x-3 overflow-x-auto max-w-[1200px]">
                  <FilePickerForm
                    name={`supporting_doc`}
                    file={map(detail?.supporting_doc, (doc) => ({
                      file_name: doc?.fileName,
                      file_size: doc?.fileSize,
                      url: doc?.path,
                    }))}
                    readOnly
                  />
                  {/* {map(detail?.supporting_doc, (doc, key) => (
                    <DocumentItem key={key} data={doc} />
                  ))} */}
                </div>

                <div className="w-full h-[1px] bg-gray-400" />

                <div className="grid grid-cols-2 gap-6">
                  <DetailItem
                    title="Nomor Rekening Penerima"
                    value={detail?.recipient_account_number}
                  />
                  <DetailItem
                    title="Nama Pemilik Rekening"
                    value={detail?.recipient_name}
                  />
                  <DetailItem
                    title="Bank Penerima"
                    value={detail?.recipient_bank}
                  />
                  <DetailItem title="Cabang" value={detail?.bank_branch} />
                </div>

                <div className="w-full h-[1px] bg-gray-400" />

                  

                <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded space-x-4 flex items-center">
                  <label>Pembayaran</label>
                  <div className="bg-gray-300 p-1 rounded text-xs text-black">
                    Total Pembayaran: {convertToIDR(detail?.total_payment)}
                  </div>
                </div>

                {/* <CustomDatepickerForm
                  name="payment_date"
                  label="Tanggal Pembayaran"
                  placeholder={"dd/mm/yyyy"}
                  required
                  disabled={true}
                /> */}

                <div className="w-full h-[1px] bg-gray-400" />

                {map(payments, (payment, key) => (
                  <div key={payment?.id} className="flex items-end space-x-4">
                    <div className="grid grid-cols-3 gap-4 flex-1">
                      <SelectProjectAccountForm
                        name={`payments.${key}.project_account`}
                        label={"Nomor Rekening Sumber Dana Pembayaran"}
                        className="z-[12]"
                        placeholder=""
                        isDisabled={isDetail}
                        onSelect={(params) =>
                          methods.resetField(`payments.${key}.account_name`, {
                            defaultValue: params?.account_owner,
                          })
                        }
                      />
                      <InputForm
                        name={`payments.${key}.account_name`}
                        label={"Nama Pemilik Rekening"}
                        className={`py-2 px-4 border w-full rounded-md`}
                        placeholder=""
                        disabled
                      />
                      <NumberInputForm
                        name={`payments.${key}.value`}
                        label={"Nilai Yang Dibayar"}
                        className={`py-2 px-4 border w-full rounded-md`}
                        placeholder=""
                        value={detail?.total_payment}
                        disabled
                      />
                    </div>
                  </div>
                ))}

                <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded">
                  <label>Catatan Review</label>
                </div>

                <InputForm
                  name="note"
                  label="Catatan Review"
                  className="w-full min-h-[120px]"
                  type="textarea"
                  value={detail?.review_note}
                  disabled={isDetail}
                />

                {isDetail && (
                  <>
                    <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded">
                      <label>Alasan Penolakan</label>
                    </div>

                    <DetailItem title="Alasan" value={detail?.reason} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isDetail && (
          <>
            <div className="h-[120px]" />
            <BottomFormAction
              onSubmit={methods.handleSubmit(onSubmit)}
              onCancel={handlePrevious}
              _submitButton={{
                label: "Simpan",
              }}
              _cancelButton={{
                label: "Sebelumnya",
              }}
              renderAction={() => (
                <div className="grid grid-cols-2 gap-2">
                  <Button
                    className="bg-error-600 border-error-600 gap-2"
                    startIcon={<RiCloseCircleLine size={18} />}
                    onClick={() => setIsOpen(true)}
                  >
                    Tolak
                  </Button>
                  <Button
                    className="bg-success-500 border-success-500 gap-2"
                    startIcon={<RiCheckboxCircleLine size={18} />}
                    onClick={() => setIsOpenConfirmation(true)}
                  >
                    Setujui
                  </Button>
                </div>
              )}
            />
          </>
        )}
      </FormProvider>
    </>
  );
};

export default RequestProjectPaymentDetail;
