import React, { Fragment } from "react";

const Card2 = ({ children }) => {
  return (
    <Fragment>
      <div className="bg-white rounded-xl border-[1px] border-[#EAECF0] relative w-full">
        <div className="border-2 border-primary-600 rounded-l-lg  absolute h-full"></div>
        <div className="pl-2">{children}</div>
      </div>
    </Fragment>
  );
};

export default Card2;
