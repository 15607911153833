import { useQuery, useMutation } from "react-query";
import { AR } from "services";

export const useAccountRequest = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return AR.getAccountRequestList({ ...params }, signal);
    },
    ...options
  });
};

export const useAccountRequestDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return AR.getAccountRequestDetail(params);
      return null;
    },
    ...options
  });
};

export const usePostAccountRequest = () => {
  return useMutation((payload) => AR.createAccountRequestData(payload))
}

export const usePutAccountRequest = () => {
  return useMutation((payload) => AR.updateAccountRequestData(payload))
}

export const useDeleteAccountRequest = () => {
  return useMutation((payload) => AR.deleteAccountRequestData(payload))
}