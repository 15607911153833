export const getSubtotal = (qty, value, multiplier = 1) => {
  return parseInt(qty || 0) * parseInt(value || 0) * parseInt(multiplier || 1);
};

export const getNeeds = ({ needs, multiplier = 1, useVolume }) => {
  return needs?.map((need) => ({
    ...need,
    subtotal: getSubtotal(need?.volume, need?.value, multiplier),
    ...(useVolume
      ? { volume: need?.volume }
      : { qty: need?.volume, volume: undefined }),
    sbm: undefined,
    account_code: need?.account_code?.value,
    detail_code: need?.detail_code?.value,
    currency: "IDR",
  }));
};

export const getStatusProperties = (status, role) => {
  let statusProperties = getProperties(status);
  return statusProperties;
};

const getProperties = (status) => {
  let statusProperties;

  switch (status) {
    case "Disetujui Staff PPK":
    case "Disetujui":
    case "Sudah Dibayarkan Bendahara":
    case "Selesai":
      statusProperties = {
        variant: "success",
      };
      break;
    case "Perlu Dieksekusi":
    case "Perlu Revisi":
    case "Perlu Direview":
    case "Perlu Persetujuan":
      statusProperties = {
        variant: "blue",
      };
      break;
    case "Ditolak":
      statusProperties = {
        variant: "danger",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }

  return statusProperties;
};

export const getFiscalYear = () => {
  const currentYear = new Date().getFullYear();
  return [
    {
      label: currentYear.toString(),
      value: currentYear,
    },
    {
      label: (currentYear - 1).toString(),
      value: currentYear - 1,
    },
  ];
};

export const dummyDownPayment = {
  id: 1,
  request_date: Date.now(),
  activity_name: "Kegiatan A",
  activity_type: "Perjalanan Dinas",
  director: "PPD",
  account: 4714,
  output_detail: "4714.FAM.001.100",
  pum: "Ratna",
  job_number: "ST123",
  status: "baru",
};

export const dummyDownPayments = [
  {
    id: 1,
    request_date: Date.now(),
    activity_name: "Kegiatan A",
    activity_type: "Perjalanan Dinas",
    director: "PPD",
    account: 4714,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "baru",
  },
  {
    id: 2,
    request_date: Date.now(),
    activity_name: "Kegiatan B",
    activity_type: "Perjalanan Dinas",
    director: "KUSI",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "menunggu_approval_pengajuan",
  },
  {
    id: 3,
    request_date: Date.now(),
    activity_name: "Kegiatan C",
    activity_type: "Perjalanan Dinas",
    director: "KUSI",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "menunggu_approval_pertanggung_jawaban",
  },
  {
    id: 4,
    request_date: Date.now(),
    activity_name: "Kegiatan D",
    activity_type: "Perjalanan Dinas",
    director: "KUSI",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "progress_PUM",
  },
  {
    id: 5,
    request_date: Date.now(),
    activity_name: "Kegiatan E",
    activity_type: "Perjalanan Dinas",
    director: "PPD",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "perlu_verifikasi_staf_PPK",
  },
  {
    id: 6,
    request_date: Date.now(),
    activity_name: "Kegiatan F",
    activity_type: "Perjalanan Dinas",
    director: "PPD",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "perlu_approval_PPK",
  },

  {
    id: 7,
    request_date: Date.now(),
    activity_name: "Kegiatan G",
    activity_type: "Perjalanan Dinas",
    director: "KUSI",
    account: 4717,
    output_detail: "4714.FAM.001.100",
    pum: "Ratna",
    job_number: "ST123",
    status: "perlu_dibayar_bendahara",
  },
];

export const getDummyDownPayments = (role) => {
  if (role === "pjk") {
    return dummyDownPayments;
  } else if (role === "pum") {
    return [
      "perlu_rincian_kegiatan",
      "perlu_revisi",
      "sudah_dibayar_bendahara",
      "draft_pertanggung_jawaban",
      "sudah_di_approve_PJK",
    ].map((status, index) => ({
      ...dummyDownPayment,
      id: index,
      activity_name: `Kegiatan ${index}`,
      status,
    }));
  } else if (role === "ppk") {
    return ["pertanggung_jawaban", "sudah_diverifikasi"].map(
      (status, index) => ({
        ...dummyDownPayment,
        id: index,
        activity_name: `Kegiatan ${index}`,
        status,
      })
    );
  }
};
