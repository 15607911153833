import { useQuery, useMutation } from "react-query";
import { COA } from "services";

export const useCoaByLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COA.getCoaByLevel({ ...params }, signal);
    },
    ...options,
  });
};

export const useChartOfAccount = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COA.getChartOfAccountList({ ...params }, signal);
    },
    ...options,
  });
};

export const useChartOfAccountLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COA.getChartOfAccountLevel({ ...params }, signal);
    },
    ...options,
  });
};

export const useChartOfAccountDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return COA.getChartOfAccountDetail(params);
      return null;
    },
    ...options,
  });
};

export const useUpdateChartOfAccount = () => {
  return useMutation((payload) => COA.updateChartOfAccountData(payload));
};

export const useImportChartOfAccount = () => {
  return useMutation((payload) => COA.importChartOfAccountData(payload));
};

export const useDeleteChartOfAccount = () => {
  return useMutation((payload) => COA.deleteChartOfAccountData(payload));
};
