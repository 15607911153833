import { Button, Input } from "components";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
} from "components/HookForm";
import { useTaxesQuery } from "hooks/externalService";
import { convertToIDR } from "pages/DownPayment/util";
import { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { NumericFormat } from "react-number-format";
import { HelperContext } from "..";

export const TaxForm = ({ fieldName, detailIndex }) => {
  const methods = useFormContext();
  const {
    fields: taxesField,
    remove: removeTax,
    append: appendTax,
    replace: replaceTax,
  } = useFieldArray({
    control: methods.control,
    name: `${fieldName}.${detailIndex}.taxes`,
  });

  const { isRealizationCreated } = useContext(HelperContext);

  useEffect(() => {
    if (taxesField?.length === 0) {
      replaceTax([
        {
          tax: null,
          billing_code: "",
          value: 0,
        },
      ]);
    }
  }, []);

  const { data: taxes } = useTaxesQuery(["dp-realization-taxes"]);

  const getTaxAmount = (amount) => amount / 100 || 0;

  return (
    <>
      {taxesField?.map((field, index) => (
        <div className="flex flex-row items-end gap-4 mt-4" key={field.id}>
          <CustomSelectForm
            name={`${fieldName}.${detailIndex}.taxes.${index}.tax`}
            options={taxes?.map((tax) => ({
              label: tax?.nama,
              value: tax?.id,
              amount: tax?.value,
            }))}
            label="Pajak"
            placeholder="Pajak"
            isDisabled={isRealizationCreated}
          />
          <InputForm
            name={`${fieldName}.${detailIndex}.taxes.${index}.billing_code`}
            label="Kode Billing"
            placeholder="Masukkan kode billing"
            className="w-full"
            disabled={isRealizationCreated}
          />
          <NumberInputForm
            name={`${fieldName}.${detailIndex}.taxes.${index}.value`}
            label="Nilai Pajak (Rp)"
            className="w-full"
            placeholder="0"
            value={
              methods.watch(`${fieldName}.${detailIndex}.subtotal`) *
                getTaxAmount(
                  methods.watch(
                    `${fieldName}.${detailIndex}.taxes.${index}.tax`
                  )?.amount
                ) || 0
            }
            disabled
          />
          {!isRealizationCreated && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeTax(index)}
            />
          )}
        </div>
      ))}

      <div className="flex flex-1 flex-col gap-4 items-end border-y-[1px] border-gray-100 mt-4 py-4">
        {!isRealizationCreated && (
          <Button
            onClick={() => {
              appendTax({
                tax_id: "",
                tax: null,
                billing_code: "",
                value: 0,
              });
            }}
            className="bg-white border-gray-600 text-gray-600 hover:text-white"
            startIcon={<BiPlus />}
          >
            Tambah Pajak
          </Button>
        )}
        <div className="space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Total Pajak (Rp)</span>
          </label>
          <NumericFormat
            value={methods
              .watch(`${fieldName}.${detailIndex}.taxes`)
              ?.reduce((acc, o) => acc + o?.value, 0)}
            placeholder="0"
            className="w-full"
            customInput={Input}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            disabled
          />
        </div>
      </div>
    </>
  );
};
