import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";

import {
  usePostReconciliation,
  useReconciliationDetail,
} from "hooks/reconciliation";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import { Breadcrumbs, Button, Table, TitleText } from "components";
import ImportModal from "./import-modal";
import { IoIosPaper } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import TransactionListModal from "./transaction-list-modal";

const Reconciliation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isPost = location?.state?.isPost ?? false;

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const [open, setOpen] = useState(false);

  const links = [
    {
      label: "Daftar Rekonsialisasi",
      path: "/reconciliation-list",
    },
    {
      label: "Rekonsialisasi",
    },
  ];

  const methods = useForm({ mode: "onChange" });

  const post = usePostReconciliation();

  const { data: detail, isLoading } = useReconciliationDetail([
    "reconciliation-detail",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const tableColumns = [
    {
      id: "transactionDate",
      title: "Tanggal Transaksi",
      dataIndex: "transactionDate",
      render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
    },
    {
      id: "transactionId",
      title: "ID Transaksi",
      dataIndex: "transactionId",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "name",
      title: "Nama Pengirim",
      dataIndex: "name",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "note",
      title: "Keterangan",
      dataIndex: "note",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "value",
      title: "Nilai (RP)",
      dataIndex: "value",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "balance",
      title: "Balance (RP)",
      dataIndex: "balance",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "checkbox",
      title: () => (
        <div className="flex items-center justify-center space-x-2">
          <input type="checkbox" />
          <label>Match</label>
        </div>
      ),
      dataIndex: "code",
      className: "z-[0]",
      render: (value, data) => (
        <div className="flex items-center justify-center">
          <input type="checkbox" />
        </div>
      ),
    },
  ];

  return (
    <>
      <TransactionListModal open={open} setOpen={setOpen} />
      <Breadcrumbs items={links} />
      <div className="h-4" />
      <div className="card bg-white shadow-sm rounded-xl border-gray-200 border-solid border px-8 py-4">
        <div className="flex items-center justify-between">
          <label className="font-bold text-xl">Rekonsialisasi</label>
        </div>
        <FormProvider {...methods}>
          <div className="space-y-4 py-4">
            <div className="space-x-5 flex items-center">
              <InputForm name="bank" label="Bank" className="w-full" disabled />
              <CustomDatepickerForm
                name="period"
                label="Periode Transaksi yang akan Direkonsialisasi"
                placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                className="w-full"
                selectsRange
                disabled
              />
            </div>

            <div className={`md:flex block items-center my-4`}>
              <TitleText className="flex-1">
                {"Daftar Rekonsialisasi"}
              </TitleText>
              {isPost && (
                <Button
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex items-center space-x-2"
                  onClick={() => setOpen(true)}
                >
                  <IoIosPaper />
                  <span>Lihat Data Transaksi yang Perlu di Cocokan</span>
                </Button>
              )}
            </div>

            <div className="space-x-5 flex items-center">
              <InputForm
                name="search"
                className="flex-1 w-full"
                placeholder="Cari"
              />
              <CustomDatepickerForm
                name="date"
                className="flex-1"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={detail?.transaction?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: detail?.transaction?.currentPage,
                limit: detail?.transaction?.size,
                total: detail?.transaction?.totalItems,
                previous_pages: detail?.transaction?.currentPage > 1,
                next_pages: !(
                  detail?.transaction?.currentPage ===
                  detail?.transaction?.totalPages
                ),
              }}
            />
          </div>
        </FormProvider>
      </div>
      <div className="h-4" />
      {isPost && (
        <div className="card bg-white shadow-sm rounded-xl border-gray-200 border-solid border px-8 py-4">
          <div className="flex justify-between">
            <Button
              type="button"
              className="p-4 border rounded-lg bg-gray-600 border-gray-600 w-40"
              disabled={post?.isLoading}
              onClick={() => navigate(-1)}
            >
              <div className="flex items-center gap-2">Batal</div>
            </Button>
            <Button
              type="submit"
              className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
              disabled={post?.isLoading}
            >
              <div className="flex items-center gap-2">
                {post?.isLoading ? "Loading..." : "Submit"}
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Reconciliation;
