import { Table } from "components";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
} from "components/HookForm";
import { InformationModal } from "components/Modal";
import { useReconciliationTrxList } from "hooks/reconciliation";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiImageAdd } from "react-icons/bi";

const TransactionListModal = ({ open, setOpen }) => {
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const post = {
    isLoading: false,
  };

  const methods = useForm({
    mode: "onChange",
  });

  const { data: list, isLoading } = useReconciliationTrxList([
    "reconciliation-trx-list",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const tableColumns = [
    {
      id: "nap",
      title: "NAP",
      dataIndex: "nap",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "name",
      title: "Nama Debitur",
      dataIndex: "name",
      render: (value) => (value ? value : "-"),
    },
    {
      id: "paymentDate",
      title: "Tanggal Pembayaran",
      dataIndex: "paymentDate",
      render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
    },
    {
      id: "paymentValue",
      title: "Nominal Pembayaran",
      dataIndex: "paymentValue",
      render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
    },
    {
      id: "note",
      title: "Keterangan",
      dataIndex: "note",
      render: (value) => (value ? value : "-"),
    },
  ];

  return (
    <InformationModal
      title="Daftar Transaksi Pengembalian yang perlu di Rekon"
      className="bg-white rounded-xl w-[80vw]"
      childClassName="flex-1 max-h-[80vh] overflow-y-scroll px-8 py-4"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="space-x-5 flex items-center">
            <InputForm
              name="search"
              className="flex-1 w-full"
              placeholder="Cari"
            />
            <CustomDatepickerForm
              name="date"
              className="flex-1"
              placeholder="dd/mm/yyyy"
            />
          </div>

          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={list?.result}
            isLoading={isLoading}
            onChangePage={setNewPage}
            onChangeRowsPerPage={setNewLimit}
            pagination={{
              page: list?.currentPage,
              limit: list?.size,
              total: list?.totalItems,
              previous_pages: list?.currentPage > 1,
              next_pages: !(list?.currentPage === list?.totalPages),
            }}
          />
        </div>
      </FormProvider>
    </InformationModal>
  );
};

export default TransactionListModal;
