import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import moment from "moment";

import { useReconciliation } from "hooks/reconciliation";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { render } from "@testing-library/react";
import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";
import { map } from "lodash";

const ReconciliationDetailTransaction = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Rekonsialisasi",
      path: "/reconciliation-accounting",
    },
    {
      label: "Telah Disetujui",
      path: "/reconciliation-accounting/detail-reconciliation",
    },
    {
      label: "Detail Rekonsilisasi",
    },
  ];

  const DetailItem = ({ title, value, className }) => (
    <div className={classNames("flex flex-col gap-2", className)}>
      <span className="text-sm font-semibold">{title}</span>
      <span>{value}</span>
    </div>
  );

  const data = {
    fiscal_year: "2021",
    activity_types: [{ name: "Pendidikan" }, { name: "Pelatihan" }],
    activity_name: "Kegiatan Pendidikan",
    city: "Jakarta",
    destination: "Bandung",
    country: "Indonesia",
    start_date: "2021-08-01",
    end_date: "2021-08-05",
    pum: { nip: "1234567890", name: "John Doe" },
    status: { description: "Disetujui" },
  };

  return (
    <div>
      <Breadcrumbs items={links} />
      {/* <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Detail Rekonsiliasi"}</TitleText>
      </div> */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] mt-4">
        <div className="card-body p-4 space-y-4">
          <div className="space-y-4">
            <div className="text-lg font-semibold flex flex-row items-center gap-2">
              <span>Detail Rekonsiliasi</span>
              {/* <StatusBadge status={data?.status?.description} /> */}
            </div>
            <div className="grid grid-cols-3 gap-6">
              <DetailItem title="NoA" value={data?.noa} />
              <DetailItem title="Nama Debitur" value={data?.nama_debitur} />
              <DetailItem
                title="Negara Asal/Keberangkatan"
                value={data?.country}
              />
              <DetailItem title="Negara Tujuan" value={data?.destination} />

              <DetailItem
                title="Tanggal Mulai Kegiatan"
                value={moment(data?.start_date).format("DD/MM/YYYY")}
              />
              <DetailItem
                title="Tanggal Selesai Kegiatan"
                value={moment(data?.end_date).format("DD/MM/YYYY")}
              />
              <DetailItem
                title="PUM"
                value={`${data?.pum?.nip} - ${data?.pum?.name}`}
              />
              <DetailItem
                title="PUM"
                value={`${data?.pum?.nip} - ${data?.pum?.name}`}
              />
              <DetailItem
                title="PUM"
                value={`${data?.pum?.nip} - ${data?.pum?.name}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReconciliationDetailTransaction;
