import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { FiPlus } from "react-icons/fi";
import { find } from "lodash";

import { BottomFormAction, Breadcrumbs, Button, TitleText } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine } from "react-icons/ri";

const FORM_TYPE = ["add", "edit"];
const LOCALE_FORM = {
  add: {
    title: "Tambah Group Akun",
  },
  edit: {
    title: "Set Kewenangan Akun",
  },
};

const AuthorityDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const formType = useMemo(
    () => find(FORM_TYPE, (type) => location.pathname.includes(type)),
    []
  );

  const links = [
    {
      path: "/authority",
      label: "Daftar Kewenangan Akun",
    },
    {
      label: "Detail",
    },
  ];

  const schema = yup.object().shape({
    // is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      trx_transaction: "",
      information: "",
      debet_account: null,
      debet_nominal: null,
      credit_account: null,
      credit_nominal: null,
    },
  });

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = () => {};

  const renderAllModal = () => {
    return null;
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className="h-2" />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Detail</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-3">
          <div className="space-y-1">
            <p className="text-sm font-bold">Direktorat</p>
            <p className="text-sm ">Penghimpunan & Pengembangan Dana</p>
          </div>
          <div className="space-y-2">
            <table className="min-w-full border text-sm">
              <thead className="bg-primary-600">
                <tr>
                  <th className="px-6 py-4 text-left text-white">Kode Akun</th>
                  <th className="px-6 py-4 text-left text-white">Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border">
                  <td className="px-6 py-4">4714.FAM.001.100.A</td>
                  <td className="px-6 py-4">Perangkat pengolah data dan komunikasi</td>
                </tr>
                <tr className="border">
                  <td className="px-6 py-4">4714.FAM.001.100.A</td>
                  <td className="px-6 py-4">Perangkat pengolah data dan komunikasi</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorityDetail;
