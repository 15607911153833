import axiosInstance from "app/interceptors";

export const getProjectPayments = async (params, signal) => {
  const { data } = await axiosInstance.get("/project/payment/list", { params, signal });
  return data;
};

export const postProjectPayment = async (params) => {
  const { data } = await axiosInstance.post(`/project/payment/${params?.id}`, params?.payload);
  return data;
};
