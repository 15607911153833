import { useQuery, useMutation } from "react-query";
import { TJM } from "services";

export const useTrxJournal = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TJM.getTrxJournalList({ ...params }, signal);
    },
    ...options
  });
};

export const usePostTrxJournal = () => {
  return useMutation((payload) => TJM.createTrxJournalData(payload))
}

export const usePutTrxJournal = () => {
  return useMutation((payload) => TJM.updateTrxJournalData(payload))
}

export const useDeleteTrxJournal = () => {
  return useMutation((payload) => TJM.deleteTrxJournalData(payload))
}