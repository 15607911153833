import config from "app/config";
import axiosInstance from "app/interceptors";

export const getCoaByLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/coa/items/filtered-by-level`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const getChartOfAccountList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/coa`, {
    params,
    signal,
  });
  return data;
};

export const getChartOfAccountDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/coa/${id}`);
  return data;
};

export const importChartOfAccountData = async (payload) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/coa/import`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const updateChartOfAccountData = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/coa/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteChartOfAccountData = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.BASE_URL}/coa/${params?.id}`
  );
  return data;
};

export const getChartOfAccountLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/coa/items/filtered-by-level`,
    { params, signal }
  );
  return data;
};
