import { useBankAccountsQuery } from "hooks/generalSettings";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { CustomSelectForm } from "..";

const SelectBankAccountForm = ({
  name,
  label,
  placeholder,
  type,
  ...props
}) => {
  const [keyword, setKeyword] = useState("");

  const { data } = useBankAccountsQuery([
    "bank-account-option-list",
    {
      page: 1,
      size: 20,
      search: keyword,
      type,
    },
  ]);

  const handleSearchBank = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );

  return (
    <CustomSelectForm
      name={name}
      options={data?.result?.map((value) => ({
        ...value,
        label: `${value?.number} - ${value?.bank_name} - ${value?.name}`,
        value: value?.id,
        id: value?.id,
        number: value?.number,
        name: value?.name,
        bank_name: value?.bank_name,
      }))}
      label={label}
      placeholder={placeholder}
      onInputChange={(keyword) => {
        handleSearchBank(keyword);
      }}
      {...props}
    />
  );
};

export default SelectBankAccountForm;
