import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { FiPlus } from "react-icons/fi";
import { find } from "lodash";

import {
  BottomFormAction,
  Breadcrumbs,
  Button,
  Table,
  TitleText,
} from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine } from "react-icons/ri";
import { useBudgetRevisionDetail } from "hooks/budgetRevision";
import CollapsingCard from "components/Card/CollapsingCard";
import ResourceCeilling from "./resource-ceiling";
import GoalCeilling from "./goal-ceiling";
import moment from "moment";

const BudgetRevisionForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const links = [
    {
      path: "/budget-revision",
      label: `Daftar Pengajuan Revisi Anggaran ${moment().format("YYYY")}`,
    },
    {
      label: "Buat Pengajuan Revisi",
    },
  ];

  const schema = yup.object().shape({
    // is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const { data: componentList } = useBudgetRevisionDetail([
    "budget-revision-detail",
    1,
  ]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = () => {};

  const tableColumns = [
    {
      id: "code",
      title: "Kode",
      dataIndex: "code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "full_code",
      title: "Full Code",
      dataIndex: "full_code",
      width: 540,
      className: "overflow-hidden text-ellipsis whitespace-normal flex-wrap",
      sortable: true,
      render: (value) => (
        <div className="w-full break-words max-w-[200px]">{value}</div>
      ),
    },
    {
      id: "coa_name",
      title: "Nama Akun",
      dataIndex: "coa_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "position",
      title: "Posisi",
      dataIndex: "position",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "level",
      title: "Level",
      dataIndex: "level",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
  ];

  const renderAllModal = () => {
    return null;
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className="h-2" />
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border p-4">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumns}
          dataSource={componentList?.result}
        />
      </div>
      <div className="h-4" />
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border p-4">
        <div className={`md:flex block items-center mt-3 mb-4`}>
          <TitleText className="flex-1">
            {"Tambah Pengajuan Revisi Anggaran"}
          </TitleText>
        </div>
        <FormProvider {...methods}>
          <div className="space-y-4">
            <ResourceCeilling data={componentList?.result} />
            <GoalCeilling data={componentList?.result} />
          </div>
        </FormProvider>
      </div>
      <div className="h-24" />
      <BottomFormAction onCancel={onCancel} onSubmit={onSubmit} />
    </>
  );
};

export default BudgetRevisionForm;
