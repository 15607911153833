import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  NumberInputForm,
} from "components/HookForm";
import { useBanksQuery } from "hooks/generalSettings";
import { startCase } from "lodash";

const Form = () => {
  const { data: banks } = useBanksQuery(["banks"]);
  return (
    <div className="flex flex-col gap-4">
      <InputForm
        name="name"
        className="py-2 px-4 border w-full rounded-md"
        label="Nama Rekening"
        placeholder="Nama Rekening"
      />
      <InputForm
        name="number"
        className="py-2 px-4 border w-full rounded-md"
        label="Nomor Rekening"
        placeholder="Nomor Rekening"
      />
      <CustomSelectForm
        name="bank"
        options={banks?.data?.data?.map((bank) => ({
          label: bank?.name,
          value: bank?.id,
        }))}
        label="Bank"
        placeholder="Pilih Bank"
      />
      <InputForm
        name="branch_name"
        className="py-2 px-4 border w-full rounded-md"
        label="Cabang"
        placeholder="Cabang"
      />
      <NumberInputForm
        name="nominal"
        label="Nominal"
        className="w-full"
        placeholder="0"
      />
      <InputForm
        name="permit_letter"
        className="py-2 px-4 border w-full rounded-md"
        label="Surat Izin"
        placeholder="Surat Izin"
      />
      <NumberInputForm
        name="interest"
        label="Bunga"
        className="w-full"
        placeholder="0"
      />

      <InputForm
        name="sk_number"
        className="py-2 px-4 border w-full rounded-md"
        label="Nomor SK"
        placeholder="Nomor SK"
      />
      <CustomDatepickerForm
        name="placement_date"
        label="Tanggal Placement"
        placeholder={"dd/mm/yyyy"}
      />
      <CustomDatepickerForm
        name="due_date"
        label="Jatuh Tempo"
        placeholder={"dd/mm/yyyy"}
      />
    </div>
  );
};

export default Form;
