import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { useEmployeeQuery } from "hooks/downPayment";
import { useCreateParticipant, useParticipantQuery } from "hooks/participant";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import * as yup from "yup";

const ParticipantFormModal = ({ title, isOpen, onClose, onSubmit, type }) => {
  const schema = yup.object().shape({
    nip: yup.string().required("Wajib diisi"),
    name: yup.string().required("Wajib diisi"),
    gender: yup.object({
      label: yup.string().required("Wajib diisi"),
      value: yup.string().required("Wajib diisi"),
    }),
    phone_number: yup.string().required("Wajib diisi"),
    npwp: yup.string().required("Wajib diisi"),
    agency: yup.string().required("Wajib diisi"),
    class: yup.string().required("Wajib diisi"),
    position: yup.string().required("Wajib diisi"),
    bank_account_number: yup.string().required("Wajib diisi"),
    bank_account_name: yup.string().required("Wajib diisi"),
    bank_name: yup.string().required("Wajib diisi"),
  });
  const methods = useForm({
    mode: "all",
    defaultValues: {
      nip: "",
      name: "",
      gender: "",
      phone_number: "",
      npwp: "",
      agency: "",
      class: "",
      position: "",
      bank_account_number: "",
      bank_account_name: "",
      bank_name: "",
    },
    resolver: yupResolver(schema),
  });
  const [keyword, setKeyword] = useState("");
  const handleSearchParticipant = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );

  const { data: participants, isLoading } = useParticipantQuery(
    [
      "dp-activities",
      {
        page: 1,
        limit: 20,
        search: keyword,
        type: type,
      },
    ],
    { enabled: type !== "employee" }
  );

  const { data: employees, isLoading: employeesLoading } = useEmployeeQuery(
    [
      "dp-activities",
      {
        page: 1,
        limit: 20,
        search: keyword,
      },
    ],
    { enabled: type === "employee" }
  );

  const [isCreating, setIsCreating] = useState(false);
  const saveParticipant = useCreateParticipant();

  const handleCloseModal = () => {
    methods.reset({
      participant: null,
      nip: "",
      name: "",
      gender: "",
      phone_number: "",
      npwp: "",
      agency: "",
      class: "",
      position: "",
      bank_account_number: "",
      bank_account_name: "",
      bank_name: "",
    });
    onClose();
  };

  const onSave = (payload) => {
    saveParticipant.mutate(
      {
        ...payload,
        id: undefined,
        participant: undefined,
        type,
        gender: payload?.gender?.value,
      },
      {
        onSuccess: (res) => {
          onSubmit(res?.result);
          handleCloseModal();
        },
      }
    );
  };
  return (
    <FormModal
      title="Pilih Peserta"
      className="bg-white rounded-xl w-1/3"
      open={isOpen}
      onClose={handleCloseModal}
      onSubmit={methods.handleSubmit((data) => {
        onSubmit(data);
        methods.reset({
          participant: null,
          nip: "",
          name: "",
          gender: "",
          phone_number: "",
          npwp: "",
          agency: "",
          class: "",
          position: "",
          bank_account_number: "",
          bank_account_name: "",
          bank_name: "",
        });
        onClose();
      })}
      _submitButton={{
        label: "Pilih",
        disabled: !methods.formState.isValid || !methods.watch("id"),
      }}
      bodyClassname="min-h-[700px]"
      renderAction={() => (
        <Button
          startIcon={<FiPlus />}
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
          type="submit"
          onClick={methods.handleSubmit(onSave)}
          disabled={!methods.formState.isValid}
        >
          Sebagai Data Baru
        </Button>
      )}
    >
      <FormProvider {...methods}>
        <div className="flex flex-col space-y-3 min-h-screen">
          <div className="flex flex-row items-center gap-2 justify-center">
            <CustomSelectForm
              name="participant"
              options={
                type === "employee"
                  ? employees?.map((value) => ({
                      label: value?.name,
                      value: value,
                    }))
                  : participants?.map((value) => ({
                      label: value?.name,
                      value: value,
                    }))
              }
              onInputChange={(keyword) => {
                handleSearchParticipant(keyword);
              }}
              placeholder="Cari Narasumber"
              isLoading={isLoading}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onSelect={(value) => {
                methods.reset({
                  ...value?.value,
                  gender: {
                    label: value?.value?.gender,
                    value: value?.value?.gender,
                  },
                  participant: {
                    label: value?.value?.name,
                    value: value?.value,
                  },
                });
              }}
              isDisabled={isCreating}
            />
            <Button
              startIcon={<FiPlus />}
              className="text-white border-[#E34F13] bg-[#E34F13] min-h-8 h-10 mt-2"
              onClick={() => setIsCreating(!isCreating)}
            >
              {isCreating ? "Pilih Data" : "Buat Data Baru"}
            </Button>
          </div>
          <InputForm type="hidden" name="id" />
          <InputForm
            name="nip"
            label="NIK/NIP"
            className="w-full"
            placeholder="NIK/NIP"
            disabled={!isCreating}
          />
          <InputForm
            name="name"
            label="Nama"
            className="w-full"
            placeholder="Nama"
            disabled={!isCreating}
          />
          <CustomSelectForm
            name="gender"
            label="Jenis Kelamin"
            options={[
              {
                label: "Pria",
                value: "Pria",
              },
              {
                label: "Wanita",
                value: "Wanita",
              },
            ]}
            placeholder="Pilih Jenis Kelamin"
            isDisabled={!isCreating}
          />
          <InputForm
            name="phone_number"
            label="No Handphone"
            className="w-full"
            placeholder="No Handphone"
            disabled={!isCreating}
          />
          <InputForm
            name="npwp"
            label="NPWP"
            className="w-full"
            placeholder="NPWP"
            disabled={!isCreating}
          />
          <InputForm
            name="agency"
            label="Instansi"
            className="w-full"
            placeholder="Instansi"
            disabled={!isCreating}
          />
          <InputForm
            name="class"
            label="Golongan"
            className="w-full"
            placeholder="Golongan"
            disabled={!isCreating}
          />
          <InputForm
            name="position"
            label="Jabatan"
            className="w-full"
            placeholder="Jabatan"
            disabled={!isCreating}
          />
          <InputForm
            name="bank_account_number"
            label="Nomer Rekening"
            className="w-full"
            placeholder="Nomer Rekening"
            disabled={!isCreating}
          />
          <InputForm
            name="bank_account_name"
            label="Nama Pemilik Rekening"
            className="w-full"
            placeholder="Nama Pemilik Rekening"
            disabled={!isCreating}
          />
          <InputForm
            name="bank_name"
            label="Bank"
            className="w-full"
            placeholder="Bank"
            disabled={!isCreating}
          />
        </div>
      </FormProvider>
    </FormModal>
  );
};

export default ParticipantFormModal;
