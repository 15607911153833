import axiosInstance from "app/interceptors";
import config from "app/config";

export const getParticipants = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/participant`, {
    params,
    signal,
  });

  return data?.result;
};

export const createParticipant = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/participant`,
    params
  );
  return data;
};
