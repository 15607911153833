import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";

let NEXT_PAGE = null;

const CustomSelectForm = ({
  label,
  required,
  placeholder = "",
  onSelect,
  ...props
}) => {
  const { name } = props;
  const { control } = useFormContext();

  const SelectStyles = useMemo(
    () => ({
      input: (styles) => ({
        ...styles,
        border: "0px",
      }),
      control: (styles) => ({
        ...styles,
        borderColor: "#EAECF0",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#EAECF0",
        },
        ":focus": {
          ...styles[":focus"],
          borderColor: "#EAECF0",
        },
        boxShadow: "none",
        borderRadius: "6px",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#98A2B3",
      }),
      indicatorContainer: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? "#98A2B3" : "#98A2B3",
        ":hover": {
          ...styles[":hover"],
          color: "#98A2B3",
        },
      }),
      option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
        ...styles,
        cursor: isDisabled ? "not-allowed" : "auto",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#01A24A"
          : isFocused
          ? "#EEFFF4"
          : undefined,
        color: isDisabled
          ? "hsl(0, 0%, 80%)"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#98A2B3"
          : data.color,
      }),
    }),
    []
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <ReactSelect
            {...props}
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(val);
              onSelect && onSelect(val);
            }}
            onBlur={onBlur}
            menuPlacement="auto"
            styles={{
              input: (styles) => ({
                ...styles,
                border: "0px",
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#98A2B3",
                fontSize: 14,
                fontWeight: 400,
              }),
              control: (styles) => ({
                ...styles,
                borderColor: invalid ? "#dc3545" : "#EAECF0",
                ":hover": {
                  ...styles[":hover"],
                  borderColor: invalid ? "#dc3545" : "#EAECF0",
                },
                boxShadow: "none",
                borderRadius: "6px",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#1f2938",
                fontSize: 14,
              }),
              indicatorContainer: (styles, { isFocused }) => ({
                ...styles,
                color: isFocused ? "#98A2B3" : "#98A2B3",
                ":hover": {
                  ...styles[":hover"],
                  color: "#98A2B3",
                },
              }),
              valueContainer: (styles) => ({
                ...styles,
                padding: "2px 14px",
              }),
              option: (
                styles,
                { data, isSelected, isFocused, isDisabled }
              ) => ({
                ...styles,
                cursor: isDisabled ? "not-allowed" : "auto",
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? "#01A24A"
                  : isFocused
                  ? "#EEFFF4"
                  : undefined,
                fontSize: 14,
                color: isDisabled
                  ? "hsl(0, 0%, 80%)"
                  : isSelected
                  ? "#fff"
                  : isFocused
                  ? "#98A2B3"
                  : data.color,
              }),
              multiValue: (styles) => {
                return {
                  ...styles,
                  backgroundColor: "#01A24A",
                  borderRadius: "0.375rem",
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                color: "white",
                fontWeight: "500",
                marginTop: -1,
                paddingLeft: "12px",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: "white",
                ":hover": {
                  color: "black",
                },
              }),
            }}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(CustomSelectForm);
