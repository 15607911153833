import { Breadcrumbs } from "components";
import { useCashTransferDetailQuery } from "hooks/cash";
import { convertToIDR } from "pages/DownPayment/util";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { cashTransferMap } from "services/cash";

const DetailItem = ({ title, value }) => (
  <div className="space-y-2 flex flex-col">
    <span className="font-bold text-sm">{title}</span>
    <span className="text-sm">{value}</span>
  </div>
);

const BankCashFlowDetail = () => {
  const { id } = useParams();

  const { data: detail } = useCashTransferDetailQuery([
    "cash-transfer-detail",
    {
      id,
    },
  ]);

  const links = [
    {
      label: "Daftar Pemindahan Uang Keluar",
      path: "/bank-cash-flow",
    },
    {
      label: "Detail",
    },
  ];

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />

      <div className="space-y-4">
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
          <div className="card-body p-4 space-y-4">
            <div className="text-lg font-semibold mb-3">Detail</div>
            <div className="grid grid-cols-3 gap-8">
              <DetailItem
                title="Kategori Kas"
                value={cashTransferMap[detail?.category]}
              />
              <DetailItem title="Nominal" value={convertToIDR(detail?.value)} />
              <div></div>
              <DetailItem
                title="Nomor Rekening Asal"
                value={detail?.source_bank_account?.number}
              />
              <DetailItem
                title="Akun Asal"
                value={detail?.source_account_name}
              />
              <div></div>
              <DetailItem
                title="Nomor Rekening Tujuan"
                value={detail?.target_bank_account?.number}
              />
              <DetailItem
                title="Akun Tujuan"
                value={detail?.target_account_name}
              />
              <div></div>
              <DetailItem title="Uraian" value={detail?.description} />
              <div></div>
              <div></div>
              <DetailItem title="User" value="Ari Nugraha" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankCashFlowDetail;
