export const getStatusProperties = (status) => {
  let statusProperties = getProperties(status);
  return statusProperties;
};

export const getTransactionTypeProperties = (id) => {
  switch (id) {
    case 1: return "implementing partner"
    case 2: return "LEMTARA"
    case 3: return "resources project"
    case 4: return "uang muka"
    case 5: return "penyaluran langsung"
  }
}

const getProperties = (status) => {
  let statusProperties;
  switch (status) {
    case 0:
      statusProperties = {
        variant: "warning",
      };
      break;
    case 1:
      statusProperties = {
        variant: "success",
      };
      break;
    default:
      statusProperties = {
        variant: "danger",
      };
      break;
  }

  return statusProperties;
};

export const getStatusLabel = (status) => {
  return status === 0
    ? "Menunggu Persetujuan"
    : status === 1
      ? "Disetujui"
      : "Ditolak";
}