import { Button } from "components";
import Accordion from "components/Accordion";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  SelectAccountForm,
} from "components/HookForm";
import { debounce, map } from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import Activity from "./activity";
import { useCallback, useContext, useState } from "react";
import { useChartOfAccount } from "hooks/chartOfAccount";
import { useLocation } from "react-router";
import { convertToIDR } from "pages/DownPayment/util";
import { HelperContext } from "..";

const Account = () => {
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const { isRealizationCreated } = useContext(HelperContext);

  const { data: coaOptions } = useChartOfAccount([
    "coa-oa-add",
    {
      page: 1,
      size: 20,
      search: keyword,
      level: "8",
    },
  ]);

  const handleSearchCoa = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );

  const { control, watch, getValues } = useFormContext();
  const { fields: accounts, append: addAccount } = useFieldArray({
    control,
    name: "accounts",
  });

  return (
    <div className="space-y-4">
      {map(accounts, (account, key) => (
        <Accordion
          key={account?.id}
          label={`Akun ${key + 1}`}
          renderLeft={() => (
            <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
              Nilai:{" "}
              {convertToIDR(
                watch(`accounts.${key}`)?.activities?.reduce(
                  (acc, curr) => acc + curr?.value,
                  0
                )
              )}
            </div>
          )}
        >
          <div className="space-y-4">
            <CustomDatepickerForm
              name={`accounts.${key}.activity_date`}
              label="Tanggal Kegiatan"
              placeholder={"dd/mm/yyyy"}
              disabled={isRealizationCreated}
            />
            {/* <CustomSelectForm
              name={`accounts.${key}.activity`}
              options={coaOptions?.result?.map((value) => ({
                label: value?.coa_name,
                value: value?.full_code,
              }))}
              label="Kegiatan"
              placeholder="Pilih kegiatan"
              onInputChange={(keyword) => {
                handleSearchCoa(keyword);
              }}
              required
              isDisabled={isRealizationCreated}
            />
            <CustomSelectForm
              name={`accounts.${key}.output`}
              options={coaOptions?.result?.map((value) => ({
                label: value?.coa_name,
                value: value?.full_code,
              }))}
              label="Rincian Output"
              placeholder="Pilih rincian output"
              onInputChange={(keyword) => {
                handleSearchCoa(keyword);
              }}
              required
              isDisabled={isRealizationCreated}
            /> */}

            <SelectAccountForm
              label="Komponen"
              placeholder="Pilih Komponen"
              name={`accounts.${key}.component`}
              level="8"
              isDisabled={isRealizationCreated}
              required
            />
            <SelectAccountForm
              label="Akun Belanja"
              placeholder="Pilih Akun Belanja"
              name={`accounts.${key}.account`}
              level="9"
              parentId={watch(`accounts.${key}.component`)?.id}
              isDisabled={
                !watch(`accounts.${key}.component`)?.id || isRealizationCreated
              }
              required
            />
            <div className="space-y-4">
              <div className="font-bold text-sm text-gray-600">
                Detail Kegiatan
              </div>
              <Activity
                name={`accounts.${key}.activities`}
                parentName={`accounts.${key}.account`}
              />
            </div>
          </div>
        </Accordion>
      ))}

      {!isRealizationCreated && (
        <div className="flex items-center justify-center w-full">
          <Button
            startIcon={<FiPlus />}
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
            onClick={() =>
              addAccount({
                activity: null,
                output_detail: null,
                component: null,
                account_code: null,
                activities: [{ activity_detail: null, value: "" }],
              })
            }
          >
            Tambah Akun
          </Button>
        </div>
      )}
    </div>
  );
};

export default Account;
