import React from "react";
import classNames from "classnames";

const TableRow = ({ columns, datas, hidePagination, allChecked, setState }) => {
  const getCellValue = (object, data, key) => {
    if (object?.render) {
      if (typeof object?.render === "string") return object.render;
      if (typeof object?.render === "function")
        return object.render(data[object.dataIndex], data, key);
    } else return data[object.dataIndex];
    return;
  };

  const getClassNameValue = (object, data, key) => {
    if (object?.className) {
      if (typeof object?.className === "string")
        return [...object.className.split(" ")];
      if (typeof object?.className === "function")
        return object.className(data[object.dataIndex], data, key);
    } else return data[object.dataIndex];
    return;
  };

  if (!columns.length) return null;
  if (!datas.length) {
    return (
      <tr className="text-gray-600 text-sm text-center font-semibold">
        <td colSpan={columns.length} className="text-gray-600 text-sm">
          No data to display
        </td>
      </tr>
    );
  }

  const handleRowCheck = (index) => {
    // Update the checked state of the row at the given index
    setState(prevDataSource => prevDataSource.map((data, i) => (
      i === index ? {...data, checked: !data.checked} : data
    )));
  };

  return datas.map((data, key) => {
    return (
      <tr key={key}>
        {columns.map((col, idx) =>
          col.id === "checkbox" ? (
            <td key={idx}
            className={classNames(
              ["text-gray-600", "text-sm"],
              { sticky: Boolean(col?.fixed) },
              { "right-0": col?.fixed && col?.fixed === "right" },
              { "left-0": col?.fixed && col?.fixed === "left" },
              // `${(key % 2) !== 0 ? 'bg-white' : 'bg-gray-200'}`,
              `${
                key === datas.length - 1
                  ? !hidePagination
                    ? "border-b-[1px]"
                    : ""
                  : ""
              }`,
              getClassNameValue(col, data, key)
            )}>
              <input type="checkbox" checked={data.status === 'Paid' ? false : data.checked} disabled={
                data.status === 'Paid' ? true : false
              } onChange={() => handleRowCheck(key)}/>
            </td>
          ) : (
            <td
              key={idx}
              className={classNames(
                ["text-gray-600", "text-sm"],
                { sticky: Boolean(col?.fixed) },
                { "right-0": col?.fixed && col?.fixed === "right" },
                { "left-0": col?.fixed && col?.fixed === "left" },
                // `${(key % 2) !== 0 ? 'bg-white' : 'bg-gray-200'}`,
                `${
                  key === datas.length - 1
                    ? !hidePagination
                      ? "border-b-[1px]"
                      : ""
                    : ""
                }`,
                getClassNameValue(col, data, key)
              )}
            >
              {getCellValue(col, data, key)}
            </td>
          )
        )}
      </tr>
    );
  });
};

export default TableRow;
