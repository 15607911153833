import config from "app/config";
import axiosInstance from "app/interceptors";

export const getProjectAccounts = async (params, signal) => {
  const { data } = await axiosInstance.get("/project-account", { params, signal });
  return data;
};

export const getProjectAccountTrxHistory = async (params, signal) => {
  const { data } = await axiosInstance.get(`/project-account/transaction/list`, { params, signal });
  return data;
};

export const getProjectAccountTrxHistoryDetail = async (id, signal) => {
  const { data } = await axiosInstance.get(`/project-account/transaction/detail/${id}`, { signal });
  return data;
};

export const getProjectAccountDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(`/project-account/${params}`, { signal });
  return data;
};

export const postProjectAccount = async (params) => {
  const { data } = await axiosInstance.post("/project-account", params);
  return data;
};

export const postProjectAccountTrx = async (params) => {
  const { data } = await axiosInstance.post("/project-account/transaction", params);
  return data;
};

export const getCurrencyList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.ROOT_BASE_URL}/master/v1/Currency/list`, { params, signal });
  return data;
};
