import { map, sumBy } from "lodash";

export const getSubtotal = (qty, value, multiplier = 1) => {
  return parseInt(qty || 0) * parseInt(value || 0) * parseInt(multiplier || 1);
};

export const getStatusProperties = (status, role) => {
  let statusProperties = getProperties(status);
  return statusProperties;
};

const getProperties = (status) => {
  let statusProperties;

  switch (status) {
    case "Disetujui Staff PPK":
    case "Disetujui":
      statusProperties = {
        variant: "success",
      };
      break;
    case "Perlu Dieksekusi":
    case "Perlu Revisi":
    case "Perlu Direview":
    case "Perlu Persetujuan":
      statusProperties = {
        variant: "blue",
      };
      break;
    case "Ditolak":
      statusProperties = {
        variant: "danger",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }

  return statusProperties;
};

export const getFiscalYear = () => {
  const currentYear = new Date().getFullYear();
  return [
    {
      label: currentYear.toString(),
      value: currentYear,
    },
    {
      label: (currentYear - 1).toString(),
      value: currentYear - 1,
    },
  ];
};

export const getContractualPayload = (payload) => {
  return {
    description: payload?.description,
    transaction_date: payload?.transaction_date,
    invoice_number: payload?.invoice_number,
    component_code: payload?.component?.full_code,
    total_value: payload?.total_trx_value,
    accounts: map(payload?.accounts, (account) => ({
      account_code: account?.shipping_account?.full_code,
      details: map(account?.activity_details, (detail) => ({
        detail_code: detail?.activity_detail?.full_code,
        value: detail?.unit_cost,
        qty: detail?.qty,
        volume: detail?.volume,
        taxes: map(detail?.taxes, (tax) => ({
          tax_id: tax?.tax?.value,
          tax_name: tax?.tax?.label,
          billing_code: tax?.billing_code,
          value: tax?.value,
        })),
        tax_total: detail?.taxes?.reduce((acc, o) => acc + o?.value, 0),
        total_value: sumBy(
          account.activity_details,
          (detail) =>
            parseFloat(detail.sub_total ? detail.sub_total : 0) +
            parseFloat(
              detail.taxes?.reduce(
                (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                0
              )
            )
        ),
        currency: "IDR",
      })),
    })),
    vendor: {
      vendor_id: payload?.thirdparty?.vendor_procurement?.value,
      name: payload?.thirdparty?.vendor_procurement?.label,
      bank_account_number: payload?.thirdparty?.vendor?.value,
      bank_account_name: payload?.thirdparty?.account_name,
      bank_name: payload?.thirdparty?.bank,
    },
    documents: payload?.documents,
  };
};

export const getNonContractualStaffPayload = (payload) => {
  return {
    description: payload?.description,
    transaction_date: payload?.transaction_date,
    transaction_type:
      payload?.trx_type?.value === 1
        ? "staff"
        : payload?.trx_type?.value === 2
          ? "invite"
          : "expert",
    invoice_number: payload?.invoice_number,
    component_code: payload?.component?.full_code,
    total_value: payload?.total_trx_value,
    accounts: map(payload?.accounts, (account) => ({
      account_code: account?.shipping_account?.full_code,
      detail_code: account?.activity_detail?.full_code,
      details: map(account?.details, (detail) => ({
        staff_id: detail?.detailInfo?.id,
        staff_name: detail?.detailInfo?.name,
        sbm_id: detail?.requirement_item?.value,
        value: detail?.value,
        qty: detail?.qty,
        subtotal: detail?.sub_total,
        taxes: map(detail?.taxes, (tax) => ({
          tax_id: tax?.tax?.value,
          tax_name: tax?.tax?.label,
          billing_code: tax?.billing_code,
          value: tax?.value,
        })),
        tax_total: detail.taxes?.reduce((acc, o) => acc + o?.value, 0),
        total_value: sumBy(
          account.details,
          (detail) =>
            parseFloat(detail.sub_total ? detail.sub_total : 0) +
            parseFloat(
              detail.taxes?.reduce(
                (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                0
              )
            )
        ),
        currency: "IDR",
        bank_account_number: detail?.detailInfo?.bank_account_number,
        bank_account_name: detail?.detailInfo?.bank_account_name,
        bank_name: detail?.detailInfo?.bank_name,
      })),
    })),
    documents: payload?.documents,
  };
};

export const getNonContractualParticipantPayload = (payload) => {
  return {
    description: payload?.description,
    transaction_date: payload?.transaction_date,
    transaction_type:
      payload?.trx_type?.value === 1
        ? "staff"
        : payload?.trx_type?.value === 2
          ? "invite"
          : "expert",
    invoice_number: payload?.invoice_number,
    component_code: payload?.component?.full_code,
    total_value: payload?.total_trx_value,
    accounts: map(payload?.accounts, (account) => ({
      account_code: account?.shipping_account?.full_code,
      detail_code: account?.activity_detail?.full_code,
      details: map(account?.details, (detail) => ({
        participant_id: detail?.detailInfo?.id,
        ministry_staff: detail?.is_ministry_employee,
        sbm_id: detail?.requirement_item?.value,
        value: detail?.value,
        qty: detail?.qty,
        subtotal: detail?.sub_total,
        taxes: map(detail?.taxes, (tax) => ({
          tax_id: tax?.tax?.value,
          tax_name: tax?.tax?.label,
          billing_code: tax?.billing_code,
          value: tax?.value,
        })),
        tax_total: detail.taxes?.reduce((acc, o) => acc + o?.value, 0),
        total_value: sumBy(
          account.details,
          (detail) =>
            parseFloat(detail.sub_total ? detail.sub_total : 0) +
            parseFloat(
              detail.taxes?.reduce(
                (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                0
              )
            )
        ),
        currency: "IDR",
        bank_account_number: detail?.detailInfo?.bank_account_number,
        bank_account_name: detail?.detailInfo?.bank_account_name,
        bank_name: detail?.detailInfo?.bank_name,
      })),
    })),
    documents: payload?.documents,
  };
};

export const getVendorPayload = (payload) => {
  return {
    description: payload?.description,
    transaction_date: payload?.transaction_date,
    invoice_number: payload?.invoice_number,
    component_code: payload?.component?.full_code,
    total_value: payload?.total_trx_value,
    accounts: map(payload?.accounts, (account) => ({
      account_code: account?.shipping_account?.full_code,
      details: map(account?.activity_details, (detail) => ({
        detail_code: detail?.activity_detail?.full_code,
        value: detail?.value,
        taxes: map(detail?.taxes, (tax) => ({
          tax_id: tax?.tax?.value,
          tax_name: tax?.tax?.label,
          billing_code: tax?.billing_code,
          value: tax?.value,
        })),
        tax_total: detail.taxes?.reduce(
          (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
          0
        ),
        total_value:
          parseFloat(detail?.value ? detail?.value : 0) +
          parseFloat(
            detail?.taxes?.reduce(
              (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
              0
            )
          ),
        currency: "IDR",
      })),
    })),
    vendor: {
      vendor_id: payload?.thirdparty?.vendor_procurement?.value,
      name: payload?.thirdparty?.vendor_procurement?.label,
      bank_account_number: payload?.thirdparty?.vendor?.value,
      bank_account_name: payload?.thirdparty?.account_name,
      bank_name: payload?.thirdparty?.bank,
    },
    documents: payload?.documents,
  };
};
