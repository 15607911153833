import React, { useEffect, useState } from "react";
import { CustomSelectForm } from "..";
// import { useChartOfAccount } from "hooks/chartOfAccount";
import { useBudgetSet } from "hooks/budget";
import { debounce } from "lodash";

const SelectBudgetListForm = ({ level, ...props }) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: budgetSetOptions, isFetching } = useBudgetSet([
    "budget-set-options",
    { page, size, search },
  ]);

  useEffect(() => {
    if (!isFetching) {
      setOptions(
        loadMore
          ? options.concat(
              budgetSetOptions?.result?.map((data) => ({
                ...data,
                value: data?.operationalcomponents_id,
                label: data?.full_code,
              }))
            )
          : budgetSetOptions?.result?.map((data) => ({
              ...data,
              value: data?.operationalcomponents_id,
              label: data?.full_code,
            }))
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (page < budgetSetOptions?.totalPages) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <CustomSelectForm
      {...props}
      isLoading={isFetching}
      options={options}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectBudgetListForm;
