import React, { useEffect, useState } from "react";
import { CustomSelectForm } from "..";
// import { useChartOfAccount } from "hooks/chartOfAccount";
import { useBudgetSet } from "hooks/budget";
import { debounce } from "lodash";
import { useCoaByLevel } from "hooks/chartOfAccount";
import moment from "moment";

const SelectCoaByLevelForm = ({
  level,
  parent_id,
  year = moment().format("YYYY"),
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: coaOptions, isFetching } = useCoaByLevel([
    "coa-by-level-options",
    { page, size, search, level, parent_id },
  ]);

  useEffect(() => {
    if (!isFetching) {
      setOptions(
        loadMore
          ? options.concat(
              coaOptions?.result?.map((data) => ({
                ...data,
                level,
                value: data?.full_code,
                label: `${data?.full_code} - ${data?.name}`,
                description: `${data?.name}`,
              }))
            )
          : coaOptions?.result?.map((data) => ({
              ...data,
              level,
              value: data?.full_code,
              label: `${data?.full_code} - ${data?.name}`,
              description: `${data?.name}`,
            }))
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (page < coaOptions?.totalPages) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <CustomSelectForm
      {...props}
      isLoading={isFetching}
      options={options}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectCoaByLevelForm;
