import { CustomSelectForm, InputForm } from "components/HookForm";
import { useBanksQuery } from "hooks/generalSettings";
import { startCase } from "lodash";

const Form = () => {
  const { data: banks } = useBanksQuery(["banks"]);
  return (
    <div className="flex flex-col gap-4">
      <InputForm
        name="name"
        className="py-2 px-4 border w-full rounded-md"
        label="Nama Rekening"
        placeholder="Nama Rekening"
      />
      <InputForm
        name="number"
        className="py-2 px-4 border w-full rounded-md"
        label="Nomor Rekening"
        placeholder="Nomor Rekening"
      />
      <CustomSelectForm
        name="bank"
        options={banks?.data?.data?.map((bank) => ({
          label: bank?.name,
          value: bank?.id,
        }))}
        label="Bank"
        placeholder="Pilih Bank"
      />
      <InputForm
        name="branch_name"
        className="py-2 px-4 border w-full rounded-md"
        label="Cabang"
        placeholder="Cabang"
      />
      <CustomSelectForm
        name="type"
        options={["Pemasukan", "Pengeluaran", "Dana Kelolaan"].map((type) => ({
          label: startCase(type),
          value: type,
        }))}
        label="Tipe"
        placeholder="Pilih Tipe"
      />
    </div>
  );
};

export default Form;
