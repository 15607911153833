import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
} from "components/HookForm";
import { getFiscalYear } from "../util";
import { useFormContext } from "react-hook-form";

const destinationOptions = [
  { value: "domestic", label: "Dalam Negeri" },
  { value: "international", label: "Luar Negeri" },
];

const ActivityDetail = () => {
  const methods = useFormContext();
  const destinationType = methods.watch("destination_type");
  return (
    <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
      <div className="card-body p-4 space-y-4">
        <div className="space-y-3">
          <div className="text-lg font-semibold">Informasi Kegiatan</div>
          <div className="flex flex-row gap-4 items-center">
            <CustomSelectForm
              name="fiscal_year"
              options={getFiscalYear()}
              label="Tahun Anggaran"
              isDisabled
            />
            <CustomSelectForm
              name="activity_types"
              options={[]}
              label="Jenis Kegiatan"
              isMulti
              isDisabled
            />
          </div>
          <InputForm
            name="activity_name"
            label="Nama Kegiatan"
            className="w-full"
            disabled
          />
          <RadioGroupForm
            label="Destinasi"
            controllerName="destination_type"
            values={destinationOptions}
            disabled
          />
          {destinationType && (
            <>
              {destinationType === "domestic" ? (
                <div className="flex flex-row gap-4 items-center">
                  <CustomSelectForm
                    name="city"
                    options={[]}
                    label="Kota Asal/Keberangkatan"
                    placeholder="Pilih kota"
                    isDisabled
                  />
                  <CustomSelectForm
                    name="destination"
                    options={[]}
                    label="Kota Tujuan Kegiatan"
                    placeholder="Pilih kota"
                    isDisabled
                  />
                </div>
              ) : (
                <div className="flex flex-row gap-4 items-center">
                  <CustomSelectForm
                    name="country"
                    options={[]}
                    label="Negara Asal/Keberangkatan"
                    placeholder="Pilih negara"
                    isDisabled
                  />
                  <CustomSelectForm
                    name="destination"
                    options={[]}
                    label="Negara Tujuan Kegiatan"
                    placeholder="Pilih negara"
                    isDisabled
                  />
                </div>
              )}
            </>
          )}
          <div className="flex flex-row gap-4 items-center">
            <InputForm
              name="assignment_letter_number"
              label="No Surat Tugas"
              className="w-full"
              disabled
            />
            <CustomDatepickerForm
              name="assignment_letter_date"
              label="Tanggal Surat Tugas"
              disabled
            />
          </div>
          <div className="flex flex-row gap-4 items-center">
            <CustomDatepickerForm
              name="start_date"
              label="Tanggal Mulai Kegiatan"
              placeholder={"dd/mm/yyyy"}
              disabled
            />
            <CustomDatepickerForm
              name="end_date"
              label="Tanggal Selesai Kegiatan"
              placeholder={"dd/mm/yyyy"}
              disabled
            />
          </div>
          <CustomSelectForm
            name="pum"
            options={[]}
            label="PUM"
            placeholder="Pilih PUM"
            isDisabled
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityDetail;
