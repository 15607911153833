import React from "react";
import { removeToken } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <nav className="navbar border-b top-0 px-8" style={{ zIndex: 99 }}>
      <div className="flex-1 cursor-pointer">
        <a 
          href={`${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/Portal/Bpdlh`}
          className="border border-gray-300 rounded-lg px-4 py-2 text-sm font-medium"
          >
          Portal Menu E-Office
        </a>
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            <div>
              <IoMdNotificationsOutline className="text-gray-500 text-xl" />
            </div>
            <label className="text-sm text-gray-500">
              {oidc_user?.profile?.Name ?? "-"}
            </label>
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src="https://www.pngarts.com/files/5/User-Avatar-PNG-Picture.png" />
              </div>
            </label>
          </div>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <li onClick={() => onLogout()}>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
