import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, Table, TitleText } from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import { CustomDatepickerForm, RadioGroupForm } from "components/HookForm";
import { useFinanceReport } from "hooks/financeReport";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PiPrinterFill } from "react-icons/pi";
import * as yup from "yup";
import BalanceSheet from "./balanceSheet";
import FinanceLoss from "./financeLoss";
import { useLocation, useOutletContext } from "react-router";
import axiosInstance from "app/interceptors";
import config from "app/config";
import moment from "moment";
import { render } from "@testing-library/react";
import { convertToIDR } from "helpers";
import { data } from "autoprefixer";

const schema = yup.object().shape({
  report_type: yup.string().required("Jenis laporan baru wajib diisi"),
  startdate: yup.string().required("Tanggal Mulai baru wajib diisi"),
  enddate: yup.string().required("Tanggal Akhir baru wajib diisi"),
});

const FinanceReport = () => {
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];
  const links = [
    {
      label: "Laporan Keuangan",
    },
  ];

  const post = useFinanceReport();

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(100);

  const [arrLapDanaKelolaan, setArrLapDanaKelolaan] = React.useState([]);
  const [arrLapKasBankPendapatan, setArrLapKasBankPendapatan] = React.useState(
    []
  );
  const [arrLapKasBankPengeluaran, setArrLapKasBankPengeluaran] =
    React.useState([]);

  const [arrLapReconReport, setArrLapReconReport] = React.useState([]);
  const [arrLapProjectReport, setArrLapProjectReport] = React.useState([]);

  const tableColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Tanggal penerimaan pendapatan",
      dataIndex: "transaction_date",
      key: "transaction_date",
      width: "10%",
      render: (value, data, index) => {
        if (value === null) return "-";
        return moment(value || "").format("DD-MM-YYYY");
      },
    },
    {
      title: "Tipe Transaksi Pendapatan",
      dataIndex: "type",
      key: "type",
      width: "5%",
      render: (value, data, index) => {
        if(value === "managed") return "Uang Masuk Dana Kelolaan";
        if(value === "treasury") return "Uang Masuk Bendahara Penerimaan";
        else return "-";
      }
    },
    {
      title: "Keterangan",
      dataIndex: "description",
      key: "description",
      width: "10%",
    },

    //tipe transaksi pendapatan
    // keterangan

    // {
    //   title: "Tanggal Pengajuan Rekon",
    //   dataIndex: "tanggal_pengajuan",
    //   key: "tanggal_pengajuan",
    //   width: "10%",
    //   render: (value, data, index) => {
    //     if (value === null) return "-";
    //     return moment(value || "").format("DD-MM-YYYY");
    //   },
    // },
    {
      title: "Nilai Pendapatan",
      dataIndex: "value",
      key: "value",
      width: "10%",
      render: (value, data, index) => {
        return convertToIDR(value || 0);
      },
    },
  ];

  const tableKasBankPendapatan = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Nama Akun Bank",
      dataIndex: "name_account",
      key: "name_account",
      render: (value, data, index) => {
        return value || "-";
      },
    },
    {
      title: "No Rekening",
      dataIndex: "account_number",
      key: "account_number",
      render: (value, data, index) => {
        return value || "-";
      },
    },
    {
      title: "Nilai",
      dataIndex: "value",
      key: "value",
      render: (value, data, index) => {
        return convertToIDR(value || 0);
      },
    },
  ];

  const tableKasBankPengeluaran = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Nama Akun Bank",
      dataIndex: "bank_account_name",
      key: "bank_account_name",
      render: (value, data, index) => {
        return value || "-";
      },
    },
    {
      title: "No Rekening",
      dataIndex: "bank_account_number",
      key: "bank_account_number",
      render: (value, data, index) => {
        return value || "-";
      },
    },
    {
      title: "Nilai",
      dataIndex: "value",
      key: "value",
      render: (value, data, index) => {
        return convertToIDR(value || 0);
      },
    },
  ];

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      report_type: null,
      startdate: "",
      enddate: "",
    },
  });

  const fetchReport = async (payload) => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/reconciliation/summaries`,
      {
        params: payload,
      }
    );
    console.log("res", res);
    return res?.data;
  };

  const fetchReportFundReceipt = async (payload) => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/fund-receipt/summary`,
      {
        params: payload,
      }
    );
    return res?.data?.result;
  };

  const fetchReportCashTransfer = async (payload) => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/cash-transfer/summary`,
      {
        params: payload,
      }
    );
    return res?.data?.result;
  };

  const fetchReconReport = async (payload) => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/fund-receipt`,
      {
        params: payload,
      }
    );
    return res?.data?.result;
  };

  const fetchProjectReport = async (payload) => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/project-account/summary`,
      {
        params: payload,
      }
    );
    return res?.data?.result;
  };

  const onSubmit = async (payload) => {
    let tanggal_import = `${moment(payload.startdate).format(
      "YYYY-MM-DD"
    )}<>${moment(payload.enddate).format("YYYY-MM-DD")}`;
    let params = {
      tanggal_import: tanggal_import,
      page: page,
      limit: perPage,
    };
    let res = null;
    if (payload.report_type === "laporan-kas-bank") {
      res = await fetchReportFundReceipt(params);
      const r = await fetchReportCashTransfer(params);
      const v = await fetchReport(params);
      if (r?.length > 0) {
        setArrLapKasBankPengeluaran(
          r?.map((item, index) => {
            return {
              no: index + 1,
              ...item,
            };
          })
        );
      } else {
        setArrLapKasBankPengeluaran([]);
      }
      if (v?.length > 0) {
        setArrLapDanaKelolaan(
          v?.map((item, index) => {
            return {
              no: index + 1,
              ...item,
            };
          })
        );
      }
    } else if (payload.report_type === "laporan-pendapatan") {
      res = await fetchReconReport(params);
    } else if (payload.report_type === "laporan-proyek") {
      res = await fetchProjectReport({
        startDate: payload?.startdate,
        endDate: payload?.enddate,
      });
      console.log("res", res);
    }
    if (res?.length > 0) {
      if (payload.report_type === "laporan-kas-bank") {
        setArrLapKasBankPendapatan(
          res?.map((item, index) => {
            return {
              no: index + 1,
              name_account: item?.bank_account?.name || "-",
              account_number: item?.bank_account?.number || "-",
              ...item,
            };
          })
        );
      } else if (payload.report_type === "laporan-proyek") {
        setArrLapProjectReport(
          res?.map((item, index) => {
            return {
              no: index + 1,
              ...item,
            };
          }) || []
        );
      } else {
        setArrLapReconReport(
          res?.map((item, index) => {
            return {
              no: index + 1,
              ...item,
            };
          }) || []
        );
      }
    } else {
      setArrLapReconReport([]);
      setArrLapDanaKelolaan([]);
      setArrLapKasBankPendapatan([]);
      setArrLapKasBankPengeluaran([]);
    }
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Laporan Keuangan"}</TitleText>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border px-8 py-4 gap-4">
              <div className="space-y-4">
                <RadioGroupForm
                  label={"Pilih Laporan"}
                  controllerName={`report_type`}
                  values={[
                    {
                      label: "Laporan Saldo Bank",
                      value: "laporan-kas-bank",
                    },
                    {
                      label: "Laporan Pendapatan",
                      value: "laporan-pendapatan",
                    },
                    // {
                    //   label: "Laporan Project",
                    //   value: "laporan-project",
                    // },
                    {
                      label: "Laporan Saldo Rekening Proyek",
                      value: "laporan-proyek",
                    },
                  ]}
                />
                <div className="flex flex-col pt-2">
                  <span className="">Tanggal Laporan</span>
                  <div className="flex gap-4 w-full">
                    <CustomDatepickerForm
                      name={"startdate"}
                      // label={"Dari Tanggal"}
                      placeholder={"dd/mm/yyyy"}
                    />
                    <CustomDatepickerForm
                      name={"enddate"}
                      // label={"Sampai Dengan"}
                      placeholder={"dd/mm/yyyy"}
                    />
                  </div>
                </div>
              </div>
              {roleAccess?.canCreate && (
                <div className="card bg-white shadow-sm rounded-xl border-gray-200 border-solid border px-8 py-4">
                  <div className="flex justify-between">
                    <Button
                      type="button"
                      className="p-4 border rounded-lg bg-gray-600 border-gray-600 w-40"
                      disabled={post?.isLoading}
                    >
                      <div className="flex items-center gap-2">Batal</div>
                    </Button>
                    <Button
                      type="submit"
                      className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
                      disabled={post?.isLoading}
                    >
                      <div className="flex items-center gap-2">
                        {post?.isLoading ? "Loading..." : "Proses"}
                      </div>
                    </Button>
                  </div>
                </div>
              )}

              <>
                {/* <TitleText className="flex-1">
                      {"Laporan Keuangan Kas Bank"}
                    </TitleText> */}
                {methods.formState.isSubmitSuccessful &&
                  methods.watch("report_type") === "laporan-kas-bank" && (
                    <CollapsingCard
                      title={`Laporan Kas   (${moment(
                        methods.watch("startdate")
                      ).format("DD/MM/YYYY")} - ${moment(
                        methods.watch("enddate")
                      ).format("DD/MM/YYYY")})`}
                    >
                      <div className="space-y-4">
                        {/* ///////////////////////////////////////////// */}
                        <div className="space-y-2">
                          <div className="bg-gray-200 px-4 py-2 font-medium">
                            Bendahara Penerimaan
                          </div>
                          {arrLapKasBankPendapatan.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="flex items-center justify-between px-6 py-2 text-sm w-full">
                                  <div className="flex flex-col gap-2">
                                    <div className="flex gap-1">
                                      <div>
                                        {item?.bank_account?.name || "-"}
                                      </div>
                                      <div>-</div>
                                      <div>
                                        {item?.bank_account?.number || "-"}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="font-semibold">
                                    {convertToIDR(item?.value || 0)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="bg-gray-400 flex items-center justify-between px-4 py-4 text-lg font-bold text-white">
                          <div>Total</div>
                          <div>
                            {convertToIDR(
                              arrLapKasBankPendapatan?.reduce(
                                (acc, curr) => acc + curr?.value,
                                0
                              ) || 0
                            )}
                          </div>
                        </div>
                        {/* ///////////////////////////////////////////// */}
                        <div className="space-y-2">
                          <div className="bg-gray-200 px-4 py-2 font-medium">
                            Bendahara Pengeluaran
                          </div>
                          {arrLapKasBankPengeluaran.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="flex items-center justify-between px-6 py-2 text-sm w-full">
                                  <div className="flex flex-col gap-2">
                                    <div className="flex gap-1">
                                      <div>
                                        {item?.bank_account_name || "-"}
                                      </div>
                                      <div>-</div>
                                      <div>
                                        {item?.bank_account_number || "-"}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="font-semibold">
                                    {convertToIDR(item?.value || 0)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="bg-gray-400 flex items-center justify-between px-4 py-4 text-lg font-bold text-white">
                          <div>Total</div>
                          <div>
                            {convertToIDR(
                              arrLapKasBankPengeluaran?.reduce(
                                (acc, curr) => acc + curr?.value,
                                0
                              ) || 0
                            )}
                          </div>
                        </div>
                        {/* ///////////////////////////////////////////// */}
                        <div className="space-y-2">
                          <div className="bg-gray-200 px-4 py-2 font-medium">
                            Bendahara Dana Kelolaan
                          </div>
                          {arrLapDanaKelolaan.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="flex items-center justify-between px-6 py-2 text-sm w-full">
                                  <div className="flex flex-col gap-2">
                                    {/* <div className="flex flex-col gap-1">
                                  <div>Tanggal Pengajuan</div>
                                  <div className="font-semibold">
                                    {moment(item?.tanggal_pengajuan)?.format(
                                      "DD-MM-YYYY"
                                    ) || "-"}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <div>Tanggal Penerimaan</div>
                                  <div className="font-semibold">
                                    {moment(item?.tanggal_penerimaan)?.format(
                                      "DD-MM-YYYY"
                                    ) || "-"}
                                  </div>
                                </div> */}
                                    <div className="flex flex-col gap-2">
                                      <div className="flex gap-1">
                                        <div>{item?.nama_rekening || "-"}</div>
                                        <div>-</div>
                                        <div>{item?.no_rekening || "-"}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="font-semibold">
                                    {convertToIDR(
                                      item?.total_nilai_pendapatan || 0
                                    )}
                                  </div>
                                </div>
                                <div className="h-[0.1rem] w-full bg-gray-200" />
                              </div>
                            );
                          })}
                        </div>
                        <div className="bg-gray-400 flex items-center justify-between px-4 py-4 text-lg font-bold text-white">
                          <div>Total</div>
                          <div>
                            {convertToIDR(
                              arrLapDanaKelolaan?.reduce(
                                (acc, curr) =>
                                  acc + curr?.total_nilai_pendapatan,
                                0
                              ) || 0
                            )}
                          </div>
                        </div>
                      </div>
                    </CollapsingCard>
                  )}

                {methods.formState.isSubmitSuccessful &&
                  methods.watch("report_type") === "laporan-pendapatan" && (
                    <>
                      <div className="bg-gray-400 flex items-center justify-between px-4 py-4 text-lg font-bold text-white">
                        <div>Total Pendapatan</div>
                        <div>
                          {convertToIDR(
                            arrLapReconReport?.reduce(
                              (acc, curr) => acc + curr?.value,
                              0
                            ) || 0
                          )}
                        </div>
                      </div>
                      <Table
                        bordered
                        stripped
                        layout="fixed"
                        className="mb-4"
                        columns={tableColumns}
                        dataSource={arrLapReconReport || []}
                        hidePagination
                        // isLoading={isLoading}
                        // onChangePage={setNewPage}
                        // onChangeRowsPerPage={setNewLimit}
                        // pagination={{
                        //   page: list?.currentPage,
                        //   limit: list?.size,
                        //   total: list?.totalItems,
                        //   previous_pages: list?.currentPage > 1,
                        //   next_pages: !(list?.currentPage === list?.totalPages),
                        // }}
                      />
                    </>
                  )}

                {methods.formState.isSubmitSuccessful &&
                  methods.watch("report_type") === "laporan-proyek" && (
                    <CollapsingCard
                      title={`Laporan Rekening Proyek   (${moment(
                        methods.watch("startdate")
                      ).format("DD/MM/YYYY")} - ${moment(
                        methods.watch("enddate")
                      ).format("DD/MM/YYYY")})`}
                    >
                      <div className="flex flex-col gap-8">
                        {arrLapProjectReport.map((item, index) => {
                          return (
                            <>
                              <div key={index} className="space-y-2">
                                <div className="bg-gray-200 px-4 py-2 font-medium">
                                  REKENING DONOR {item?.currency || "-"}
                                </div>
                                {item?.accounts?.map?.((i, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="flex items-center justify-between px-6 py-2 text-sm w-full">
                                        <div className="flex flex-col gap-2">
                                          <div className="flex gap-1">
                                            <div>
                                              {i?.account_number || "-"}
                                            </div>
                                            <div>-</div>
                                            <div>{i?.account_owner || "-"}</div>
                                          </div>
                                        </div>
                                        <div className="font-semibold">
                                          {i?.currency_name}{" "}
                                          {i?.balance?.toLocaleString?.() || 0}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="bg-gray-400 flex items-center justify-between px-4 py-4 text-lg font-bold text-white">
                                <div>Total</div>
                                <div>
                                  {item?.currency}{" "}
                                  {item?.accounts
                                    ?.reduce(
                                      (acc, curr) => acc + curr?.balance,
                                      0
                                    )
                                    ?.toLocaleString?.()}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </CollapsingCard>
                  )}
                {/* <Table
                      bordered
                      stripped
                      layout="fixed"
                      className="mb-4"
                      columns={tableColumns}
                      dataSource={arrLapDanaKelolaan || []}
                      hidePagination
                      // isLoading={isLoading}
                      // onChangePage={setNewPage}
                      // onChangeRowsPerPage={setNewLimit}
                      // pagination={{
                      //   page: list?.currentPage,
                      //   limit: list?.size,
                      //   total: list?.totalItems,
                      //   previous_pages: list?.currentPage > 1,
                      //   next_pages: !(list?.currentPage === list?.totalPages),
                      // }}
                    /> */}
              </>
              {/* {post?.isLoading || !post?.data?.data ? null : post?.data?.data ===
              "neraca" ? ( */}
              {/* <BalanceSheet /> */}
              {/* ) : ( */}
              {/* <FinanceLoss /> */}
              {/* )} */}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FinanceReport;
