import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import { BottomFormAction, Breadcrumbs, TitleText } from "components";
import { CustomSelectForm, InputForm, SelectCurrencyForm } from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useAccountRequestDetail,
  usePutAccountRequest,
} from "hooks/accountRequest";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { getErrorMessage } from "helpers";
import { useBanksQuery } from "hooks/generalSettings";

const AccountRequestForm = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const putAccountRequest = usePutAccountRequest();
  const { data: banks } = useBanksQuery(["banks"]);

  const id = location?.state?.account_id ?? null;
  const isEdit = location?.state?.isEdit ?? false;

  const { data: detail } = useAccountRequestDetail(["ar_detail", id]);

  const links = [
    {
      path: "/authority",
      label: "Daftar Permintaan Rekening",
    },
    {
      label: "Input Rekening",
    },
  ];

  const schema = yup.object().shape({
    donor_name: yup.string().required("Wajib diisi."),
    account_number: yup.string().required("Wajib diisi."),
    currency: yup.mixed().required("Wajib diisi."),
    account_name: yup.string().required("Wajib diisi."),
    bank_name: yup.mixed().required("Wajib diisi."),
    branch_name: yup.string().required("Wajib diisi."),
    notes: yup.string().required("Wajib diisi."),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      donor_name: "",
      program: null,
      account_number: "",
      currency: null,
      account_name: "",
      bank_name: null,
      branch_name: "",
      notes: "",
    },
  });

  useEffect(() => {
    if (detail) {
      methods.reset({
        donor_name: detail?.donor_name,
        program: { value: detail?.program_id, label: detail?.program_name },
        account_number: detail?.account_number,
        currency: { value: detail?.currency, label: detail?.currency },
        account_name: detail?.account_name,
        bank_name: { value: detail?.bank_name, label: detail?.bank_name },
        branch_name: detail?.branch_name,
        notes: detail?.notes,
      });
    }
  }, [detail]);

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = (payload) => {
    console.log(payload)
    if (payload) {
      const params = {
        id,
        payload: {
          donor_id: detail?.donor_id,
          donor_name: payload?.donor_name,
          program_id: payload?.program?.value,
          program_name: payload?.program?.label,
          currency: payload?.currency?.value,
          request_date: null,
          opening_date: null,
          account_number: payload?.account_number,
          account_name: payload?.account_name,
          bank_name: payload?.bank_name?.label,
          branch_name: payload?.branch_name,
          notes: payload?.notes,
          status: 1,
        },
      };
      putAccountRequest.mutate(params, {
        onSuccess: (data, variables) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          queryClient.invalidateQueries("ar");
          navigate("/account-request");
        },
        onError: (error, variables) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    }
  };

  const renderAllModal = () => {
    return null;
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className="h-2" />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Input Rekening</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-4">
          <FormProvider {...methods}>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <InputForm
                  name={`donor_name`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Nama Donor"}
                  required={true}
                  disabled={isEdit}
                />
              </div>
              <div className="col-span-2">
                <CustomSelectForm
                  name={`program`}
                  value={null}
                  optionsData={[]}
                  label="Program"
                  placeholder="Pilih Program"
                />
              </div>
              <div className="my-2 col-span-2 bg-primary-600 rounded px-4 py-2">
                <label className="font-bold text-lg text-white">
                  Dibayarkan Kepada
                </label>
              </div>
              <InputForm
                name={`account_number`}
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Nomor Rekening"}
                required={true}
              />
              <SelectCurrencyForm
                name="currency"
                label="Mata Uang"
                placeholder="Pilih mata uang"
                isClearable
                required={true}
                isDisabled={isEdit}
                onSelect={(v) =>
                  methods.resetField("currency_type", {
                    defaultValue:
                      v.label === "IDR" ? "Local Currency" : "Valuta Asing",
                  })
                }
              />
              <div className="col-span-2">
                <InputForm
                  name={`account_name`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Nama Pemilik Rekening"}
                  required={true}
                />
              </div>
              <CustomSelectForm
                name="bank_name"
                options={banks?.data?.data?.map((bank) => ({
                  label: bank?.name,
                  value: bank?.id,
                }))}
                label="Bank"
                placeholder="Pilih Bank"
              />
              <InputForm
                name={`branch_name`}
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Cabang"}
                required={true}
              />
              <div className="col-span-2">
                <InputForm
                  name={`notes`}
                  type="textarea"
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Keterangan"}
                  required={true}
                />
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
      <div className="h-24" />
      <BottomFormAction
        onCancel={onCancel}
        disabled={putAccountRequest.isLoading}
        isLoading={putAccountRequest.isLoading}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </>
  );
};

export default AccountRequestForm;
