import { Card, CardBody, CardHeader } from "components";
import {
  CustomSelectForm,
  InputForm,
  SelectVendorProcurementForm,
} from "components/HookForm";
import React from "react";
import { useFormContext } from "react-hook-form";

const ThirdParty = ({ disabled }) => {
  const methods = useFormContext();
  return (
    <Card>
      <CardHeader>Pihak Ketiga</CardHeader>
      <CardBody>
        <SelectVendorProcurementForm
          name="thirdparty.vendor_procurement"
          label="Vendor"
          placeholder="Pilih vendor"
          isDisabled={disabled}
        />
        <div className="mt-4 grid grid-cols-3 gap-4">
          <CustomSelectForm
            name={`thirdparty.vendor`}
            options={[
              {
                value: `${Math.floor(Math.random() * 10000000000)}`,
                label: `${Math.floor(Math.random() * 10000000000)}`,
                bank_account_number: Math.floor(Math.random() * 10000000000),
                bank_account_name: `Reynald`,
                bank_name: "BRI",
              },
              {
                value: `${Math.floor(Math.random() * 10000000000)}`,
                label: `${Math.floor(Math.random() * 10000000000)}`,
                bank_account_number: Math.floor(Math.random() * 10000000000),
                bank_account_name: `John`,
                bank_name: "BCA",
              },
              {
                value: `${Math.floor(Math.random() * 10000000000)}`,
                label: `${Math.floor(Math.random() * 10000000000)}`,
                bank_account_number: Math.floor(Math.random() * 10000000000),
                bank_account_name: `Donald`,
                bank_name: "Mandiri",
              },
            ]}
            label="Nomor Rekening"
            placeholder="Pilih nomor rekening"
            isDisabled={disabled}
            onSelect={(value) => {
              methods.resetField("thirdparty.account_name", {
                defaultValue: value?.bank_account_name,
              });
              methods.resetField("thirdparty.bank", {
                defaultValue: value?.bank_name,
              });
            }}
          />
          <InputForm
            disabled
            name={`thirdparty.account_name`}
            label={"Nama Pemilik Rekening"}
            className={`py-2 px-4 border w-full rounded-md`}
          />
          <InputForm
            disabled
            name={`thirdparty.bank`}
            label={"Bank"}
            className={`py-2 px-4 border w-full rounded-md`}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ThirdParty;
