import config from "app/config";
import axiosInstance from "app/interceptors";

export const getCountries = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/master/v1/Negara/list`,
    {
      signal,
    }
  );

  return data?.data;
};

export const getCities = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/master/v1/KabupatenKota/list`,
    {
      signal,
    }
  );

  return data?.data;
};

export const uploadFile = async (payload) => {
  const { data } = await axiosInstance.post(
    `${config.ROOT_BASE_URL}/file-service/v1/files`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const getEmployees = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/user-management/v1/Employee/list`,
    {
      signal,
    }
  );

  return data?.data;
};

export const getTaxes = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/master/v1/JenisPajak/list`,
    {
      params,
      signal,
    }
  );

  return data?.data;
};
