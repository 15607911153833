import moment from "moment";

export const getSubtotal = (qty, value, multiplier = 1) => {
  return parseInt(qty || 0) * parseInt(value || 0) * parseInt(multiplier || 1);
};

export const getNeeds = ({ needs, multiplier = 1, useVolume }) => {
  return needs?.map((need) => ({
    ...need,
    subtotal: getSubtotal(need?.volume, need?.value, multiplier),
    ...(useVolume
      ? { volume: need?.volume }
      : { qty: need?.volume, volume: undefined }),
    sbm: undefined,
    account_code: need?.account_code?.value,
    detail_code: need?.detail_code?.value,
    currency: "IDR",
  }));
};

export const getRealizationNeeds = (need) => {
  return {
    volume: need?.volume || need?.qty,
    realization_volume: need?.realization_volume || need?.realization_qty,
    realization_value: need?.realization_value,
    realization_subtotal: need?.realization_subtotal,
    taxes: need?.taxes?.map((tax) => ({
      tax: {
        label: tax?.tax_name,
        value: tax?.id,
        amount: (tax?.value / need?.realization_subtotal) * 100,
      },
      billing_code: tax?.billing_code,
      value: tax?.value,
    })),
    with_prove: need?.with_prove,
    notes: need?.notes,
  };
};

export const getStatusProperties = (status, role) => {
  let statusProperties;
  switch (role) {
    case "pjk":
      statusProperties = getPjkProperties(status);
      break;
    case "pum":
      statusProperties = getPumProperties(status);
      break;
    default:
      statusProperties = getPjkProperties(status);
      break;
  }

  return statusProperties;
};

const getPjkProperties = (status) => {
  let statusProperties;

  switch (status) {
    case "Baru":
      statusProperties = {
        variant: "blue",
      };
      break;
    case "Sudah diapprove PPSPM":
      statusProperties = {
        variant: "success",
        text: "Selesai",
      };
      break;
    case "UM - Telah dibayar":
    case "Sudah Dibayar Bendahara":
    case "Telah dibayar":
      statusProperties = {
        variant: "success",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }

  return statusProperties;
};

const getPumProperties = (status) => {
  let statusProperties;

  switch (status) {
    case "Baru":
      statusProperties = {
        variant: "warning",
      };
      break;
    case "Sudah diapprove PPSPM":
      statusProperties = {
        variant: "success",
        text: "Selesai",
      };
      break;
    case "UM - Telah dibayar":
    case "Sudah Dibayar Bendahara":
    case "Telah dibayar":
      statusProperties = {
        variant: "success",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }

  return statusProperties;
};

export const getFiscalYear = () => {
  const currentYear = new Date().getFullYear();
  return [
    {
      label: currentYear.toString(),
      value: currentYear,
    },
    {
      label: (currentYear - 1).toString(),
      value: currentYear - 1,
    },
  ];
};

export async function getFileFromUrl(url, name, defaultType = "image/jpeg") {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export const convertToIDR = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number || 0);
};
