import classNames from "classnames";
import { Button, TitleText } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const InformationModal = ({
  title = "",
  open,
  onClose,
  onSubmit,
  children,
  hideHeader = false,
  className = "",
  childClassName = "",
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          {/* <motion.div
            key="modal-confirmation"
            className={`${className ? className : 'bg-white rounded-xl w-1/3'}`}
            initial={{ scale: 0 }}
            animate={{ scale: 1, animation: 10 }}
            exit={{ scale: 0 }}
          > */}
          <div
            key="modal-confirmation"
            className={`${className ? className : "bg-white rounded-xl w-1/3"}`}
          >
            <div className="flex flex-col">
              {!hideHeader && (
                <div className="flex-1 flex items-center justify-between py-4 px-8 border-b">
                  <TitleText className="">{title}</TitleText>
                  <CgClose
                    className="text-xl cursor-pointer"
                    onClick={() => onClose(false)}
                  />
                </div>
              )}
              <div
                className={classNames(
                  "flex-1 max-h-[400px] overflow-y-auto px-8 py-4",
                  childClassName
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default InformationModal;
