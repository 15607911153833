import React from "react";
import classNames from "classnames";

const defaultClassName =
  "py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none";
const errorClassName = "!border-error-500";
const Input = React.forwardRef(
  ({ isError = false, className = "", ...props }, ref) => {
    if (props.type === "textarea") {
      return (
        <textarea
          ref={ref}
          {...props}
          className={classNames(
            ...defaultClassName?.split(" "),
            ...className?.split(" "),
            { [errorClassName]: isError }
          )}
        />
      );
    } else if (props.type === "daterange") {
      return (
        <div className="flex gap-2">
          <input
            type="date"
            value={props?.startDate}
            className={classNames(
              ...defaultClassName.split(" "),
              ...className?.split(" "),
              {
                [errorClassName]: isError,
              }
            )}
            onChange={(e) => props?.setStartDate?.(e.target.value)}
          />
          <input
            type="date"
            value={props?.endDate}
            className={classNames(
              ...defaultClassName.split(" "),
              ...className?.split(" "),
              {
                [errorClassName]: isError,
              }
            )}
            onChange={(e) => props?.setEndDate?.(e.target.value)}
          />
        </div>
      );
    }
    return (
      <input
        ref={ref}
        {...props}
        className={classNames(
          ...defaultClassName?.split(" "),
          ...className?.split(" "),
          { [errorClassName]: isError }
        )}
      />
    );
  }
);

export default Input;
