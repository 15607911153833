import { useQuery, useMutation } from "react-query";
import { AG } from "services";

export const useDirectorate = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return AG.getDirectorate({ ...params }, signal);
    },
    ...options
  });
};

export const useAccountGroup = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return AG.getAccountGroupList({ ...params }, signal);
    },
    ...options
  });
};

export const useAccountGroupDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return AG.getAccountGroupDetail(params);
      return null;
    },
    ...options
  });
};

export const usePostAccountGroup = () => {
  return useMutation((payload) => AG.createAccountGroupData(payload))
}

export const usePutAccountGroup = () => {
  return useMutation((payload) => AG.updateAccountGroupData(payload))
}

export const useDeleteAccountGroup = () => {
  return useMutation((payload) => AG.deleteAccountGroupData(payload))
}