import { Table } from "components";
import { useBudgetDetail } from "hooks/budget";
import moment from "moment";
import React, { useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation } from "react-router";

const Detail = ({ onDetail }) => {
  const location = useLocation();

  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const resource = [
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Menunggu Persetujuan",
    },
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Menunggu Persetujuan Revisi",
    },
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Ditetapkan",
    },
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Ditetapkan",
    },
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Ditetapkan",
    },
    {
      full_code: "015.08.WA",
      uraian: "Program Dukungan Manajemen",
      level: 1,
      total_max_cost: "30,813,683,000",
      outstandingUM: "10,000,000",
      realization_value: "10,000,000",
      remaining_ceiling: "10,000,000",
      status: "Ditetapkan",
    },
  ];

  const { data: detail, isLoading } = useBudgetDetail([
    "mj_detail",
    {
      year: location?.state?.year,
      params: {
        version: 0,
        page: newPage,
        size: newLimit,
        sort: "full_code",
        order: "asc",
      },
    },
  ]);

  const handleBg = (level) => {
    let bg = "";
    switch (level) {
      case "1":
        bg = "bg-[#3AADD9]";
        break;
      case "2":
        bg = "bg-[#46CEAC]";
        break;
      case "3":
        bg = "bg-[#FDCD56]";
        break;
      case "5":
        bg = "bg-[#AC92EA]";
        break;
      case "7":
        bg = "bg-[#9ED26A]";
        break;
      case "8":
        bg = "bg-[#5E9CEA]";
        break;
      case "9":
        bg = "bg-[#E8553E]";
        break;
      default:
        break;
    }
    return bg;
  };

  const tableColumns = [
    {
      id: "full_code",
      title: "Full Code",
      dataIndex: "full_code",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis break-all ${handleBg(
          data?.level
        )}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "budget_name",
      title: "Uraian",
      dataIndex: "budget_name",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "level",
      title: "Level",
      dataIndex: "level",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_max_cost",
      title: "Jumlah Pagu (Rp)",
      dataIndex: "total_max_cost",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "outstandingUM",
      title: "Outstanding UM",
      dataIndex: "outstandingUM",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "realization_value",
      title: "Nilai Realisasi",
      dataIndex: "realization_value",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "remaining_ceiling",
      title: "Sisa Pagu",
      dataIndex: "remaining_ceiling",
      width: 540,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: (value, data) => {
        return `overflow-hidden text-ellipsis ${handleBg(data?.level)}`;
      },
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onDetail(data)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-4">
        <div className="text-sm">
          <label className="font-medium">Tahun Anggaran</label>
          <p>{detail?.year}</p>
        </div>
        <div className="text-sm">
          <label className="font-medium">Tanggal Efektif</label>
          <p>
            {detail?.start_date
              ? moment(detail?.start_date).format("DD/MM/YYYY")
              : "-"}
          </p>
        </div>
        <div className="text-sm">
          <label className="font-medium">Tanggal Berakhir</label>
          <p>
            {detail?.end_date
              ? moment(detail?.end_date).format("DD/MM/YYYY")
              : "-"}
          </p>
        </div>
        <div className="text-sm">
          <label className="font-medium">Versi Revisi</label>
          <p>{detail?.version}</p>
        </div>
      </div>
      <Table
        bordered
        stripped
        layout="fixed"
        className="mb-4"
        columns={tableColumns}
        dataSource={detail?.data?.result}
        isLoading={isLoading}
        onChangePage={setNewPage}
        onChangeRowsPerPage={setNewLimit}
        pagination={{
          page: detail?.data?.currentPage,
          limit: detail?.data?.size,
          total: detail?.data?.totalItems,
          previous_pages: detail?.data?.currentPage > 1,
          next_pages: !(detail?.data?.currentPage === detail?.data?.totalPages),
        }}
      />
    </div>
  );
};
export default Detail;
