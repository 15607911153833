import { yupResolver } from "@hookform/resolvers/yup";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
} from "components/HookForm";
import { useSbmQuery, useUpdateActivityStep1 } from "hooks/downPayment";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { getFiscalYear } from "../util";
import { BottomFormAction } from "components";
import { enqueueSnackbar } from "notistack";
import { FormHelperContext } from ".";

const destinationOptions = [
  { value: "domestic", label: "Dalam Negeri" },
  { value: "international", label: "Luar Negeri" },
];

const InformationForm = () => {
  const schema = yup.object().shape({
    expert: yup.array().min(1, "SBM Wajib Diisi"),
    member: yup.array().min(1, "SBM Wajib Diisi"),
  });

  const { detail, handleNext, setSelectedSbm } = useContext(FormHelperContext);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      expert: [],
      member: [],
    },
  });

  const destinationType = methods.watch("destination_type");
  const [keyword, setKeyword] = useState("");

  const { data: sbm, isLoading: isLoadingSbm } = useSbmQuery([
    "dp-activities",
    {
      page: 1,
      limit: 20,
      search: keyword,
    },
  ]);

  const updateActivityStep1 = useUpdateActivityStep1();

  useEffect(() => {
    if (detail) {
      methods.reset({
        fiscal_year: {
          label: detail?.fiscal_year,
          value: detail?.fiscal_year,
        },
        activity_types: detail?.activity_types?.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
        activity_name: detail?.activity_name ?? "",
        destination_type: detail?.destination_type ?? "",
        start_date: moment(detail?.start_date).toDate(),
        end_date: moment(detail?.end_date).toDate(),
        country: {
          label: detail?.country ?? "Indonesia",
          value: detail?.country ?? "Indonesia",
        },
        city: detail?.city
          ? {
              label: detail?.city,
              value: detail?.city,
            }
          : "",
        destination: {
          label: detail?.destination,
          value: detail?.destination,
        },
        pum: {
          label: `${detail?.pum?.nip} - ${detail?.pum?.name}`,
          value: detail?.pum?.user_id,
        },
        component_code: detail?.component_code,
        assignment_letter_number: detail?.assignment_letter_number,
        assignment_letter_date: moment(detail?.assignment_letter_date).toDate(),
        staffs: detail?.staffs?.map((staff) => ({
          user_id: {
            label: `${staff?.nip} - ${staff?.name}`,
            value: staff?.user_id,
          },
          nip: staff?.nip,
          gender: staff?.gender,
          position: staff?.position,
          division: staff?.division,
        })),
      });
    }
  }, [detail]);

  const handleSearchSbm = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );

  const onSubmit = (values) => {
    const params = {
      expert: values?.expert?.map((expert) => JSON.parse(expert?.value)?.id),
      member: values?.member?.map((member) => JSON.parse(member?.value)?.id),
    };

    updateActivityStep1.mutate(
      { id: detail?.id, payload: params },
      {
        onSuccess: (data) => {
          setSelectedSbm({
            expert: values?.expert,
            member: values?.member,
          });
          handleNext();
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">Informasi Kegiatan</div>
              <div className="flex flex-row gap-4 items-center">
                <CustomSelectForm
                  name="fiscal_year"
                  options={getFiscalYear()}
                  label="Tahun Anggaran"
                  isDisabled
                />
                <CustomSelectForm
                  name="activity_types"
                  options={[]}
                  label="Jenis Kegiatan"
                  isMulti
                  isDisabled
                />
              </div>
              <InputForm
                name="activity_name"
                label="Nama Kegiatan"
                className="w-full"
                disabled
              />
              <RadioGroupForm
                label="Destinasi"
                controllerName="destination_type"
                values={destinationOptions}
                disabled
              />
              {destinationType && (
                <>
                  {destinationType === "domestic" ? (
                    <div className="flex flex-row gap-4 items-center">
                      <CustomSelectForm
                        name="city"
                        options={[]}
                        label="Kota Asal/Keberangkatan"
                        placeholder="Pilih kota"
                        isDisabled
                      />
                      <CustomSelectForm
                        name="destination"
                        options={[]}
                        label="Kota Tujuan Kegiatan"
                        placeholder="Pilih kota"
                        isDisabled
                      />
                    </div>
                  ) : (
                    <div className="flex flex-row gap-4 items-center">
                      <CustomSelectForm
                        name="country"
                        options={[]}
                        label="Negara Asal/Keberangkatan"
                        placeholder="Pilih negara"
                        isDisabled
                      />
                      <CustomSelectForm
                        name="destination"
                        options={[]}
                        label="Negara Tujuan Kegiatan"
                        placeholder="Pilih negara"
                        isDisabled
                      />
                    </div>
                  )}
                </>
              )}
              <div className="flex flex-row gap-4 items-center">
                <InputForm
                  name="assignment_letter_number"
                  label="No Surat Tugas"
                  className="w-full"
                  disabled
                />
                <CustomDatepickerForm
                  name="assignment_letter_date"
                  label="Tanggal Surat Tugas"
                  disabled
                />
              </div>
              <div className="flex flex-row gap-4 items-center">
                <CustomDatepickerForm
                  name="start_date"
                  label="Tanggal Mulai Kegiatan"
                  placeholder={"dd/mm/yyyy"}
                  disabled
                />
                <CustomDatepickerForm
                  name="end_date"
                  label="Tanggal Selesai Kegiatan"
                  placeholder={"dd/mm/yyyy"}
                  disabled
                />
              </div>
              <CustomSelectForm
                name="pum"
                options={[]}
                label="PUM"
                placeholder="Pilih PUM"
                isDisabled
              />
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">
                Kategori SBM yang Digunakan untuk Narasumber
              </div>
              <CustomSelectForm
                name="expert"
                options={sbm?.map((value) => ({
                  label: value?.description,
                  value: JSON.stringify(value),
                }))}
                label="SBM Yang Digunakan Untuk Narasumber"
                onInputChange={(keyword) => {
                  handleSearchSbm(keyword);
                }}
                placeholder="Pilih Kategori SBM"
                isMulti
                isLoading={isLoadingSbm}
              />
            </div>
          </div>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">
                Kategori SBM yang Digunakan untuk Peserta
              </div>
              <CustomSelectForm
                name="member"
                options={sbm?.map((value) => ({
                  label: value?.description,
                  value: JSON.stringify(value),
                }))}
                label="SBM Yang Digunakan Untuk Peserta"
                onInputChange={(keyword) => {
                  handleSearchSbm(keyword);
                }}
                placeholder="Pilih Kategori SBM"
                isMulti
                isLoading={isLoadingSbm}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-24" />
      <BottomFormAction
        onSubmit={methods.handleSubmit(onSubmit)}
        _submitButton={{
          label: "Selanjutnya",
        }}
        _cancelButton={{
          label: "Sebelumnya",
        }}
        hideCancelButton
      />
    </FormProvider>
  );
};

export default InformationForm;
