import { useMutation, useQuery } from "react-query";
import { DP } from "services";

export const useActivityQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DP.getActivities({ ...params }, signal);
    },
    ...options,
  });
};

export const useActivityStatusQuery = () => {
  return useQuery({
    queryKey: ["activity-status"],
    queryFn: ({ signal, queryKey }) => {
      return DP.getActivityStatus(signal);
    },
  });
};

export const useActivityTypes = () => {
  return useQuery({
    queryKey: ["acivity-types"],
    queryFn: ({ signal }) => {
      return DP.getActivityTypes(signal);
    },
  });
};

export const useCreateDownPayment = () => {
  return useMutation((payload) => DP.createDownPayment(payload));
};

export const useUpdateDownPayment = () => {
  return useMutation((payload) => DP.updateDownPayment(payload));
};

export const useDeleteActivity = () => {
  return useMutation((payload) => DP.deleteActivity(payload));
};

export const useActivityDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getActivityDetail(params);
      return null;
    },
    ...options,
  });
};

export const useSbmQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DP.getSbm({ ...params }, signal);
    },
    ...options,
  });
};

export const useGeneralSettingsQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DP.getGeneralSettings({ ...params }, signal);
    },
    ...options,
  });
};

export const useUpdateActivityStep1 = () => {
  return useMutation((payload) => DP.updateActivityStep1(payload));
};

export const useSubmitActivityDetail = () => {
  return useMutation((payload) => DP.submitActivityDetail(payload));
};

export const useApproveActivityDetail = () => {
  return useMutation((payload) => DP.approveActivityDetail(payload));
};

export const useUpdateActivityType = () => {
  return useMutation((payload) => DP.updateActivityType(payload));
};

export const useActivityTypeDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getActivityTypeDetail(params);
      return null;
    },
    ...options,
  });
};

export const useActivityRecapQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getActivityRecap(params);
      return null;
    },
    ...options,
  });
};

export const useUpdateRealization = () => {
  return useMutation((payload) => DP.updateRealization(payload));
};

export const useUpdateParticipant = () => {
  return useMutation((payload) => DP.updateParticipant(payload));
};

export const useRealizationDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getRealizationDetail(params);
      return null;
    },
    ...options,
  });
};

export const useEmployeeQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DP.getEmployees({ ...params }, signal);
    },
    ...options,
  });
};

export const useRealizationRecap = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getRealizationRecap(params);
      return null;
    },
    ...options,
  });
};

export const useRealizationDifference = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getRealizationDifference(params);
      return null;
    },
    ...options,
  });
};

export const useRealizationDocument = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      if (params) return DP.getRealizationDocument(params);
      return null;
    },
    ...options,
  });
};

export const useUploadRealizationDocument = () => {
  return useMutation((payload) => DP.uploadRealizationDocument(payload));
};

export const useSubmitRealization = () => {
  return useMutation((payload) => DP.submitRealization(payload));
};

export const useApproveRealization = () => {
  return useMutation((payload) => DP.approveRealization(payload));
};

export const useValidatePayment = () => {
  return useMutation((payload) => DP.validatePayment(payload));
};