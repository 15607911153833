import { BasicCard, Button, CardBody } from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import { CustomSelectForm, SelectCoaByLevelForm } from "components/HookForm";
import { map } from "lodash";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";

import Participant from "./participant";
import { DEFAULT_VALUE_ACCOUNTS } from "../form";
import Accordion from "components/Accordion";
import { BiTrash } from "react-icons/bi";
import { useLocation } from "react-router";

const NonContractual = ({ disabled }) => {
  const location = useLocation();
  
  const { control, resetField, watch } = useFormContext();
  const {
    fields: accounts,
    append: addAccount,
    remove: removeAccount,
  } = useFieldArray({
    control: control,
    name: "accounts",
  });

  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  return (
    <>
      <BasicCard>
        <CardBody>
          <div className="space-y-4">
            {map(accounts, (account, key) => (
              <Accordion
                key={account?.id}
                label={`Akun Belanja ${key + 1}`}
                renderRight={
                  !isDetail && key > 0
                    ? () => (
                        <Button
                          iconOnly
                          startIcon={<BiTrash size={20} />}
                          className="px-2 mr-2 bg-red-600 border-red-600"
                          onClick={() => removeAccount(key)}
                        />
                      )
                    : null
                }
              >
                <div className="space-y-4">
                  <SelectCoaByLevelForm
                    name={`accounts.${key}.shipping_account`}
                    className="w-full"
                    label="Akun Belanja"
                    placeholder="Pilih akun belanja"
                    level={9}
                    parent_id={watch("component")?.id}
                    menuPortalTarget={document.body}
                    isDisabled={disabled}
                    onSelect={(value) =>
                      resetField(`accounts.${key}.activity_detail`, {
                        defaultValue: null,
                      })
                    }
                  />
                  <SelectCoaByLevelForm
                    name={`accounts.${key}.activity_detail`}
                    className="w-full"
                    label="Detail Kegiatan"
                    placeholder="Pilih detail kegiatan"
                    level={11}
                    isDisabled={
                      !watch(`accounts.${key}.shipping_account`) || disabled
                    }
                    parent_id={watch(`accounts.${key}.shipping_account`)?.id}
                    menuPortalTarget={document.body}
                  />
                  <Participant
                    accountName={`Akun Belanja ${key + 1}`}
                    name={`accounts.${key}`}
                  />
                </div>
              </Accordion>
            ))}
            {
              !isDetail && (
              <div className="flex items-center justify-end">
                <Button
                  startIcon={<FiPlus />}
                  className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
                  onClick={() =>
                    addAccount({
                      shipping_account: null,
                      activity_detail: null,
                      accounts: [],
                    })
                  }
                >
                  Tambah Akun
                </Button>
              </div>
              )
            }
          </div>
        </CardBody>
      </BasicCard>
    </>
  );
};

export default NonContractual;
