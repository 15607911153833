import classNames from "classnames";
import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const Accordion = ({
  children,
  label,
  renderLeft,
  renderRight,
  contentClassName,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="flex flex-col flex-1 border-2 border-gray-200 rounded-xl">
      <div className="flex flex-row justify-between bg-[#E1F1D6] rounded-t-lg py-2 px-4">
        <div className="flex flex-row items-center gap-2">
          <span className="text-primary-600 text-lg font-bold">{label}</span>
          {renderLeft?.()}
        </div>
        <div className="flex flex-row items-center gap-2">
          {renderRight?.()}
          <button
            className="text-primary-600 text-lg font-bold flex flex-row items-center gap-2"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? "Tutup" : "Buka"}
            {isOpen ? <FiChevronUp /> : <FiChevronDown />}
          </button>
        </div>
      </div>
      <div
        className={classNames(
          "p-6 bg-white rounded-xl",
          isOpen ? "block" : "hidden",
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
