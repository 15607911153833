import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs, Button } from "components";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { ConfirmationModal } from "components/Modal";
import { Step, Steps } from "components/Steps";
import {
  useActivityDetailQuery,
  useApproveRealization,
  useRealizationDocument,
  useUploadRealizationDocument,
} from "hooks/downPayment";
import { useUploadFile } from "hooks/externalService";
import { enqueueSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import RejectionModal from "../rejection-modal";
import { getFileFromUrl } from "../util";
import InformationForm from "./information-form";
import { RealizationForm } from "./realization-form";
import { Recap } from "./recap";

export const FormHelperContext = createContext({
  activeStep: 0,
  setActiveStep: () => {},
  handleNext: () => {},
  handlePrevious: () => {},
  detail: null,
  isRealizationCreated: false,
});

const AccountabilityApproval = () => {
  const [activeStep, setActiveStep] = useState(0);
  const params = useParams();

  const { data: detail } = useActivityDetailQuery([
    "dp-detail-pum",
    params?.id,
  ]);

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Lengkapi Rincian Detail Kegiatan",
    },
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const schema = yup.object();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      items: [
        {
          item: "",
        },
      ],
    },
  });

  return (
    <FormHelperContext.Provider
      value={{
        activeStep,
        detail,
        handleNext,
        handlePrevious,
        setActiveStep,
        isRealizationCreated: detail?.realization_status === "created",
      }}
    >
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <Steps activeStep={activeStep}>
          <Step label="Informasi Kegiatan">
            <InformationForm />
          </Step>
          {detail?.activity_types?.map((activityType) => (
            <Step label={activityType?.name} key={activityType?.id}>
              <RealizationForm activityTypeId={activityType?.id} />
            </Step>
          ))}
          <Step label="Rekap">
            <Recap
              detail={detail}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          </Step>
          <Step label="Upload Dokumen Bukti">
            <DocumentUpload />
          </Step>
        </Steps>
      </FormProvider>
      <div className="h-24" />
    </FormHelperContext.Provider>
  );
};

const DocumentUpload = () => {
  const navigate = useNavigate();
  const { detail, handlePrevious, isRealizationCreated } =
    useContext(FormHelperContext);
  const uploadFile = useUploadFile();
  const uploadRealizationDocument = useUploadRealizationDocument();
  const approveRealization = useApproveRealization();
  const { data: document } = useRealizationDocument(
    [
      "dp-realization-document",
      {
        id: detail?.id,
      },
    ],
    {
      enabled: isRealizationCreated,
    }
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const schema = yup.object().shape({
    documents: yup.array().typeError("Wajib diisi").min(1, "Wajib diisi"),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const setDocument = async () => {
    const files = await Promise.all(
      document?.map(async (doc) => {
        const generatedFile = await getFileFromUrl(
          doc?.url,
          doc?.file_name ?? "File"
        );

        return generatedFile;
      })
    );
    methods.reset({
      documents: files,
    });
  };

  useEffect(() => {
    if (isRealizationCreated && document) {
      setDocument();
    }
  }, [document]);

  const onSubmit = async (payload) => {
    try {
      const formData = new FormData();
      formData.append("file", payload?.documents[0]);

      const uploadData = await uploadFile.mutateAsync(formData);

      const params = {
        documents: [
          {
            file_id: uploadData?.data?.id,
            file_name: uploadData?.data?.name,
            file_size: uploadData?.data?.size,
            mime_type: uploadData?.data?.mimeType,
            path: uploadData?.data?.key,
          },
        ],
      };

      uploadRealizationDocument.mutate(
        { id: detail?.id, payload: params },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const onApproveRealization = (payload) => {
    try {
      const params = {
        approved: !payload?.reject_reason,
        reject_reason: payload?.reject_reason ?? "",
      };

      approveRealization.mutate(
        { id: detail?.id, payload: params },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setIsOpen(false);
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const renderModal = () => (
    <>
      <RejectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={onApproveRealization}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        onSubmit={onApproveRealization}
        title="Setujui Detail Rencana Kegiatan"
        message="Anda yakin akan menyetujui Detail Rencana Kegiatan ini?"
      />
    </>
  );

  return (
    <FormProvider {...methods}>
      {renderModal()}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-4">
          <div className="space-y-4">
            <div className="text-lg font-semibold">Dokumen Pendukung</div>

            <div>
              {!isRealizationCreated ? (
                <FilePickerForm name="documents" />
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {document?.map((doc) => (
                    <FilePickerForm
                      name="documents"
                      file={doc}
                      readOnly
                      key={doc?.file_id}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomFormAction
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={handlePrevious}
        _submitButton={{
          label: "Simpan",
        }}
        _cancelButton={{
          label: "Sebelumnya",
        }}
        renderAction={
          isRealizationCreated
            ? () => (
                <div className="grid grid-cols-2 gap-2">
                  <Button
                    className="bg-error-600 border-error-600 gap-2"
                    startIcon={<RiCloseCircleLine size={18} />}
                    onClick={() => setIsOpen(true)}
                  >
                    Tolak
                  </Button>
                  <Button
                    className="bg-success-500 border-success-500 gap-2"
                    startIcon={<RiCheckboxCircleLine size={18} />}
                    onClick={() => setIsOpenConfirmation(true)}
                  >
                    Setujui
                  </Button>
                </div>
              )
            : undefined
        }
      />
    </FormProvider>
  );
};

export default AccountabilityApproval;
