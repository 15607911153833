import { useQuery, useMutation } from "react-query";
import { RECONCILIATION } from "services";

export const useReconciliation = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RECONCILIATION.getReconciliationList({ ...params }, signal);
    },
    ...options
  });
};

export const useReconList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RECONCILIATION.getReconList({ ...params }, signal);
    },
    ...options
  });
};

export const useReconDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return RECONCILIATION.getReconDetail(params);
      return null;
    },
    ...options
  });
}

export const useReconDetailTrx = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RECONCILIATION.getReconDetailTrx(params);
    },
    ...options
  });
}

export const usePostRecon = () => {
  return useMutation((payload) => RECONCILIATION.postRecon(payload))
}

export const useReconciliationDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return RECONCILIATION.getReconciliationDetail(params);
      return null;
    },
    ...options
  });
};

export const useReconciliationTrxList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RECONCILIATION.getReconciliationTrxList({ ...params }, signal);
    },
    ...options
  });
};

export const usePostReconciliation = () => {
  return useMutation((payload) => RECONCILIATION.createReconciliationData(payload))
}

export const usePutReconciliation = () => {
  return useMutation((payload) => RECONCILIATION.updateReconciliationData(payload))
}

export const useDeleteReconciliation = () => {
  return useMutation((payload) => RECONCILIATION.deleteReconciliationData(payload))
}