import { Input } from "components";
import { useFormContext, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const NumberInputForm = ({ name, label, required, ...props }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({
        fieldState: { invalid, error },
        field: { value, onChange },
      }) => {
        return (
          <div className="w-full space-y-2">
            <label className="flex gap-1 font-semibold text-[14px]">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
            <NumericFormat
              value={value}
              customInput={Input}
              allowLeadingZeros
              thousandSeparator="."
              decimalSeparator=","
              isError={invalid}
              displayType="input"
              fixedDecimalScale={true}
              decimalScale={2}
              onValueChange={(target) => {
                onChange(target.floatValue);
              }}
              {...props}
            />
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default NumberInputForm;
