import { Breadcrumbs } from "components";
import { useFundReceiptDetailQuery } from "hooks/cash";
import moment from "moment";
import { convertToIDR } from "pages/DownPayment/util";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { fundTypeMap } from "services/cash";

const DetailItem = ({ title, value }) => (
  <div className="space-y-2 flex flex-col">
    <span className="font-bold text-sm">{title}</span>
    <span className="text-sm">{value}</span>
  </div>
);

const CashReceivingDetail = () => {
  const { id } = useParams();
  const links = [
    {
      label: "Daftar Mekanisme Penerimaan Uang Masuk",
      path: "/payment-request",
    },
    {
      label: "Detail",
    },
  ];

  const { data: detail } = useFundReceiptDetailQuery([
    "fund-receipt-detail",
    {
      id,
    },
  ]);

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <div className="space-y-4">
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
          <div className="card-body p-4 space-y-4">
            <div className="text-lg font-semibold mb-3">Detail</div>
            <div className="grid grid-cols-3 gap-8">
              <DetailItem
                title="Jenis Transaksi"
                value={fundTypeMap[detail?.type]}
              />
              <DetailItem
                title="Tanggal Transaksi"
                value={moment(detail?.transaction_date).format("DD/MM/YYYY")}
              />
              <DetailItem title="Nominal" value={convertToIDR(detail?.value)} />
              <DetailItem
                title="Nomor Rekening"
                value={detail?.bank_account?.number}
              />
              <DetailItem title="Akun" value={detail?.account_name} />
              <div></div>
              <DetailItem title="Uraian" value={detail?.description} />
              <div></div>
              <div></div>
              <DetailItem title="User" value={detail?.bank_account?.name} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashReceivingDetail;
