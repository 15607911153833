import axios from "axios";
import config from 'app/config';
import axiosInstance from "app/interceptors";

export const getWriteOffRequest = async (params, signal) => {
    const { data } = await axios.get(`${config.BASE_URL}/debtor-financing`, {
      params,
      signal,
    });
  
    return data;
};

export const getWriteOffRequestDetail = async (id) => {
    const { data } = await axiosInstance.get(`${config.BASE_URL}/debtor-financing/${id}`);
    return data;
}

export const putWriteOffRequest = async (options) => {
    const { data } = await axiosInstance.put(`${config.BASE_URL}/debtor-financing/${options.id}`, options.payload);
    return data;
}