import { useMutation, useQuery } from "react-query";
import { PARTICIPANT } from "services";

export const useParticipantQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PARTICIPANT.getParticipants({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreateParticipant = () => {
  return useMutation((payload) => PARTICIPANT.createParticipant(payload));
};
