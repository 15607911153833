import { Button, Input } from "components";
import Accordion from "components/Accordion";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectAccountForm,
} from "components/HookForm";
import { useTaxesQuery } from "hooks/externalService";
import { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { NumericFormat } from "react-number-format";
import { FormHelperContext } from ".";
import { convertToIDR } from "../util";

const documentStatusOptions = [
  { value: true, label: "Dengan Bukti" },
  { value: false, label: "Tanpa Bukti" },
];

export const RealizationNeedsForm = ({ fieldName, defaultValues, type }) => {
  const { isRealizationCreated } = useContext(FormHelperContext);
  const methods = useFormContext();
  const { fields, replace, append } = useFieldArray({
    control: methods.control,
    name: fieldName,
  });

  useEffect(() => {
    if (fields?.length === 0) {
      replace(defaultValues);
    }
  }, []);

  return (
    <Accordion label="Daftar Item Kebutuhan" contentClassName="space-y-4">
      {fields.map((data, indexNeed) => {
        return (
          <>
            <div
              className="flex flex-row border-b-[1px] border-gray-200"
              key={data?.id}
            >
              <div className="space-y-3  py-4 flex-1">
                {type === "item" ? (
                  <InputForm
                    name={`${fieldName}.${indexNeed}.item_name`}
                    label="Nama Barang"
                    className="w-full"
                    placeholder="Masukkan Nama Barang"
                    disabled={!data?.isNew}
                  />
                ) : (
                  <>
                    <InputForm
                      name={`${fieldName}.${indexNeed}.sbm`}
                      label="Item Kebutuhan"
                      className="w-full"
                      disabled
                    />
                    <InputForm
                      name={`${fieldName}.${indexNeed}.sbm_id`}
                      type="hidden"
                      disabled
                    />
                  </>
                )}
                {data?.isNew ? (
                  <>
                    <SelectAccountForm
                      label="Akun Belanja"
                      placeholder="Pilih Akun"
                      name={`dummyItems.${indexNeed}.account_code_select`}
                      level="9"
                      enabled={type === "item"}
                      onSelect={(value) => {
                        methods.setValue(
                          `${fieldName}.${indexNeed}.account_code`,
                          value?.value
                        );
                      }}
                    />
                    <SelectAccountForm
                      label="Akun Belanja"
                      placeholder="Pilih Akun"
                      name={`dummyItems.${indexNeed}.detail_code_select`}
                      level="11"
                      enabled={type === "item"}
                      parentId={
                        methods.watch(
                          `dummyItems.${indexNeed}.account_code_select`
                        )?.id
                      }
                      isDisabled={
                        !methods.watch(
                          `dummyItems.${indexNeed}.account_code_select`
                        )?.id
                      }
                      onSelect={(value) => {
                        methods.setValue(
                          `${fieldName}.${indexNeed}.detail_code`,
                          value?.value
                        );
                      }}
                    />
                    <InputForm
                      name={`${fieldName}.${indexNeed}.account_code`}
                      className="w-full p-0 m-0"
                      type="hidden"
                    />
                    <InputForm
                      name={`${fieldName}.${indexNeed}.detail_code`}
                      className="w-full p-0 m-0"
                      type="hidden"
                    />
                  </>
                ) : (
                  <>
                    <InputForm
                      name={`${fieldName}.${indexNeed}.account_code`}
                      label="Akun Belanja"
                      className="w-full"
                      disabled
                    />
                    <InputForm
                      name={`${fieldName}.${indexNeed}.detail_code`}
                      label="Detail Kegiatan"
                      className="w-full"
                      disabled
                    />
                  </>
                )}

                <div className="grid grid-cols-3 gap-2">
                  <NumberInputForm
                    label="Nilai SBM (Rp)"
                    name={`${fieldName}.${indexNeed}.value`}
                    disabled={!data?.isNew}
                    className="w-full"
                    placeholder="0"
                  />
                  <NumberInputForm
                    name={`${fieldName}.${indexNeed}.volume`}
                    label="Qty Di RAB"
                    className="w-full"
                    placeholder="0"
                    disabled={!data?.isNew}
                  />

                  <NumberInputForm
                    name={`${fieldName}.${indexNeed}.subtotal`}
                    label="Subtotal RAB (Rp)"
                    className="w-full"
                    placeholder="0"
                    value={
                      methods.watch(`${fieldName}.${indexNeed}.value`) *
                      methods.watch(`${fieldName}.${indexNeed}.volume`)
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="bg-[#F9FCF6] p-4 rounded-lg mt-6">
              <div className="space-y-3">
                <span className="span text-primary-600 font-bold text-lg">
                  Realisasi
                </span>
                <div className="grid grid-cols-3 gap-2">
                  <NumberInputForm
                    name={`${fieldName}.${indexNeed}.realization_volume`}
                    label="Qty Realisasi"
                    className="w-full"
                    placeholder="0"
                    disabled={type !== "item" || isRealizationCreated}
                  />
                  <NumberInputForm
                    name={`${fieldName}.${indexNeed}.realization_value`}
                    label="Nilai Realisasi Per Qty (Rp)"
                    className="w-full"
                    placeholder="0"
                    disabled={isRealizationCreated && !data?.isNew}
                  />
                  <NumberInputForm
                    name={`${fieldName}.${indexNeed}.realization_subtotal`}
                    label="Sub Total Realisasi (Rp)"
                    className="w-full"
                    placeholder="0"
                    value={
                      methods.watch(
                        `${fieldName}.${indexNeed}.realization_volume`
                      ) *
                      methods.watch(
                        `${fieldName}.${indexNeed}.realization_value`
                      )
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="space-y-3 mt-4">
                <TaxForm fieldName={fieldName} indexNeed={indexNeed} />

                <RadioGroupForm
                  label="Status Dokumen Bukti"
                  controllerName={`${fieldName}.${indexNeed}.with_prove`}
                  values={documentStatusOptions}
                  disabled={isRealizationCreated && !data?.isNew}
                />
                <InputForm
                  name={`${fieldName}.${indexNeed}.notes`}
                  label="Keterangan"
                  className="w-full"
                  type="textarea"
                  disabled={isRealizationCreated && !data?.isNew}
                />
              </div>
            </div>
          </>
        );
      })}
      {type === "item" && !isRealizationCreated && (
        <div className="flex flex-row justify-end">
          <Button
            startIcon={<FiPlus />}
            onClick={() =>
              append({
                isNew: true,
              })
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 mt-4"
          >
            Tambah Item Kebutuhan
          </Button>
        </div>
      )}
    </Accordion>
  );
};

export const TaxForm = ({ fieldName, indexNeed }) => {
  const { isRealizationCreated } = useContext(FormHelperContext);
  const methods = useFormContext();
  const {
    fields: taxesField,
    remove: removeTax,
    append: appendTax,
    replace: replaceTax,
  } = useFieldArray({
    control: methods.control,
    name: `${fieldName}.${indexNeed}.taxes`,
  });

  useEffect(() => {
    if (taxesField?.length === 0) {
      replaceTax([
        {
          tax: null,
          billing_code: "",
          value: 0,
        },
      ]);
    }
  }, []);

  const { data: taxes } = useTaxesQuery(["dp-realization-taxes"]);

  const getTaxAmount = (amount) => amount / 100 || 0;

  return (
    <>
      <span className="span text-primary-600 font-bold text-lg">Pajak</span>
      {taxesField?.map((field, index) => (
        <div className="flex flex-row items-end gap-4" key={field.id}>
          <CustomSelectForm
            name={`${fieldName}.${indexNeed}.taxes.${index}.tax`}
            options={taxes?.map((tax) => ({
              label: tax?.nama,
              value: tax?.id,
              amount: tax?.value,
            }))}
            label="Pajak"
            placeholder="Pajak"
            isDisabled={isRealizationCreated}
          />
          <InputForm
            name={`${fieldName}.${indexNeed}.taxes.${index}.billing_code`}
            label="Kode Billing"
            placeholder="Masukkan kode billing"
            className="w-full"
            disabled={isRealizationCreated}
          />
          <NumberInputForm
            name={`${fieldName}.${indexNeed}.taxes.${index}.value`}
            label="Nilai Pajak (Rp)"
            className="w-full"
            placeholder="0"
            value={
              methods.watch(`${fieldName}.${indexNeed}.realization_volume`) *
                methods.watch(`${fieldName}.${indexNeed}.realization_value`) *
                getTaxAmount(
                  methods.watch(`${fieldName}.${indexNeed}.taxes.${index}.tax`)
                    ?.amount
                ) || 0
            }
            disabled
          />
          {!isRealizationCreated && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeTax(index)}
            />
          )}
        </div>
      ))}

      <div className="flex flex-1 flex-col gap-4 items-end border-y-[1px] border-gray-100 mt-4 py-4">
        {!isRealizationCreated && (
          <Button
            onClick={() => {
              appendTax({
                tax_id: "",
                tax: null,
                billing_code: "",
                value: 0,
              });
            }}
            className="bg-white border-gray-600 text-gray-600 hover:text-white"
            startIcon={<BiPlus />}
          >
            Tambah Pajak
          </Button>
        )}
        <div className="space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Total Pajak (Rp)</span>
          </label>
          <NumericFormat
            value={methods
              .watch(`${fieldName}.${indexNeed}.taxes`)
              ?.reduce((acc, o) => acc + o?.value, 0)}
            placeholder="0"
            className="w-full"
            customInput={Input}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            disabled
          />
        </div>
        <span className="text-sm inline-block font-semibold bg-primary-50 text-primary-600 rounded-xl px-3 py-1">
          Total Realisasi (Rp):{" "}
          <span className="font-bold">
            {convertToIDR(
              methods
                .watch(`${fieldName}.${indexNeed}.taxes`)
                ?.reduce((acc, o) => acc + o?.value, 0) +
                methods.watch(`${fieldName}.${indexNeed}.realization_volume`) *
                  methods.watch(
                    `${fieldName}.${indexNeed}.realization_value`
                  ) || 0
            )}
          </span>
        </span>
      </div>
    </>
  );
};
