import { useQuery, useMutation } from "react-query";
import { COA_PROJECT } from "services";

export const useCoaProject = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COA_PROJECT.getCoaProjectList({ ...params }, signal);
    },
    ...options,
  });
};

export const useCoaProjectDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COA_PROJECT.getCoaProjectDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const useCoaProjectUpdate = () => {
  return useMutation((payload) => COA_PROJECT.updateCoaProject(payload));
};
