import { Button } from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectSbmForm,
} from "components/HookForm";
import { map, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";

import Tax from "./tax";
import Accordion from "components/Accordion";
import ParticipantFormModal from "pages/DownPayment/AccountabilityApproval/participant-form-modal";
import { useLocation } from "react-router";
import { BiTrash } from "react-icons/bi";

const Participant = ({ accountName, name }) => {
  const location = useLocation();

  const { control, watch, resetField } = useFormContext();

  const [isOpenParticipant, setIsOpenParticipant] = useState(false);

  const {
    fields: details,
    append: addDetail,
    remove: removeDetail,
  } = useFieldArray({
    control: control,
    name: `${name}.details`,
  });

  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  useEffect(() => {
    !isDetail && resetField("total_trx_value", {
      defaultValue: sumBy(watch("accounts"), (account) =>
        account.details?.length > 0
          ? sumBy(
              account.details,
              (detail) =>
                parseFloat(detail.sub_total ? detail.sub_total : 0) +
                parseFloat(
                  detail.taxes?.reduce(
                    (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                    0
                  )
                )
            )
          : 0
      ),
    });
  }, [
    sumBy(watch("accounts"), (account) =>
      sumBy(
        account.details,
        (detail) =>
          parseFloat(detail.sub_total) +
          parseFloat(detail.taxes?.reduce((acc, o) => acc + o?.value, 0))
      )
    ),
  ]);

  return (
    <div>
      <ParticipantFormModal
        isOpen={isOpenParticipant}
        onClose={() => {
          setIsOpenParticipant(false);
        }}
        type={
          watch("trx_type")?.value === 1
            ? "employee"
            : watch("trx_type")?.value === 2
            ? "invite"
            : "expert"
        }
        onSubmit={(value) => {
          addDetail({
            detailInfo: value,
            name: value?.name,
            is_ministry_employee: false,
            requirement_item: null,
            qty: "",
            value: "",
            sub_total: "",
            taxes: [
              {
                tax: null,
                billing_code: "",
                value: "",
              },
            ],
            total_taxes: "",
            account_number: value?.bank_account_number,
            account_name: value?.bank_account_name,
            bank: value?.bank_name,
            total: "",
          });
        }}
      />
      <div className="space-y-4">
        {map(details, (user, key) => (
          <React.Fragment key={user.id}>
            <Accordion
              label={user?.name}
              renderRight={
                !isDetail
                  ? () => (
                      <Button
                        iconOnly
                        startIcon={<BiTrash size={20} />}
                        className="px-2 mr-2 bg-red-600 border-red-600"
                        onClick={() => removeDetail(key)}
                      />
                    )
                  : null
              }
            >
              <div className="space-y-4">
                <RadioGroupForm
                  label={"Apakah orang ini termasuk pegawai kementrian?"}
                  controllerName={`${name}.details.${key}.is_ministry_employee`}
                  values={[
                    {
                      label: "Ya",
                      value: true,
                    },
                    {
                      label: "Tidak",
                      value: false,
                    },
                  ]}
                />
                <Accordion label="Daftar Item Kebutuhan">
                  <div className="space-y-4">
                    <SelectSbmForm
                      name={`${name}.details.${key}.requirement_item`}
                      type={"perjadin"}
                      label="Item Kebutuhan"
                      placeholder="Pilih item kebutuhan"
                      onSelect={(value) => {
                        let qty = watch(`${name}.details.${key}.qty`);
                        qty = qty ? qty : 0;
                        resetField(`${name}.details.${key}.sub_total`, {
                          defaultValue: qty
                            ? parseFloat(qty) * parseFloat(value?.sbm_value)
                            : parseFloat(value?.sbm_value),
                        });
                        resetField(`${name}.details.${key}.value`, {
                          defaultValue: value?.sbm_value,
                        });
                      }}
                    />
                    <div className="grid grid-cols-3 gap-4">
                      <NumberInputForm
                        name={`${name}.details.${key}.qty`}
                        label={"Qty (Jam/ Hari/ Bulan)"}
                        className={`py-2 px-4 border w-full rounded-md`}
                        onValueChanged={(value) => {
                          value = value ? value : 0;
                          resetField(`${name}.details.${key}.sub_total`, {
                            defaultValue: value
                              ? parseFloat(value) *
                                parseFloat(
                                  watch(`${name}.details.${key}.value`)
                                )
                              : parseFloat(
                                  watch(`${name}.details.${key}.value`)
                                ),
                          });
                        }}
                      />
                      <NumberInputForm
                        disabled
                        name={`${name}.details.${key}.value`}
                        label={"Nilai (Rp)"}
                        className={`py-2 px-4 border w-full rounded-md`}
                      />
                      <NumberInputForm
                        disabled
                        name={`${name}.details.${key}.sub_total`}
                        label={"Sub Total (Rp)"}
                        className={`py-2 px-4 border w-full rounded-md`}
                      />
                    </div>
                    <div className="h-[1px] w-full bg-gray-400" />
                    <Tax name={`${name}.details.${key}`} />
                  </div>
                </Accordion>
              </div>
            </Accordion>
          </React.Fragment>
        ))}
        {details?.length > 0 && (
          <div>
            <div className="flex items-center justify-between bg-gray-200 px-4 py-2 rounded">
              <div>{accountName}</div>
              <div>
                total:{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(
                  sumBy(
                    watch(`${name}.details`),
                    (user) =>
                      parseFloat(user.sub_total ? user.sub_total : 0) +
                      parseFloat(
                        user.taxes?.reduce(
                          (acc, o) =>
                            (acc ? acc : 0) + (o?.value ? o?.value : 0),
                          0
                        )
                      )
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {
          !isDetail && (
            <div className="flex items-center justify-center">
              <Button
                startIcon={<FiPlus />}
                onClick={() => setIsOpenParticipant(true)}
              >
                Tambah{" "}
                {watch("trx_type")?.value === 1
                  ? "Pegawai"
                  : watch("trx_type")?.value === 2
                  ? "Undangan"
                  : "Narasumber"}
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Participant;
