import { NumberInputForm, SelectProjectAccountForm } from "components/HookForm";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const TransferOfFunds = () => {
  const { resetField, watch } = useFormContext();

  useEffect(() => {
    if (
      parseFloat(watch("nominal_transfer")) >
      parseFloat(watch("account_number")?.balance)
    ) {
      resetField("nominal_transfer", {
        defaultValue: watch("account_number")?.balance,
      });
    }
  }, [watch("nominal_transfer")]);
  
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-3">
        <SelectProjectAccountForm
          name="account_number"
          label="Nomor Rekening Sumber"
          placeholder="Pilih nomor rekening sumber"
          isClearable
          required={true}
        />
      </div>
      <NumberInputForm
        name={`nominal_transfer`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Nominal Pemindahan"}
        required={true}
      />
      <div className="col-span-3">
        <SelectProjectAccountForm
          name="target_account_number"
          label="Nomor Rekening Tujuan"
          placeholder="Pilih nomor rekening tujuan"
          isClearable
          required={true}
        />
      </div>
    </div>
  );
};
export default TransferOfFunds;
