import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import moment from "moment";

import { useReconciliation } from "hooks/reconciliation";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import ImportModal from "./import-modal";
import { useNavigate } from "react-router";

const ReconciliationList = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const [open, setOpen] = useState(false);

  const links = [
    {
      label: "Daftar Rekonsialisasi",
    },
  ];

  const filters = useForm({ mode: "onChange" });

  const { data: list, isLoading } = useReconciliation([
    "reconciliation",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "uploadDate",
      title: "Tanggal Upload",
      dataIndex: "uploadDate",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "bank",
      title: "Bank",
      dataIndex: "bank",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "period",
      title: "Periode Transaksi yang di Rekon",
      dataIndex: "period",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {`${
            data?.rekonStartPeriod
              ? moment(data?.rekonStartPeriod).format("DD/MM/YYYY")
              : ""
          } ${data?.rekonEndPeriod ? "-" : ""} ${
            data?.rekonEndPeriod
              ? moment(data?.rekonEndPeriod).format("DD/MM/YYYY")
              : ""
          }`}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => (
        <div className="flex items-center justify-end mr-4">Aksi</div>
      ),
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center justify-end w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer"
            size={20}
            onClick={() => navigate("/reconciliation")}
          />
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-32 btn-sm"
            onClick={() =>
              navigate("/reconciliation", { state: { isPost: true } })
            }
          >
            Posting
          </Button>
        </div>
      ),
    },
  ];

  const renderAllModal = () => {
    <></>;
  };

  return (
    <>
      <ImportModal open={open} setOpen={setOpen} />
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Rekonsialisasi"}</TitleText>
        <Button
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          onClick={() => setOpen(!open)}
        >
          Import Bank Statement
        </Button>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <FormProvider {...filters}>
              <div className="space-x-5 flex items-center">
                <InputForm
                  name="search"
                  className="flex-1 w-full"
                  placeholder="Cari"
                />
                <CustomDatepickerForm
                  name="date"
                  className="flex-1"
                  placeholder="dd/mm/yyyy"
                />
              </div>
            </FormProvider>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconciliationList;
