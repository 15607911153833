import config from "app/config";
import axiosInstance from "app/interceptors";

const dummyRes = {
  totalItems: 1,
  size: 5,
  result: [
    {
      id: 25,
      coa_id: "2e82924f-6622-46df-be06-846a19d268d2",
      coa_name: "Belanja Perjalanan",
      coa_code: "A",
      code: "525115",
      level: "8",
      full_code: "A.525115",
      coa_parent_id: "f49e5b90-14d6-4980-8dc2-154a6ae986ab",
      account_type: null,
      position: "-",
      createdAt: "2024-01-13T13:40:18.232Z",
      updatedAt: "2024-02-06T15:54:40.963Z",
    },
  ],
  totalPages: 1,
  currentPage: 1,
};

export const getCoaProjectList = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/coa-project/items`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const getCoaProjectDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/coa-project/${params?.id}`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const updateCoaProject = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/coa-project/${params?.id}`,
    params?.payload
  );
  return data;
};
