import { InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";

const RejectionModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <FormModal
      title="Tolak"
      open={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <div className="space-y-3 py-4">
        <InputForm
          name="reject_reason"
          label="Alasan Penolakan"
          type="textarea"
          placeholder="Catatan"
          className="w-full"
        />
      </div>
    </FormModal>
  );
};

export default RejectionModal;
