import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs, Button } from "components";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { Step, Steps } from "components/Steps";
import {
  useActivityDetailQuery,
  useApproveActivityDetail,
} from "hooks/downPayment";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { FormHelperContext } from "../ActivityDetailPum";
import { RecapTab } from "../ActivityDetailPum/recap";
import RejectionModal from "../rejection-modal";
import { ConfirmationModal } from "components/Modal";

const VerificationRequest = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: detail } = useActivityDetailQuery([
    "dp-detail-pum",
    params?.id,
  ]);

  const approval = useApproveActivityDetail();

  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Verifikasi Pengajuan Uang Muka",
    },
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const schema = yup.object();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handleApproval = (payload) => {
    approval.mutate(
      {
        id: detail?.id,
        payload: {
          approved: !payload?.reject_reason,
          reject_reason: payload?.reject_reason ?? "",
        },
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const renderModal = () => (
    <>
      <RejectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={handleApproval}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        onSubmit={handleApproval}
        title="Setujui Detail Rencana Kegiatan"
        message="Anda yakin akan menyetujui Detail Rencana Kegiatan ini?"
      />
    </>
  );

  return (
    <>
      {renderModal()}
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormHelperContext.Provider
        value={{
          activeStep,
          detail,
          handleNext,
          handlePrevious,
          setActiveStep,
        }}
      >
        <FormProvider {...methods}>
          <Steps activeStep={activeStep}>
            <Step label="Dokumen Pendukung">
              <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
                <div className="card-body p-4 space-y-4">
                  <div className="space-y-4">
                    <div className="text-lg font-semibold">
                      Dokumen Pendukung
                    </div>

                    <div>
                      <span className="font-semibold text-sm">
                        Dokumen pendukung
                      </span>
                      <div className="grid grid-cols-2 gap-4">
                        <FilePickerForm
                          name="job_document"
                          file={detail?.assignment_document}
                          readOnly
                        />
                        <FilePickerForm
                          name="job_document"
                          file={detail?.additional_documents?.[0]}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Step>
            {detail?.activity_types?.map((activityType) => (
              <Step label={activityType?.name} key={activityType?.id}>
                <RecapTab activityType={activityType} showMembers />
              </Step>
            ))}
          </Steps>
          <div className="h-24"></div>
          <BottomFormAction
            onCancel={handlePrevious}
            onSubmit={handleNext}
            _submitButton={{
              label: "Selanjutnya",
            }}
            _cancelButton={{
              label: "Sebelumnya",
            }}
            renderAction={() => {
              if (activeStep === detail?.activity_types?.length) {
                return (
                  <div className="grid grid-cols-2 gap-2">
                    <Button
                      className="bg-error-600 border-error-600 gap-2"
                      startIcon={<RiCloseCircleLine size={18} />}
                      onClick={() => setIsOpen(true)}
                    >
                      Tolak
                    </Button>
                    <Button
                      className="bg-success-500 border-success-500 gap-2"
                      startIcon={<RiCheckboxCircleLine size={18} />}
                      onClick={() => setIsOpenConfirmation(true)}
                    >
                      Setujui
                    </Button>
                  </div>
                );
              }

              return undefined;
            }}
          />
        </FormProvider>
      </FormHelperContext.Provider>
    </>
  );
};

export default VerificationRequest;
