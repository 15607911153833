import { Button, CustomSelect, Input } from "components";
import {
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
} from "components/HookForm";
import {
  useChartOfAccount,
  useChartOfAccountDetail,
} from "hooks/chartOfAccount";
import { find, findIndex, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import ChildCoaForm from "./ChildCoaForm";

const levelOptions = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 11,
    label: 11,
  },
];

const accountTypeOptions = [
  { value: "100", label: "Aset" },
  { value: "200", label: "Liabilitis" },
  { value: "300", label: "Equality" },
  { value: "400", label: "Pendapatan" },
  { value: "500", label: "Belanja / Beban" },
];

const positionOptions = [
  { value: "000", label: "Laporan Laba Rugi" },
  { value: "999", label: "Neraca" },
];

const Form = ({ id }) => {
  const methods = useFormContext();

  const [coaOptions, setCoaOptions] = useState([]);

  const [newPageChild, setNewPageChild] = useState(1);
  const [newLimitChild, setNewLimitChild] = useState(10);

  const { data: coa_options, isFetching, refetch } = useChartOfAccount(["coa"]);
  const { data: detail } = useChartOfAccountDetail(["coa", id]);
  const { data: childData, isFetching: fetchingChild } = useChartOfAccount([
    "coa-child",
    { parent_id: detail?.coa_id, page: newPageChild, size: newLimitChild },
  ]);

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "accounts",
  });

  useEffect(() => {
    return () => {
      methods.reset({
        is_parent: false,
        parent_account: null,
        parent_level: null,
        new_level: null,
        account_type: null,
        account_code: "",
        new_account_name: "",
        accounts: [{ account_code: "", account_name: "", full_code: "" }],
      });
    };
  }, []);

  useEffect(() => {
    if (childData?.result?.length > 0) {
      methods.setValue(
        "accounts",
        map(childData?.result ?? [], (result) => ({
          account_code: result?.code,
          account_name: result?.coa_name,
          full_code: result?.full_code,
          coa_id: result?.coa_id,
        }))
      );
    }
  }, [fetchingChild]);

  useEffect(() => {
    if (coa_options) {
      setCoaOptions(
        map(coa_options, (data) => ({
          value: data?.coa_id,
          label: `${data?.full_code} - ${data?.coa_name}`,
          level: data?.level ? parseInt(data?.level) : null,
          full_code: data?.full_code,
          code: data?.code,
        }))
      );
    }
  }, [coa_options]);

  useEffect(() => {
    if (detail) {
      methods.reset({
        is_parent: parseInt(detail?.level) === 1 ? true : false,
        parent_account: {
          value: detail?.coa_id,
          label: `${detail?.full_code} - ${detail?.coa_name}`,
          level: detail?.level ? parseInt(detail?.level) : null,
          code: detail?.code,
        },
        parent_level: {
          value: parseInt(detail?.level),
          label: parseInt(detail?.level),
        },
        new_account_level: {
          value: parseInt(detail?.level) + 1,
          label: parseInt(detail?.level) + 1,
        },
        account_type: find(accountTypeOptions, { value: detail?.account_type }),
        position: find(positionOptions, { value: detail?.position })?.value,
      });
    }
  }, [detail]);

  return (
    <div className="flex flex-col gap-4">
      <RadioGroupForm
        label={
          "apakah posisi akun baru ini ada di “parent” paling tinggi/ paling atas?"
        }
        controllerName={`is_parent`}
        values={[
          {
            label: "Ya",
            value: true,
          },
          {
            label: "Tidak",
            value: false,
          },
        ]}
      />
      {!methods.watch("is_parent") && (
        <CustomSelectForm
          name={`parent_account`}
          options={coaOptions}
          isLoading={isFetching}
          label="Pilih Akun Parent Untuk Akun Yang Baru"
          onSelect={(params) => {
            methods.resetField("parent_level", {
              defaultValue: {
                value: parseInt(params?.level),
                label: parseInt(params?.level),
              },
            });
            methods.resetField("new_account_level", {
              defaultValue: {
                value: parseInt(params?.level) + 1,
                label: parseInt(params?.level) + 1,
              },
            });
          }}
        />
      )}
      {!methods.watch("is_parent") && (
        <CustomSelectForm
          name={`parent_level`}
          options={levelOptions}
          label={
            !methods.watch("is_parent")
              ? "Level Akun Parent"
              : "Pilih Akun Parent Untuk Akun Yang Baru"
          }
          isDisabled={true}
          placeholder={
            !methods.watch("is_parent")
              ? "Level Akun Parent"
              : "Pilih Akun Parent Untuk Akun Yang Baru"
          }
          required
        />
      )}
      <div className="border-t w-full border-gray-400" />
      <CustomSelectForm
        name={`new_account_level`}
        options={
          methods.watch("parent_level")?.value
            ? levelOptions.slice(
                findIndex(
                  levelOptions,
                  (opt) => opt?.value === methods.watch("parent_level")?.value
                ) + 1,
                levelOptions.length
              )
            : levelOptions
        }
        label={!methods.watch("is_parent") ? "Level Akun Baru" : "Level Akun"}
        placeholder={
          !methods.watch("is_parent") ? "Level Akun Baru" : "Level Akun"
        }
        isDisabled={true}
        required
      />
      <CustomSelectForm
        name={`account_type`}
        options={accountTypeOptions}
        label="Jenis Akun"
      />
      <div className={"flex-1"}>
        <RadioGroupForm
          label={"Posisi"}
          controllerName={`position`}
          values={positionOptions}
        />
      </div>
      {map(fields, (field, key) => (
        <div key={field?.id} className="flex items-end flex-1 gap-4">
          <ChildCoaForm name={`accounts.${key}`} />
          {key === fields?.length - 1 && (
            <Button
              onClick={() =>
                key === fields?.length - 1
                  ? append({
                      account_code: "",
                      account_name: "",
                      full_code: "",
                    })
                  : remove(key)
              }
              className={
                key === fields?.length - 1
                  ? "text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 h-[40px] min-h-[40px] w-[40px] p-0 flex-none"
                  : "text-white border-error-600 bg-error-600 hover:bg-error-700 hover:border-error-700 h-[40px] min-h-[40px] w-[40px] p-0 flex-none"
              }
            >
              {key === fields?.length - 1 ? <FiPlus /> : <BiTrash />}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Form;
