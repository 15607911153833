import { InputForm } from "components/HookForm";
import { useCoaProjectDetail } from "hooks/coaProject";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const Form = ({ id }) => {
  const methods = useFormContext();
  const { data } = useCoaProjectDetail(["coa-project-detail", { id }]);

  useEffect(() => {
    if (data) {
      methods.resetField("code", { defaultValue: data?.coa_code });
      methods.resetField("full_code", { defaultValue: data?.full_code });
    }
  }, [data]);
  return (
    <div className="flex flex-col gap-4">
      <InputForm
        name={`code`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Kode"}
        required={true}
      />
      <InputForm
        name={`full_code`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Fullcode"}
        required={true}
      />
    </div>
  );
};

export default Form;
