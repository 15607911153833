import config from "app/config";
import axiosInstance from "app/interceptors";

// Payment

export const getPaymentDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/payment/apbn/${params?.id}`,
    {
      params,
      signal,
    }
  );

  return data?.result;
};

export const createPayment = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/payment/apbn/${params?.id}`,
    params?.payload
  );
  return data;
};

export const getPaymentRequests = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/payment/apbn`, {
    params,
    signal,
  });

  return data;
};

export const getExpense = async (params, signal) => {
  const data = new Promise((resolve) => {
    resolve({
      status: 200,
      message: "OK",
      result: Array.from({ length: 5 }).map((_, index) => ({
        id: index,
        request_date: new Date(),
        payment_type:
          index > 2 ? "Pembayaran dana program" : "Pembayaran uang muka",
        amount: 10000000,
        requester: "John Doe",
        payment_date: new Date(),
        status: {
          step: 1,
          action: index > 2 ? "pay" : "view",
          description: index > 2 ? "Menunggu Pembayaran" : "Telah Dibayar",
        },
        account_name: "Akun A",
        account_number: 1234567890,
      })),
      paginator: {
        itemCount: 1,
        limit: 10,
        pageCount: 1,
        page: 1,
        hasPrevPage: false,
        hasNextPage: true,
        prevPage: null,
        nextPage: null,
      },
    });
  });
  return data;
};

export const getFundReceipt = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/fund-receipt`, {
    params,
    signal,
  });

  return data;
};

export const getFundReceiptDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/fund-receipt/${params?.id}`,
    {
      params,
      signal,
    }
  );

  return data?.result;
};

export const createFundReceipt = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/fund-receipt`,
    params
  );
  return data;
};

export const fundTypeMap = {
  treasury: "Uang Masuk Bendahara Penerimaan",
  managed: "Uang Masuk Dana Kelolaan",
};

export const createFundReceiptPosting = async (id) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/fund-receipt/${id}/posting`
  );
  return data;
};

// Cash Transfer

export const getCashTransfer = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/cash-transfer`, {
    params,
    signal,
  });

  return data;
};

export const getCashTransferDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/cash-transfer/${params?.id}`,
    {
      params,
      signal,
    }
  );

  return data?.result;
};

export const createCashTransfer = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/cash-transfer/${params?.type}`,
    params?.payload
  );
  return data;
};

export const cashTransferMap = {
  "operational-expense": "Operasional Pengeluaran",
  "operational-receipt": "Operasional Penerimaan",
  "managed": "Dana Kelolaan",
};

export const createCashTransferPosting = async (id) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/cash-transfer/${id}/posting`
  );
  return data;
};

// Direct Cash Transfer

export const getDirectCashTransfer = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/direct-cash-transfer`,
    {
      params,
      signal,
    }
  );

  return data;
};

export const getDirectCashTransferDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/direct-cash-transfer/${params?.id}`,
    {
      params,
      signal,
    }
  );

  return data?.result;
};

export const createDirectCashTransfer = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/direct-cash-transfer`,
    params
  );
  return data;
};

export const directCashTransferMap = {
  withdraw: "Tarik",
  store: "Setor",
};

export const createDirectCashTransferPosting = async (id) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/direct-cash-transfer/${id}/posting`
  );
  return data;
};

export const getPaymentFDB = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/payment-inquiries`, {
    params,
    signal,
  });

  return data;
};

export const getPaymentFDBDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/payment-inquiries/${params?.id}`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const getDebtorFDBDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/payment-inquiries/${params?.id}/debtors`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const postTransactionFDB = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/payment-inquiries/${params.id}/add-transaction`,
    params?.payload
  );
  return data;
};
