import {
  BasicCard,
  BottomFormAction,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
} from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
  SelectChartOfAccountForm,
} from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { find, map, sum, sumBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useCreateContractual,
  useSubmissionDetailQuery,
  useUpdateContractual,
} from "hooks/directTransaction";
import { NumericFormat } from "react-number-format";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { usePutWriteOffMutation, useWriteOffDetailQuery } from "hooks/writeOff";
import { CgSpinner } from "react-icons/cg";

const WriteOffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const [isLoading, setIsLoading] = useState(false);

  const isEditing = !!params?.id;

  const links = [
    {
      label: "Daftar Pengajuan Hapus Bukuan Tidak Hapus Tagih",
      path: "/write-off-request",
    },
    {
      label: "Detail Hapus Bukuan",
    },
  ];

  const paymentTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Kontraktual" },
      { value: 2, label: "Pembayaran Non Kontraktual" },
      { value: 3, label: "Pembayaran Vendor" },
    ],
    []
  );

  const trxTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Perjadin Pegawai" },
      { value: 2, label: "Pembayaran Undangan" },
      { value: 3, label: "Pembayaran Narasumber" },
    ],
    []
  );

  const { data: detail } = useWriteOffDetailQuery(
    ["write-off-detail", { id: params?.id }],
    { enabled: true }
  );

  const putWriteOfff = usePutWriteOffMutation();

  const onSubmit = (id, payload) => {
    putWriteOfff.mutate(
      { payload: payload, id },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const methods = useForm({
    defaultValues: {
      // description: "",
      // transaction_date: "",
      // invoice_number: "",
      // component: "",
      // total_trx_value: "",
      // thirdparty: {
      //   vendor_procurement: null,
      //   vendor: null,
      //   account_name: "",
      //   bank: "",
      // },
      // accounts: [],
    },
  });

  const [catatan, setCatatan] = useState("");

  let documents = detail?.documents?.map((doc) => {
    return {
      file_name: doc.name,
      file_size: doc.size,
      url: doc.url,
    };
  });

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">Hapus Bukuan</div>
              <CustomDatepickerForm
                name="createdAt"
                label="Tanggal Permintaan"
                placeholder={"dd/mm/yyyy"}
                disabled
                value={moment(detail?.createdAt).format("DD/MM/YYYY")}
              />
              <div className="text-lg font-semibold mb-3">
                Data Permintaan Hapus Bukuan
              </div>
              <div className={`grid grid-cols-1 ${"md:grid-cols-2"} gap-4`}>
                <InputForm
                  name={`nap`}
                  label={"NAP"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="NAP"
                  disabled
                  value={detail?.nap}
                />
                <InputForm
                  name={`debtor_name`}
                  label={"Nama Debitur"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="Nama Debitur"
                  disabled
                  value={detail?.debtor_name}
                />
              </div>
              <div className={`grid grid-cols-1 ${"md:grid-cols-2"} gap-4`}>
                <InputForm
                  name={`principal_arrears_balance`}
                  label={"Sisa Saldo Pokok"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="Sisa Saldo Pokok"
                  disabled
                  value={detail?.principal_arrears_balance}
                />
                <InputForm
                  name={`interest_arrears_balance`}
                  label={"Sisa Saldo Hutang"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="Sisa Saldo Hutang"
                  disabled
                  value={detail?.interest_arrears_balance}
                />
              </div>
              {/* <div className="space-y-2">
                  <label className="flex gap-1 font-semibold text-[14px]">
                    <span className={`label-text`}>Total Nilai Transaksi</span>
                  </label>
                  <NumericFormat
                    value={sumBy(methods.watch("accounts"), (account) =>
                      sumBy(
                        account.activity_details,
                        (activity_detail) =>
                          parseFloat(activity_detail.unit_cost) *
                            (parseFloat(activity_detail.volume) +
                              parseFloat(activity_detail.qty)) -
                          parseFloat(
                            activity_detail.taxes?.reduce(
                              (acc, o) => acc + o?.value,
                              0
                            )
                          )
                      )
                    )}
                    placeholder="0"
                    className="w-full"
                    customInput={Input}
                    allowLeadingZeros
                    thousandSeparator="."
                    decimalSeparator=","
                    disabled
                  />
                </div> */}
            </div>
          </div>

          <Card>
            <CardHeader>Dokumen Bukti Pendukung</CardHeader>
            <CardBody>
              {!documents?.length ? (
                <div className="text-center">Tidak ada dokumen</div>
              ) : (
                <FilePickerForm
                  name="supporting_document"
                  file={documents}
                  readOnly
                  disabled
                />
              )}
                  <hr
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                />
             
                <InputForm
                  name={`catatan`}
                  label={"Catatan"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="Catatan"
                  type="textarea"
                  value={action === 'hapus-bukuan' ? catatan : detail?.write_off_notes}
                  disabled={action === "hapus-bukuan" ? false : true}
                  onChange={(e) => setCatatan(e.target.value)}
                />
            </CardBody>
          </Card>
          {action && action === "hapus-bukuan" && (
            <div className="card py-4 w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
              <div className="flex-1 flex items-center space-x-4 px-8">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  disabled={isLoading}
                  onClick={() => navigate("/write-off-request")}
                >
                  Batal
                </Button>
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 space-x-1"
                  disabled={putWriteOfff.isLoading}
                  onClick={() =>
                    onSubmit(params?.id, {
                      write_off_notes: catatan,
                    })
                  }
                >
                  <span>{"Submit"}</span>
                  {putWriteOfff.isLoading && (
                    <CgSpinner className="animate-spin" />
                  )}
                </Button>
              </div>
            </div>
          )}

        </div>
      </FormProvider>
    </>
  );
};

export default WriteOffDetail;
