import { FiPlus } from "react-icons/fi";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import {
  useCreateDirectCashTransferPosting,
  useDirectCashTransferQuery,
} from "hooks/cash";
import { debounce } from "lodash";
import moment from "moment";
import { convertToIDR } from "pages/DownPayment/util";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { directCashTransferMap } from "services/cash";
import { enqueueSnackbar } from "notistack";
import CustomDatepickerForm from "components/CustomDatepicker";
import { useSelector } from "react-redux";

const CashFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const createPosting = useCreateDirectCashTransferPosting();

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [openPosting, setOpenPosting] = useState(false);
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const { data: expenses, refetch } = useDirectCashTransferQuery([
    "direct-cash-transfer-list",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      is_draft: statusFilter?.value,
      type: typeFilter?.value,
      startDate: dateFilter
        ? moment(dateFilter).format("YYYY-MM-DD")
        : undefined,
      endDate: dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : undefined,
    },
  ]);

  const links = [
    {
      label: "Daftar Pemindahan Kas Tunai",
    },
  ];

  const renderStatusAction = (data) => {
    if (data?.is_draft) {
      return (
        <Button
          onClick={() => {
            setOpenPosting(true);
            methods.reset({
              id: data?.id,
              transaction_date: moment(data?.transaction_date).format(
                "DD/MM/YYYY"
              ),
              type: directCashTransferMap[data?.type],
              account_number: data?.bank_account?.number,
              value: data?.value,
            });
          }}
          className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
        >
          Posting
        </Button>
      );
    }

    return (
      <HiOutlineEye
        className="text-gray-600 cursor-pointer flex-1"
        size={20}
        onClick={() => navigate(`/cash-flow/${data?.id}`)}
      />
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "transaction_date",
      title: "Tanggal Transaksi",
      dataIndex: "transaction_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "type",
      title: "Kategori Kas",
      dataIndex: "type",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {directCashTransferMap[value] ?? "-"}
        </div>
      ),
    },
    {
      id: "account_number",
      title: "Nomor Rekening",
      dataIndex: "bank_account",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value?.number ?? "-"}
        </div>
      ),
    },
    // {
    //   id: "account_name",
    //   title: "Akun",
    //   dataIndex: "account_name",
    //   width: 540,
    //   className: "overflow-hidden text-ellipsis",
    //   sortable: true,
    //   render: (value) => (
    //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
    //       {value ?? "-"}
    //     </div>
    //   ),
    // },

    {
      id: "amount",
      title: "Nominal Pemindahan",
      dataIndex: "value",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
    // {
    //   id: "status",
    //   title: "Status",
    //   dataIndex: "is_draft",
    //   width: 200,
    //   className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
    //   columnClassName: "right-[181px] block shadow-lg",
    //   fixed: "right",
    //   sortable: true,
    //   render: (value) => {
    //     return (
    //       <div className="space-x-2 flex items-center w-full min-h-12">
    //         <StatusBadge
    //           status={value ? "Belum Diposting" : "Sudah Diposting"}
    //           role={role}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const methods = useForm();

  const onPosting = (payload) => {
    createPosting.mutate(payload?.id, {
      onSuccess: (data) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        refetch();
        setOpenPosting(false);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error?.message ?? "Error",
          variant: "error",
        });
        setOpenPosting(false);
      },
    });
  };

  return (
    <>
      <FormModal
        title="Anda yakin akan memposting transaksi berikut ini?"
        className="bg-white rounded-xl w-1/3"
        open={openPosting}
        onClose={() => {
          setOpenPosting(false);
          methods.reset();
        }}
        onSubmit={methods.handleSubmit(onPosting)}
      >
        <FormProvider {...methods}>
          <div className="space-y-4">
            <InputForm
              label="Tanggal Transaksi"
              name="transaction_date"
              className="w-full"
              disabled
            />
            <InputForm
              label="Kategori Kas"
              name="type"
              className="w-full"
              disabled
            />
            <InputForm
              label="Nominal"
              name="value"
              className="w-full"
              disabled
            />
            <InputForm
              label="Nomor Rekening"
              name="account_number"
              className="w-full"
              disabled
            />
            <InputForm
              label="Uraian"
              name="description"
              className="w-full"
              disabled
              type="textarea"
            />
            <InputForm
              label="User"
              value="Ari Nugraha"
              name="date"
              className="w-full"
              disabled
            />
          </div>
        </FormProvider>
      </FormModal>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Pemindahan Kas Tunai</TitleText>
        {roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() => navigate("/cash-flow/create")}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah Transaksi
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[81vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              <CustomSelect
                value={typeFilter}
                optionsData={Object.keys(directCashTransferMap).map((type) => ({
                  label: directCashTransferMap[type],
                  value: type,
                }))}
                label="Jenis Transaksi"
                placeholder="Jenis Transaksi"
                className="w-1/6"
                onChange={(value) => {
                  setTypeFilter(value);
                }}
              />
              {/* <CustomSelect
                value={statusFilter}
                optionsData={[
                  {
                    label: "Sudah Diposting",
                    value: false,
                  },
                  {
                    label: "Belum Diposting",
                    value: true,
                  },
                ]}
                label="Status"
                placeholder="Status"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              /> */}
              {/* <Input className="flex-1" placeholder="dd/mm/yyyy" /> */}
              <div className="flex-1">
                <CustomDatepickerForm
                  name={`start_date`}
                  placeholder={"dd/mm/yyyy"}
                  onChange={setDateFilter}
                  value={dateFilter}
                />
              </div>
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={expenses?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: expenses?.paginator?.page,
                limit: expenses?.paginator?.limit,
                total: expenses?.paginator?.itemCount,
                previous_pages: expenses?.paginator?.page > 1,
                next_pages: !(
                  expenses?.paginator?.page === expenses?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CashFlow;
