import { Button, Input } from "components";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
} from "components/HookForm";
import { useTaxesQuery } from "hooks/externalService";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { NumericFormat } from "react-number-format";

const Tax = ({ name, disabled }) => {
  const methods = useFormContext();
  const {
    fields: taxesField,
    remove: removeTax,
    append: appendTax,
    replace: replaceTax,
  } = useFieldArray({
    control: methods.control,
    name: `${name}.taxes`,
  });

  useEffect(() => {
    if (taxesField?.length === 0) {
      replaceTax([
        {
          tax: null,
          billing_code: "",
          value: 0,
        },
      ]);
    }
  }, []);

  const { data: taxes } = useTaxesQuery(["dt-realization-taxes"]);

  const getTaxAmount = (name) =>
    taxes?.find((tax) => tax?.nama === name)?.value / 100 || 0;

  return (
    <>
      <span className="span text-primary-600 font-bold text-lg">Pajak</span>
      {taxesField?.map((field, index) => (
        <div className="flex flex-row items-end gap-4" key={field.id}>
          <CustomSelectForm
            name={`${name}.taxes.${index}.tax`}
            options={taxes?.map((tax) => ({
              label: tax?.nama,
              value: tax?.id,
            }))}
            label="Pajak"
            placeholder="Pajak"
            isDisabled={disabled}
          />
          <InputForm
            name={`${name}.taxes.${index}.billing_code`}
            label="Kode Billing"
            placeholder="Masukkan kode billing"
            className="w-full"
            disabled={disabled}
          />
          <NumberInputForm
            name={`${name}.taxes.${index}.value`}
            label="Nilai Pajak (Rp)"
            className="w-full"
            placeholder="0"
            value={
              parseFloat(methods.watch(`${name}.sub_total`)) *
                parseFloat(methods.watch(`${name}.qty`)) *
                getTaxAmount(
                  methods.watch(`${name}.taxes.${index}.tax`)?.label
                ) || 0
            }
            disabled
          />
          {index > 0 && !disabled && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeTax(index)}
            />
          )}
        </div>
      ))}

      <div className="flex flex-1 flex-col gap-4 items-end border-y-[1px] border-gray-100 mt-4 py-4">
        {!disabled && (
          <Button
            onClick={() => {
              appendTax({
                tax_id: "",
                tax: null,
                billing_code: "",
                value: 0,
              });
            }}
            className="bg-white border-gray-600 text-gray-600 hover:text-white"
            startIcon={<BiPlus />}
          >
            Tambah Pajak
          </Button>
        )}
        <div className="space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Total Pajak (Rp)</span>
          </label>
          <NumericFormat
            value={methods
              .watch(`${name}.taxes`)
              ?.reduce((acc, o) => acc + o?.value, 0)}
            placeholder="0"
            className="w-full"
            customInput={Input}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            disabled
          />
        </div>
        <span className="text-sm inline-block font-semibold bg-primary-50 text-primary-600 rounded-xl px-3 py-1">
          Total Realisasi (Rp):{" "}
          <span className="font-bold">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(
              parseFloat(
                methods.watch(`${name}.sub_total`)
                  ? methods.watch(`${name}.sub_total`)
                  : 0
              ) +
                parseFloat(
                  methods
                    .watch(`${name}.taxes`)
                    ?.reduce(
                      (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                      0
                    )
                )
            )}
          </span>
        </span>
      </div>
    </>
  );
};

export default Tax;
