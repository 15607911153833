import { useMutation, useQuery } from "react-query";
import { PA } from "services";

export const useProjectAccountsQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PA.getProjectAccounts({ ...params }, signal);
    },
    ...options,
  });
};

export const useProjectAccountTrxHistoriesQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PA.getProjectAccountTrxHistory({ ...params }, signal);
    },
    ...options,
  });
};

export const useProjectAccountTrxHistoryDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PA.getProjectAccountTrxHistoryDetail(params, signal);
    },
    ...options,
  });
};

export const useProjectAccountDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PA.getProjectAccountDetail(params, signal);
    },
    ...options,
  });
};

export const useCurrencyListQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PA.getCurrencyList({ ...params }, signal);
    },
    ...options,
  });
};

export const useProjectAccountPost = () => {
  return useMutation((payload) => PA.postProjectAccount(payload))
}

export const useProjectAccountTrxInput = () => {
  return useMutation((payload) => PA.postProjectAccountTrx(payload))
}