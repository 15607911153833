import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction } from "components";
import Accordion from "components/Accordion";
import { InputForm, NumberInputForm } from "components/HookForm";
import { Tab, Tabs } from "components/Tabs";
import {
  useActivityTypeDetailQuery,
  useUpdateActivityType,
} from "hooks/downPayment";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { FormHelperContext } from ".";
import { convertToIDR, getNeeds, getSubtotal } from "../util";
import { RABNeedsForm } from "./rab-needs-form";

export const RABForm = ({ activityTypeId }) => {
  const { detail, selectedSbm, handleNext, handlePrevious } =
    useContext(FormHelperContext);

  const needSchema = yup.array().of(
    yup.object({
      account_code: yup.object({
        label: yup.string().required("Akun belanja wajib diisi"),
        value: yup.string().required("Akun belanja wajib diisi"),
      }),
      detail_code: yup.object({
        label: yup.string().required("Detail kegiatan wajib diisi"),
        value: yup.string().required("Akun belanja wajib diisi"),
      }),
      volume: yup
        .number()
        .typeError("Volume/Qty wajib diisi")
        .required("Volume/Qty wajib diisi")
        .positive("Masukkan angka yang valid"),
    })
  );

  const schema = yup.object().shape({
    expert: yup.object({
      total: yup
        .number()
        .typeError("Jumlah narasumber wajib diisi")
        .required("Jumlah narasumber wajib diisi")
        .positive("Masukkan angka yang valid"),
      needs: needSchema,
    }),
    invite: yup.object({
      total: yup
        .number()
        .typeError("Jumlah wajib diisi")
        .required("Jumlah wajib diisi")
        .positive("Masukkan angka yang valid"),
      needs: needSchema,
    }),
    staff: yup.array().of(
      yup.object({
        needs: needSchema,
      })
    ),
    item: needSchema,
  });

  const { data: activityTypeDetail } = useActivityTypeDetailQuery([
    "dp-activity-type-detail",
    {
      id: detail?.id,
      activityTypeId,
    },
  ]);

  const updateActivityType = useUpdateActivityType();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      expert: null,
      staff: [],
      invite: null,
      item: [],
    },
  });

  const { fields: staff, replace: replaceStaff } = useFieldArray({
    control: methods.control,
    name: "staff",
  });

  useEffect(() => {
    replaceStaff(
      activityTypeDetail?.member?.staff?.map((value) => {
        return {
          user_id: value?.user?.user_id,
          name: value?.user?.name,
          needs: selectedSbm?.member?.map((value) => {
            const sbmData = JSON.parse(value?.value);

            return {
              sbm_id: sbmData?.id,
              sbm: value,
              value: sbmData?.value,
            };
          }),
        };
      })
    );
  }, [detail, activityTypeDetail]);

  const expertSbm = useMemo(
    () =>
      selectedSbm?.expert?.map((value) => {
        const sbmData = JSON.parse(value?.value);
        return {
          sbm_id: sbmData?.id,
          sbm: value,
          value: sbmData?.value,
        };
      }),
    [selectedSbm]
  );

  const staffSbm = useMemo(
    () =>
      selectedSbm?.member?.map((value) => {
        const sbmData = JSON.parse(value?.value);

        return {
          sbm_id: sbmData?.id,
          sbm: value,
          value: sbmData?.value,
        };
      }),
    [selectedSbm]
  );

  const onSubmit = (values) => {
    const params = {
      expert: {
        total: parseInt(values?.expert?.total ?? ""),
        needs: getNeeds({
          needs: values?.expert?.needs,
          useVolume: true,
          multiplier: values?.expert?.total,
        }),
      },
      member: {
        staff: values?.staff?.map((staff) => ({
          ...staff,
          needs: getNeeds({ needs: staff?.needs, useVolume: false }),
          name: undefined,
        })),
        invite: {
          total: parseInt(values?.invite?.total ?? ""),
          needs: getNeeds({
            needs: values?.invite?.needs,
            useVolume: true,
            multiplier: values?.invite?.total,
          }),
        },
      },
      item: getNeeds({ needs: values?.item, useVolume: false }),
    };

    updateActivityType.mutate(
      { id: detail?.id, activityTypeId, payload: params },
      {
        onSuccess: (data) => {
          methods.reset({
            expert: null,
            staff: [],
            invite: null,
            item: [],
          });
          handleNext();
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <Tabs key={activityTypeId}>
        <Tab label="RAB Narasumber">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="border-b-[1px] border-gray-100 pb-4">
                <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                  Estimasi Kebutuhan:{" "}
                  <span className="font-bold">
                    {convertToIDR(
                      methods
                        .watch("expert.needs")
                        ?.reduce(
                          (acc, o) =>
                            acc +
                            getSubtotal(
                              o?.volume,
                              o?.value,
                              methods.watch("expert.total")
                            ),
                          0
                        )
                    )}
                  </span>
                </span>
              </div>
              <NumberInputForm
                name="expert.total"
                label="Jumlah Narasumber"
                className="w-full"
                placeholder="Masukkan jumlah narasumber"
              />

              <RABNeedsForm
                fieldName="expert.needs"
                defaultValues={expertSbm}
                totalMultiplier={methods.watch("expert.total")}
              />
            </div>
          </div>
        </Tab>
        <Tab label="RAB Peserta">
          <div className="space-y-3">
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-4 space-y-4">
                <div className="border-b-[1px] border-gray-100 pb-4 flex flex-row gap-2 items-center">
                  <span className="font-bold text-lg">Peserta Pegawai</span>
                  <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                    Estimasi Kebutuhan:{" "}
                    <span className="font-bold">
                      {convertToIDR(
                        methods
                          .watch("staff")
                          .reduce(
                            (sum, staff) =>
                              sum +
                              staff?.needs.reduce(
                                (subtotalSum, need) =>
                                  subtotalSum +
                                  getSubtotal(need?.volume, need?.value),
                                0
                              ),
                            0
                          )
                      )}
                    </span>
                  </span>
                </div>
                {staff?.map((data, index) => (
                  <Accordion
                    key={data?.id}
                    label={data?.name ?? ""}
                    // renderRight={() => (
                    //   <Button
                    //     className="bg-white text-gray-700 hover:text-white border-gray-300 mr-2"
                    //     size="sm"
                    //     startIcon={<BiPencil />}
                    //   >
                    //     Ubah Pegawai
                    //   </Button>
                    // )}
                  >
                    <RABNeedsForm
                      fieldName={`staff.${index}.needs`}
                      defaultValues={staffSbm}
                      volumeLabel="Qty"
                      key={data?.id}
                    />
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-4 space-y-4">
                <div className="border-b-[1px] border-gray-100 pb-4 flex flex-row gap-2 items-center">
                  <span className="font-bold text-lg">Peserta Undangan</span>
                  <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                    Estimasi Kebutuhan:{" "}
                    <span className="font-bold">
                      {convertToIDR(
                        methods
                          .watch("invite.needs")
                          ?.reduce(
                            (acc, o) =>
                              acc +
                              getSubtotal(
                                o?.volume,
                                o?.value,
                                methods.watch("invite.total")
                              ),
                            0
                          )
                      )}
                    </span>
                  </span>
                </div>
                <InputForm
                  name="invite.total"
                  label="Jumlah"
                  className="w-full"
                  placeholder="Masukkan jumlah"
                />
                <RABNeedsForm
                  fieldName="invite.needs"
                  defaultValues={staffSbm}
                  totalMultiplier={methods.watch("invite.total")}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab label="RAB Barang">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="border-b-[1px] border-gray-100 pb-4">
                <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                  Estimasi Kebutuhan:{" "}
                  <span className="font-bold">
                    {convertToIDR(
                      methods
                        .watch("item")
                        ?.reduce(
                          (acc, o) => acc + getSubtotal(o?.volume, o?.value),
                          0
                        )
                    )}
                  </span>
                </span>
              </div>
              <RABNeedsForm
                fieldName="item"
                defaultValues={[]}
                type="item"
                volumeLabel="Qty"
              />
            </div>
          </div>
        </Tab>
      </Tabs>
      <div className="h-24" />
      <BottomFormAction
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={handlePrevious}
        _submitButton={{
          label: "Selanjutnya",
        }}
        _cancelButton={{
          label: "Sebelumnya",
        }}
      />
    </FormProvider>
  );
};
