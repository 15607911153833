import React, { useCallback, useRef, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FiCheckCircle } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, map } from "lodash";

import {
  useChartOfAccount,
  useDeleteChartOfAccount,
  useImportChartOfAccount,
  useUpdateChartOfAccount,
} from "hooks/chartOfAccount";

import {
  ConfirmationModal,
  FormModal,
  InformationModal,
} from "components/Modal";
import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import Form from "./form";
import { enqueueSnackbar } from "notistack";

import TemplateCoa from "app/coa-template.xlsx";
import Detail from "./detail";
import { CgSpinner } from "react-icons/cg";
import { useLocation, useOutletContext } from "react-router";

const ChartOfAccount = () => {
  const importRef = useRef();

  const importCoa = useImportChartOfAccount();
  const deleteCoa = useDeleteChartOfAccount();

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);
  const [openPopupForm, setOpenPopupForm] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const links = [
    {
      label: "Chart of Account",
    },
  ];

  const {
    data: list,
    isLoading,
    refetch,
  } = useChartOfAccount([
    "coa",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
      sort: "full_code",
      order: "asc",
    },
  ]);

  const update = useUpdateChartOfAccount();

  const schema = yup.object().shape({
    is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_parent: false,
      parent_account: null,
      parent_level: null,
      new_level: null,
      account_type: null,
      account_code: "",
      new_account_name: "",
      accounts: [{ account_code: "", account_name: "", full_code: "" }],
    },
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onTriggerDetail = (data) => {
    setOpenDetail(true);
    setSelectedData(data);
  };

  const onTriggerEdit = (data) => {
    setOpenPopupForm(true);
    setSelectedData(data);
    methods.reset();
  };

  const onTriggerImport = () => {
    importRef?.current?.click();
  };

  const onHandleImport = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        file.type.includes("application/vnd.ms-excel")
      ) {
        const formData = new FormData();
        formData.append("file", file);

        importCoa.mutate(formData, {
          onSuccess: (data, variables) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setTimeout(() => {
              refetch();
            }, 100);
          },
          onError: (error, variables) => {
            //
          },
        });
      } else {
        enqueueSnackbar({
          message: "Ekstensi file tidak valid",
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Something went wrong", variant: "error" });
    }
  };

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = async () => {
    try {
      const res = await deleteCoa.mutateAsync(
        { id: selectedData?.coa_id },
        {
          onSuccess: () => {},
        }
      );
      setSelectedData(null);
      setOpenPopupDelete(false);
      setOpenPopupSuccessDelete(true);
      refetch();
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar({
        message: error?.message ?? "Error Deleting Data",
        variant: "error",
      });
    }
  };

  const onClosePopupSuccess = () => {
    setOpenPopupSuccessDelete(false);
  };

  const onSubmit = (payload) => {
    const params = {
      data: map(payload?.accounts, (account) => ({
        coa_id: account?.coa_id,
        coa_name: account?.account_name,
        coa_code: payload?.parent_account?.code,
        code: account?.account_code,
        level: payload?.parent_account?.level,
        full_code: account?.full_code,
        coa_parent_id: payload?.parent_account?.value,
        account_type: payload?.account_type?.value,
        position: payload?.position,
      })),
    };
    update.mutate(
      { id: selectedData?.coa_id, payload: params },
      {
        onSuccess: (data) => {
          setOpenPopupForm(false);
          setSelectedData(null);
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          setTimeout(() => {
            refetch();
          }, 100);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "full_code",
      title: "Full Code",
      dataIndex: "full_code",
      width: 540,
      className: "overflow-hidden text-ellipsis whitespace-normal flex-wrap",
      sortable: true,
      render: (value) => (
        <div className="w-full break-words max-w-[200px]">{value}</div>
      ),
    },
    {
      id: "code",
      title: "Kode",
      dataIndex: "code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "coa_name",
      title: "Nama Akun",
      dataIndex: "coa_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "position",
      title: "Posisi",
      dataIndex: "position",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "level",
      title: "Level",
      dataIndex: "level",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          {roleAccess?.canView && (
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDetail(data)}
            />
          )}
          {roleAccess?.canUpdate && (
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerEdit(data)}
            />
          )}
          {roleAccess?.canDelete && (
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDelete(data)}
            />
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={onClosePopupSuccess}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <InformationModal
        title="Detail"
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        className="bg-white rounded-xl w-2/3"
        childClassName="flex-1 max-h-[70vh] overflow-y-scroll px-8 py-4"
      >
        <Detail id={selectedData?.coa_id} />
      </InformationModal>
      <FormModal
        title={
          selectedData?.coa_id
            ? "Edit Chart of Account"
            : "Tambah Chart of Account"
        }
        className="bg-white rounded-xl w-2/3"
        open={openPopupForm}
        onClose={() => setOpenPopupForm(false)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FormProvider {...methods}>
          <Form id={selectedData?.coa_id} />
        </FormProvider>
      </FormModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">
          {"Daftar Chart of Account (CoA)"}
        </TitleText>
        <div className="flex space-x-4 justify-end flex-1">
          <a
            href={TemplateCoa}
            download={`Chart of Account Template - (Tahun Periode)`}
            target="_blank"
            className="btn capitalize text-white border-secondary-600 bg-secondary-600 hover:bg-secondary-700 hover:border-secondary-700"
            rel="noreferrer"
          >
            Download Template
          </a>
          <Button
            onClick={onTriggerImport}
            disabled={importCoa.isLoading}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 space-x-1"
          >
            <span>Import Data Coa</span>
            {importCoa.isLoading && <CgSpinner className="animate-spin" />}
          </Button>
          <input
            ref={importRef}
            type="file"
            className="hidden"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onHandleImport}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartOfAccount;
