import classNames from "classnames";
import { startCase } from "lodash";
import { getStatusPropertiesExecution } from "../util";

const statusMap = {
  Selesai: "Selesai",
  "Menunggu Pembayaran": "Menunggu Pembayaran",
  "Sedang diproses": "Sedang Diproses",
};

const StatusBadgeExecution = ({ status }) => {
  const statusProperty = getStatusPropertiesExecution(statusMap[status]);

  const variants = {
    blue: "bg-bluelight-50 text-bluelight-700 border-bluelight-50",
    warning: "bg-warning-50 text-warning-700 border-warning-50",
    success: "bg-primary-50 text-primary-600 border-primary-50",
    danger: "bg-red-50 text-red-600 border-red-50",
  };
  return (
    <div
      className={classNames(
        "rounded-xl text-xs px-2 flex flex-row grow-0 items-center gap-1 font-semibold",
        variants[statusProperty.variant]
      )}
    >
      <h1
        className={classNames(
          "text-2xl -mt-1",
          variants[statusProperty.variant]
        )}
      >
        &#8226;
      </h1>
      <span className="whitespace-pre-wrap">{statusMap[status]}</span>
    </div>
  );
};

export default StatusBadgeExecution;
