import classNames from "classnames";
import React, { useState } from "react";

export const Tab = ({ label, children, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={classNames(
      "px-6 py-2 cursor-pointer border-b-2 transition-all font-semibold text-sm -mb-[2px] hover:border-primary-600 hover:text-primary-600 hover:bg-primary-50",
      isActive
        ? "border-primary-600 text-primary-600 bg-primary-50"
        : "border-gray-400 text-gray-400"
    )}
  >
    {label}
  </button>
);

export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div>
      <div className="flex w-full border-b-2 border-gray-400 box-border">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child ?? <></>, {
            onClick: () => handleTabClick(index),
            isActive: activeTab === index,
          })
        )}
      </div>
      <div className="py-4">
        {React.Children.toArray(children)[activeTab]?.props?.children}
      </div>
    </div>
  );
};
