import config from 'app/config';
import axiosInstance from 'app/interceptors';

export const getBudgetList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/budget`, { params, signal });
  return data;
}

export const getBudgetSetList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/budget/set`, { params, signal });
  return data;
}

export const getBudgetDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/budget/${params?.year}`, { params: params?.params, signal });
  return data;
}

export const importBudgetData = async (payload) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/budget/import`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
}

export const updateBudgetData = async (params) => {
  const { data } = await axiosInstance.put(`${config.BASE_URL}/budget/${params?.id}`, params?.payload);
  return data;
}

export const deleteBudgetData = async (params) => {
  const { data } = await axiosInstance.delete(`${config.BASE_URL}/budget/${params?.id}`);
  return data;
}

export const updateStatusBudgetData = async (params) => {
  // const data = new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({data: "success"})
  //   }, 1000);
  // })
  const { data } = await axiosInstance.put(`${config.BASE_URL}/budget/${params?.id}/status`, params?.payload);
  return data;
}