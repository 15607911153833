import { useQuery, useMutation } from "react-query";
import { BUDGET } from "services";

export const useBudget = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return BUDGET.getBudgetList({ ...params }, signal);
    },
    ...options
  });
};

export const useBudgetSet = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return BUDGET.getBudgetSetList({ ...params }, signal);
    },
    ...options
  });
};

export const useBudgetDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return BUDGET.getBudgetDetail(params);
      return null;
    },
    ...options
  });
};

export const useImportBudget = () => {
  return useMutation((payload) => BUDGET.importBudgetData(payload))
}

export const useDeleteBudget = () => {
  return useMutation((payload) => BUDGET.deleteBudgetData(payload))
}

export const useUpdateStatus = () => {
  return useMutation((payload) => BUDGET.updateStatusBudgetData(payload))
}