import { useMutation, useQuery } from "react-query";
import { WOR } from "services";

export const useWriteOffQuery = (keys, options) => {
    return useQuery({
      queryKey: keys,
      queryFn: ({ signal, queryKey }) => {
        const params = queryKey[1];
        return WOR.getWriteOffRequest({ ...params }, signal);
      },
      ...options,
    });
  };

  export const useWriteOffDetailQuery = (keys, options) => {
    return useQuery({
      queryKey: keys,
      queryFn: ({ signal, queryKey }) => {
        const params = queryKey[1];
        if (params) return WOR.getWriteOffRequestDetail(params.id);
        return null;
      },
      ...options
    });
  };

  export const usePutWriteOffMutation = (options) => {
    console.log({options});
    return useMutation(WOR.putWriteOffRequest, options);
  }
