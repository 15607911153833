import classNames from "classnames";
import { last, map, sumBy } from "lodash";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";

import { BottomFormAction, Breadcrumbs, Button } from "components";
import {
  CustomDatepickerForm,
  InputForm,
  NumberInputForm,
  SelectProjectAccountForm,
} from "components/HookForm";
import { useEffect, useState } from "react";
import {
  useProjectPaymentDetailQuery,
  useProjectPaymentPost,
} from "hooks/projectPayment";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { convertToIDR } from "helpers";
import { BiMinus, BiPlus } from "react-icons/bi";
import moment from "moment";
import { getTransactionTypeProperties } from "./util";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";

const RequestProjectPaymentDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: detail } = useProjectPaymentDetailQuery(["payment-detail", id]);

  const post = useProjectPaymentPost();

  const isDetail = !location?.pathname?.includes("/payment") && id;

  const links = [
    {
      label: "Daftar Permintaan Pembayaran",
      path: "/project-payment",
    },
    {
      label: "Detail",
    },
  ];

  const schema = yup.object().shape({
    payment_date: yup.string().required("Wajib diisi"),
    // payments: yup
    //   .array()
    //   .of(
    //     yup.object({
    //       project_account: yup.mixed().required("Wajib diisi"),
    //       account_name: yup.string().required("Wajib diisi"),
    //       value: yup
    //         .number()
    //         .typeError("Wajib diisi")
    //         .required("Wajib diisi")
    //         .positive("Masukkan angka yang valid")
    //         .test({
    //           message:
    //             "Nominal tidak boleh kurang / lebih dari total pembayaran",
    //           test: (value) => {
    //             const payments = methods.watch("payments");
    //             const sumValue = sumBy(payments, "value");
    //             if (sumValue == detail?.total_payment) return true;
    //             return false;
    //           },
    //         }),
    //     })
    //   )
    //   .test({
    //     message: "Nominal tidak boleh kurang / lebih dari total pembayaran",
    //     test: (value) => {
    //       const payments = methods.watch("payments");
    //       const sumValue = sumBy(payments, "value");
    //       if (sumValue == detail?.total_payment) return true;
    //       return false;
    //     },
    //   }),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      recipient_account_number: "",
      recipient_name: "",
      recipient_bank: "",
      bank_branch: "",
      review_note: "",
      payments: [
        {
          project_account: null,
          value: "",
        },
      ],
    },
  });

  const {
    fields: payments,
    append: appendPayment,
    remove: removePayment,
  } = useFieldArray({
    name: "payments",
    control: methods.control,
  });

  useEffect(() => {
    if (detail) {
      methods.reset({
        submission_date: moment(detail?.submission_date).toDate(),
        transaction_type: getTransactionTypeProperties(
          detail?.transaction_type
        ),
        total_payment: detail?.total_payment,
        recipient_account_number: detail?.recipient_account_number,
        recipient_name: detail?.recipient_name,
        recipient_bank: detail?.recipient_bank,
        bank_branch: detail?.bank_branch,
        review_note: detail?.review_note,

        payment_date: last(detail?.payment)?.payment_date
          ? moment(last(detail?.payment)?.payment_date).toDate()
          : "",

        payments:
          detail?.payment?.length > 0
            ? map(detail?.payment, (payment) => ({
                project_account: {
                  value: payment?.project_account_id,
                  label: `${payment?.project_account_number} - ${payment?.project_account_owner}`,
                },
                account_name: payment?.project_account_owner,
                value: payment?.value,
              }))
            : [
                {
                  project_account: null,
                  account_name: "",
                  value: "",
                },
              ],
      });
    }
  }, [detail]);

  const onSubmit = (payload) => {
    // const params = {
    //   payment: map(payload?.payments, (payment) => ({
    //     payment_date: moment(payload?.payment_date).format("YYYY-MM-DD"),
    //     project_account_id: payment?.project_account?.value,
    //     project_account_number: payment?.project_account?.account_number,
    //     project_account_owner: payment?.account_name,
    //     // value: payment?.value,
    //   })),
    // };

    post.mutate(
      { id, payload: {payment_date: moment(payload?.payment_date).format("YYYY-MM-DD")} },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold flex flex-row items-center gap-2">
                  <span>Pembayaran</span>
                </div>
                <CustomDatepickerForm
                  name="submission_date"
                  label="Tanggal Permintaan"
                  placeholder={"dd/mm/yyyy"}
                  disabled
                />
                <InputForm
                  name={`transaction_type`}
                  label={"Jenis Transaksi"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder=""
                  disabled
                />
                <div className="flex items-center space-x-3 overflow-x-auto max-w-[80vw]">
                  <FilePickerForm
                    name={`supporting_doc`}
                    file={map(detail?.supporting_doc, (doc) => ({
                      file_name: doc?.fileName,
                      file_size: doc?.fileSize,
                      url: doc?.path,
                    }))}
                    readOnly
                  />
                </div>

                <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded">
                  <label>Data yang perlu dibayar</label>
                </div>

                <NumberInputForm
                  name={`total_payment`}
                  label={"Bayarlah Sejumlah"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder=""
                  disabled
                />

                <div className="space-y-2">
                  <div className="font-bold">Kepada</div>
                  <div className="grid grid-cols-2 gap-4">
                    <InputForm
                      name={`recipient_account_number`}
                      label={"Nomor Rekening"}
                      className={`py-2 px-4 border w-full rounded-md`}
                      placeholder=""
                      disabled
                    />
                    <InputForm
                      name={`recipient_name`}
                      label={"Nama Pemilik Rekening"}
                      className={`py-2 px-4 border w-full rounded-md`}
                      placeholder=""
                      disabled
                    />
                    <InputForm
                      name={`recipient_bank`}
                      label={"Bank"}
                      className={`py-2 px-4 border w-full rounded-md`}
                      placeholder=""
                      disabled
                    />
                    <InputForm
                      name={`bank_branch`}
                      label={"Cabang"}
                      className={`py-2 px-4 border w-full rounded-md`}
                      placeholder=""
                      disabled
                    />
                    <div className="col-span-2">
                      <InputForm
                        name="review_note"
                        label="Catatan Review"
                        className="w-full min-h-[120px]"
                        type="textarea"
                        value={detail?.review_note}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-cyan-800 text-white font-medium px-4 py-2 text-xl rounded space-x-4 flex items-center">
                  <label>Pembayaran</label>
                  <div className="bg-gray-300 p-1 rounded text-xs text-black">
                    Total Pembayaran: {convertToIDR(detail?.total_payment)}
                  </div>
                </div>

                <CustomDatepickerForm
                  name="payment_date"
                  label="Tanggal Pembayaran"
                  placeholder={"dd/mm/yyyy"}
                  required
                  disabled={isDetail}
                />

                <div className="w-full h-[1px] bg-gray-400" />

                {map(payments, (payment, key) => (
                  <div key={payment?.id} className="flex items-end space-x-4">
                    <div className="grid grid-cols-3 gap-4 flex-1">
                      <SelectProjectAccountForm
                        name={`payments.${key}.project_account`}
                        label={"Nomor Rekening Sumber Dana Pembayaran"}
                        className="z-[12]"
                        placeholder=""
                        isDisabled={true}
                        onSelect={(params) =>
                          methods.resetField(`payments.${key}.account_name`, {
                            defaultValue: params?.account_owner,
                          })
                        }
                      />
                      <InputForm
                        name={`payments.${key}.account_name`}
                        label={"Nama Pemilik Rekening"}
                        className={`py-2 px-4 border w-full rounded-md`}
                        placeholder=""
                        disabled
                      />
                      <NumberInputForm
                        name={`payments.${key}.value`}
                        label={"Nilai Yang Dibayar"}
                        className={`py-2 px-4 border w-full rounded-md`}
                        placeholder=""
                        value={convertToIDR(detail?.total_payment)}
                        disabled={true}
                      />
                    </div>
                    {/* {!isDetail && (
                      <div>
                        {key === 0 ? (
                          <Button
                            onClick={() =>
                              appendPayment({
                                project_account: null,
                                value: "",
                              })
                            }
                            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
                          >
                            <BiPlus />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => removePayment(key)}
                            className="flex-1 text-white border-red-600 bg-red-600 hover:bg-red-700 hover:border-red-700 whitespace-pre-wrap"
                          >
                            <BiMinus />
                          </Button>
                        )}
                      </div>
                    )} */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="h-32"></div>
        {!isDetail && (
          <BottomFormAction
            onSubmit={methods.handleSubmit(onSubmit)}
            onCancel={() => navigate(-1)}
          />
        )}
      </FormProvider>
    </>
  );
};

export default RequestProjectPaymentDetail;
