import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Button, Table } from "components";
import Accordion from "components/Accordion";
import { Tab, Tabs } from "components/Tabs";
import {
  useActivityTypeDetailQuery,
  useGeneralSettingsQuery,
  useRealizationDetail,
  useUpdateParticipant,
  useUpdateRealization,
  useValidatePayment,
} from "hooks/downPayment";
import _ from "lodash";
import moment from "moment";
import "moment/locale/id";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { FormHelperContext } from ".";
import { convertToIDR, getRealizationNeeds, getSubtotal } from "../util";
import ParticipantFormModal from "./participant-form-modal";
import { RealizationNeedsForm } from "./realization-needs-form";

const needSchema = yup.array().of(
  yup.object({
    account_code: yup.string().typeError("Wajib diisi"),
    detail_code: yup.string().typeError("Wajib diisi"),
    sbm_id: yup.string().typeError("Wajib diisi"),
    notes: yup.string().optional(),
    realization_subtotal: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    realization_value: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    realization_volume: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    volume: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    value: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    subtotal: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    with_prove: yup.boolean().typeError("Wajib diisi"),
    taxes: yup.array().of(
      yup.object({
        tax_id: yup.string().typeError("Wajib diisi"),
        tax_name: yup.string().typeError("Wajib diisi"),
        billing_code: yup.string().typeError("Wajib diisi"),
        value: yup
          .number()
          .typeError("Wajib diisi")
          .required("Wajib diisi")
          .positive("Masukkan angka yang valid"),
      })
    ),
  })
);

const schema = yup.object().shape({
  expert: yup.array().of(
    yup.object({
      expert_id: yup
        .string()
        .typeError("Narasumber wajib diisi")
        .required("Narasumber wajib diisi"),
      needs: needSchema,
    })
  ),
  invite: yup.array().of(
    yup.object({
      invite_id: yup
        .string()
        .typeError("Narasumber wajib diisi")
        .required("Narasumber wajib diisi"),
      needs: needSchema,
    })
  ),
  staff: yup.array().of(
    yup.object({
      needs: needSchema,
    })
  ),
  item: needSchema,
});

export const RealizationForm = ({ activityTypeId }) => {
  const { detail, handleNext, handlePrevious, isRealizationCreated } =
    useContext(FormHelperContext);
  const navigate = useNavigate();
  moment.locale("id");
  const defaultValues = {
    expert: [],
    staff: [],
    invite: [],
    item: [],
  };
  const [generalPayments, setGeneralPayments] = useState([]);

  const { data: generalSettings } = useGeneralSettingsQuery([
    "dp-general-settings",
    {
      search: "ACTIVITY_STAFF_DAILY_COST_PERCENTAGE",
    },
  ]);

  const { data: activityTypeDetail } = useActivityTypeDetailQuery([
    "dp-activity-type-detail",
    {
      id: detail?.id,
      activityTypeId,
    },
  ]);

  const { data: realizationDetail } = useRealizationDetail([
    "dp-realization-detail",
    {
      id: detail?.id,
      activityTypeId,
    },
  ]);

  const updateRealization = useUpdateRealization();
  const updateParticipant = useUpdateParticipant();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { fields: staff, append: appendStaff } = useFieldArray({
    control: methods.control,
    name: "staff",
  });

  const { fields: expert, append: appendExpert } = useFieldArray({
    control: methods.control,
    name: "expert",
  });

  const { fields: invite, append: appendInvite } = useFieldArray({
    control: methods.control,
    name: "invite",
  });

  const setInitialValues = () => {
    let tempPayments = [];
    methods.reset({
      staff:
        realizationDetail?.member?.staff?.length > 0
          ? realizationDetail?.member?.staff?.map((value) => {
              tempPayments = value?.payments;
              setGeneralPayments(tempPayments);
              return {
                user_id: value?.user?.user_id,
                name: value?.user?.name,
                needs: value?.needs?.map((need) => ({
                  account_code: need?.account_code,
                  detail_code: need?.detail_code,
                  sbm: need?.sbm?.description,
                  sbm_id: need?.sbm?.id,
                  value: need?.value,
                  subtotal: need?.subtotal,
                  volume: need?.volume || need?.qty,
                  ...getRealizationNeeds(need),
                })),
                payments: value?.payments,
              };
            })
          : activityTypeDetail?.member?.staff?.map((value) => {
              return {
                user_id: value?.user?.user_id,
                name: value?.user?.name,
                needs: value?.needs?.map((need) => ({
                  account_code: need?.account_code,
                  detail_code: need?.detail_code,
                  sbm: need?.sbm?.description,
                  sbm_id: need?.sbm?.id,
                  value: need?.value,
                  subtotal: need?.subtotal,
                  volume: need?.volume || need?.qty,
                })),
                payments: [],
              };
            }),
      invite: realizationDetail?.member?.invite?.map((value) => {
        return {
          invite_id: value?.participant?.id,
          invite_name: value?.participant?.name,
          needs: value?.needs?.map((need) => ({
            account_code: need?.account_code,
            detail_code: need?.detail_code,
            sbm: need?.sbm?.description,
            sbm_id: need?.sbm?.id,
            value: need?.value,
            subtotal: need?.subtotal,
            volume: need?.volume || need?.qty,
            ...getRealizationNeeds(need),
          })),
          payments:
            value?.payments?.length > 0 ? value?.payments : tempPayments,
        };
      }),
      expert: realizationDetail?.expert?.data?.map((value) => {
        return {
          expert_id: value?.participant?.id,
          expert_name: value?.participant?.name,
          needs: value?.needs?.map((need) => ({
            account_code: need?.account_code,
            detail_code: need?.detail_code,
            sbm: need?.sbm?.description,
            sbm_id: need?.sbm?.id,
            value: need?.value,
            subtotal: need?.subtotal,
            volume: need?.volume || need?.qty,
            ...getRealizationNeeds(need),
          })),
          payments:
            value?.payments?.length > 0 ? value?.payments : tempPayments,
        };
      }),
      item:
        realizationDetail?.item?.needs?.length > 0
          ? realizationDetail?.item?.needs?.map((need) => ({
              item_name: need?.item_name,
              account_code: need?.account_code,
              detail_code: need?.detail_code,
              sbm: need?.sbm?.description,
              sbm_id: need?.sbm?.id,
              value: need?.value,
              subtotal: need?.subtotal,
              volume: need?.volume || need?.qty,
              ...getRealizationNeeds(need),
            }))
          : activityTypeDetail?.item?.needs?.map((need) => ({
              item_name: need?.item_name,
              account_code: need?.account_code,
              detail_code: need?.detail_code,
              sbm: need?.sbm?.description,
              sbm_id: need?.sbm?.id,
              value: need?.value,
              subtotal: need?.subtotal,
              volume: need?.volume || need?.qty,
            })),
    });
  };

  useEffect(() => {
    setInitialValues();
  }, [detail, activityTypeDetail, realizationDetail]);

  const onSubmit = (values) => {
    const params = {
      expert: values?.expert?.map((expert) => ({
        expert_id: expert?.expert_id,
        payments: expert?.payments,
        needs: expert?.needs?.map((need) => ({
          ...need,
          sbm: undefined,
          currency: "IDR",
          realization_total:
            need?.taxes?.reduce((acc, o) => acc + o?.value, 0) +
            need?.realization_subtotal,
          taxes: need?.taxes?.map((tax) => ({
            tax_id: tax?.tax?.value,
            tax_name: tax?.tax?.label,
            billing_code: tax?.billing_code,
            value: tax?.value,
          })),
        })),
      })),
      member: {
        staff: values?.staff?.map((staff) => ({
          user_id: staff?.user_id,
          payments: staff?.payments ?? [],
          needs: staff?.needs?.map((need) => ({
            ...need,
            sbm: undefined,
            currency: "IDR",
            qty: need?.volume,
            realization_qty: need?.realization_volume,
            realization_total:
              need?.taxes?.reduce((acc, o) => acc + o?.value, 0) +
              need?.realization_subtotal,
            taxes: need?.taxes?.map((tax) => ({
              tax_id: tax?.tax?.value,
              tax_name: tax?.tax?.label,
              billing_code: tax?.billing_code,
              value: tax?.value,
            })),
          })),
        })),
        invite: values?.invite?.map((invite) => ({
          invite_id: invite?.invite_id,
          payments: invite?.payments,
          needs: invite?.needs?.map((need) => ({
            ...need,
            sbm: undefined,
            currency: "IDR",
            realization_total:
              need?.taxes?.reduce((acc, o) => acc + o?.value, 0) +
              need?.realization_subtotal,
            taxes: need?.taxes?.map((tax) => ({
              tax_id: tax?.tax?.value,
              tax_name: tax?.tax?.label,
              billing_code: tax?.billing_code,
              value: tax?.value,
            })),
          })),
        })),
      },
      item: values?.item?.map((need) => ({
        ...need,
        sbm: undefined,
        currency: "IDR",
        qty: need?.volume,
        realization_qty: need?.realization_volume,
        realization_total:
          need?.taxes?.reduce((acc, o) => acc + o?.value, 0) +
          need?.realization_subtotal,
        taxes: need?.taxes?.map((tax) => ({
          tax_id: tax?.tax?.value,
          tax_name: tax?.tax?.label,
          billing_code: tax?.billing_code,
          value: tax?.value,
        })),
      })),
    };

    updateRealization.mutate(
      { id: detail?.id, activityTypeId, payload: params },
      {
        onSuccess: (data) => {
          methods.reset({
            expert: [],
            staff: [],
            invite: [],
            item: [],
          });
          handleNext();
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const expertNeeds = methods.watch("expert");
  const staffNeeds = methods.watch("staff");
  const inviteNeeds = methods.watch("invite");
  const itemNeeds = methods.watch("item");

  const [isOpenExpert, setIsOpenExpert] = useState(false);
  const [isOpenInvite, setIsOpenInvite] = useState(false);

  const renderAllModal = () => (
    <>
      <ParticipantFormModal
        isOpen={isOpenExpert}
        onClose={() => {
          setIsOpenExpert(false);
        }}
        type="expert"
        onSubmit={(value) => {
          updateParticipant.mutate(
            {
              id: detail?.id,
              activityTypeId,
              payload: {
                participant_id: value?.id,
              },
            },
            {
              onSuccess: (data) => {
                appendExpert({
                  expert_id: value?.id,
                  expert_name: value?.name,
                  needs: activityTypeDetail?.expert?.needs?.map((need) => ({
                    account_code: need?.account_code,
                    detail_code: need?.detail_code,
                    sbm: need?.sbm?.description,
                    sbm_id: need?.sbm?.id,
                    value: need?.value,
                    subtotal: need?.subtotal,
                    volume: need?.volume || need?.qty,
                  })),
                  payments: generalPayments,
                });
              },
              onError: (error) => {
                enqueueSnackbar({
                  message: error?.message ?? "Error",
                  variant: "error",
                });
              },
            }
          );
        }}
      />
      <ParticipantFormModal
        isOpen={isOpenInvite}
        onClose={() => {
          setIsOpenInvite(false);
        }}
        type="invite"
        onSubmit={(value) => {
          updateParticipant.mutate(
            {
              id: detail?.id,
              activityTypeId,
              payload: {
                participant_id: value?.id,
              },
            },
            {
              onSuccess: (data) => {
                appendInvite({
                  invite_id: value?.id,
                  invite_name: value?.name,
                  needs: activityTypeDetail?.expert?.needs?.map((need) => ({
                    account_code: need?.account_code,
                    detail_code: need?.detail_code,
                    sbm: need?.sbm?.description,
                    sbm_id: need?.sbm?.id,
                    value: need?.value,
                    subtotal: need?.subtotal,
                    volume: need?.volume || need?.qty,
                  })),
                  payments: generalPayments,
                });
              },
              onError: (error) => {
                enqueueSnackbar({
                  message: error?.message ?? "Error",
                  variant: "error",
                });
              },
            }
          );
        }}
      />
    </>
  );

  const calculateTotal = (needs) => {
    if (needs?.length > 0) {
      return convertToIDR(
        needs?.reduce(
          (sum, need) =>
            sum +
            need?.needs?.reduce(
              (subtotalSum, need) =>
                subtotalSum +
                getSubtotal(need?.realization_volume, need?.realization_value) +
                (need?.taxes?.reduce((acc, tax) => acc + tax?.value, 0) || 0),
              0
            ),
          0
        )
      );
    }
    return 0;
  };

  return (
    <FormProvider {...methods}>
      {renderAllModal()}
      <Tabs key={activityTypeId}>
        <Tab label="Realisasi Narasumber">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="border-b-[1px] border-gray-100 pb-4">
                <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                  Realisasi Biaya:{" "}
                  {expertNeeds?.length > 0 && (
                    <span className="font-bold">
                      {calculateTotal(expertNeeds)}
                    </span>
                  )}
                </span>
              </div>

              {expert?.map((data, index) => (
                <Accordion key={data?.id} label={data?.expert_name ?? ""}>
                  <PaymentTable
                    payments={data?.payments}
                    participantIndex={index}
                    fieldName="expert"
                    userId={data?.expert_id}
                  />
                  <RealizationNeedsForm
                    fieldName={`expert.${index}.needs`}
                    defaultValues={[]}
                    volumeLabel="Qty"
                    key={data?.id}
                  />
                </Accordion>
              ))}

              <div className="flex items-center justify-end">
                {!isRealizationCreated && (
                  <Button
                    startIcon={<FiPlus />}
                    className="p-4 border rounded-lg bg-primary-600 border-primary-600"
                    onClick={() => {
                      setIsOpenExpert(true);
                    }}
                  >
                    Tambah Narasumber
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Tab>
        <Tab label="Realisasi Peserta">
          <div className="space-y-3">
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-4 space-y-4">
                <div className="border-b-[1px] border-gray-100 pb-4 flex flex-row gap-2 items-center">
                  <span className="font-bold text-lg">Peserta Pegawai</span>
                  <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                    Realisasi Biaya:{" "}
                    {staffNeeds?.length > 0 && (
                      <span className="font-bold">
                        {calculateTotal(staffNeeds)}
                      </span>
                    )}
                  </span>
                </div>
                {staff?.map((data, index) => (
                  <Accordion key={data?.user_id} label={data?.name ?? ""}>
                    <PaymentTable
                      payments={data?.payments}
                      participantIndex={index}
                      fieldName="staff"
                      userId={data?.user_id}
                    />
                    <RealizationNeedsForm
                      fieldName={`staff.${index}.needs`}
                      defaultValues={[]}
                      volumeLabel="Qty"
                      key={data?.id}
                    />
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-4 space-y-4">
                <div className="border-b-[1px] border-gray-100 pb-4 flex flex-row gap-2 items-center">
                  <span className="font-bold text-lg">Peserta Undangan</span>
                  <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                    Realisasi Biaya:{" "}
                    {inviteNeeds?.length > 0 && (
                      <span className="font-bold">
                        {calculateTotal(inviteNeeds)}
                      </span>
                    )}
                  </span>
                </div>

                {invite?.map((data, index) => (
                  <Accordion key={data?.id} label={data?.invite_name ?? ""}>
                    <PaymentTable
                      payments={data?.payments}
                      participantIndex={index}
                      fieldName="invite"
                      userId={data?.invite_id}
                    />
                    <RealizationNeedsForm
                      fieldName={`invite.${index}.needs`}
                      defaultValues={[]}
                      volumeLabel="Qty"
                      key={data?.id}
                    />
                  </Accordion>
                ))}

                <div className="flex items-center justify-end">
                  {!isRealizationCreated && (
                    <Button
                      startIcon={<FiPlus />}
                      className="p-4 border rounded-lg bg-primary-600 border-primary-600"
                      onClick={() => {
                        setIsOpenInvite(true);
                      }}
                    >
                      Tambah Undangan
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab label="Realisasi Barang">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="border-b-[1px] border-gray-100 pb-4">
                <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                  Realisasi Biaya:{" "}
                  {itemNeeds?.length > 0 && (
                    <span className="font-bold">
                      {convertToIDR(
                        itemNeeds?.reduce(
                          (subtotalSum, need) =>
                            subtotalSum +
                            getSubtotal(
                              need?.realization_volume,
                              need?.realization_value
                            ) +
                            (need?.taxes?.reduce(
                              (acc, tax) => acc + tax?.value,
                              0
                            ) || 0),
                          0
                        )
                      )}
                    </span>
                  )}
                </span>
              </div>
              <RealizationNeedsForm
                fieldName="item"
                defaultValues={[]}
                type="item"
                volumeLabel="Qty"
              />
            </div>
          </div>
        </Tab>
      </Tabs>
      <div className="h-24" />
      <BottomFormAction
        onCancel={handlePrevious}
        _cancelButton={{
          label: "Sebelumnya",
        }}
        renderAction={() => (
          <div className="flex flex-row items-center gap-2">
            {!isRealizationCreated && (
              <Button
                className="border-gray-300 bg-white text-gray-700 hover:text-white"
                startIcon={<BiSave size={18} />}
                onClick={() => {
                  methods.handleSubmit(onSubmit)();
                  navigate(-1);
                }}
              >
                Simpan Draft
              </Button>
            )}
            <Button
              onClick={
                !isRealizationCreated
                  ? methods.handleSubmit(onSubmit)
                  : handleNext
              }
              type="submit"
              className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
            >
              <div className="flex items-center gap-2">Selanjutnya</div>
            </Button>
          </div>
        )}
      />
    </FormProvider>
  );
};

const PaymentTable = ({ fieldName, participantIndex, payments, userId }) => {
  const { isRealizationCreated, detail } = useContext(FormHelperContext);
  const validatePayment = useValidatePayment();
  const methods = useFormContext();

  const getPaymentColumns = (staffIndex) => [
    {
      id: "date",
      title: "Tanggal Pelaksanaan Surat Tugas",
      dataIndex: "date",
      className: "overflow-hidden",
      render: (value, data, index) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {moment(value).format("DD MMMM YYYY")}
        </div>
      ),
    },
    {
      id: "paid",
      title: "Status Dibayarkan",
      dataIndex: "paid",
      className: "overflow-hidden",
      render: (value, data, index) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          <Controller
            name={`${fieldName}.${staffIndex}.payments.${index}.paid`}
            control={methods.control}
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <input
                className="cursor-pointer"
                type="checkbox"
                onChange={(e) => {
                  onChange(e.target.checked);
                  validatePayment.mutate(
                    {
                      id: detail?.id,
                      user_id: userId,
                      type: fieldName,
                      date: moment(data?.date).format("YYYY-MM-DD"),
                    },
                    {
                      onSuccess: (data) => {
                        methods
                          ?.watch(`${fieldName}.${staffIndex}.needs`)
                          ?.forEach((_, needIndex) => {
                            const volumeFieldName = `${fieldName}.${staffIndex}.needs.${needIndex}.realization_volume`;
                            methods.setValue(
                              volumeFieldName,
                              e.target.checked
                                ? parseInt(
                                    methods.watch(volumeFieldName) ?? 0
                                  ) + 1
                                : parseInt(
                                    methods.watch(volumeFieldName) ?? 0
                                  ) - 1
                            );
                          });
                      },
                      onError: (err) => {
                        onChange(!e.target.checked);
                      },
                    }
                  );
                }}
                disabled={isRealizationCreated}
                checked={value}
              />
            )}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="space-y-4 mb-8">
      <Table
        bordered
        stripped
        layout="fixed"
        className="mb-4"
        columns={getPaymentColumns(participantIndex)}
        dataSource={payments}
        pagination={{
          page: 1,
          limit: 25,
          total: payments?.length,
          previous_pages: false,
          next_pages: false,
        }}
      />
    </div>
  );
};
