export const getStatusProperties = (status) => {
  let statusProperties = getProperties(status);
  return statusProperties;
};

export const getStatusPropertiesExecution = (status) => {
  let statusProperties = getPropertiesExecution(status);
  return statusProperties;
};

const getProperties = (status) => {
  let statusProperties;
  switch (status) {
    case "Telah Dibayar":
      statusProperties = {
        variant: "success",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }
  return statusProperties;
};


const getPropertiesExecution = (status) => {
  let statusProperties;
  switch (status) {
    case "Selesai":
      statusProperties = {
        variant: "success",
      };
      break;
    default:
      statusProperties = {
        variant: "warning",
      };
      break;
  }

  return statusProperties;
};