import config from "app/config";
import axiosInstance from "app/interceptors";

const getMappedStatus = (activity, role) => {
  let description, action;

  switch (role) {
    case "pjk":
      switch (activity?.status?.step) {
        case 1:
          description = "Menunggu approval staf PPK";
          action = "view";
          break;
        case 2:
          description = "Menunggu approval PPK";
          action = "view";
          break;
        case 3:
          description = "Menunggu approval PPSPM";
          action = "view";
          break;
        case 4:
          description = "Menunggu Pembayaran";
          action = "view";
          break;
        case 5:
          description = "Selesai";
          action = "view";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
          break;
      }
      break;
    case "staff ppk":
      switch (activity?.status?.step) {
        case 1:
          description = "Menunggu approval staf PPK";
          action = "approval";
          break;
        case 2:
          description = "Menunggu approval PPK";
          action = "view";
          break;
        case 3:
          description = "Menunggu approval PPSPM";
          action = "view";
          break;
        case 4:
          description = "Menunggu Pembayaran";
          action = "view";
          break;
        case 5:
          description = "Selesai";
          action = "view";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
          break;
      }
      break;
    case "ppk":
      switch (activity?.status?.step) {
        case 1:
          description = "Menunggu approval staf PPK";
          action = "view";
          break;
        case 2:
          description = "Menunggu approval PPK";
          action = "approval";
          break;
        case 3:
          description = "Menunggu approval PPSPM";
          action = "view";
          break;
        case 4:
          description = "Menunggu Pembayaran";
          action = "view";
          break;
        case 5:
          description = "Selesai";
          action = "view";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
          break;
      }
      break;
    case "ppspm":
      switch (activity?.status?.step) {
        case 1:
          description = "Menunggu approval staf PPK";
          action = "view";
          break;
        case 2:
          description = "Menunggu approval PPK";
          action = "view";
          break;
        case 3:
          description = "Menunggu approval PPSPM";
          action = "approval";
          break;
        case 4:
          description = "Menunggu Pembayaran";
          action = "view";
          break;
        case 5:
          description = "Selesai";
          action = "view";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
          break;
      }
      break;
    default:
      description = "Baru";
      action = "crud";
      break;
  }

  return {
    status: activity?.status,
    description,
    action,
  };
};

const getAction = (index, params) => {
  if (params?.role === "pjk") {
    switch (index) {
      case 0:
      case 5:
        return "crud";
      case 1:
      case 6:
        return "process";
      case 2:
      case 7:
        return "process";
      case 3:
      case 8:
        return "process";
      case 4:
      case 9:
        return "detail";
    }
  } else if (params?.role === "staff ppk") {
    switch (index) {
      case 0:
      case 5:
        return "review";
      case 1:
      case 6:
        return "detail";
      default:
        return "defail";
    }
  } else if (params?.role === "ppk" || params?.role === "pum") {
    switch (index) {
      case 0:
      case 5:
        return "approval";
      case 1:
      case 6:
        return "detail";
      default:
        return "defail";
    }
  }
};

const getDesc = (index, params) => {
  if (params?.role === "pjk") {
    switch (index) {
      case 0:
      case 5:
        return "Menunggu persetujuan PPK";
      case 1:
      case 6:
        return "Perlu Dieksekusi";
      case 2:
      case 7:
        return "Perlu Dieksekusi";
      case 3:
      case 8:
        return "Perlu Revisi";
      case 4:
      case 9:
        return "Disetujui Staff PPK";
    }
  } else if (params?.role === "staff ppk") {
    switch (index) {
      case 0:
      case 5:
        return "Perlu Direview";
      case 1:
      case 6:
        return "Disetujui";
      default:
        return "Ditolak";
    }
  } else if (params?.role === "ppk" || params?.role === "pum") {
    switch (index) {
      case 0:
      case 5:
        return "Perlu Persetujuan";
      case 1:
      case 6:
        return "Disetujui";
      default:
        return "Ditolak";
    }
  }
};

const getType = (index) => {
  if (index % 3 == 0) {
    return "Gaji";
  } else if (index % 2 == 0) {
    return "Non Gaji";
  }
  return "Pihak Ketiga";
};

export const getSubmissions = async (params, signal) => {
  const { data } = await axiosInstance.get("/lsactivity", { params, signal });

  let res = data;

  const result = res?.result?.map((activity) => {
    return {
      ...activity,
      status: getMappedStatus(activity, params?.role),
    };
  });

  res = {
    ...res,
    result,
  };

  return res;
};

export const getSubmissionDetail = async (params, signal) => {
  const { data } = await axiosInstance.get(`/lsactivity/${params?.id}`, {
    signal,
  });
  return data;
};

export const getSubmissionRecap = async (params, signal) => {
  const { data } = await axiosInstance.get(`/lsactivity/${params?.id}/recap`, {
    signal,
  });
  return data;
};

export const createContractual = async (params) => {
  const { data } = await axiosInstance.post(`/lsactivity/contractual`, params);
  return data;
};

export const updateContractual = async (params) => {
  const { data } = await axiosInstance.put(
    `/lsactivity/contractual/${params?.id}`,
    params?.payload
  );
  return data;
};

export const createNonContractualStaff = async (params) => {
  const { data } = await axiosInstance.post(
    `/lsactivity/non-contractual-staff`,
    params
  );
  return data;
};

export const updateNonContractualStaff = async (params) => {
  const { data } = await axiosInstance.put(
    `/lsactivity/non-contractual-staff/${params?.id}`,
    params?.payload
  );
  return data;
};

export const createNonContractualParticipant = async (params) => {
  const { data } = await axiosInstance.post(
    `/lsactivity/non-contractual-participant`,
    params
  );
  return data;
};

export const updateNonContractualParticipant = async (params) => {
  const { data } = await axiosInstance.put(
    `/lsactivity/non-contractual-participant/${params?.id}`,
    params?.payload
  );
  return data;
};

export const createVendor = async (params) => {
  const { data } = await axiosInstance.post(`/lsactivity/vendor`, params);
  return data;
};

export const updateVendor = async (params) => {
  const { data } = await axiosInstance.put(
    `/lsactivity/vendor/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteSubmission = async (params) => {
  const { data } = await axiosInstance.delete(`/lsactivity/${params?.id}`);
  return data;
};

export const getVendorProcurement = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/procurement/v1/cms/vendors`,
    { params, signal }
  );
  return data;
};

export const getSbm = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/sbm`, {
    params,
    signal,
  });
  return data;
};

export const approvalSubsmission = async (params) => {
  const { data } = await axiosInstance.put(
    `/lsactivity/approval/${params?.id}`,
    params?.payload
  );
  return data;
}
