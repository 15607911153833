import { NumberInputForm, SelectProjectAccountForm } from "components/HookForm";

const AdmissionFee = () => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-3">
        <SelectProjectAccountForm
          name="account_number"
          label="Nomor Rekening"
          placeholder="Pilih nomor rekening"
          isClearable
          required={true}
        />
      </div>
      <NumberInputForm
        name={`nominal_input`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Nominal"}
        required={true}
      />
    </div>
  );
};
export default AdmissionFee;
