import { useQuery, useMutation } from "react-query";
import { MJ } from "services";

export const useManualJournal = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return MJ.getManualJournalList({ ...params }, signal);
    },
    ...options
  });
};

export const useManualJournalDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return MJ.getManualJournalDetail(params);
      return null;
    },
    ...options
  });
};

export const usePostManualJournal = () => {
  return useMutation((payload) => MJ.createManualJournalData(payload))
}

export const usePutManualJournal = () => {
  return useMutation((payload) => MJ.updateManualJournalData(payload))
}

export const useDeleteManualJournal = () => {
  return useMutation((payload) => MJ.deleteManualJournalData(payload))
}