import React from "react";

import CollapsingCard from "components/Card/CollapsingCard";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { Button, Table } from "components";
import { BiPlus } from "react-icons/bi";

const ResourceCeilling = () => {
  const data = [
    {
      activity_detail:
        "52513.02 Honorarium Narasumber Kegiatan Pendanaan di Sektor Kehutanan",
      pagu_remaining: "Rp 10,000,000",
      volume: "Rp 10,000,000",
      price_per_unit: "Rp 10,000",
      pagu_value: "Rp 10,000,000",
    },
    {
      activity_detail:
        "52513.02 Honorarium Narasumber Kegiatan Pendanaan di Sektor Kehutanan",
      pagu_remaining: "Rp 10,000,000",
      volume: "Rp 10,000,000",
      price_per_unit: "Rp 10,000",
      pagu_value: "Rp 10,000,000",
    },
    {
      activity_detail:
        "52513.02 Honorarium Narasumber Kegiatan Pendanaan di Sektor Kehutanan",
      pagu_remaining: "Rp 10,000,000",
      volume: "Rp 10,000,000",
      price_per_unit: "Rp 10,000",
      pagu_value: "Rp 10,000,000",
    },
  ];

  const tableColumns = [
    {
      id: "checkbox",
      title: () => <input type="checkbox" />,
      dataIndex: "code",
      className: "z-[0]",
      render: (value, data) => <input type="checkbox" />,
    },
    {
      id: "activity_detail",
      title: "Detail Kegiatan",
      dataIndex: "activity_detail",
      render: (value) => value,
    },
    {
      id: "pagu_remaining",
      title: "Sisa Pagu",
      dataIndex: "pagu_remaining",
      render: (value) => value,
    },
    {
      id: "volume",
      title: "Volume",
      dataIndex: "volume",
      render: (value) => value,
    },
    {
      id: "price_per_unit",
      title: "Harga Satuan",
      dataIndex: "price_per_unit",
      render: (value) => value,
    },
    {
      id: "pagu_value",
      title: "Nilai Pagu",
      dataIndex: "pagu_value",
      render: (value) => value,
    },
  ];

  return (
    <CollapsingCard title="Pagu Sumber">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <CustomSelectForm
            className="w-full"
            name={`resource_ceiling.component`}
            label="Komponen"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.ceiling_value`}
          label="Nilai Pagu"
        />
        <div className="col-span-2">
          <CustomSelectForm
            className="w-full"
            name={`resource_ceiling.shopping`}
            label="Belanja"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.shopping_ceiling_value`}
          label="Nilai Pagu"
        />

        {/* Account Detail */}
        <div className="col-span-3 space-y-3">
          <label className="font-bold">Daftar Detail Akun</label>
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={data}
          />
        </div>

        {/* Activity Detail */}
        <div className="col-span-3">
          <CustomSelectForm
            className="w-full"
            name={`resource_ceiling.activity_detail`}
            label="Detail Kegiatan"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.volume`}
          label="Volume"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.price_per_unit`}
          label="Harga Satuan"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.ceiling_value_activity`}
          label="Nilai Pagu"
        />
        <InputForm
          className="w-full"
          name={`resource_ceiling.price_changes`}
          label="Perubahan Harga"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.ceiling_after_revision`}
          label="Nilai Pagu Setelah Revisi"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.transferable_value`}
          label="Nilai Yang Lapat Dipindahkan"
        />

        {/* Divider */}
        <div className="col-span-3 py-2">
          <div className="h-[1px] bg-gray-400" />
        </div>

        {/* Activity Detail */}
        <div className="col-span-3">
          <CustomSelectForm
            className="w-full"
            name={`resource_ceiling.activity_detail_2`}
            label="Detail Kegiatan"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.volume_2`}
          label="Volume"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.price_per_unit_2`}
          label="Harga Satuan"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.ceiling_value_activity_2`}
          label="Nilai Pagu"
        />
        <InputForm
          className="w-full"
          name={`resource_ceiling.price_changes_2`}
          label="Perubahan Harga"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.ceiling_after_revision_2`}
          label="Nilai Pagu Setelah Revisi"
        />
        <InputForm
          disabled
          className="w-full"
          name={`resource_ceiling.transferable_value_2`}
          label="Nilai Yang Lapat Dipindahkan"
        />

        {/* Divider */}
        <div className="col-span-3 py-2">
          <div className="h-[1px] bg-gray-400" />
        </div>

        {/* Result */}
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="flex flex-col items-end justify-center space-y-4">
          <InputForm
            disabled
            className="w-full"
            name={`resource_ceiling.total_revision_resource`}
            label="Total Nominal Sumber Revisi"
          />
          <Button
            type="submit"
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 flex items-center space-x-2"
          >
            <BiPlus />
            <div className="flex items-center gap-2">
              Tambah Detail Kegiatan
            </div>
          </Button>
        </div>
      </div>
    </CollapsingCard>
  );
};

export default ResourceCeilling;
