import config from 'app/config';
import axiosInstance from 'app/interceptors';

export const getAccountRequestList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/account`, { params, signal });
  return data
}

export const getAccountRequestDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/account/${id}`);
  return data;
}

export const createAccountRequestData = async (params) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/account`, params);
  return data;
}

export const updateAccountRequestData = async (params) => {
  const { data } = await axiosInstance.put(`${config.BASE_URL}/account/${params?.id}`, params?.payload);
  return data;
}

export const deleteAccountRequestData = async (params) => {
  const { data } = await axiosInstance.delete(`${config.BASE_URL}/account/${params?.id}`);
  return data;
}