import { Button } from "components";
import Accordion from "components/Accordion";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectAccountForm,
} from "components/HookForm";
import { useChartOfAccount } from "hooks/chartOfAccount";
import { debounce, map } from "lodash";
import { convertToIDR } from "pages/DownPayment/util";
import { useCallback, useContext, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { HelperContext } from "..";
import { TaxForm } from "./tax-form";

const Activity = ({ name, parentName }) => {
  const { control, watch } = useFormContext();
  const { fields: activities, append: addActivity } = useFieldArray({
    control,
    name,
  });

  const [keyword, setKeyword] = useState("");
  const { isRealizationCreated } = useContext(HelperContext);

  const { data: coaOptions } = useChartOfAccount([
    "coa-oa-add",
    {
      page: 1,
      size: 20,
      search: keyword,
      level: "8",
    },
  ]);

  const handleSearchCoa = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );

  const getTotalValue = (name, key) => {
    return (
      watch(`${name}.${key}.subtotal`) +
      watch(`${name}.${key}.taxes`)?.reduce((acc, o) => acc + o?.value, 0)
    );
  };
  return (
    <div className="space-y-4">
      {map(activities, (activity, key) => (
        <Accordion
          key={activity?.id}
          label={`Detail Kegiatan ${key + 1}`}
          renderLeft={() => (
            <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
              Nilai: {convertToIDR(getTotalValue(name, key))}
            </div>
          )}
        >
          <div className="space-y-4">
            <SelectAccountForm
              label="Detail Kegiatan"
              placeholder="Pilih detail kegiatan"
              name={`${name}.${key}.activity_detail`}
              required
              level="11"
              parentId={watch(parentName)?.id}
              isDisabled={!watch(parentName)?.id || isRealizationCreated}
            />
            <NumberInputForm
              name={`${name}.${key}.subtotal`}
              className={`border w-full rounded-md`}
              label="Nilai Realisasi"
              placeholder="Pilih nilai pengajuan"
              disabled={isRealizationCreated}
            />
            <NumberInputForm
              name={`${name}.${key}.value`}
              label="Nilai Realisasi"
              placeholder="Pilih nilai pengajuan"
              type="hidden"
              value={getTotalValue(name, key)}
              isDisabled={isRealizationCreated}
            />
            <TaxForm fieldName={name} detailIndex={key} />
            <InputForm
              label="Catatan"
              name={`${name}.${key}.notes`}
              className="w-full"
              type="textarea"
              disabled={isRealizationCreated}
            />
          </div>
        </Accordion>
      ))}

      {!isRealizationCreated && (
        <div className="flex items-center justify-end w-full">
          <Button
            startIcon={<FiPlus />}
            className=" bg-white border-[1px] border-gray-200 rounded-md text-black hover:text-white"
            onClick={() => addActivity({ activity_detail: null, value: "" })}
          >
            Tambah Detail Kegiatan
          </Button>
        </div>
      )}
    </div>
  );
};

export default Activity;
