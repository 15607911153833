export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
    return message;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const convertToIDR = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number || 0);
};

export const convertToUSD = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number || 0);
}