import { useQuery, useMutation } from "react-query";
import { BR } from "services";

export const useBudgetRevision = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return BR.getBudgetRevisionList({ ...params }, signal);
    },
    ...options
  });
};

export const useBudgetRevisionDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return BR.getBudgetRevisionDetail(params);
      return null;
    },
    ...options
  });
};

export const usePostBudgetRevision = () => {
  return useMutation((payload) => BR.createBudgetRevisionData(payload))
}

export const usePutBudgetRevision = () => {
  return useMutation((payload) => BR.updateBudgetRevisionData(payload))
}

export const useDeleteBudgetRevision = () => {
  return useMutation((payload) => BR.deleteBudgetRevisionData(payload))
}

export const useDownloadTemplateBudgetRevision = () => {
  return useMutation((payload) => BR.downloadTemplate(payload))
}