import React, { Fragment } from "react";

const BasicCard = ({ children }) => {
  return (
    <Fragment>
      <div className="bg-white rounded-xl border-[1px] border-[#EAECF0] relative">
        {children}
      </div>
    </Fragment>
  );
};

export default BasicCard;
