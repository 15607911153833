import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import moment from "moment";

import { useReconciliation } from "hooks/reconciliation";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { Tab, Tabs } from "components/Tabs";
import { render } from "@testing-library/react";
import { CgSpinner } from "react-icons/cg";

const ReconciliationDetail = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Rekonsialisasi",
      path: "/reconciliation-accounting",
    },
    {
      label: "Telah Disetujui",
    },
  ];

  const headers = [
    {
      title: "No",
      dataIndex: "index",
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "tanggal_transaksi",
    },
    {
      title: "noa",
      dataIndex: "noa",
    },
    {
      title: "nama debitur",
      dataIndex: "nama_debitur",
      fixed: "left",
    },
    {
      title: "kategori debitur",
      dataIndex: "kategori_debitur",
    },
    {
      title: "kelompok",
      dataIndex: "kelompok_debitur",
    },
    {
      title: "kota/kabupaten",
      dataIndex: "kota",
    },
    {
      title: "provinsi",
      dataIndex: "provinsi",
    },
    {
      title: "Angsuran/Tenor",
      dataIndex: "tenor",
    },
    {
      title: "nilai pokok yg ditagihkan",
      dataIndex: "nilai_pokok_ditagihkan",
    },
    {
      title: "nilai bunga yg ditagihkan",
      dataIndex: "nilai_bunga_ditagihkan",
    },
    {
      title: "nilai ditagihkan",
      dataIndex: "total_ditagihkan",
    },
    {
      title: "Nilai pokok pengembalian",
      dataIndex: "nilai_pokok_pengembalian",
      render: (value, data, index) => {
        return (
          //render input nilai pokok pengembalian
          <input
            type="text"
            className="w-full h-8 border border-gray-200 rounded-md"
            defaultValue={value}
          />
        );
      },
    },
    {
      title: "Nilai bunga pengembalian",
      dataIndex: "nilai_bunga_pengembalian",
      render: (value, data, index) => {
        return (
          //render input nilai pokok pengembalian
          <input
            type="text"
            className="w-full h-8 border border-gray-200 rounded-md"
            defaultValue={value}
          />
        );
      },
    },
    {
      title: "Nilai pengembalian",
      dataIndex: "total_pengembalian",
    },
    {
      title: "Aksi",
      dataIndex: "action",
      fixed: "right",
      render: (value, data, index) => {
        return (
          //render chekbox dan textarea input alasan
          <div className="space-x-2 flex items-center w-[150px]">
            {/* render detail icon */}
            <HiOutlineEye
              className="text-gray-600 cursor-pointer"
              size={20}
              onClick={() => {
                navigate(`/reconciliation-accounting/detail-reconciliation/${data?.noa}`, {
                  state: data,
                });
              }}
            />
          </div>
        );
      },
    },
    // {
    //   title: "Sesuai Rekening Koran",
    //   dataIndex: "sesuai_rekening_koran",
    //   fixed: "right",
    //   render: (value, data, index) => {
    //     return (
    //       //render chekbox
    //       <input type="checkbox" />
    //     );
    //   },
    // },
    // {
    //   title: "Tidak Sesuai Rekening Koran",
    //   dataIndex: "tidak_sesuai_rekening_koran",
    //   fixed: "right",
    //   render: (value, data, index) => {
    //     return (
    //       //render chekbox dan textarea input alasan
    //       <div className="flex items-center gap-4 justify-start">
    //         <input type="checkbox" />
    //         <div>
    //           <div className="text-sm font-semibold">Alasan</div>
    //           <textarea className="w-full border border-gray-200 rounded-md" />
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Telah Disetujui"}</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] ">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="flex items-center overflow-auto">
              <Table
                bordered
                stripped
                layout="auto"
                className="w-full"
                columns={headers}
                dataSource={[
                  {
                    index: 1,
                    tanggal_transaksi: "2021-10-10",
                    noa: "1234",
                    nama_debitur: "Budi",
                    kategori_debitur: "Kategori",
                    kelompok_debitur: "Kelompok",
                    kota: "Kota",
                    provinsi: "Provinsi",
                    tenor: "Angsuran",
                    nilai_pokok_ditagihkan: "Rp 1.000.000",
                    nilai_bunga_ditagihkan: "Rp 1.000.000",
                    total_ditagihkan: "Rp 2.000.000",
                    nilai_pokok_pengembalian: "1.000.000",
                    nilai_bunga_pengembalian: "1.000.000",
                    total_pengembalian: "Rp 2.000.000",
                    action: "Action",
                  },
                ]}
                //   onChangePage={setNewPage}
                //   onChangeRowsPerPage={setNewLimit}
                //   pagination={{
                //     page: submissions?.paginator?.page,
                //     limit: submissions?.paginator?.limit,
                //     total: submissions?.paginator?.itemCount,
                //     previous_pages: submissions?.paginator?.page > 1,
                //     next_pages: !(
                //       submissions?.paginator?.page ===
                //       submissions?.paginator?.pageCount
                //     ),
                //   }}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex-1 flex items-center justify-between space-x-4 px-8 py-4">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1 max-w-[100px]"
            //   disabled={isLoading}
            onClick={() => {
              navigate("/reconciliation-accounting");
            }}
          >
            Batal
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 space-x-1 max-w-[100px]"
              disabled={isLoading || _submitButton?.disabled}
              onClick={onSubmit}
          >
            <span>{"Submit"}</span>
            {isLoading && <CgSpinner className="animate-spin" />}
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default ReconciliationDetail;
