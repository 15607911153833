import React, { useEffect, useState } from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { Spinner, Pagination } from "components";

const CustomTable = ({
  columns = [],
  dataSource = [],
  isLoading = false,
  stripped = false,
  wrapperClassName = "",
  headerClassName = "",
  className = "",
  layout = "auto",
  defaultSortOrder = undefined,
  pagination = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
  hidePagination = false,
  setState = () => {},
}) => {
  const [allChecked, setAllChecked] = useState(false);

  const handleCheckAll = () => {
    if (allChecked) {
      setState((prevDataSource) =>
        prevDataSource.map((data) => ({ ...data, checked: false }))
      );
    } else if (!allChecked) {
      setState((prevDataSource) => {
        return prevDataSource.map((data) => ({ ...data, checked: data?.status === 'Paid' ? false : true }));
      });
    }
    setAllChecked(!allChecked);
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const checked = dataSource?.filter((data)=> {
        return data.status !== 'Paid'
      }).every((data) => data.checked);
      setAllChecked(checked);
    } else {
      setAllChecked(false);
    }
  }, [dataSource]);

  return (
    <div className="border-[1px] border-solid border-gray-200 rounded-md">
      <div
        className={classNames(
          ["overflow-x-auto", "overflow-y-hidden"],
          [...wrapperClassName.split(" ")]
        )}
      >
        <table
          className={classNames(
            ["table min-w-full"],
            { "table-auto": layout === "auto" },
            { "table-fixed": layout === "fixed" },
            {
              "table-striped": stripped,
            },
            [...className.split(" ")]
          )}
        >
          {layout === "fixed" && <TableColumn items={columns} />}
          <TableHeader
            items={columns}
            headerClassName={headerClassName}
            defaultSortOrder={defaultSortOrder}
            onCheckAll={handleCheckAll}
            allChecked={allChecked}
            setState={setState}
          />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-100 h-[300px]">
                    <Spinner className="text-xl" />
                  </div>
                </td>
              </tr>
            ) : (
              <TableRow
                columns={columns}
                datas={dataSource}
                hidePagination={hidePagination}
                allChecked={allChecked}
                setState={setState}
              />
            )}
          </tbody>
        </table>
      </div>
      {!hidePagination && (
        <Pagination
          pagination={pagination}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
        />
      )}
    </div>
  );
};

export default CustomTable;
