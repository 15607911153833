import MainPage from "pages";

import ChartOfAccount from "pages/ChartOfAccount";
import CoaProject from "pages/ChartOfAccountProject";

import ManualJournal from "pages/ManualJournal";
import ManualJournalForm from "pages/ManualJournal/form";

import GeneralLedger from "pages/GeneralLedger";
import GeneralLedgerForm from "pages/GeneralLedger/form";

import TransactionJournalMapping from "pages/TransactionJournalMapping";

import AccountRequest from "pages/AccountRequest";
import AccountRequestDetail from "pages/AccountRequest/detail";
import AccountRequestForm from "pages/AccountRequest/form";

import AccountGroup from "pages/AccountGroup";
import AccountGroupDetail from "pages/AccountGroup/detail";
import AccountGroupForm from "pages/AccountGroup/form";

import Budget from "pages/Budget";
import BudgetDetail from "pages/Budget/Detail";

import BudgetRevision from "pages/BudgetRevision";
import BudgetRevisionDetail from "pages/BudgetRevision/detail";
import BudgetRevisionForm from "pages/BudgetRevision/form";

import DownPayment from "pages/DownPayment";
import AccountabilityApproval from "pages/DownPayment/AccountabilityApproval";
import DownPaymentActivityDetail from "pages/DownPayment/detail";
import DownPaymentActivityForm from "pages/DownPayment/form";
import VerificationRequest from "pages/DownPayment/VerificationRequest";
import FinanceReport from "pages/FinanceReport";

import SubmitAccountabilityApproval from "pages/DownPayment/AccountabilityApproval/submit";
import ActivityDetailPum from "pages/DownPayment/ActivityDetailPum";
import ReconciliationList from "pages/ReconciliationList";
import Reconciliation from "pages/ReconciliationList/reconciliation";

import DirectTransaction from "pages/DirectTransaction";
import DirectTransactionDetail from "pages/DirectTransaction/detail";
import DirectTransactionForm from "pages/DirectTransaction/form";
import DirectTrxVerificationRequest from "pages/DirectTransaction/VerificationRequest";

import BankCashFlow from "pages/BankCashFlow";
import BankCashFlowDetail from "pages/BankCashFlow/detail";
import BankCashFlowForm from "pages/BankCashFlow/form";
import CashFlow from "pages/CashFlow";
import CashFlowDetail from "pages/CashFlow/detail";
import CashFlowForm from "pages/CashFlow/form";
import CashReceiving from "pages/CashReceiving";
import CashReceivingDetail from "pages/CashReceiving/detail";
import CashReceivingForm from "pages/CashReceiving/form";
import GeneralSettingsBank from "pages/GeneralSettings/Bank";
import GeneralSettingsDeposit from "pages/GeneralSettings/Deposit";
import OperationalAdvance from "pages/OperationalAdvance";
import OperationalAdvanceForm from "pages/OperationalAdvance/form";
import OperationalAdvanceRealization from "pages/OperationalAdvance/Realization";
import PaymentRequest from "pages/PaymentRequest";
import PaymentRequestForm from "pages/PaymentRequest/form";

import WriteOffRequest from "pages/WriteOffRequest";
import WriteOffDetail from "pages/WriteOffRequest/detail";

import PaymentRequestFDB from "pages/PaymentRequestFDB";
import PaymentRequestFDBDetail from "pages/PaymentRequestFDB/detail";
import ProjectAccount from "pages/ProjectAccount";
import ProjectAccountForm from "pages/ProjectAccount/form";
import ProjectAccountTrxInputForm from "pages/ProjectAccount/transaction-input";
import ProjectAccountDetail from "pages/ProjectAccount/detail";
import ProjectAccountTrxHistory from "pages/ProjectAccount/transaction-history";

import PaymentExecutionFDB from "pages/PaymentRequestFDB/payment-execution";
import ExecutionFDBDetail from "pages/PaymentRequestFDB/detail-execution";
import { element } from "prop-types";

import RequestProjectPayment from "pages/RequestProjectPayment";
import RequestProjectPaymentDetail from "pages/RequestProjectPayment/detail";

import ProjectPayment from "pages/ProjectPayment";
import ProjectPaymentDetail from "pages/ProjectPayment/detail";
import Dashboard from "pages/Dashboard";
import ReconciliationAccounting from "pages/ReconciliationAccounting";
import ReconciliationApprove from "pages/ReconciliationAccounting/approve-reconciliation";
import ReconciliationDetail from "pages/ReconciliationAccounting/detail-reconciliation";
import ReconciliationDetailTransaction from "pages/ReconciliationAccounting/detail-transaction";

const dashboard = [
  {
    routeType: "public",
    path: "/dashboard",
    element: Dashboard,
    exact: true,
    key: "dashboard",
    type: "canView",
  },
];

const downPayment = [
  {
    routeType: "public",
    path: "/down-payment-activity",
    element: DownPayment,
    exact: true,
    key: "down-payment-activity",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/add",
    element: DownPaymentActivityForm,
    exact: true,
    key: "down-payment-activity",
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/edit/:id",
    element: DownPaymentActivityForm,
    exact: true,
    key: "down-payment-activity",
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/detail/:id",
    element: DownPaymentActivityDetail,
    exact: true,
    key: "down-payment-activity",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/verification-request/:id",
    element: VerificationRequest,
    exact: true,
    key: "down-payment-activity",
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/accountability-approval/:id",
    element: AccountabilityApproval,
    exact: true,
    key: "down-payment-activity",
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/submit-accountability-approval/:id",
    element: SubmitAccountabilityApproval,
    exact: true,
    key: "down-payment-activity",
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/down-payment-activity/activity-detail-pum/:id",
    element: ActivityDetailPum,
    exact: true,
    key: "down-payment-activity",
    type: "canUpdate",
  },
];

const directTransaction = [
  {
    routeType: "public",
    path: "/direct-transactions",
    element: DirectTransaction,
    exact: true,
    key: "direct-transactions",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/direct-transactions/add",
    element: DirectTransactionForm,
    exact: true,
    key: "direct-transactions",
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/direct-transactions/detail/:id",
    element: DirectTransactionDetail,
    exact: true,
    key: "direct-transactions",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/direct-transactions/edit/:id",
    element: DirectTransactionForm,
    exact: true,
    key: "direct-transactions",
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/direct-transactions/verification-request/:id",
    element: DirectTrxVerificationRequest,
    exact: true,
    key: "direct-transactions",
    type: "canUpdate",
  },
];

const operationalAdvances = [
  {
    routeType: "public",
    path: "/operational-advances",
    key: "operational-advances",
    element: OperationalAdvance,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/operational-advances/add",
    key: "operational-advances",
    element: OperationalAdvanceForm,
    exact: true,
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/operational-advances/detail/:id",
    key: "operational-advances",
    element: OperationalAdvanceForm,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/operational-advances/edit/:id",
    key: "operational-advances",
    element: OperationalAdvanceForm,
    exact: true,
    type: "canUpdate",
  },
  {
    routeType: "public",
    path: "/operational-advances/approval/:id",
    key: "operational-advances",
    element: OperationalAdvanceForm,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/operational-advances/realization/:id",
    key: "operational-advances",
    element: OperationalAdvanceRealization,
    exact: true,
    type: "canView",
  },
];

const treasurerExpenses = [
  {
    routeType: "public",
    key: "payment-request",
    path: "/payment-request",
    element: PaymentRequest,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/payment-request/detail/:id",
    key: "payment-request",
    element: PaymentRequestForm,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/payment-request/pay/:id",
    key: "payment-request",
    element: PaymentRequestForm,
    exact: true,
    type: "canCreate",
  },
  // Receiving
  {
    routeType: "public",
    path: "/cash-receiving",
    key: "cash-receiving",
    element: CashReceiving,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/cash-receiving/create",
    key: "cash-receiving",
    element: CashReceivingForm,
    exact: true,
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/cash-receiving/:id",
    key: "cash-receiving",
    element: CashReceivingDetail,
    exact: true,
    type: "canView",
  },

  {
    routeType: "public",
    path: "/bank-cash-flow/receipt",
    key: "bank-cash-flow-receipt",
    element: BankCashFlow,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/bank-cash-flow/receipt/create",
    key: "bank-cash-flow-receipt",
    element: BankCashFlowForm,
    exact: true,
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/bank-cash-flow/receipt/detail/:id",
    key: "bank-cash-flow-receipt",
    element: BankCashFlowDetail,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/bank-cash-flow/expense",
    key: "bank-cash-flow-expense",
    element: BankCashFlow,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/bank-cash-flow/expense/create",
    key: "bank-cash-flow-expense",
    element: BankCashFlowForm,
    exact: true,
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/bank-cash-flow/expense/detail/:id",
    key: "bank-cash-flow-expense",
    element: BankCashFlowDetail,
    exact: true,
    type: "canView",
  },

  {
    routeType: "public",
    path: "/cash-flow",
    key: "cash-flow",
    element: CashFlow,
    exact: true,
    type: "canView",
  },
  {
    routeType: "public",
    path: "/cash-flow/create",
    key: "cash-flow",
    element: CashFlowForm,
    exact: true,
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/cash-flow/:id",
    key: "cash-flow",
    element: CashFlowDetail,
    exact: true,
    type: "canView",
  },
];

const coa = [
  {
    routeType: "public",
    parentPath: "/chart-of-account",
    path: "/chart-of-account",
    element: ChartOfAccount,
    exact: true,
    key: "chart-of-account",
    type: "canView",
  },
];

const coaProject = [
  {
    routeType: "public",
    parentPath: "/coa-project",
    path: "/coa-project",
    element: CoaProject,
    exact: true,
    key: "coa-project",
    type: "canView",
  },
];

const manualJournal = [
  {
    routeType: "public",
    path: "/manual-journal",
    element: ManualJournal,
    exact: true,
  },
  {
    routeType: "public",
    path: "/add-manual-journal",
    element: ManualJournalForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/edit-manual-journal",
    element: ManualJournalForm,
    exact: true,
  },
];

const generalLedger = [
  {
    routeType: "public",
    parentPath: "/general-ledger",
    path: "/general-ledger",
    element: GeneralLedger,
    exact: true,
    key: "general-ledger",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/general-ledger",
    path: "/add-general-ledger",
    element: GeneralLedgerForm,
    exact: true,
    key: "general-ledger",
    type: "canCreate",
  },
  {
    routeType: "public",
    parentPath: "/general-ledger",
    path: "/edit-general-ledger",
    element: GeneralLedgerForm,
    exact: true,
    key: "general-ledger",
    type: "canUpdate",
  },
];

const tjm = [
  {
    routeType: "public",
    parentPath: "/transaction-journal-mapping",
    path: "/transaction-journal-mapping",
    element: TransactionJournalMapping,
    exact: true,
    key: "transaction-journal-mapping",
    type: "canView",
  },
];

const ar = [
  {
    routeType: "public",
    parentPath: "/account-request",
    path: "/account-request",
    element: AccountRequest,
    exact: true,
    key: "account-request",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/account-request",
    path: "/detail-account-request",
    element: AccountRequestDetail,
    exact: true,
    key: "account-request",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/account-request",
    path: "/edit-account-request",
    element: AccountRequestForm,
    exact: true,
    key: "account-request",
    type: "canUpdate",
  },
];

const ag = [
  {
    routeType: "public",
    parentPath: "/authority",
    path: "/authority",
    element: AccountGroup,
    exact: true,
    key: "authority",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/authority",
    path: "/detail-authority",
    element: AccountGroupDetail,
    exact: true,
    key: "authority",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/authority",
    path: "/edit-authority",
    element: AccountGroupForm,
    exact: true,
    key: "authority",
    type: "canUpdate",
  },
];

const budget = [
  {
    routeType: "public",
    parentPath: "/budget",
    path: "/budget",
    element: Budget,
    exact: true,
    key: "budget",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/budget",
    path: "/budget/detail",
    element: BudgetDetail,
    exact: true,
    key: "budget",
    type: "canView",
  },
];

const br = [
  {
    routeType: "public",
    parentPath: "/budget-revision",
    path: "/budget-revision",
    element: BudgetRevision,
    exact: true,
    key: "budget-revision",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/budget-revision",
    path: "/detail-budget-revision",
    element: BudgetRevisionDetail,
    exact: true,
    key: "budget-revision",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/budget-revision",
    path: "/edit-budget-revision",
    element: BudgetRevisionForm,
    exact: true,
    key: "budget-revision",
    type: "canUpdate",
  },
];

const fr = [
  {
    routeType: "public",
    parentPath: "/finance-report",
    path: "/finance-report",
    element: FinanceReport,
    exact: true,
    key: "finance-report",
    type: "canView",
  },
];

const reconciliation = [
  {
    routeType: "public",
    parentPath: "/reconciliation-list",
    path: "/reconciliation-list",
    element: ReconciliationList,
    exact: true,
    key: "reconciliation",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/reconciliation",
    path: "/reconciliation",
    element: Reconciliation,
    exact: true,
    key: "reconciliation",
    type: "canView",
  },
];

const reconciliationAccounting = [
  {
    routeType: "public",
    parentPath: "/reconciliation-accounting",
    path: "/reconciliation-accounting",
    element: ReconciliationAccounting,
    exact: true,
    key: "reconciliation-accounting",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/reconciliation-accounting",
    path: "/reconciliation-accounting/:id",
    element: ReconciliationApprove,
    exact: true,
    key: "reconciliation-accounting",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/reconciliation-accounting/detail-reconciliation",
    path: "/reconciliation-accounting/detail-reconciliation",
    element: ReconciliationDetail,
    exact: true,
    key: "reconciliation-accounting",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/reconciliation-accounting",
    path: "/reconciliation-accounting/detail-reconciliation/:id",
    element: ReconciliationDetailTransaction,
    exact: true,
    key: "reconciliation-accounting",
    type: "canView",
  }
];

const writeOff = [
  {
    routeType: "public",
    parentPath: "/write-off-request",
    path: "/write-off-request",
    element: WriteOffRequest,
    exact: true,
    key: "write-off-request",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/write-off-request",
    path: "/write-off-request/detail/:id",
    element: WriteOffDetail,
    exact: true,
    key: "write-off-request",
    type: "canView",
  },
];

const projectAccount = [
  {
    routeType: "public",
    parentPath: "/project-account",
    path: "/project-account",
    element: ProjectAccount,
    exact: true,
    key: "project-account",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/project-account/trx-input",
    element: ProjectAccountTrxInputForm,
    exact: true,
    key: "project-account",
    type: "canCreate",
  },
  {
    routeType: "public",
    path: "/project-account/trx-history",
    element: ProjectAccountTrxHistory,
    exact: true,
    key: "project-account",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/project-account/add",
    element: ProjectAccountForm,
    exact: true,
    key: "project-account",
    type: "canCreate",
  }
];

const requestProjectPayment = [
  {
    routeType: "public",
    parentPath: "/request-project-payment",
    path: "/request-project-payment",
    element: RequestProjectPayment,
    exact: true,
    key: "request-project-payment",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/request-project-payment",
    path: "/request-project-payment/:id",
    element: RequestProjectPaymentDetail,
    exact: true,
    key: "request-project-payment",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/request-project-payment",
    path: "/request-project-payment/review/:id",
    element: RequestProjectPaymentDetail,
    exact: true,
    key: "request-project-payment",
    type: "canView",
  }
]

const projectPayment = [
  {
    routeType: "public",
    parentPath: "/project-payment",
    path: "/project-payment",
    element: ProjectPayment,
    exact: true,
    key: "project-payment",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/project-payment",
    path: "/project-payment/:id",
    element: ProjectPaymentDetail,
    exact: true,
    key: "project-payment",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/project-payment",
    path: "/project-payment/payment/:id",
    element: ProjectPaymentDetail,
    exact: true,
    key: "project-payment",
    type: "canView",
  }
]

const paymentRequestFDB = [
  {
    routeType: "public",
    parentPath: "/payment-request-fdb",
    path: "/payment-request-fdb",
    // element: PaymentRequestFDB,
    element: PaymentExecutionFDB,
    exact: true,
    key: "payment-request-fdb",
    type: "canView",
  },
  {
    routeType: "public",
    parentPath: "/payment-request-fdb",
    path: "/payment-request-fdb/detail/:id",
    // element: PaymentRequestFDBDetail,
    element: ExecutionFDBDetail,
    exact: true,
    key: "payment-request-fdb",
    type: "canView",

  },

];

const executionFDB = [
  {
    routeType: "public",
    // parentPath: "/payment-request-fdb",
    path: "/executions-fdb",
    element: PaymentExecutionFDB,
    exact: true,
    key: "executions-fdb",
    type: "canView",
  },
  {
    routeType: "public",
    // parentPath: "/payment-request-fdb",
    path: "/executions-fdb/detail/:id",
    element: ExecutionFDBDetail,
    exact: true,
    key: "executions-fdb",
    type: "canView",
  },
]

const generalSettings = [
  {
    routeType: "public",
    path: "/general-settings/bank",
    element: GeneralSettingsBank,
    exact: true,
    key: "general-settings-bank",
    type: "canView",
  },
  {
    routeType: "public",
    path: "/general-settings/deposit",
    element: GeneralSettingsDeposit,
    exact: true,
    key: "general-settings-deposit",
    type: "canView",
  },
];

const routesName = [
  {
    routeType: "public",
    path: "/",
    element: MainPage,
    exact: true,
    key: "dashboard",
    type: "canView",
  },
  ...dashboard,
  ...downPayment,
  ...directTransaction,
  ...operationalAdvances,
  ...coaProject,
  ...coa,
  // ...manualJournal,
  ...generalLedger,
  ...tjm,
  ...ar,
  ...ag,
  ...budget,
  ...br,
  ...fr,
  ...reconciliation,
  ...reconciliationAccounting,
  ...treasurerExpenses,
  ...projectAccount,
  ...requestProjectPayment,
  ...projectPayment,
  ...generalSettings,
  ...writeOff,
  ...paymentRequestFDB,
  ...executionFDB,
];

export default routesName;
