import config from 'app/config';
import axiosInstance from 'app/interceptors';

export const getGeneralLedgerList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/manual-journal`, { params, signal });
  return data;
}

export const getGeneralLedgerDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/manual-journal/${id}`);
  return data;
}

export const createGeneralLedgerData = async (params) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/manual-journal`, params);
  return data;
}

export const updateGeneralLedgerData = async (params) => {
  const { data } = await axiosInstance.put(`${config.BASE_URL}/manual-journal/${params?.id}`, params?.payload);
  return data;
}

export const deleteGeneralLedgerData = async (params) => {
  const { data } = await axiosInstance.delete(`${config.BASE_URL}/manual-journal/${params?.id}`);
  return data;
}