import { useQuery, useMutation } from "react-query";
import { GL } from "services";

export const useGeneralLedger = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return GL.getGeneralLedgerList({ ...params }, signal);
    },
    ...options
  });
};

export const useGeneralLedgerDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) return GL.getGeneralLedgerDetail(params);
      return null;
    },
    ...options
  });
};

export const usePostGeneralLedger = () => {
  return useMutation((payload) => GL.createGeneralLedgerData(payload))
}

export const usePutGeneralLedger = () => {
  return useMutation((payload) => GL.updateGeneralLedgerData(payload))
}

export const useDeleteGeneralLedger = () => {
  return useMutation((payload) => GL.deleteGeneralLedgerData(payload))
}