import { BottomFormAction, Input, Table } from "components";
import { Tab, Tabs } from "components/Tabs";
import { useContext } from "react";
import { FormHelperContext } from ".";
import {
  useActivityRecapQuery,
  useSubmitActivityDetail,
} from "hooks/downPayment";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { convertToIDR } from "../util";

export const RecapTab = ({ activityType, showMembers }) => {
  const { detail } = useContext(FormHelperContext);

  const { data } = useActivityRecapQuery([
    "dp-activity-type-detail",
    {
      id: detail?.id,
      activityTypeId: activityType?.id,
    },
  ]);

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "activity_detail",
      title: "Detail Kegiatan",
      dataIndex: "detail_code",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
            <span className="font-medium">{data?.name}</span>
            <span>{value}</span>
          </div>
        );
      },
    },
    {
      id: "quantity",
      title: "Qty",
      dataIndex: "qty",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "0"}
          </div>
        );
      },
    },
    {
      id: "volume",
      title: "Volume",
      dataIndex: "volume",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "0"}
          </div>
        );
      },
    },
    {
      id: "price",
      title: "Harga SBM",
      dataIndex: "price",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(data?.subtotal / (data?.volume ?? data?.qty))}
          </div>
        );
      },
    },
    {
      id: "subtotal",
      title: "Sub Total",
      dataIndex: "subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(value)}
          </div>
        );
      },
    },
  ];

  return (
    <div className="space-y-3">
      {showMembers && (
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="text-lg font-semibold">Peserta yang Diundang</div>
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Narasumber</span>
                </label>
                <Input value={data?.expert_total} className="w-full" disabled />
              </div>
              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Peserta Pegawai</span>
                </label>
                <Input value={data?.staff_total} className="w-full" disabled />
              </div>

              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Undangan</span>
                </label>
                <Input value={data?.invite_total} className="w-full" disabled />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-4">
          <div className="border-b-[1px] border-gray-100 pb-4">
            <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
              Total Nilai:{" "}
              <span className="font-bold">{convertToIDR(data?.total)}</span>
            </span>
          </div>
          {data?.accounts?.map((account) => (
            <div
              className="rounded-lg border-[1px] border-gray-100"
              key={account?.id}
            >
              <div className="flex flex-row gap-4 items-center bg-[#E1F1D6] px-4 py-2 rounded-t-lg">
                <span className="text-primary-600 font-bold">
                  Akun Belanja:{" "}
                  <span className="font-medium">{account?.account_code}</span>
                </span>
                <span className="text-sm inline-block font-semibold bg-warning-50 text-warning-700 rounded-xl px-3 py-1 mix-blend-multiply">
                  Total Nilai:{" "}
                  <span className="font-bold">
                    {convertToIDR(account?.subtotal)}
                  </span>
                </span>
              </div>
              <div className="p-4">
                <Table
                  bordered
                  stripped
                  layout="fixed"
                  className="mb-4 rounded-xl"
                  columns={tableColumns}
                  dataSource={account?.details}
                  pagination={{
                    page: 1,
                    limit: 20,
                    total: account?.details?.length,
                    previous_pages: false,
                    next_pages: false,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Recap = () => {
  const { detail } = useContext(FormHelperContext);
  const submit = useSubmitActivityDetail();
  const navigate = useNavigate();

  const handleSubmit = () => {
    submit.mutate(
      {
        id: detail?.id,
        payload: {
          is_draft: false,
        },
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Tabs>
        {detail?.activity_types?.map((activityType) => (
          <Tab label={`Realisasi ${activityType?.name}`} key={activityType?.id}>
            <RecapTab activityType={activityType} />
          </Tab>
        ))}
      </Tabs>
      <BottomFormAction
        onSubmit={handleSubmit}
        _submitButton={{
          label: "Submit",
        }}
        hideCancelButton
      />
    </>
  );
};
