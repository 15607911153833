import { Button } from "components";
import Accordion from "components/Accordion";
import { NumberInputForm, SelectAccountForm } from "components/HookForm";
import { map } from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router";

const Activity = ({ name, parentName }) => {
  const { control, watch } = useFormContext();
  const {
    fields: activities,
    append: addActivity,
    remove: removeActivity,
  } = useFieldArray({
    control,
    name,
  });

  const location = useLocation();
  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  return (
    <div className="space-y-4">
      {map(activities, (activity, key) => (
        <Accordion
          key={activity?.id}
          label={`Detail Kegiatan ${key + 1}`}
          renderRight={
            !isDetail
              ? () => (
                  <Button
                    iconOnly
                    startIcon={<BiTrash size={20} />}
                    className="px-2 mr-2 bg-red-600 border-red-600"
                    onClick={() => removeActivity(key)}
                  />
                )
              : null
          }
        >
          <div className="space-y-4">
            <SelectAccountForm
              label="Detail Kegiatan"
              placeholder="Pilih detail kegiatan"
              name={`${name}.${key}.activity_detail`}
              level="11"
              parentId={watch(parentName)?.id}
              isDisabled={!watch(parentName)?.id || isDetail}
            />
            <NumberInputForm
              name={`${name}.${key}.value`}
              className={`border w-full rounded-md`}
              label="Nilai Pengajuan"
              placeholder="Masukkan nilai pengajuan"
              disabled={isDetail}
            />
          </div>
        </Accordion>
      ))}
      {!isDetail && (
        <div className="flex items-center justify-end w-full">
          <Button
            startIcon={<FiPlus />}
            className=" bg-white border-[1px] border-gray-200 rounded-md text-black hover:text-white"
            onClick={() => addActivity({ activity_detail: null, value: "" })}
          >
            Tambah Detail Kegiatan
          </Button>
        </div>
      )}
    </div>
  );
};

export default Activity;
