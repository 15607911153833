import React from "react";
import { Input } from "components";
import { useFormContext, Controller } from "react-hook-form";

const InputForm = ({ name, label, required, onValueChanged, ...props }) => {
  const { control } = useFormContext();

  const handleOnChange = (event, onChange) => {
    const value = event?.target?.value;
    onChange(event);
    onValueChanged && onValueChanged(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <Input
            value={value}
            onChange={(event) => handleOnChange(event, onChange)}
            isError={invalid}
            {...props}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default InputForm;
