import { FiPlus } from "react-icons/fi";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { usePaymentRequestQuery } from "hooks/cash";
import { debounce } from "lodash";
import moment from "moment";
import { convertToIDR } from "pages/DownPayment/util";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import StatusBadge from "./StatusBadge";
import CustomDatepickerForm from "components/CustomDatepicker";
import { useSelector } from "react-redux";

const PaymentRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [dateFilter, setDateFilter] = useState("");
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [type, setType] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: expenses } = usePaymentRequestQuery([
    "treasurer-expense-list",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      type: type?.value,
      status: statusFilter?.value,
      startDate: dateFilter
        ? moment(dateFilter).format("YYYY-MM-DD")
        : undefined,
      endDate: dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : undefined,
    },
  ]);

  const links = [
    {
      label: "Daftar Permintaan Pembayaran",
      path: "/payment-request",
    },
    {
      label: "Daftar Permintaan Pembayaran",
    },
  ];

  const renderStatusAction = (data) => {
    switch (data?.status) {
      case "unpaid":
        return (
          <Button
            onClick={() => navigate(`/payment-request/pay/${data?.id}`)}
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Bayar
          </Button>
        );
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/payment-request/detail/${data?.id}`)}
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "created_at",
      title: "Tanggal Permintaan",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "type",
      title: "Jenis Transaksi Pembayaran",
      dataIndex: "type",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "value",
      title: "Nilai Pembayaran",
      dataIndex: "value",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
    {
      id: "requester_name",
      title: "Requester",
      dataIndex: "requester_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "payment_date",
      title: "Tanggal Pembayaran",
      dataIndex: "payment_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
      columnClassName: "right-[181px] block shadow-lg",
      fixed: "right",
      sortable: true,
      render: (value) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadge status={value} role={role} />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Permintaan Pembayaran</TitleText>
        {role === "pjk" && roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() =>
              navigate("/operational-advances/add", { state: { role } })
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah Data
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              <CustomSelect
                value={type}
                optionsData={[
                  { value: "UM", label: "UM" },
                  { value: "UMO", label: "UMO" },
                  { value: "LS", label: "LS" },
                ]}
                label="Jenis Transaksi"
                placeholder="Jenis Transaksi"
                className="w-1/6"
                onChange={(value) => {
                  setType(value);
                }}
              />
              <CustomSelect
                value={statusFilter}
                optionsData={[
                  { value: "paid", label: "Telah Dibayar" },
                  { value: "unpaid", label: "Belum Dibayar" },
                ]}
                label="Status"
                placeholder="Status"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              />
              {/* <Input className="flex-1" placeholder="dd/mm/yyyy" /> */}
              <div className="flex-1">
                <CustomDatepickerForm
                  name={`start_date`}
                  placeholder={"dd/mm/yyyy"}
                  onChange={setDateFilter}
                  value={dateFilter}
                />
              </div>
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={expenses?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: expenses?.paginator?.page,
                limit: expenses?.paginator?.limit,
                total: expenses?.paginator?.itemCount,
                previous_pages: expenses?.paginator?.page > 1,
                next_pages: !(
                  expenses?.paginator?.page === expenses?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentRequest;
