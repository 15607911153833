import React, { useEffect } from "react";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const ChildCoaForm = ({ name }) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      `${name}.full_code`,
      `${watch("parent_account")?.code}${
        watch(`${name}.account_code`) ? `.${watch(`${name}.account_code`)}` : ""
      }`
    );
  }, [watch(`${name}.account_code`)]);

  return (
    <div className="flex items-center gap-4 flex-1">
      <InputForm
        name={`${name}.account_code`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Kode Akun"}
        placeholder={"Kode Akun Baru"}
        required={true}
      />
      <InputForm
        name={`${name}.account_name`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Nama Akun Baru"}
        placeholder={"Nama Akun Baru"}
        required={true}
      />
      <InputForm
        name={`${name}.full_code`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Fullcode"}
        placeholder={"Fullcode"}
        required={true}
        disabled
      />
    </div>
  );
};

export default ChildCoaForm;
