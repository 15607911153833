import { Breadcrumbs } from "components";
import { Step, Steps } from "components/Steps";
import { useActivityDetailQuery } from "hooks/downPayment";
import { createContext, useState } from "react";
import { useParams } from "react-router";
import InformationForm from "./information-form";
import { RABForm } from "./rab-form";
import { Recap } from "./recap";

const dummyData = {
  activity_detail: "Tiket Pesawat",
  quantity: "100",
  volume: "100",
  price: "Rp 10,000",
  subtotal: "Rp 10,000,000",
};

export const FormHelperContext = createContext({
  activeStep: 0,
  setActiveStep: () => {},
  handleNext: () => {},
  handlePrevious: () => {},
  detail: null,
  selectedSbm: null,
  setSelectedSbm: () => {},
});

const ActivityDetailPum = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSbm, setSelectedSbm] = useState(null);
  const params = useParams();

  const { data: detail } = useActivityDetailQuery([
    "dp-detail-pum",
    params?.id,
  ]);

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Lengkapi Rincian Detail Kegiatan",
    },
  ];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <FormHelperContext.Provider
      value={{
        activeStep,
        detail,
        handleNext,
        handlePrevious,
        setActiveStep,
        selectedSbm,
        setSelectedSbm,
      }}
    >
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <Steps activeStep={activeStep}>
        <Step label="Informasi Kegiatan">
          <InformationForm />
        </Step>
        {detail?.activity_types?.map((activityType) => (
          <Step label={activityType?.name} key={activityType?.id}>
            <RABForm activityTypeId={activityType?.id} />
          </Step>
        ))}

        <Step label="Rekap">
          <Recap />
        </Step>
      </Steps>
      <div className="h-24" />
    </FormHelperContext.Provider>
  );
};

export default ActivityDetailPum;
