import { useQuery } from "react-query";
import { DASHBOARD } from "services";

export const useDashboard = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DASHBOARD.getDashboard({ ...params }, signal);
    },
    ...options
  });
};