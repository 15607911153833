import { Breadcrumbs, Card, CardBody, CardHeader, TitleText } from "components";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PiCoins, PiMoney, PiMoneyWavy } from "react-icons/pi";
import { Chart } from "react-google-charts";
import { GoKebabHorizontal } from "react-icons/go";
import { TbClipboardText } from "react-icons/tb";
import Card2 from "components/Card/card2";
import { useDashboard } from "hooks/dashboard";
import { convertToIDR } from "helpers";

function MonthYearPicker({onChange}) {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {setStartDate(date); onChange(date)}}
      dateFormat="yyyy"
      showYearPicker
      className="border border-gray-300 rounded-md px-2 py-1 w-32 text-sm"
    />
  );
}

function HeroComponent({
  title = "Total Anggaran",
  value = "Rp. 100.000.000",
  icon = <PiMoneyWavy color="#0086C9" size={22} />,
  bgColor = "#0086C9",
  bgColorIcon = "#EAFEFF",
}) {
  return (
    <div className="flex w-full">
      <div
        style={{
          backgroundColor: bgColor,
        }}
        className={`w-[100%] h-auto text-white p-4 rounded-xl flex flex-col gap-4`}
      >
        <div
          className="flex justify-center items-center w-10 h-10 rounded-xl"
          style={{
            backgroundColor: bgColorIcon,
          }}
        >
          {icon}
        </div>
        <div
          style={{
            backgroundColor: `rgba(255, 255, 255, 0.3)`,
            borderRadius: "12px",
          }}
        >
          <div className="rounded-xl border-white border-[1px] p-4 flex flex-col gap-2">
            <div className="text-md">{title}</div>
            <div
              className="text-xl font-bold"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {value}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { MonthYearPicker };

export const data = [
  ["Task", "Percentage"],
  ['rasio_realized_budget', 11],
  ['', 4],
];

export const options = {
  title: "",
  pieHole: 0.4,
  legend: "none",
  is3D: false,
  pieStartAngle: 135,
  tooltip: { isHtml: true },
  slices: {
    0: { color: "#89C053" },
    1: { color: "#E0E0E0" },
  },
};

export const data2 = [
  ["Belanja Pegawai", "Percentage"],
  ["Belanja Barang", 11],
  ["Belanja Jasa", 2],
  ["Belanja Perjalanan", 2],
  ["Belanja Pegawai", 2],
];

export const options2 = {
  title: "",
  // legend: "none",
  is3D: false,
  pieStartAngle: 90,
  tooltip: { isHtml: true },
};

export const data3 = [
  ["Permohonan", "Permohonan", { role: "style" }],
  ["Baru", 8.94, "color: green"], // RGB value
  ["Menunggu Approval PJK", 10.49, "color: green"], // English color name
  ["Menunggu Approval Staf PPK", 19.3, "color: green"],
  ["Menunggu Approval PPK", 21.45, "color: green"],
  ["Menunggu Pembayaran", 21.45, "color: green"],
  ["Perlu LPJ", 21.45, "color: green"],
  ["Menunggu Approval PJK", 21.45, "color: green"],
  ["Menunggu Approval Staf PPK", 21.45, "color: green"],
  ["Menunggu Approval PPK", 21.45, "color: green"],
  ["Menunggu Approval PPSPM", 21.45, "color: green"],
  ["Menunggu Dibukukan", 21.45, "color: green"],
  ["Telah Disahkan", 21.45, "color: green"],
];

export const options3 = {
  title: "",
  height: 400,
  bar: { groupWidth: "90%" },
  legend: "none",
};

function Dashboard() {

  const [year, setYear] = useState(new Date().getFullYear())

  const { data: dashboard, refetch } = useDashboard([
    "dashboard",
    {
      year,
    },
  ]);

  const links = [
    {
      label: "Dashboard Anggaran",
    },
  ];
  
  return (
    <div>
      <Breadcrumbs items={links} />
      <div className={`md:flex block my-3 flex-col gap-4`}>
        <div className="flex justify-between gap-2">
          <TitleText className="flex-1">{"Dashboard Anggaran"}</TitleText>
          <div className="flex items-center space-x-3 gap-2">
            {/* <MonthYearPicker />
            <div>-</div> */}
            <MonthYearPicker onChange={(e) => setYear(e.getFullYear())}/>
          </div>
        </div>
        <div className="flex gap-4 justify-between">
          <HeroComponent
            value={`${convertToIDR(dashboard?.data?.total_budget)}`}
           />
          <HeroComponent
            value={`${convertToIDR(dashboard?.data?.total_outstanding)}`}
            bgColor="#F79009"
            icon={<PiMoney color="#F79009" size={22} />}
            title="Total Outstanding UM"
            bgColorIcon="#FFFCE7"
          />
          <HeroComponent
            value={`${convertToIDR(dashboard?.data?.total_realization)}`}
            bgColor="#6938EF"
            icon={<PiMoney color="#6938EF" size={22} />}
            title="Total Realisasi"
            bgColorIcon="#F4F3FF"
          />
          <HeroComponent
            value={`${convertToIDR(dashboard?.data?.total_remaining_pagu)}`}
            bgColor="#E93636"
            icon={<PiCoins color="#E93636" size={22} />}
            title="Sisa Pagu"
            bgColorIcon="#FCEEED"
          />
        </div>
        <div className="flex gap-2">
          <div className="card w-[50%] bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-3">
              <div className="flex justify-between ">
                <div className="font-bold text-[18px]">
                  Rasio Anggaran & Realisasi
                </div>
                <GoKebabHorizontal
                  className="text-gray-400 w-6 h-6 rotate-90 cursor-pointer"
                  color="black"
                />
              </div>
              <hr className="my-2" />
              <div className="space-y-4">
                <div className="space-x-5 flex items-center">
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={[
                      ["rasio budget", "Percentage", { role: "tooltip", type: "string", p: { html: true } }],
                      ['Sudah di realisasi', dashboard?.data?.rasio_realized_budget, `<div style='padding:10px'><strong>Sudah di realisasi</strong><br>${dashboard?.data?.rasio_realized_budget}%</div>`],
                      ['Belum di realisasi', 100 - dashboard?.data?.rasio_realized_budget, `<div style='padding:10px'><strong>Belum di realisasi</strong><br>${100 - dashboard?.data?.rasio_realized_budget}%</div>`],
                      ]}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card w-[50%] bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-3">
              <div className="space-y-4">
                <div className="flex justify-between ">
                  <div className="font-bold text-[18px]">Pendapatan {year} - {convertToIDR(dashboard?.data?.total_income)}</div>
                  <GoKebabHorizontal
                    className="text-gray-400 w-6 h-6 rotate-90 cursor-pointer"
                    color="black"
                  />
                </div>
                <hr className="my-2" />
                <div className="space-x-5 flex items-center">
                  {dashboard?.data?.income && (<Chart
                    chartType="PieChart"
                    data={[
                      ["rasio budget", "Percentage", { role: "tooltip", type: "string", p: { html: true } }],
                        ...dashboard?.data?.income.map(item => [
                          item.type.charAt(0).toUpperCase() + item.type.slice(1) + " Income", // Capitalize first letter of type
                          item.percentage,
                          `<div style='padding:10px'><strong>${item.type.charAt(0).toUpperCase() + item.type.slice(1)} Income</strong><br>${item.percentage}%</div>`
                        ])
                      ]}
                    options={options2}
                    width={"100%"}
                    height={"400px"}
                  />)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <CardHeader>Transaksi Uang Muka {year}</CardHeader>
          <CardBody>
            <div className="flex flex-col w-full gap-4">
              <div className="flex w-full gap-4">
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Outstanding Uang Muka
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.um?.outstanding)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Uang Muka yang belum dipertanggungjawabkan
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.um?.realization)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Total Nilai Pengajuan Uang Muka
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.um?.total)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                      Jumlah SPM
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${dashboard?.data?.um?.spm}`}
                      </span>
                    </div>
                  </div>
                </Card2>
              </div>
              <div className="flex space-x-4 w-full">
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Jumlah Pengajuan UM</span>
                    <span className="text-2xl font-semibold">
                      {`${dashboard?.data?.um?.total_application}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Belum Dipertanggung jawabakan</span>
                    <span className="text-2xl font-semibold">
                    {`${dashboard?.data?.um?.total_not_accountable}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Sudah DIpertanggung Jawabkan</span>
                    <span className="text-2xl font-semibold">
                    {`${dashboard?.data?.um?.total_accountable}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-8">
            <div className="font-bold text-[18px]">Grafik UM {year}</div>
              <Chart
                chartType="ColumnChart"
                data={
                  [
                    ["Permohonan", "Value", { role: "style" }],
                    ["Baru", dashboard?.data?.um?.um_grafik?.new, "green"],
                    ["Menunggu Approval PJK", dashboard?.data?.um?.um_grafik?.waiting_approval_pjk, "color: orange"],
                    ["Menunggu Approval Staf PPK", dashboard?.data?.um?.um_grafik?.waiting_approval_staff_ppk, "color: red"],
                    ["Menunggu Approval PPK", dashboard?.data?.um?.um_grafik?.waiting_approval_ppk, "color: blue"],
                    ["Menunggu Pembayaran", dashboard?.data?.um?.um_grafik?.waiting_payment, "color: purple"],
                    ["Perlu LPJ", dashboard?.data?.um?.um_grafik?.waiting_lpj, "color: cyan"],
                    ["Menunggu Approval LPJ", dashboard?.data?.um?.um_grafik?.waiting_approval_lpj, "color: magenta"],
                    ["Menunggu Approval Staf PPK LPJ", dashboard?.data?.um?.um_grafik?.waiting_approval_staff_ppk_for_lpj, "color: yellow"],
                    ["Menunggu Approval PPK LPJ", dashboard?.data?.um?.um_grafik?.waiting_approval_ppk_for_lpj, "color: gray"],
                    ["Menunggu Approval PPSPM", dashboard?.data?.um?.um_grafik?.waiting_approval_ppspm, "color: pink"],
                    // ["Menunggu Dibukukan", dashboard?.data?.um?.um_grafik?.waiting_approval_staff_ppk, "color: green"],
                    ["Telah Disahkan", dashboard?.data?.um?.um_grafik?.already_approved_by_ppspm, "color: brown"],
                  ]
                }
                options={options3}
              />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Transaksi LS {year}</CardHeader>
          <CardBody>
            <div className="flex flex-col w-full gap-4">
              <div className="flex w-full gap-4">
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Total Nilai Pengajuan LS
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                        {`${convertToIDR(dashboard?.data?.ls?.total)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Total Nilai Pembayaran LS
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                        {`${convertToIDR(dashboard?.data?.ls?.total_payment)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Jumlah SPM
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                        {`${dashboard?.data?.ls?.spm}`}
                      </span>
                    </div>
                  </div>
                </Card2>
              </div>
              <div className="flex space-x-4 w-full">
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Jumlah Pengajuan LS</span>
                    <span className="text-2xl font-semibold">
                      {`${dashboard?.data?.ls?.total_application}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Belum Dibayarkan</span>
                    <span className="text-2xl font-semibold">
                      {`${dashboard?.data?.ls?.total_not_paid_yet}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Sudah Dibayarkan/ Selesai</span>
                    <span className="text-2xl font-semibold">
                      {`${dashboard?.data?.ls?.total_already_paid}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-8">
              <div className="font-bold text-[18px]">Grafik LS {year}</div>
                <Chart
                  chartType="ColumnChart"
                  data={[
                    ["Permohonan", "Permohonan", { role: "style" }],
                    ["Butuh Revisi", dashboard?.data?.ls?.ls_grafik?.need_revision, "color: green"],
                    ["Menunggu Approval Staf PPK", dashboard?.data?.ls?.ls_grafik?.waiting_approval_staff_ppk, "color: blue"],
                    ["Menunggu Approval PPK", dashboard?.data?.ls?.ls_grafik?.waiting_approval_ppk, "color: red"],
                    ["Menunggu Pembayaran", dashboard?.data?.ls?.ls_grafik?.waiting_payment, "color: orange"],
                    ["Menunggu Approval PPSPM", dashboard?.data?.ls?.ls_grafik?.waiting_approval_ppspm, "color: purple"],
                    ["Selesai", dashboard?.data?.ls?.ls_grafik?.done, "color: brown"],
                  ]}
                  options={options3}
                  />
              </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Transaksi UMO {year}</CardHeader>
          <CardBody>
            <div className="flex flex-col w-full gap-4">
              <div className="flex w-full gap-4">
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Total Nilai Pengajuan UMO
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.umo?.total)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        UMO yang Sudah Dipertangung Jawabkan
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.umo?.total_payment)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Outstanding UMO
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${convertToIDR(dashboard?.data?.umo?.total_outstanding)}`}
                      </span>
                    </div>
                  </div>
                </Card2>
                <Card2>
                  <div className="flex flex-col p-4 gap-4">
                    <div className="flex gap-4 items-center">
                      <div className="bg-[#D6FFE8] flex justify-center items-center w-[40px] h-[40px] rounded-full">
                        <TbClipboardText size={22} color="#00BD52" />
                      </div>
                      <div className="text-[#1D2939] text-[14px] font-semibold">
                        Jumlah SPM
                      </div>
                    </div>
                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                      <span className="text-[#1D2939] text-2xl font-semibold">
                      {`${dashboard?.data?.umo?.spm}`}
                      </span>
                    </div>
                  </div>
                </Card2>
              </div>
              <div className="flex space-x-4 w-full">
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Jumlah Pengajuan UMO</span>
                    <span className="text-2xl font-semibold">  
                    {`${dashboard?.data?.umo?.total_application}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Belum Dipertanggung jawabakan</span>
                    <span className="text-2xl font-semibold">
                    {`${dashboard?.data?.umo?.total_not_paid_yet}`}
                    </span>
                  </div>
                </div>
                <div className="flex bg-[#01A24A] p-4 rounded-xl w-full">
                  <div
                    className="flex w-full flex-col border-[1px] border-white rounded-xl p-4 gap-4 text-white"
                    style={{
                      backgroundColor: `rgba(255, 255, 255, 0.3)`,
                    }}
                  >
                    <span>Sudah DIpertanggung Jawabkan</span>
                    <span className="text-2xl font-semibold">
                    {`${dashboard?.data?.umo?.total_already_paid}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-8">
              <div className="font-bold text-[18px]">Grafik UMO {year}</div>
                <Chart
                  chartType="ColumnChart"
                  data={[
                    ["Permohonan", "Permohonan", { role: "style" }],
                    ["Draft", dashboard?.data?.umo?.umo_grafik?.draft, "color: green"],
                    ["Menunggu Approval Staf PPK", dashboard?.data?.umo?.umo_grafik?.waiting_approval_staff_ppk, "color: blue"],
                    ["Menunggu Approval PPK", dashboard?.data?.umo?.umo_grafik?.waiting_approval_ppk, "color: red"],
                    ["Menunggu Approval PPSPM", dashboard?.data?.umo?.umo_grafik?.waiting_approval_ppspm, "color: orange"],
                    ["Menunggu Pembayaran", dashboard?.data?.umo?.umo_grafik?.waiting_payment, "color: purple"],
                    ["Butuh Accountability", dashboard?.data?.umo?.umo_grafik?.need_accountability, "color: cyan"],
                    ["Menunggu Approval Accountability Staf PPK", dashboard?.data?.umo?.umo_grafik?.waiting_approval_accountability_staff_ppk, "color: magenta"],
                    ["Menunggu Approval Accountability PPK", dashboard?.data?.umo?.umo_grafik?.waiting_approval_accountability_ppk, "color: yellow"],
                    ["Menunggu Approval Accountability PPSPM", dashboard?.data?.umo?.umo_grafik?.waiting_approval_accountability_ppspm, "color: gray"],
                    ["Done", dashboard?.data?.umo?.umo_grafik?.done, "color: pink"],
                  ]}
                  options={options3}
                  />
              </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Dashboard;
