import config from "app/config";
import axiosInstance from "app/interceptors";

export const getUserDetail = async (userId, signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/user-management/v1/User/internal/${userId}`,
    { signal }
  );
  return data;
};
