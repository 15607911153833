import React, { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { FiCheckCircle } from "react-icons/fi";
import { debounce } from "lodash";

import {
  useBudgetRevision,
  useDownloadTemplateBudgetRevision,
} from "hooks/budgetRevision";

import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { BiPencil } from "react-icons/bi";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { CgSpinner } from "react-icons/cg";
import config from "app/config";
import { Link } from "react-router-dom";

const BudgetRevision = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);

  const links = [
    {
      label: `Daftar Pengajuan Revisi Anggaran ${moment().format("YYYY")}`,
    },
  ];

  const downloadTemplate = useDownloadTemplateBudgetRevision();
  const { data: list, isLoading } = useBudgetRevision([
    "br",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "submission_id",
      title: "ID Pengajuan",
      dataIndex: "submission_id",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "submission_date",
      title: "Tanggal Pengajuan",
      dataIndex: "submission_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD MMMM YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "document",
      title: "Dokumen (Link)",
      dataIndex: "document",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "processing_date",
      title: "Tanggal Diproses",
      dataIndex: "processing_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD MMMM YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value == 1 ? "Sudah Diproses" : "Pengajuan Baru"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis w-48",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-4 flex items-center w-full">
          {/* <HiOutlineEye
            className="text-gray-600 cursor-pointer"
            size={20}
          /> */}
          {roleAccess?.canUpdate && (
            <BiPencil
              className="text-gray-600 cursor-pointer"
              size={20}
              onClick={() => navigate("/edit-budget-revision", { state: data })}
            />
          )}
          {data?.cancelable && (
            <Button className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-28 btn-sm">
              Batalkan
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={async () => {
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        }}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={() => setOpenPopupSuccessDelete(false)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">
          {`Daftar Pengajuan Revisi Anggaran ${moment().format("YYYY")}`}
        </TitleText>
        <div className="flex space-x-4 justify-end flex-1">
          {/* <Button
            className="text-white border-secondary-600 bg-secondary-600 hover:bg-secondary-700 hover:border-secondary-700 space-x-1"
            onClick={onDownloadTemplate}
            disabled={downloadTemplate.isLoading}
          >
            <span>Download Template</span>
            {downloadTemplate.isLoading && (
              <CgSpinner className="animate-spin" />
            )}
          </Button> */}
          <Link
            to={`${config.BASE_URL}/budget-revision/template`}
            target="_blank"
            style={{
              borderRadius: 8,
              display: "flex",
              alignItems: "center",
              padding: "0px 8px",
              fontSize: 14,
              fontWeight: "medium",
            }}
            className="text-white border-gray-600 bg-gray-600 hover:bg-gray-700 hover:border-gray-700 space-x-1"
          >
            <div>
              <span>Download Template</span>
              {downloadTemplate.isLoading && (
                <CgSpinner className="animate-spin" />
              )}
            </div>
          </Link>
          <Button
            // onClick={onTriggerImport}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Import Data Revisi
          </Button>
          {/* <input
            ref={importRef}
            type="file"
            className="hidden"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onHandleImport}
            onClick={(event) => {
              event.target.value = null;
            }}
          /> */}
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetRevision;
