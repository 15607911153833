import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import moment from "moment";

import { useReconList } from "hooks/reconciliation";
import { CustomDatepickerForm, InputForm } from "components/HookForm";
import {
  Breadcrumbs,
  Button,
  CustomSelect,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { Tab, Tabs } from "components/Tabs";
import { optionStatusRekon } from "./status";
import { convertToIDR } from "helpers";

const ReconciliationAccounting = () => {
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);
  const [startDate1, setStartDate1] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate1, setEndDate1] = useState(moment().format("YYYY-MM-DD"));
  const [startDate2, setStartDate2] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate2, setEndDate2] = useState(moment().format("YYYY-MM-DD"));
  const { data, isLoading } = useReconList([
    "recon-list",
    {
      page: newPage,
      size: newLimit,
      status: statusFilter?.value || "",
      tanggal_import: `${startDate1}<>${endDate1}` || "",
    },
  ]);
  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Rekonsialisasi",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "z-2",
      render: (value, data, index) => {
        return (
          <div className="text-ellipsis w-full max-w-[540px]">{index + 1}</div>
        );
      },
    },
    {
      id: "tanggal_upload",
      title: "Tanggal Pengajuan",
      dataIndex: "tanggal_upload",
      //   width: 540,
      className: "text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "tanggal_verifikasi_akunting",
      title: "Tanggal Rekon Bendahara",
      dataIndex: "tanggal_verifikasi_akunting",
      //   width: 540,
      className: "text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "jumlah_transaksi",
      title: "Jumlah Transaksi",
      dataIndex: "jumlah_transaksi",
      //   width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_nilai_transaksi",
      title: "Total Pengajuan Rekon",
      dataIndex: "total_nilai_transaksi",
      //   width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? convertToIDR(value) : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      //   width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      //   width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {data?.status === "Menunggu Rekon Akunting" ? (
              <Button
                onClick={() => {
                  navigate(
                    `/reconciliation-accounting/${data?.id}?action=recon`,
                    {
                      state: data,
                    }
                  );
                }}
                className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
              >
                Rekon
              </Button>
            ) : (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer"
                size={20}
                onClick={() => {
                  navigate(
                    `/reconciliation-accounting/${data?.id}`,
                    {
                      state: data,
                    }
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Rekonsialisasi"}</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              {/* <SearchInput placeholder="Cari" /> */}
              {/* <div className="w-full">
                <SearchInput placeholder="Cari" onChange={onSearch} />
              </div> */}

              <div className="w-full flex flex-col gap-2">
                <span className="text-gray-600">Tanggal Pengajuan</span>
                <Input
                  type="daterange"
                  className="w-full"
                  startDate={startDate1}
                  endDate={endDate1}
                  setStartDate={setStartDate1}
                  setEndDate={setEndDate1}
                  label="Tanggal Pengajuan"
                />
              </div>
              {/* <div className="w-full flex flex-col gap-2">
                <span className="text-gray-600">Tanggal Rekon Bendahara</span>
                <Input
                  type="daterange"
                  className="w-full"
                  startDate={startDate2}
                  endDate={endDate2}
                  setStartDate={setStartDate2}
                  setEndDate={setEndDate2}
                  label="Tanggal Rekon Bendahara"
                />
              </div> */}
              <div className="w-full flex flex-col gap-2">
                <span className="text-gray-600">Status</span>
                <CustomSelect
                  value={statusFilter}
                  optionsData={optionStatusRekon}
                  label="Status"
                  placeholder="Status"
                  className="flex-1"
                  onChange={(value) => {
                    setStatusFilter(value);
                  }}
                  isClearable
                />
              </div>
            </div>
            {/* <Tabs key={"dd"}>
              <Tab label="Perlu Disetujui"> */}
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              isLoading={isLoading}
              dataSource={data?.data}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: data?.meta?.current_page,
                limit: data?.meta?.per_page,
                total: data?.meta?.total,
                previous_pages: data?.meta?.current_page > 1,
                next_pages: !(
                  data?.meta?.current_page === data?.meta?.last_page
                ),
              }}
            />
            {/* </Tab> */}
            {/* <Tab label="Telah Disetujui">
                <Table
                  bordered
                  stripped
                  layout="auto"
                  className="w-full"
                  columns={tableColumns2}
                  dataSource={[
                    {
                      no: 1,
                      created_at: "2021-08-01",
                      amount_transaction: "100",
                      total_amount_transaction: "100",
                    },
                    {
                      no: 2,
                      created_at: "2021-08-01",
                      amount_transaction: "100",
                      total_amount_transaction: "100",
                    },
                  ]}
                  //   onChangePage={setNewPage}
                  //   onChangeRowsPerPage={setNewLimit}
                  //   pagination={{
                  //     page: submissions?.paginator?.page,
                  //     limit: submissions?.paginator?.limit,
                  //     total: submissions?.paginator?.itemCount,
                  //     previous_pages: submissions?.paginator?.page > 1,
                  //     next_pages: !(
                  //       submissions?.paginator?.page ===
                  //       submissions?.paginator?.pageCount
                  //     ),
                  //   }}
                />
              </Tab>
            </Tabs> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconciliationAccounting;
