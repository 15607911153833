import { BottomFormAction, Breadcrumbs } from "components";
import {
  CustomDatepickerForm,
  InputForm,
  RadioGroupForm,
} from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProjectAccountTrxInput } from "hooks/projectAccount";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import AdmissionFee from "./AdmissionFee";
import Conversion from "./Conversion";
import TransferOfFunds from "./TransferOfFunds";
import moment from "moment";
import { useEffect, useState } from "react";

const ProjectAccountTrxInputForm = () => {
  const navigate = useNavigate();

  const links = [
    {
      label: "Daftar Rekening Proyek",
      path: "/project-account",
    },
    {
      label: "Input Transaksi",
    },
  ];

  const [schema, setSchema] = useState();

  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      transaction_type: 1,
    },
  });

  const post = useProjectAccountTrxInput();

  useEffect(() => {
    methods.resetField("description", { defaultValue: "" });
    methods.resetField("transaction_date", { defaultValue: "" });
    methods.resetField("account_number", { defaultValue: null });
    methods.resetField("nominal_input", { defaultValue: "" });
    methods.resetField("nominal_transfer", { defaultValue: "" });
    // methods.resetField("kurs", { defaultValue: "" });
    // methods.resetField("convertion_result", { defaultValue: "" });
    methods.resetField("target_account_number", { defaultValue: null });

    revalidateValidation(methods.watch("transaction_type"));
  }, [methods.watch("transaction_type")]);

  useEffect(() => {
    methods.resetField("convertion_result", {
      defaultValue: methods.watch("nominal_transfer")
        ? parseFloat(methods.watch("nominal_transfer")) *
          parseFloat(methods.watch("kurs"))
        : 0,
    });
  }, [methods.watch("nominal_transfer"), methods.watch("kurs")]);

  const handlePayload = (payload) => {
    switch (payload?.transaction_type) {
      case 1:
        return {
          transaction_type: payload?.transaction_type,
          transaction_date: payload?.transaction_date
            ? moment(payload?.transaction_date).format("YYYY-MM-DD")
            : "",
          project_account_id: payload?.account_number?.value,
          account_number: payload?.account_number?.account_number,
          nominal_input: payload?.nominal_input,
          description: payload?.description,
        };
      case 2:
        return {
          transaction_type: payload?.transaction_type,
          transaction_date: payload?.transaction_date
            ? moment(payload?.transaction_date).format("YYYY-MM-DD")
            : "",
          project_account_id: payload?.account_number?.value,
          project_account_transfer_id: payload?.target_account_number?.value,
          account_number: payload?.account_number?.account_number,
          target_account_number: payload?.target_account_number?.account_number,
          nominal_transfer: payload?.nominal_transfer,
          description: payload?.description,
          kurs: payload?.kurs,
          convertion_result: payload?.convertion_result,
        };
      case 3:
        return {
          transaction_type: payload?.transaction_type,
          transaction_date: payload?.transaction_date
            ? moment(payload?.transaction_date).format("YYYY-MM-DD")
            : "",
          project_account_id: payload?.account_number?.value,
          project_account_transfer_id: payload?.target_account_number?.value,
          account_number: payload?.account_number?.account_number,
          target_account_number: payload?.target_account_number?.account_number,
          nominal_transfer: payload?.nominal_transfer,
          description: payload?.description,
        };
      default:
        break;
    }
  };

  const revalidateValidation = (type) => {
    switch (type) {
      case 1:
        setSchema(
          yup.object().shape({
            transaction_date: yup
              .string()
              .required("Tanggal Transaksi wajib diisi"),
            account_number: yup.mixed().required("Nomor Rekening wajib diisi"),
            nominal_input: yup.string().required("Nominal wajib diisi"),
            description: yup.string().required("Keterangan wajib diisi"),
          })
        );
        break;
      case 2:
        setSchema(
          yup.object().shape({
            transaction_date: yup
              .string()
              .required("Tanggal Transaksi wajib diisi"),
            account_number: yup
              .mixed()
              .test({
                name: "account_number_type",
                message:
                  "Jenis mata uang Nomor Rekening Sumber Konversi dengan Nomor Rekening Tujuan Konversi tidak boleh sama.",
                test: function (value, context) {
                  if (value) {
                    return (
                      value?.currency_name !==
                      this?.parent?.target_account_number?.currency_name
                    );
                  } else {
                    return false;
                  }
                },
              })
              .required("Nomor Rekening Sumber Konversi wajib diisi"),
            nominal_transfer: yup
              .string()
              .required("Nominal Pemindahan wajib diisi"),
            kurs: yup.string().required("Kurs Konversi wajib diisi"),
            convertion_result: yup
              .string()
              .required("Total Hasil Konversi wajib diisi"),
            target_account_number: yup
              .mixed()
              .test({
                name: "target_account_number_type",
                message:
                  "Jenis mata uang Nomor Rekening Sumber Konversi dengan Nomor Rekening Tujuan Konversi tidak boleh sama.",
                test: function (value, context) {
                  if (value) {
                    return (
                      value?.currency_name !==
                      this?.parent?.account_number?.currency_name
                    );
                  } else {
                    return false;
                  }
                },
              })
              .required("Nomor Rekening Tujuan Konversi wajib diisi"),
            description: yup.string().required("Keterangan wajib diisi"),
          })
        );
        break;
      case 3:
        setSchema(
          yup.object().shape({
            transaction_date: yup
              .string()
              .required("Tanggal Transaksi wajib diisi"),
            account_number: yup
              .mixed()
              .required("Nomor Rekening Sumber wajib diisi"),
            nominal_transfer: yup
              .string()
              .required("Nominal Pemindahan wajib diisi"),
            target_account_number: yup
              .mixed()
              .required("Nomor Rekening Tujuan wajib diisi"),
            description: yup.string().required("Keterangan wajib diisi"),
          })
        );
        break;
      default:
        break;
    }
  };

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = (payload) => {
    let params = handlePayload(payload);
    post.mutate(params, {
      onSuccess: (data) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        navigate(-1);
      },
      onError: (error) => {
        //
      },
    });
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
          <div className="card-body p-4 space-y-4">
            <div className="text-lg font-semibold mb-3">
              {"Input Transaksi"}
            </div>
            <div className="grid grid-cols-1 gap-4">
              <RadioGroupForm
                label="Jenis Transaksi"
                controllerName="transaction_type"
                values={[
                  {
                    value: 1,
                    label: "Penerimaan Uang Masuk Dari Donor",
                  },
                  {
                    value: 2,
                    label: "Konversi",
                  },
                  {
                    value: 3,
                    label: "Pemindahan Dana Ke Rekening Lain",
                  },
                ]}
              />
              <div className="grid grid-cols-3 gap-4">
                <CustomDatepickerForm
                  name={`transaction_date`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Tanggal Transaksi"}
                  placeholder={"Tanggal Transaksi"}
                  required={true}
                />
              </div>
              {methods.watch("transaction_type") === 1 && <AdmissionFee />}
              {methods.watch("transaction_type") === 2 && <Conversion />}
              {methods.watch("transaction_type") === 3 && <TransferOfFunds />}
              <InputForm
                name={`description`}
                type="textarea"
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Keterangan"}
                required={true}
              />
            </div>
          </div>
        </div>
        <BottomFormAction
          onCancel={onCancel}
          isLoading={post.isLoading}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </>
  );
};

export default ProjectAccountTrxInputForm;
