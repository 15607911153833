import classNames from "classnames";
import React from "react";

export const Step = ({ label, isLast, activeStep = 0, index }) => (
  <div className="flex-1 cursor-pointer transition-all" key={label}>
    <div className="group flex flex-col items-center py-2 space-y-2 hover:border-primary-800 relative">
      <div className="flex flex-col items-center justify-center w-full">
        {index !== 0 && (
          <hr
            className={classNames(
              "w-1/2 -left-1 h-[4px] border-0 rounded absolute transition-all",
              activeStep > index - 1 ? "bg-primary-600" : "bg-gray-100"
            )}
          />
        )}
        {!isLast && (
          <hr
            className={classNames(
              "w-1/2 h-[4px] right-0 border-0 rounded absolute transition-all",
              activeStep > index ? "bg-primary-600" : "bg-gray-100"
            )}
          />
        )}
        <div
          className={classNames(
            "rounded-full h-8 w-8 p-1 z-10 transition-all",
            activeStep >= index ? "bg-[#F4EBFF]" : "bg-gray-100"
          )}
        >
          <div
            className={classNames(
              "rounded-full w-full h-full p-2 transition-all",
              activeStep >= index ? "bg-primary-50" : "bg-gray-100"
            )}
          >
            <div
              className={classNames(
                "rounded-full h-full w-full transition-all",
                activeStep >= index ? "bg-primary-600" : "bg-gray-200"
              )}
            />
          </div>
        </div>
      </div>
      <span
        className={classNames(
          "font-semibold  text-center",
          activeStep >= index
            ? "text-primary-600 group-hover:text-primary-800"
            : "text-gray-700 group-hover:text-gray-800"
        )}
      >
        {label}
      </span>
    </div>
  </div>
);

export const Steps = ({ children, activeStep = 0 }) => {
  return (
    <div>
      <div className="flex space-y-0">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child ?? <></>, {
            isLast: index === React.Children.toArray(children).length - 1,
            activeStep,
            index,
          })
        )}
      </div>
      <div className="mt-4">
        {React.Children.toArray(children)[activeStep]?.props?.children}
      </div>
    </div>
  );
};
