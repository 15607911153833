import { useMutation, useQuery } from "react-query";
import { PP, RPP } from "services";

export const useProjectPaymentsQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return PP.getProjectPayments({ ...params }, signal);
    },
    ...options,
  });
};

export const useProjectPaymentDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RPP.getRequestProjectPaymentDetail(params, signal);
    },
    ...options,
  });
};

export const useProjectPaymentPost = () => {
  return useMutation((payload) => PP.postProjectPayment(payload))
}