import { useQuery, useMutation } from "react-query";
import { GS } from "services";

export const useBanksQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return GS.getBanks({ ...params }, signal);
    },
    ...options,
  });
};

export const useBankAccountsQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return GS.getBankAccounts({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreateBankAccount = () => {
  return useMutation((payload) => GS.createBankAccount(payload));
};

export const useUpdateBankAccount = () => {
  return useMutation((payload) => GS.updateBankAccount(payload));
};

export const useDeleteBankAccount = () => {
  return useMutation((payload) => GS.deleteBankAccount(payload));
};

export const useDepositQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return GS.getDeposits({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreateDeposit = () => {
  return useMutation((payload) => GS.createDeposit(payload));
};

export const useUpdateDeposit = () => {
  return useMutation((payload) => GS.updateDeposit(payload));
};

export const useDeleteDeposit = () => {
  return useMutation((payload) => GS.deleteDeposit(payload));
};
