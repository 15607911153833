import React, { useState } from "react";
import classNames from "classnames";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const TableHeader = ({
  items,
  headerClassName,
  defaultSortOrder,
  onCheckAll,
  allChecked,
}) => {
  const [sortOrder, setSortOrder] = useState(
    defaultSortOrder?.toLowerCase() ?? "asc"
  );

  const getValue = (value) => {
    if (typeof value === "string") return value;
    if (typeof value === "function") return value();
    return "";
  };

  if (!items.length) return null;
  return (
    <thead className={headerClassName}>
      <tr>
        {items.map((item, key) => {
          if (item.id === "checkbox") {
            return (
              <th
                key={key}
                className={classNames(
                  [
                    "bg-primary-50",
                    "text-gray-600",
                    "text-sm",
                    "font-semibold",
                    "capitalize",
                  ],
                  { "!relative": key === 0 },
                  { sticky: Boolean(item?.fixed) },
                  { "right-0": item?.fixed && item?.fixed === "right" },
                  { "left-0": item?.fixed && item?.fixed === "left" },
                  item.columnClassName && [...item.columnClassName.split(" ")]
                )}
              >
                <input
                  type="checkbox"
                  onChange={onCheckAll}
                  checked={allChecked}
                />
              </th>
            );
          }
          return (
            <th
              key={key}
              className={classNames(
                [
                  "bg-primary-50",
                  "text-gray-600",
                  "text-sm",
                  "font-semibold",
                  "capitalize",
                ],
                { "!relative": key === 0 },
                { sticky: Boolean(item?.fixed) },
                { "right-0": item?.fixed && item?.fixed === "right" },
                { "left-0": item?.fixed && item?.fixed === "left" },
                item.columnClassName && [...item.columnClassName.split(" ")]
              )}
            >
              {item?.sortable && item?.sorter ? (
                <div
                  className="gap-2 flex cursor-poiner"
                  onClick={() => {
                    setSortOrder((curr) => {
                      if (curr === "asc") return "desc";
                      return "asc";
                    });
                    item.sorter(item.dataIndex);
                  }}
                >
                  {getValue(item.title)}
                  {sortOrder === "asc" ? (
                    <BsArrowUpShort
                      size={20}
                      className="text-gray-700 cursor-pointer"
                    />
                  ) : (
                    <BsArrowDownShort
                      size={20}
                      className="text-gray-700 cursor-pointer"
                    />
                  )}
                </div>
              ) : (
                getValue(item.title)
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader);
