import { BiPencil, BiTrash } from "react-icons/bi";
import { FiCheckCircle, FiPlus } from "react-icons/fi";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { ConfirmationModal, InformationModal } from "components/Modal";
import { useActivityStatusQuery } from "hooks/downPayment";
import { useDeleteUMO, useOperationalQuery } from "hooks/operationalAdvance";
import { debounce } from "lodash";
import moment from "moment";
import { convertToIDR } from "pages/DownPayment/util";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import StatusBadge from "./StatusBadge";
import { getStatusProperties } from "./util";
import { useSelector } from "react-redux";

const OperationalAdvance = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [selectedData, setSelectedData] = useState(null);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: operational, refetch } = useOperationalQuery([
    "operational-advance-list",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      status: statusFilter?.value,
    },
  ]);

  const { data: activityStatus } = useActivityStatusQuery();
  const deleteUMO = useDeleteUMO();

  const links = [
    {
      label: "Uang Muka Operasional",
      path: "/operational-advances",
    },
    {
      label: "Daftar Pengajuan UMO",
    },
  ];

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = () => {
    deleteUMO.mutate(
      { id: selectedData?.id },
      {
        onSuccess: () => {
          refetch();
          setSelectedData(null);
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        },
      }
    );
  };

  const onClosePopupSuccess = () => {
    setOpenPopupSuccessDelete(false);
  };

  const renderStatusAction = (status, data) => {
    switch (data?.status?.action) {
      case "crud":
        return (
          <>
            {roleAccess?.canView && (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/operational-advances/detail/${data.id}`)
                }
              />
            )}
            {roleAccess?.canUpdate && (
              <BiPencil
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/operational-advances/edit/${data.id}`)
                }
              />
            )}
            {roleAccess?.canDelete && (
              <BiTrash
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onTriggerDelete(data)}
              />
            )}
          </>
        );
      case "detail":
        return (
          <>
            {roleAccess?.canView && (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/operational-advances/detail/${data?.id}`)
                }
              />
            )}
          </>
        );
      case "revision":
        return (
          <Button
            onClick={() => navigate(`/operational-advances/edit/${data.id}`)}
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Revisi
          </Button>
        );
      case "review":
        return (
          <Button
            onClick={() =>
              navigate(`/operational-advances/approval/${data?.id}`)
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Review
          </Button>
        );
      case "process":
        return (
          <Button
            onClick={() =>
              navigate(`/operational-advances/verification-request/${data?.id}`)
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Proses
          </Button>
        );
      case "realization":
        return (
          <Button
            onClick={() =>
              navigate(`/operational-advances/realization/${data?.id}`)
            }
            className="flex-1 text-white border-cyan-500 bg-cyan-500 hover:bg-cyan-600 hover:border-cyan-600 whitespace-pre-wrap"
          >
            Pertanggung Jawaban
          </Button>
        );
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/operational-advances/detail/${data?.id}`)}
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "request_date",
      title: "Tanggal Pengajuan",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "description",
      title: "Deskripsi",
      dataIndex: "description",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "um",
      title: "UM",
      dataIndex: "um",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "spj",
      title: "SPJ",
      dataIndex: "total_value",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
      columnClassName: "right-[181px] block shadow-lg",
      fixed: "right",
      sortable: true,
      render: (value) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadge status={value?.description} role={role} />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        const status = getStatusProperties(data.status?.description);
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(status, data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={onClosePopupSuccess}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3 pr-4`}>
        <TitleText className="flex-1">Daftar Pengajuan UMO</TitleText>
        {role === "pjk" && roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() =>
              navigate("/operational-advances/add", { state: { role } })
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah Data
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[81vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              <CustomSelect
                value={statusFilter}
                optionsData={activityStatus?.result?.map((status) => ({
                  label: status?.description,
                  value: status?.status,
                }))}
                label="Status"
                placeholder="Status"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              />
              <Input className="flex-1" placeholder="dd/mm/yyyy" />
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={operational?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: operational?.paginator?.page,
                limit: operational?.paginator?.limit,
                total: operational?.paginator?.itemCount,
                previous_pages: operational?.paginator?.page > 1,
                next_pages: !(
                  operational?.paginator?.page ===
                  operational?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OperationalAdvance;
