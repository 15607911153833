import React, { useMemo } from "react";
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useOutletContext,
  createBrowserRouter,
  defer,
  RouterProvider,
} from "react-router-dom";
import _, { map } from "lodash";
import { useSelector } from "react-redux";

import route from "./route";

import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";

import { AuthLayout, BasicLayout } from "components";
import { AuthConsumer } from "providers/authProvider";
import { USER } from "services";
import My403Component from "pages/403";
import My404Component from "pages/404";

const ProtectedRoute = ({ children, routeKey, type }) => {
  const { userAccessRights } = useOutletContext();
  // const userAccessRights = {};

  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          if (userAccessRights?.[routeKey]?.[type]) {
            return (
              <BasicLayout>
                {React.cloneElement(children, {
                  accessRight: userAccessRights?.[routeKey],
                })}
              </BasicLayout>
            );
          } else return <Navigate to="/unautorized" replace />;
        } else {
          return signinRedirect();
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);
  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <CALLBACK />,
        path: "/signin-oidc",
      },
      {
        element: <LOGOUT />,
        path: "/logout",
      },
      {
        element: <LOGOUT_CALLBACK />,
        path: "/signout-oidc",
      },
      {
        element: <SILENT_RENEW />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              const { data: userDetail } = await USER.getUserDetail(
                user?.UserId,
                signal
              );
              if (
                userDetail?.userAccess &&
                Array.isArray(userDetail?.userAccess)
              ) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "anggaran & akuntansi"
                );
                if (index > -1) {
                  const userAccess =
                    userDetail?.userAccess?.[index]?.menuModules;
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  // userAccessRights["write-off-request"] = {
                  //   canCreate: true,
                  //   canDelete: true,
                  //   canUpdate: true,
                  //   canView: true,
                  //   idMenu: "bfa1fc70-1de0-4616-885f-2b0a9da89f4b",
                  //   key: "write-off-request",
                  //   menuName:
                  //     "Daftar Permintaan Hapus Bukuan Tidak Hapus Tagih",
                  // };
                  // userAccessRights["payment-request-fdb"] = {
                  //   canCreate: true,
                  //   canDelete: true,
                  //   canUpdate: true,
                  //   canView: true,
                  //   idMenu: "bfa1fc70-1de0-4616-885f-2b0a9da89f51",
                  //   key: "payment-request-fdb",
                  //   menuName: "Permintaan Pembayaran FDB",
                  // };
                  // userAccessRights["executions-fdb"] = {
                  //   canCreate: true,
                  //   canDelete: true,
                  //   canUpdate: true,
                  //   canView: true,
                  //   idMenu: "bfa1fc70-1de0-4616-885f-2b0a9da89f312",
                  //   key: "executions-fdb",
                  //   menuName: "Eksekusi Pembayaran FDB",
                  // };
                  userAccessRights["reconciliation-accounting"] = {
                    canCreate: true,
                    canDelete: true,
                    canUpdate: true,
                    canView: true,
                    idMenu: "bfa1fc70-1de0-4616-885f-2b0a9da89f4c",
                    key: "reconciliation-accounting",
                    menuName: "Rekonsiliasi Akuntansi",
                  };
                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
            return defer({
              userAccessRights: null,
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute routeKey={val.key} type={val.type}>
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unautorized" },
    ]);
  }, [user]);
  return <RouterProvider router={RouteElements} />;
};

export default Routes;
