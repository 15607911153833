import { Table } from "components";
import { InputForm } from "components/HookForm";
import { map } from "lodash";
import { convertToIDR } from "pages/DownPayment/util";
import { useFieldArray, useFormContext } from "react-hook-form";

const dummyData = {
  activity_detail: "Tiket Pesawat",
  quantity: "100",
  volume: "100",
  price: "Rp 10,000",
  subtotal: "Rp 10,000,000",
};

const Form = () => {
  const methods = useFormContext();

  const { fields } = useFieldArray({
    name: "accounts",
    control: methods?.control,
  });

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "Detail Kegiatan",
      dataIndex: "name",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ? value : "-"}
          </div>
        );
      },
    },
    {
      id: "qty",
      title: "Qty",
      dataIndex: "qty",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ? value : "-"}
          </div>
        );
      },
    },
    {
      id: "volume",
      title: "Volume",
      dataIndex: "volume",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ? value : "-"}
          </div>
        );
      },
    },
    {
      id: "value",
      title: "Harga SBM",
      dataIndex: "value",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ? convertToIDR(value) : "-"}
          </div>
        );
      },
    },
    {
      id: "tax_total",
      title: "Nilai Pajak",
      dataIndex: "tax_total",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ? convertToIDR(value) : "-"}
          </div>
        );
      },
    },
    {
      id: "subtotal",
      title: "Sub Total",
      dataIndex: "subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        let isValueEqualNilaiPajak = value === data?.tax_total;
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value
              ? isValueEqualNilaiPajak
                ? convertToIDR(data?.value + data?.tax_total)
                : convertToIDR(value)
              : "-"}
          </div>
        );
      },
    },
  ];

  return (
    <div className="space-y-4">
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-4">
          <div className="space-y-3">
            <div className="border-b-[1px] border-gray-100 pb-4 mb-2">
              <span className="text-sm inline-block font-semibold bg-gray-100 rounded-xl px-3 py-1">
                Total nilai:{" "}
                <span className="font-bold">
                  {convertToIDR(methods?.watch("total"))}
                </span>
              </span>
            </div>
            {map(fields, (field, key) => {
              console.log(field);
              let realization_total = field?.details.reduce(
                (acc, detail) => acc + detail.tax_total + detail.value,
                0
              );
              return (
                <div
                  key={field?.id}
                  className="rounded-lg border-[1px] border-gray-100"
                >
                  <div className="bg-[#E1F1D6] px-4 py-2 rounded-t-lg">
                    <span className="text-primary-600 font-bold">
                      Akun Belanja:{" "}
                      <span className="font-medium">{field?.account_code}</span>
                    </span>
                    <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                      Total Nilai:{" "}
                      <span className="font-bold">
                        {convertToIDR(realization_total)}
                      </span>
                    </span>
                  </div>
                  <div className="p-4">
                    <Table
                      bordered
                      stripped
                      layout="fixed"
                      className="mb-4 rounded-xl"
                      columns={tableColumns}
                      dataSource={field?.details}
                      // pagination={{
                      //   page: 1,
                      //   limit: 10,
                      //   total: 10,
                      //   previous_pages: false,
                      //   next_pages: false,
                      // }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="h-24" />
    </div>
  );
};

export default Form;
