import { Input } from "components";
import React from "react";
import { BiSearch } from "react-icons/bi";

const SearchInput = ({ placeholder = "Cari", onChange }) => {
  return (
    <div className="flex flex-1 items-center rounded-md border-gray-200 px-4 border-solid border-[1px]">
      <BiSearch className="text-gray-400" size={20} />
      <Input
        onChange={onChange}
        className="bg-white text-gray-400 w-full text-sm p-2 border-0 outline-none border-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
