import { BiPencil, BiPrinter, BiTrash } from "react-icons/bi";
import { FiCheckCircle, FiPlus } from "react-icons/fi";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  useActivityQuery,
  useActivityStatusQuery,
  useDeleteActivity,
} from "hooks/downPayment";
import { debounce, map } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import StatusBadge from "./StatusBadge";
import { getStatusProperties } from "./util";
import { useSelector } from "react-redux";

const DownPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [selectedData, setSelectedData] = useState(null);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);
  const [date, setDate] = useState("");

  const { data: activities, refetch } = useActivityQuery([
    "dp-activities",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      status: statusFilter?.value,
      start_date: date ? moment(date).format("DD-MM-YYYY") : "",
    },
  ]);

  const { data: activityStatus } = useActivityStatusQuery();
  const deleteActivity = useDeleteActivity();

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
    },
  ];

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = () => {
    deleteActivity.mutate(
      { id: selectedData?.id },
      {
        onSuccess: () => {
          refetch();
          setSelectedData(null);
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        },
      }
    );
  };

  const onClosePopupSuccess = () => {
    setOpenPopupSuccessDelete(false);
  };

  const renderStatusAction = (status, data) => {
    switch (data?.status?.action) {
      case "crud":
        return (
          <>
            {roleAccess?.canView && (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/down-payment-activity/detail/${data.id}`)
                }
              />
            )}
            {roleAccess?.canUpdate && (
              <BiPencil
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/down-payment-activity/edit/${data.id}`)
                }
              />
            )}
            {roleAccess?.canDelete && (
              <BiTrash
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onTriggerDelete(data)}
              />
            )}
          </>
        );
      case "activity_detail":
        return (
          <Button
            onClick={() =>
              navigate(`/down-payment-activity/activity-detail-pum/${data?.id}`)
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Lengkapi Rincian Kegiatan
          </Button>
        );
      case "verification":
        return (
          <Button
            onClick={() =>
              navigate(
                `/down-payment-activity/verification-request/${data?.id}`
              )
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Verifikasi
          </Button>
        );

      case "accountability":
        return (
          <Button
            onClick={() =>
              navigate(
                `/down-payment-activity/accountability-approval/${data?.id}`
              )
            }
            className="flex-1 text-white border-cyan-500 bg-cyan-500 hover:bg-cyan-600 hover:border-cyan-600 whitespace-pre-wrap"
          >
            Pertanggung Jawaban
          </Button>
        );
      case "submit_accountability":
        return (
          <div className="flex flex-col gap-2">
            <Button
              startIcon={<BiPrinter size={18} />}
              className="flex-1 text-primary-600 whitespace-pre-wrap bg-white border-white hover:text-white"
            >
              Print
            </Button>
            <Button
              onClick={() =>
                navigate(
                  `/down-payment-activity/submit-accountability-approval/${data?.id}`
                )
              }
              className="flex-1 text-white text-xs border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
            >
              Submit Pertanggung Jawaban
            </Button>
          </div>
        );
      case "accountability_approval":
        return (
          <Button
            onClick={() =>
              navigate(
                `/down-payment-activity/accountability-approval/${data?.id}`
              )
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Approval
          </Button>
        );

      case "revision":
        return (
          <Button className="flex-1 text-white border-[#E34F13] bg-[#E34F13] whitespace-pre-wrap">
            Revisi
          </Button>
        );
      case "view":
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() =>
              navigate(`/down-payment-activity/detail/${data?.id}`)
            }
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "request_date",
      title: "Tanggal Pengajuan",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "activity_name",
      title: "Nama Kegiatan",
      dataIndex: "activity_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "activity_type",
      title: "Jenis Kegiatan",
      dataIndex: "activity_types",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {map(value, "name").join(", ")}
        </div>
      ),
    },
    {
      id: "director",
      title: "Direktorat",
      dataIndex: "directorate",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "account",
      title: "Akun",
      dataIndex: "component_code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "pum",
      title: "PUM",
      dataIndex: "pum",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "job_number",
      title: "No Surat Tugas",
      dataIndex: "assignment_letter_number",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "job_date",
      title: "Tgl Surat Tugas",
      dataIndex: "assignment_letter_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "activity_date",
      title: "Tgl Kegiatan",
      dataIndex: "start_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] shadow-sm",
      columnClassName: "right-[181px] block shadow-sm",
      fixed: "right",
      sortable: true,
      render: (value) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadge status={value?.description} role={role} />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: " text-ellipsis",
      columnClassName: "min-w-[181px]",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        const status = getStatusProperties(data.status?.description);
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(status, data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal
        open={openPopupSuccessDelete}
        hideHeader
        childClassName="!overflow-hidden"
      >
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={onClosePopupSuccess}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Pengajuan Kegiatan</TitleText>
        {role === "pjk" && roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() => navigate("/down-payment-activity/add")}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah Kegiatan
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <div className="w-full">
                <SearchInput placeholder="Cari" onChange={onSearch} />
              </div>
              <div className="w-full">
                <CustomSelect
                  value={statusFilter}
                  optionsData={activityStatus?.result?.map((status) => ({
                    label: status?.description,
                    value: status?.status,
                  }))}
                  label="Status"
                  placeholder="Status"
                  onChange={(value) => {
                    setStatusFilter(value);
                  }}
                  isClearable
                />
              </div>
              <Input
                type="date"
                className="w-full"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={activities?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: activities?.paginator?.page,
                limit: activities?.paginator?.limit,
                total: activities?.paginator?.itemCount,
                previous_pages: activities?.paginator?.page > 1,
                next_pages: !(
                  activities?.paginator?.page ===
                  activities?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DownPayment;
