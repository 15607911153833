import config from 'app/config';
import axios from 'axios';

export const getReconciliationList = async (params, signal) => {
  // const { data } = await axios.get(`${config.BASE_URL}/manual-journal`, { params, signal });
  const data = new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        "totalItems": 2,
        "size": 10,
        "result": [
          {
            "id": 47,
            "uploadDate": "2023-12-18T17:00:00.209Z",
            "bank": "Bank Mandiri",
            "rekonStartPeriod": "2023-12-18T17:00:00.209Z",
            "rekonEndPeriod": "2023-12-18T17:00:00.209Z",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
          {
            "id": 45,
            "uploadDate": "2023-12-18T17:00:00.209Z",
            "bank": "BCA",
            "rekonStartPeriod": "2023-12-18T17:00:00.209Z",
            "rekonEndPeriod": "2023-12-18T17:00:00.209Z",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
          {
            "id": 44,
            "uploadDate": "2023-12-18T17:00:00.209Z",
            "bank": "Bank CIMB",
            "rekonStartPeriod": "2023-12-18T17:00:00.209Z",
            "rekonEndPeriod": "2023-12-18T17:00:00.209Z",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
        ],
        "totalPages": 1,
        "currentPage": 1
      })
    }, 1500);
  })
  return data;
}

export const getReconciliationDetail = async (id) => {
  // const { data } = await axios.get(`${config.BASE_URL}/manual-journal/${id}`);
  const data = new Promise(resolve => {
    setTimeout(() => {
      resolve({
        bank: "BCA",
        rekonStartPeriod: "2023-12-18T17:00:00.209Z",
        rekonEndPeriod: "2023-12-18T17:00:00.209Z",
        transaction: {
          "totalItems": 2,
          "size": 10,
          "result": [
            {
              "id": 47,
              "transactionDate": "2023-12-18T17:00:00.209Z",
              "transactionId": "1234567890",
              "name": "John Doe",
              "note": "Dana Hibah M4CR",
              "value": "5000000",
              "balance": "5000000",
              "createdAt": "2023-12-18T17:00:00.209Z",
              "updatedAt": "2023-12-18T17:00:00.209Z"
            },
            {
              "id": 44,
              "transactionDate": "2023-12-18T17:00:00.209Z",
              "transactionId": "1234567890",
              "name": "John Doe",
              "note": "Dana Hibah M4CR",
              "value": "5000000",
              "balance": "5000000",
              "createdAt": "2023-12-18T17:00:00.209Z",
              "updatedAt": "2023-12-18T17:00:00.209Z"
            },
            {
              "id": 41,
              "transactionDate": "2023-12-18T17:00:00.209Z",
              "transactionId": "1234567890",
              "name": "John Doe",
              "note": "Dana Hibah M4CR",
              "value": "5000000",
              "balance": "5000000",
              "createdAt": "2023-12-18T17:00:00.209Z",
              "updatedAt": "2023-12-18T17:00:00.209Z"
            },
          ],
          "totalPages": 1,
          "currentPage": 1
        }
      });
    }, 1500);
  })
  return data;
}

export const getReconciliationTrxList = async (params, signal) => {
  // const { data } = await axios.get(`${config.BASE_URL}/manual-journal`, { params, signal });
  const data = new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        "totalItems": 2,
        "size": 10,
        "result": [
          {
            "id": 47,
            "nap": "1234567890",
            "name": "John Doe",
            "paymentDate": "2023-12-18T17:00:00.209Z",
            "paymentValue": "5000000",
            "note": "Pengembalian NAP 00123487 An John Doe",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
          {
            "id": 41,
            "nap": "1234567890",
            "name": "John Doe",
            "paymentDate": "2023-12-18T17:00:00.209Z",
            "paymentValue": "5000000",
            "note": "Pengembalian NAP 00123487 An John Doe",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
          {
            "id": 42,
            "nap": "1234567890",
            "name": "John Doe",
            "paymentDate": "2023-12-18T17:00:00.209Z",
            "paymentValue": "5000000",
            "note": "Pengembalian NAP 00123487 An John Doe",
            "createdAt": "2023-12-18T17:00:00.209Z",
            "updatedAt": "2023-12-18T17:00:00.209Z"
          },
        ],
        "totalPages": 1,
        "currentPage": 1
      })
    }, 1500);
  })
  return data;
}

export const createReconciliationData = async (params) => {
  // const { data } = await axios.post(`${config.BASE_URL}/manual-journal`, params);
  const data = new Promise(resolve => {
    setTimeout(() => {
      resolve({ data: "success" })
    }, 1500);
  })
  return data;
}

export const updateReconciliationData = async (params) => {
  // const { data } = await axios.put(`${config.BASE_URL}/manual-journal/${params?.id}`, params?.payload);
  const data = new Promise(resolve => {
    setTimeout(() => {
      resolve({ data: "success" })
    }, 1500);
  })
  return data;
}

export const deleteReconciliationData = async (params) => {
  // const { data } = await axios.delete(`${config.BASE_URL}/manual-journal/${params?.id}`);
  const data = new Promise(resolve => {
    setTimeout(() => {
      resolve({ data: "success" })
    }, 1500);
  })
  return data;
}

export const getReconList = async (params, signal) => {
  const { data } = await axios.get(`${config.BASE_URL}/reconciliation/documents`, { params, signal });
  return data;
}

export const getReconDetail = async (params, signal) => {
  const { data } = await axios.get(`https://bpldh-api-v2.groot.id/api/settlement/v1/rekonsiliasi`, { params, signal });
  return data;
}

export const getReconDetailTrx = async (id) => {
  const { data } = await axios.get(`${config.BASE_URL}/reconciliation/documents/${id}`);
  return data;
}

export const postRecon = async (params) => {
  console.log({params});
  const { data } = await axios.post(`${config.BASE_URL}/reconciliation`, params);
  return data;
}