import {
  BasicCard,
  BottomFormAction,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Table,
} from "components";
import Accordion from "components/Accordion";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
  SelectChartOfAccountForm,
  NumberInputForm,
  SelectBankAccountForm,
} from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { find, map, sum, sumBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FiPlus, FiTrash } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useCreateContractual,
  useSubmissionDetailQuery,
  useUpdateContractual,
} from "hooks/directTransaction";
import { NumericFormat } from "react-number-format";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { usePutWriteOffMutation, useWriteOffDetailQuery } from "hooks/writeOff";
import { CgSpinner } from "react-icons/cg";
import { RiCheckLine, RiCloseCircleLine } from "react-icons/ri";
import {
  ConfirmationModal,
  FormModal,
  InformationModal,
} from "components/Modal";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { convertToIDR } from "pages/DownPayment/util";
import StatusBadgeExecution from "./StatusBadge/execution";
import {
  useAddTransactionFDB,
  usePaymentFDBDebtorQuery,
  usePaymentFDBDetailQuery,
  usePaymentRequestQuery,
} from "hooks/cash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ExecutionFDBDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const role = params.role;
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const isDetail = !action || action !== "pay";

  const [selectedDebtor, setSelectedDebtor] = useState(null);

  const tableColumns = [
    {
      id: "nap",
      title: "NAP",
      dataIndex: "nap",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "debtorName",
      title: "Nama Debitur",
      dataIndex: "debtorName",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "accountNumber",
      title: "No Rekening",
      dataIndex: "accountNumber",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {/* {convertToIDR(value)} */}
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "disbursementAmount",
      title: "Nominal Pencairan",
      dataIndex: "disbursementAmount",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
    {
      id: "disbursementStage",
      title: "Tahap Pencairan",
      dataIndex: "disbursementStage",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {/* {value ? moment(value).format("DD/MM/YYYY") : "-"} */}
          {value ? value : "-"}
        </div>
      ),
    },
    // {
    //   id: "status",
    //   title: "Status",
    //   dataIndex: "status",
    //   width: 200,
    //   className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
    //   // columnClassName: "right-[181px] block shadow-lg",
    //   // fixed: "right",
    //   sortable: true,
    //   render: (data) => {
    //     return (
    //       <div className="space-x-2 flex items-center w-full min-h-12">
    //         <StatusBadgeExecution
    //           status={data === "unpaid" ? "Menunggu Pembayaran" : "Disetujui"}
    //           role={role}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  if (!isDetail) {
    tableColumns.unshift({
      id: "checkbox",
      title: () => <input type="checkbox" />,
      dataIndex: "code",
      className: "z-[0]",
      render: (value, data) => <input type="checkbox" />,
    });
  }

  tableColumns.push({
    id: "status",
    title: "Status",
    dataIndex: "status",
    width: 540,
    className: "overflow-hidden text-ellipsis",
    sortable: true,
    render: (value) => (
      <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
        {value === "Paid" ? (
          <span
            style={{
              color: "#00A854",
            }}
          >
            {"Sudah Dibayarkan"}
          </span>
        ) : (
          <span
            style={{
              color: "#B01515",
            }}
          >
            {"Belum Dibayarkan"}
          </span>
        )}
      </div>
    ),
  });

  if (isDetail) {
    tableColumns.push({
      id: "disbursementDate",
      title: "Tanggal Pembayaran",
      dataIndex: "disbursementDate",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    });
  }

  const schema = yup.object().shape({
    // paid_total: yup
    //   .number()
    //   .positive()
    //   .typeError("Wajib Diisi")
    //   .required("Wajib Diisi"),
    // payment_date: yup.date().typeError("Wajib diisi").required("Wajib Diisi"),
    // bank_account_name: yup
    //   .string()
    //   .typeError("Wajib Diisi")
    //   .required("Wajib Diisi"),
    // bank_account_number: yup
    //   .string()
    //   .typeError("Wajib Diisi")
    //   .required("Wajib Diisi"),
    // bank_name: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
    // bank_branch_name: yup
    //   .string()
    //   .typeError("Wajib Diisi")
    //   .required("Wajib Diisi"),
    // transactions: yup.array().of(
    //   yup.object({
    //     fundingSources: yup.array().of(
    //       yup.object({
    //         id: yup.string().required("Wajib Diisi"),
    //         accountNumber: yup.string().required("Wajib Diisi"),
    //         accountHolderName: yup.string().required("Wajib Diisi"),
    //         paidAmount: yup.number().positive().required("Wajib Diisi"),
    //         transactionRecordId: yup.string().required("Wajib Diisi"),
    //         createdAt: yup.date().required("Wajib Diisi"),
    //         updatedAt: yup.date().required("Wajib Diisi"),
    //       })
    //     ),
    //     value: yup
    //       .number()
    //       .positive()
    //       .typeError("Wajib Diisi")
    //       .required("Wajib Diisi"),
    //   })
    // ),
    // type: yup.string().typeError("Wajib Diisi").required(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      transactions: [
        {
          paymentDate: new Date(),
          fundingSources: [
            {
              accountNumber: "",
              accountHolderName: "",
              bankName: "",
              paidAmount: 0,
            },
          ],
        },
      ],
    },
  });

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: debtorsList, refetch } = usePaymentFDBDebtorQuery([
    "debtor-fdb",
    {
      id: params.id,
      page: newPage,
      size: newLimit,
      search: keyword,
      status: statusFilter?.value,
    },
  ]);

  const {
    fields: payments,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: "fundingSources",
  });
  const id = params.id;

  const { data: detail } = usePaymentFDBDetailQuery([
    "payment-fdb-detail",
    { id },
  ]);

  const postTransaction = useAddTransactionFDB();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!debtorsList) return;
    const updatedDebtorList = debtorsList?.result?.map?.((debtor) => ({
      ...debtor,
      paymentDate: moment(debtor.paymentDate).format("MM/DD/YYYY"),
      checked: debtor.checked ?? false,
    }));
    setDataSource(updatedDebtorList);
  }, [debtorsList]);

  useEffect(() => {
    if (detail?.transactionRecords?.length > 0) {
      let arr = [detail.transactionRecords[0]];
      methods.reset({
        ...methods.getValues(),
        transactions: arr?.map((trx) => ({
          ...trx,
          fundingSources: trx.fundingSources?.map((fs) => ({
            ...fs,
            accountNumber: {
              value: fs?.accountNumber,
              label: fs?.accountNumber + " - " + fs?.accountHolderName,
              id: fs?.accountNumber,
              number: fs?.accountNumber,
            },
            accountHolderName: fs?.accountHolderName,
            bankName: fs?.bankName,
            paidAmount: fs?.paidAmount,
            bank_account: {
              value: fs?.accountNumber,
              label: fs?.accountHolderName,
              id: fs?.accountNumber,
              number: fs?.accountNumber,
            },
          })),
        })),
      });
    }
  }, [detail?.transactionRecords?.length]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isRejectSuccess, setIsRejectSuccess] = useState(false);
  const [isApproveSuccess, setIsApproveSuccess] = useState(false);
  // const [keyword, setKeyword] = useState(undefined);
  // const [statusFilter, setStatusFilter] = useState(null);

  const isEditing = !!params?.id;

  const links = [
    {
      label: "Daftar Approval Pembayaran FDB",
      path: "/payment-request-fdb",
    },
    {
      label: "Detail",
    },
  ];

  const paymentTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Kontraktual" },
      { value: 2, label: "Pembayaran Non Kontraktual" },
      { value: 3, label: "Pembayaran Vendor" },
    ],
    []
  );

  const trxTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Perjadin Pegawai" },
      { value: 2, label: "Pembayaran Undangan" },
      { value: 3, label: "Pembayaran Narasumber" },
    ],
    []
  );

  // const { data: detail } = useWriteOffDetailQuery(
  //   ["write-off-detail", { id: params?.id }],
  //   { enabled: true }
  // );

  const onSubmit = (id, payload) => {
    postTransaction.mutate(
      { payload: payload, id },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const [catatan, setCatatan] = useState("");

  // let documents = detail?.documents?.map((doc) => {
  //   return {
  //     file_name: doc.name,
  //     file_size: doc.size,
  //     url: doc.url,
  //   };
  // });

  const DetailItem = ({ title, value }) => (
    <div className="space-y-2 flex flex-col">
      <span className="font-bold text-sm">{title}</span>
      <span className="text-sm">{value}</span>
    </div>
  );

  const totalPaidAmount = methods
    .watch("transactions")
    ?.reduce?.((total, transaction) => {
      const totalForTransaction = transaction.fundingSources?.reduce?.(
        (subTotal, fundingSource) => subTotal + fundingSource.paidAmount,
        0
      );
      return total + totalForTransaction;
    }, 0);

  const onSubmitHandler = async (payload) => {
    try {
      const totalSeletedAmount = dataSource
        ?.filter((val) => val.checked || val.status === "Paid")
        ?.reduce?.((total, debtor) => total + debtor.disbursementAmount, 0);

      if (totalPaidAmount !== totalSeletedAmount) {
        enqueueSnackbar({
          message: "Total pembayaran tidak sesuai dengan total nilai pencairan",
          variant: "error",
        });
        return;
      }
      const filterCheckedDataSource = dataSource.filter((val) => val.checked);
      let dataPost = {
        paymentDate: moment(payload?.transactions?.[0]?.paymentDate).format(
          "MM-DD-YYYY"
        ),
        fundingSources: payload?.transactions?.[0]?.fundingSources.map(
          (fs) => ({
            accountNumber: fs.accountNumber?.number,
            accountHolderName: fs?.accountHolderName,
            bankName: fs?.bankName,
            paidAmount: fs?.paidAmount,
          })
        ),
        debtorIds: filterCheckedDataSource.map((val) => val.debtorId),
      };
      const res = await postTransaction.mutateAsync({ payload: dataPost, id });
      enqueueSnackbar({
        message: res?.message ?? "Success",
        variant: "success",
      });
      navigate(-1);
    } catch (error) {
      enqueueSnackbar({
        message: error?.message ?? "Error",
        variant: "error",
      });
    }
  };
  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmitHandler)}
          className="flex-1 flex flex-col gap-4"
        >
          <Card>
            <CardHeader>Pembayaran</CardHeader>
            <CardBody>
              <div className="">
                <div className="card w-full bg-white shadow-sm rounded-xl ">
                  <div className="card-body p-4 ">
                    <Accordion label="Detail">
                      <div className="flex flex-col gap-4">
                        <div
                          className={`grid grid-cols-1 ${"md:grid-cols-3"} gap-4`}
                        >
                          <DetailItem
                            title={"Tanggal Permohonan"}
                            value={
                              detail?.createdAt
                                ? moment(detail?.createdAt).format("DD/MM/YYYY")
                                : "-"
                            }
                          />
                          {/* <DetailItem title={"Status"} value={data?.status === 'Pending' ? } /> */}
                          <div className="space-y-2 flex flex-col">
                            <span className="font-bold text-sm">Status</span>
                            <div className="w-[200px]">
                              <StatusBadgeExecution
                                status={
                                  detail?.status === "Pending" || detail?.status === "Menunggu pembayaran"
                                    ? "Menunggu Pembayaran"
                                    : detail?.status
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-lg font-semibold mb-3">
                          Permohonan Diajukan Oleh
                        </div>
                        <div
                          className={`grid grid-cols-1 ${"md:grid-cols-3"} gap-4`}
                        >
                          <DetailItem
                            title={"Nama Kelompok"}
                            value={detail?.applicantGroup}
                          />
                          <DetailItem
                            title={"Lembaga Penyalur"}
                            value={detail?.applicantDistributorInstitution}
                          />
                        </div>

                        <div
                          className={`grid grid-cols-1 ${"md:grid-cols-3"} gap-4`}
                        >
                          <DetailItem
                            title={"Provinsi"}
                            value={detail?.applicantProvince}
                          />
                          <DetailItem
                            title={"Kab/ Kota"}
                            value={detail?.applicantCity}
                          />
                          <DetailItem
                            title={"Alamat"}
                            value={detail?.applicantAddress || "-"}
                          />
                        </div>
                        <Accordion label="Data Permohonan Pembayaran">
                          <div className="space-y-4 w-full">
                            <div
                              className={`grid grid-cols-1 ${"md:grid-cols-2"} gap-4`}
                            >
                              <DetailItem
                                title={"Total Nilai Pengajuan"}
                                value={convertToIDR(
                                  debtorsList?.result?.reduce?.(
                                    (total, debtor) =>
                                      total + debtor?.disbursementAmount,
                                    0
                                  )
                                )}
                              />
                              <DetailItem
                                title={"Jumlah Debitur yang Diajukan"}
                                value={debtorsList?.result?.length || "-"}
                              />
                              {!detail?.instructionLetter ? null : (
                                <FilePickerForm
                                  name="supporting_document"
                                  file={[
                                    {
                                      file_name:
                                        detail?.instructionLetter?.name,
                                      file_size:
                                        detail?.instructionLetter?.size,
                                      url: detail?.instructionLetter
                                        ?.downloadUrl,
                                    },
                                  ]}
                                  readOnly
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </Accordion>
                      </div>
                    </Accordion>
                    <div className="flex flex-col flex-1 border-2 border-gray-200 rounded-xl">
                      <div className="flex flex-row bg-[#E1F1D6] rounded-t-lg py-2 px-4">
                        <div className="flex flex-row items-center gap-2">
                          <span className="text-primary-600 text-lg font-bold">
                            Daftar Nominatif
                          </span>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
                            Total Nilai Pencairan:{" "}
                            {isDetail
                              ? convertToIDR(
                                  dataSource?.reduce?.(
                                    (total, debtor) =>
                                      total + debtor.disbursementAmount,
                                    0
                                  )
                                )
                              : convertToIDR(
                                  dataSource
                                    ?.filter((val) => {
                                      return (
                                        val.checked || val.status === "Paid"
                                      );
                                    })
                                    ?.reduce?.(
                                      (total, debtor) =>
                                        total + debtor.disbursementAmount,
                                      0
                                    )
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table
                      bordered
                      stripped
                      layout="auto"
                      className="w-full"
                      columns={tableColumns}
                      dataSource={dataSource}
                      onChangePage={setNewPage}
                      onChangeRowsPerPage={setNewLimit}
                      setState={setDataSource}
                      pagination={{
                        page: debtorsList?.currentPage,
                        limit: debtorsList?.size,
                        total: debtorsList?.totalItems,
                        previous_pages: debtorsList?.currentPage > 1,
                        next_pages: !(
                          debtorsList?.currentPage === debtorsList?.totalPages
                        ),
                      }}
                    />
                    <Accordion
                      label="Pembayaran"
                      renderLeft={() => (
                        <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
                          Nilai: {convertToIDR(totalPaidAmount)}
                        </div>
                      )}
                    >
                      {methods.watch("transactions").map((payment, index) => {
                        return (
                          <div className="pb-6 mb-6 space-y-4" key={payment.id}>
                            <CustomDatepickerForm
                              name={`paymentDate`}
                              label="Tanggal Pembayaran"
                              placeholder={"dd/mm/yyyy"}
                              required
                              disabled={isDetail}
                              onChangeDate={(value) => {
                                methods.setValue(
                                  `transactions.${index}.paymentDate`,
                                  value
                                );
                              }}
                              value={moment(payment?.paymentDate || "").format(
                                "DD/MM/YYYY"
                              )}
                            />
                            <div className="flex justify-end">
                              {isDetail ? null : (
                                <Button
                                  startIcon={<FiPlus />}
                                  className="border rounded-lg bg-primary-600 border-primary-600 p-4"
                                  onClick={() => {
                                    methods.setValue(
                                      `transactions.${index}.fundingSources`,
                                      [
                                        ...methods.getValues(
                                          `transactions.${index}.fundingSources`
                                        ),
                                        {
                                          accountNumber: "",
                                          accountHolderName: "",
                                          bankName: "",
                                          paidAmount: 0,
                                          bank_account: {
                                            value: "",
                                            label: "",
                                            id: "",
                                            number: "",
                                          },
                                        },
                                      ]
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className="flex flex-col gap-4">
                              {payment?.fundingSources?.map?.(
                                (fundingSource, idx) => (
                                  <div
                                    className="border-b-[1px] pb-6 mb-6 space-y-4"
                                    key={fundingSource.id}
                                  >
                                    <div className="flex flex-row items-end justify-end gap-4">
                                      <div className="grid grid-cols-3 gap-4 w-full">
                                        <SelectBankAccountForm
                                          label="Nomor Rekening"
                                          name={`transactions.${index}.fundingSources.${idx}.accountNumber`}
                                          placeholder="Nomor Rekening"
                                          type=""
                                          required
                                          isDisabled={isDetail}
                                          onSelect={(value) => {
                                            methods.setValue(
                                              `transactions.${index}.fundingSources.${idx}.bankName`,
                                              value?.bank_name
                                            );

                                            methods.setValue(
                                              `transactions.${index}.fundingSources.${idx}.accountHolderName`,
                                              value?.name
                                            );
                                          }}
                                        />
                                        <InputForm
                                          name={`transactions.${index}.fundingSources.${idx}.accountHolderName`}
                                          label="Nama Pemilik Rekening"
                                          className="w-full"
                                          disabled
                                          placeholder="Pilih Nomor Rekening"
                                          required
                                        />
                                        <NumberInputForm
                                          name={`transactions.${index}.fundingSources.${idx}.paidAmount`}
                                          label="Nilai Yang Dibayar"
                                          className="w-full"
                                          required
                                          disabled={isDetail}
                                        />
                                      </div>

                                      {!isDetail && (
                                        <>
                                          {payment?.fundingSources.length -
                                            1 ===
                                          index ? (
                                            // <Button
                                            //   startIcon={<FiPlus />}
                                            //   className="border rounded-lg bg-primary-600 border-primary-600 p-4"
                                            //   onClick={() => {
                                            //     methods.setValue(
                                            //       `transactions.${index}.fundingSources`,
                                            //       [
                                            //         ...methods.getValues(
                                            //           `transactions.${index}.fundingSources`
                                            //         ),
                                            //         {
                                            //           accountNumber: "",
                                            //           accountHolderName: "",
                                            //           paidAmount: 0,
                                            //           bank_account: {
                                            //             value: "",
                                            //             label: "",
                                            //             id: "",
                                            //             number: "",
                                            //           },
                                            //         },
                                            //       ]
                                            //     );
                                            //   }}
                                            // />
                                            <></>
                                          ) : (
                                            <Button
                                              startIcon={<FiTrash />}
                                              className="border rounded-lg bg-red-600 border-red-600 p-4"
                                              onClick={() => {
                                                methods.setValue(
                                                  `transactions.${index}.fundingSources`,
                                                  methods
                                                    .getValues(
                                                      `transactions.${index}.fundingSources`
                                                    )
                                                    .filter(
                                                      (o, i) => i !== index
                                                    )
                                                );
                                              }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Accordion>
                    {/* <div className="space-y-2">
                    <label className="flex gap-1 font-semibold text-[14px]">
                      <span className={`label-text`}>Total Nilai Transaksi</span>
                    </label>
                    <NumericFormat
                      value={sumBy(methods.watch("accounts"), (account) =>
                        sumBy(
                          account.activity_details,
                          (activity_detail) =>
                            parseFloat(activity_detail.unit_cost) *
                              (parseFloat(activity_detail.volume) +
                                parseFloat(activity_detail.qty)) -
                            parseFloat(
                              activity_detail.taxes?.reduce(
                                (acc, o) => acc + o?.value,
                                0
                              )
                            )
                        )
                      )}
                      placeholder="0"
                      className="w-full"
                      customInput={Input}
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      disabled
                    />
                  </div> */}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <div className="card py-4 w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="flex-1 flex items-center justify-between space-x-4 px-8">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 "
                disabled={isLoading}
                onClick={() => navigate("/executions-fdb")}
              >
                Kembali
              </Button>
              {action && action === "pay" && (
                <div className="">
                  {/* <Button
                    className="bg-error-600 border-error-600 gap-2"
                    startIcon={<RiCloseCircleLine size={18} />}
                    onClick={() => setIsOpenReject(true)}
                  >
                    Tolak
                  </Button> */}
                  <Button
                    type="submit"
                    // startIcon={<RiCheckLine size={18} />}
                    className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 space-x-1 ml-2"
                    disabled={postTransaction.isLoading}
                  >
                    <span>{"Submit"}</span>
                    {postTransaction.isLoading && (
                      <CgSpinner className="animate-spin" />
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ExecutionFDBDetail;
