import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction } from "components";
import { CustomSelectForm } from "components/HookForm";
import moment from "moment";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { FormHelperContext } from ".";
import ActivityDetail from "./activity-detail";
import { useNavigate } from "react-router";

const InformationForm = () => {
  const schema = yup.object().shape({
    expert: yup.array().min(1, "SBM Wajib Diisi"),
    member: yup.array().min(1, "SBM Wajib Diisi"),
  });

  const navigate = useNavigate();
  const { detail, handleNext } = useContext(FormHelperContext);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (detail) {
      methods.reset({
        fiscal_year: {
          label: detail?.fiscal_year,
          value: detail?.fiscal_year,
        },
        activity_types: detail?.activity_types?.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
        expert: detail?.sbm
          ?.filter((val) => val?.type === "expert")
          ?.map((sbm) => ({
            label: sbm?.sbm_name,
            value: sbm?.sbm_id,
          })),
        member: detail?.sbm
          ?.filter((val) => val?.type === "member")
          ?.map((sbm) => ({
            label: sbm?.sbm_name,
            value: sbm?.sbm_id,
          })),
        component: {
          label: detail?.component?.name,
          value: detail?.component?.code,
        },
        activity_name: detail?.activity_name ?? "",
        destination_type: detail?.destination_type ?? "",
        start_date: moment(detail?.start_date).toDate(),
        end_date: moment(detail?.end_date).toDate(),
        country: {
          label: detail?.country ?? "Indonesia",
          value: detail?.country ?? "Indonesia",
        },
        city: detail?.city
          ? {
              label: detail?.city,
              value: detail?.city,
            }
          : "",
        destination: {
          label: detail?.destination,
          value: detail?.destination,
        },
        pum: {
          label: `${detail?.pum?.nip} - ${detail?.pum?.name}`,
          value: detail?.pum?.user_id,
        },
        component_code: detail?.component_code,
        assignment_letter_number: detail?.assignment_letter_number,
        assignment_letter_date: moment(detail?.assignment_letter_date).toDate(),
        staffs: detail?.staffs?.map((staff) => ({
          user_id: {
            label: `${staff?.nip} - ${staff?.name}`,
            value: staff?.user_id,
          },
          nip: staff?.nip,
          gender: staff?.gender,
          position: staff?.position,
          division: staff?.division,
        })),
      });
    }
  }, [detail]);

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <ActivityDetail />
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">Komponen Kegiatan</div>
              <CustomSelectForm
                name="component"
                options={[]}
                label="Komponen"
                isDisabled
              />
            </div>
          </div>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">
                Kategori SBM yang Digunakan untuk Narasumber
              </div>
              <CustomSelectForm
                name="expert"
                options={[]}
                label="SBM Yang Digunakan Untuk Narasumber"
                isMulti
                isDisabled
              />
            </div>
          </div>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-4 space-y-4">
            <div className="space-y-3">
              <div className="text-lg font-semibold">
                Kategori SBM yang Digunakan untuk Peserta
              </div>
              <CustomSelectForm
                name="member"
                options={[]}
                label="SBM Yang Digunakan Untuk Peserta"
                isMulti
                isDisabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-24" />
      <BottomFormAction
        onSubmit={handleNext}
        onCancel={() => navigate(-1)}
        _submitButton={{
          label: "Selanjutnya",
        }}
        _cancelButton={{
          label: "Sebelumnya",
        }}
        hideCancelButton
      />
    </FormProvider>
  );
};

export default InformationForm;
