import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { find, map } from "lodash";

import { Breadcrumbs, TitleText } from "components";
import { useAccountGroupDetail } from "hooks/accountGroup";

const FORM_TYPE = ["add", "edit"];
const LOCALE_FORM = {
  add: {
    title: "Tambah Group Akun",
  },
  edit: {
    title: "Set Kewenangan Akun",
  },
};

const AuthorityDetail = () => {
  const location = useLocation();

  const id = location?.state?.id ?? null;

  const { data: detail } = useAccountGroupDetail(["ag_detail", id]);

  const formType = useMemo(
    () => find(FORM_TYPE, (type) => location.pathname.includes(type)),
    []
  );

  const links = [
    {
      path: "/authority",
      label: "Daftar Kewenangan Akun",
    },
    {
      label: "Detail",
    },
  ];

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-2" />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Detail</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-3">
          <div className="space-y-1">
            <p className="text-sm font-bold">Direktorat</p>
            <p className="text-sm ">
              {location?.state?.directorate_name
                ? location?.state?.directorate_name
                : "-"}
            </p>
          </div>
          <div className="space-y-2">
            <table className="min-w-full border text-sm">
              <thead className="bg-primary-600">
                <tr>
                  <th className="px-6 py-4 text-left text-white">Kode Akun</th>
                  <th className="px-6 py-4 text-left text-white">Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                {map(detail?.data ?? [], (data, key) => (
                  <tr key={key} className="border">
                    <td className="px-6 py-4">{data?.full_code}</td>
                    <td className="px-6 py-4">{data?.coa_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorityDetail;
