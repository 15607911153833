import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs } from "components";
import {
  CustomDatepickerForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectAccountForm,
  SelectBankAccountForm,
} from "components/HookForm";
import { useCreateFundReceipt } from "hooks/cash";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";

const schema = yup.object().shape({
  type: yup.string().required("Wajib Diisi."),
  // account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  // }),
  account: yup.mixed().required("Wajib Diisi."),
  value: yup.number().positive().typeError("Wajib Diisi").required(),
  transaction_date: yup.date().typeError("Wajib diisi").required(),
  // bank_account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  //   name: yup.string().typeError("Wajib Diisi").required(),
  //   number: yup.string().typeError("Wajib Diisi").required(),
  // }),
  bank_account: yup.mixed().required("Wajib Diisi."),
});

const CashReceivingForm = () => {
  const navigate = useNavigate();
  const createFundReceipt = useCreateFundReceipt();
  const links = [
    {
      label: "Daftar Mekanisme Penerimaan Uang Masuk",
      path: "/cash-receiving",
    },
    {
      label: "Tambah Mekanisme Penerimaan Uang Masuk",
    },
  ];

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      account: null,
      bank_account: null,
    },
  });

  const onSubmit = (payload) => {
    const params = {
      account_code: payload?.account?.value,
      account_name: payload?.account?.name,
      transaction_date: moment(payload.transaction_date).format("YYYY-MM-DD"),
      bank_account_id: payload?.bank_account?.id,
      is_draft: false,
      type: payload?.type,
      value: payload?.value,
      description: payload?.description,
    };

    createFundReceipt.mutate(params, {
      onSuccess: (data) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        navigate(-1);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error?.message ?? "Error",
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                Tambah Mekanisme Penerimaan Uang Masuk
              </div>
              <RadioGroupForm
                label="Jenis Transaksi"
                required
                controllerName="type"
                values={[
                  {
                    label: "Uang Masuk Bendahara Penerimaan",
                    value: "treasury",
                  },
                  {
                    label: "Uang Masuk Dana Kelolaan",
                    value: "managed",
                  },
                ]}
              />
              <CustomDatepickerForm
                name="transaction_date"
                label="Tanggal Permintaan"
                placeholder={"dd/mm/yyyy"}
                required
              />
              <div className="grid grid-cols-2 gap-4">
                <SelectBankAccountForm
                  label="Nomor Rekening"
                  name="bank_account"
                  placeholder="Pilih Nomor Rekening"
                  type="Pemasukan"
                  required
                />
                <SelectAccountForm
                  name="account"
                  label="Akun"
                  placeholder="Pilih Akun"
                  level="9"
                  required
                />
                <InputForm
                  name="account_number"
                  label="Nama Pemilik Rekening"
                  className="w-full"
                  disabled
                  value={methods.watch("bank_account")?.number}
                  placeholder="Pilih Nomor Rekening"
                  required
                />
                <InputForm
                  name="bank"
                  label="Bank"
                  className="w-full"
                  placeholder="Pilih Nomor Rekening"
                  disabled
                  value={methods.watch("bank_account")?.bank_name}
                  required
                />
              </div>
              <NumberInputForm
                label="Nominal"
                name="value"
                className="w-full"
                required
              />
              <InputForm
                name="description"
                label="Deskripsi"
                className="w-full"
                type="textarea"
              />
            </div>
          </div>
        </div>
        <div className="h-32"></div>
        <BottomFormAction
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={() => navigate(-1)}
        />
      </FormProvider>
    </>
  );
};

export default CashReceivingForm;
