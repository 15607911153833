import { Table } from "components";
import {
  useChartOfAccount,
  useChartOfAccountDetail,
} from "hooks/chartOfAccount";
import React, { useEffect, useState } from "react";

const Detail = ({ id }) => {
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const { data: detail } = useChartOfAccountDetail(["coa-detail", id]);
  const {
    data: childData,
    isLoading,
    remove,
  } = useChartOfAccount([
    "coa-child",
    { parent_id: detail?.coa_id, page: newPage, size: newLimit },
  ]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  const tableColumns = [
    {
      id: "code",
      title: "Kode",
      dataIndex: "code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "coa_name",
      title: "Nama Akun",
      dataIndex: "coa_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "position",
      title: "Posisi",
      dataIndex: "position",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "full_code",
      title: "Fullcode",
      dataIndex: "full_code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="space-y-1">
        <p className="text-sm font-bold">Akun Parent</p>
        <p className="text-sm ">
          {`${detail?.full_code} - ${detail?.coa_name}`}
        </p>
      </div>
      <div className="space-y-1">
        <p className="text-sm font-bold">Level Akun Parent</p>
        <p className="text-sm ">{`Level ${detail?.level}`}</p>
      </div>
      <div className="space-y-2">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumns}
          dataSource={childData?.result}
          isLoading={isLoading}
          onChangePage={setNewPage}
          onChangeRowsPerPage={setNewLimit}
          pagination={{
            page: childData?.currentPage,
            limit: childData?.size,
            total: childData?.totalItems,
            previous_pages: childData?.currentPage > 1,
            next_pages: !(childData?.currentPage === childData?.totalPages),
          }}
        />
      </div>
    </div>
  );
};

export default Detail;
