import { useMutation, useQuery } from "react-query";
import { DT } from "services";

export const useSubmissionQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DT.getSubmissions({ ...params }, signal);
    },
    ...options,
  });
};

export const useSubmissionDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DT.getSubmissionDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const useSubmissionRecapQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DT.getSubmissionRecap({ ...params }, signal);
    },
    ...options,
  });
};

export const useVendorProcurementQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DT.getVendorProcurement({ ...params }, signal);
    },
    ...options,
  });
};

export const useSbmQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DT.getSbm({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreateContractual = () => {
  return useMutation((payload) => DT.createContractual(payload));
};

export const useUpdateContractual = () => {
  return useMutation((payload) => DT.updateContractual(payload));
};

export const useCreateNonContractualStaff = () => {
  return useMutation((payload) => DT.createNonContractualStaff(payload));
};

export const useUpdateNonContractualStaff = () => {
  return useMutation((payload) => DT.updateNonContractualStaff(payload));
};

export const useCreateNonContractualParticipant = () => {
  return useMutation((payload) => DT.createNonContractualParticipant(payload));
};

export const useUpdateNonContractualParticipant = () => {
  return useMutation((payload) => DT.updateNonContractualParticipant(payload));
};

export const useCreateVendor = () => {
  return useMutation((payload) => DT.createVendor(payload));
};

export const useUpdateVendor = () => {
  return useMutation((payload) => DT.updateVendor(payload));
};

export const useDeleteSubmission = () => {
  return useMutation((payload) => DT.deleteSubmission(payload));
};

export const useApprovalSubmission = () => {
  return useMutation((payload) => DT.approvalSubsmission(payload));
};