import React from "react";

import CollapsingCard from "components/Card/CollapsingCard";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { Button } from "components";
import { BiPlus } from "react-icons/bi";

const GoalCeilling = ({}) => {
  return (
    <CollapsingCard title="Pagu Sumber">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <CustomSelectForm
            className="w-full"
            name={`goal_ceiling.component`}
            label="Komponen"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.ceiling_value`}
          label="Nilai Pagu"
        />
        <div className="col-span-2">
          <CustomSelectForm
            className="w-full"
            name={`goal_ceiling.shopping`}
            label="Belanja"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.shopping_ceiling_value`}
          label="Nilai Pagu"
        />

        <div className="col-span-3">
          <CustomSelectForm
            className="w-full"
            name={`goal_ceiling.component`}
            label="Komponen"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <div className="col-span-3">
          <div className="grid grid-cols-2 gap-4">
            <CustomSelectForm
              className="w-full"
              name={`goal_ceiling.shopping`}
              label="Belanja"
              options={[{ value: "dummy1", label: "Dummy 1" }]}
            />
            <InputForm
              disabled
              className="w-full"
              name={`goal_ceiling.shopping_ceiling_value`}
              label="Nilai Pagu"
            />
          </div>
        </div>

        {/* Activity Detail */}
        <div className="col-span-3">
          <CustomSelectForm
            className="w-full"
            name={`goal_ceiling.activity_detail`}
            label="Detail Kegiatan"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.volume`}
          label="Volume"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.price_per_unit`}
          label="Harga Satuan"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.ceiling_value_activity`}
          label="Nilai Pagu"
        />
        <InputForm
          className="w-full"
          name={`goal_ceiling.price_changes`}
          label="Perubahan Harga"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.ceiling_after_revision`}
          label="Nilai Pagu Setelah Revisi"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.transferable_value`}
          label="Nilai Yang Lapat Dipindahkan"
        />

        {/* Divider */}
        <div className="col-span-3 py-2">
          <div className="h-[1px] bg-gray-400" />
        </div>

        {/* Activity Detail */}
        <div className="col-span-3">
          <CustomSelectForm
            className="w-full"
            name={`goal_ceiling.activity_detail_2`}
            label="Detail Kegiatan"
            options={[{ value: "dummy1", label: "Dummy 1" }]}
          />
        </div>
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.volume_2`}
          label="Volume"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.price_per_unit_2`}
          label="Harga Satuan"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.ceiling_value_activity_2`}
          label="Nilai Pagu"
        />
        <InputForm
          className="w-full"
          name={`goal_ceiling.price_changes_2`}
          label="Perubahan Harga"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.ceiling_after_revision_2`}
          label="Nilai Pagu Setelah Revisi"
        />
        <InputForm
          disabled
          className="w-full"
          name={`goal_ceiling.transferable_value_2`}
          label="Nilai Yang Lapat Dipindahkan"
        />

        {/* Divider */}
        <div className="col-span-3 py-2">
          <div className="h-[1px] bg-gray-400" />
        </div>

        {/* Result */}
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="flex flex-col items-end justify-center space-y-4">
          <InputForm
            disabled
            className="w-full"
            name={`goal_ceiling.total_revision_resource`}
            label="Total Nominal Sumber Revisi"
          />
          <Button
            type="submit"
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 flex items-center space-x-2"
          >
            <BiPlus />
            <div className="flex items-center gap-2">
              Tambah Detail Kegiatan
            </div>
          </Button>
        </div>
      </div>
    </CollapsingCard>
  );
};

export default GoalCeilling;
