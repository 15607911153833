import { useMutation, useQuery } from "react-query";
import { TE } from "services";

export const usePaymentDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getPaymentDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreatePayment = () => {
  return useMutation((payload) => TE.createPayment(payload));
};

export const usePaymentRequestQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getPaymentRequests({ ...params }, signal);
    },
    ...options,
  });
};

export const useExpenseQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getExpense({ ...params }, signal);
    },
    ...options,
  });
};

export const useFundReceiptQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getFundReceipt({ ...params }, signal);
    },
    ...options,
  });
};

export const useFundReceiptDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getFundReceiptDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const useCreateFundReceiptPosting = () => {
  return useMutation((payload) => TE.createFundReceiptPosting(payload));
};

export const useCreateFundReceipt = () => {
  return useMutation((payload) => TE.createFundReceipt(payload));
};

// Cash Transfer

export const useCreateCashTransfer = () => {
  return useMutation((payload) => TE.createCashTransfer(payload));
};

export const useCreateCashTransferPosting = () => {
  return useMutation((payload) => TE.createCashTransferPosting(payload));
};

export const useCashTransferQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getCashTransfer({ ...params }, signal);
    },
    ...options,
  });
};

export const useCashTransferDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getCashTransferDetail({ ...params }, signal);
    },
    ...options,
  });
};

// Direct Cash Transfer

export const useCreateDirectCashTransfer = () => {
  return useMutation((payload) => TE.createDirectCashTransfer(payload));
};

export const useCreateDirectCashTransferPosting = () => {
  return useMutation((payload) => TE.createDirectCashTransferPosting(payload));
};

export const useDirectCashTransferQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getDirectCashTransfer({ ...params }, signal);
    },
    ...options,
  });
};

export const useDirectCashTransferDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getDirectCashTransferDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const usePaymentFDBQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getPaymentFDB({ ...params }, signal);
    },
    ...options,
  });
};

export const usePaymentFDBDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getPaymentFDBDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const usePaymentFDBDebtorQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return TE.getDebtorFDBDetail({ ...params }, signal);
    },
    ...options,
  });
};

export const useAddTransactionFDB = () => {
  return useMutation((payload) => TE.postTransactionFDB(payload));
};