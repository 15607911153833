import { useChartOfAccountLevel } from "hooks/chartOfAccount";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { CustomSelectForm } from "..";

const SelectAccountForm = ({
  name,
  level,
  label,
  placeholder,
  enabled,
  parentId,
  ...props
}) => {
  const [keyword, setKeyword] = useState("");

  const { data: coaOptions } = useChartOfAccountLevel([
    "coa-option-list",
    {
      page: 1,
      size: 20,
      search: keyword,
      level,
      parent_id: parentId,
    },
    {
      enabled: enabled ?? true,
    },
  ]);

  const handleSearchCoa = debounce(
    useCallback((keyword) => setKeyword(keyword ?? ""), []),
    500
  );
  return (
    <CustomSelectForm
      name={name}
      options={coaOptions?.result?.map((value) => ({
        label: `${value?.full_code} - ${value?.name}`,
        value: value?.full_code,
        id: value?.id,
        name: value?.name,
      }))}
      label={label}
      placeholder={placeholder}
      onInputChange={(keyword) => {
        handleSearchCoa(keyword);
      }}
      {...props}
    />
  );
};

export default SelectAccountForm;
