export const getStatusProperties = (status) => {
    let statusProperties = getProperties(status);
    return statusProperties;
  };
  
  const getProperties = (status) => {
    let statusProperties;
    switch (status) {
      case "Telah hapus bukuan":
        statusProperties = {
          variant: "success",
        };
        break;
      default:
        statusProperties = {
          variant: "warning",
        };
        break;
    }
  
    return statusProperties;
  };