import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import StatusBadge from "./StatusBadge";
import { useWriteOffQuery } from "hooks/writeOff";
import { useSelector } from "react-redux";

const WriteOffRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: witeOffList } = useWriteOffQuery([
    "write-off-list",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      status: statusFilter?.value,
    },
  ]);
  const links = [
    {
      label: "Daftar Permintaan Hapus Bukuan Tidak Hapus Tagih",
    },
  ];

  const renderStatusAction = (data) => {
    switch (data?.status) {
      case "Menunggu Hapus Bukuan":
        return (
          <>
            {roleAccess?.canDelete && (
              <Button
                onClick={() =>
                  navigate(
                    `/write-off-request/detail/${data?.id}?action=hapus-bukuan`
                  )
                }
                className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
              >
                Hapus Bukuan
              </Button>
            )}
          </>
        );
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/write-off-request/detail/${data?.id}`)}
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "created_at",
      title: "Tanggal Permintaan",
      dataIndex: "createdAt",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "nap",
      title: "NAP",
      dataIndex: "nap",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "debtor_name",
      title: "Nama Debitur",
      dataIndex: "debtor_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
          {/* {convertToIDR(value)} */}
        </div>
      ),
    },
    {
      id: "principal_arrears_balance",
      title: "Sisa Saldo Pokok",
      dataIndex: "principal_arrears_balance",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "interest_arrears_balance",
      title: "Sisa saldo Bunga",
      dataIndex: "interest_arrears_balance",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {/* {value ? moment(value).format("DD/MM/YYYY") : "-"} */}
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
      columnClassName: "right-[181px] block shadow-lg",
      fixed: "right",
      sortable: true,
      render: (value) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadge status={value} role={role} />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">
          Daftar Permintaan Hapus Bukuan Tidak Hapus Tagih
        </TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              {/* <CustomSelect
                value={statusFilter}
                optionsData={[]}
                label="Jenis Transaksi"
                placeholder="Jenis Transaksi"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              />
              <CustomSelect
                value={statusFilter}
                optionsData={[]}
                label="Status"
                placeholder="Status"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              />
              <Input className="flex-1" placeholder="dd/mm/yyyy" /> */}
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={witeOffList?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: witeOffList?.paginator?.currentPage,
                limit: witeOffList?.paginator?.limit,
                total: witeOffList?.paginator?.itemCount,
                previous_pages: witeOffList?.paginator?.currentPage > 1,
                next_pages: !(
                  witeOffList?.paginator?.page ===
                  witeOffList?.paginator?.totalPages
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WriteOffRequest;
