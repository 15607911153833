import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { BottomFormAction, Breadcrumbs, Input } from "components";
import { InputForm, NumberInputForm } from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import {
  useActivityDetailQuery,
  useRealizationDifference,
  useSubmitRealization,
} from "hooks/downPayment";
import { useUploadFile } from "hooks/externalService";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { createContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import ActivityDetail from "./activity-detail";
import { Recap } from "./recap";

export const FormHelperContext = createContext({
  handleNext: () => {},

  detail: null,
});

const SubmitAccountabilityApproval = () => {
  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Lengkapi Rincian Detail Kegiatan",
    },
  ];

  const navigate = useNavigate();
  const params = useParams();

  const uploadFile = useUploadFile();
  const submitRealization = useSubmitRealization();

  const { data: detail } = useActivityDetailQuery([
    "dp-detail-pum",
    params?.id,
  ]);

  const { data: difference } = useRealizationDifference(
    [
      "dp-accountability-difference",
      {
        id: detail?.id,
      },
    ],
    {
      enabled: !!detail?.id,
    }
  );

  const schema = yup.object().shape({
    ...(difference?.budget > difference?.realization && {
      bank_account_name: yup
        .string()
        .typeError("Wajib diisi")
        .min(1, "Wajib diisi"),
      bank_account_number: yup
        .string()
        .typeError("Wajib diisi")
        .min(1, "Wajib diisi"),
      bank_name: yup.string().typeError("Wajib diisi").min(1, "Wajib diisi"),
      notes: yup.string().optional(),
      value: yup
        .number()
        .typeError("Wajib diisi")
        .required("Wajib diisi")
        .positive("Masukkan angka yang valid"),
      documents: yup.array().typeError("Wajib diisi").min(1, "Wajib diisi"),
    }),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (detail) {
      methods.reset({
        fiscal_year: {
          label: detail?.fiscal_year,
          value: detail?.fiscal_year,
        },
        activity_types: detail?.activity_types?.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
        expert: detail?.sbm
          ?.filter((val) => val?.type === "expert")
          ?.map((sbm) => ({
            label: sbm?.sbm_name,
            value: sbm?.sbm_id,
          })),
        member: detail?.sbm
          ?.filter((val) => val?.type === "member")
          ?.map((sbm) => ({
            label: sbm?.sbm_name,
            value: sbm?.sbm_id,
          })),
        component: {
          label: detail?.component?.name,
          value: detail?.component?.code,
        },
        activity_name: detail?.activity_name ?? "",
        destination_type: detail?.destination_type ?? "",
        start_date: moment(detail?.start_date).toDate(),
        end_date: moment(detail?.end_date).toDate(),
        country: {
          label: detail?.country ?? "Indonesia",
          value: detail?.country ?? "Indonesia",
        },
        city: detail?.city
          ? {
              label: detail?.city,
              value: detail?.city,
            }
          : "",
        destination: {
          label: detail?.destination,
          value: detail?.destination,
        },
        pum: {
          label: `${detail?.pum?.nip} - ${detail?.pum?.name}`,
          value: detail?.pum?.user_id,
        },
        component_code: detail?.component_code,
        assignment_letter_number: detail?.assignment_letter_number,
        assignment_letter_date: moment(detail?.assignment_letter_date).toDate(),
        staffs: detail?.staffs?.map((staff) => ({
          user_id: {
            label: `${staff?.nip} - ${staff?.name}`,
            value: staff?.user_id,
          },
          nip: staff?.nip,
          gender: staff?.gender,
          position: staff?.position,
          division: staff?.division,
        })),
      });
    }
  }, [detail]);

  const onSubmit = async (payload) => {
    try {
      const formData = new FormData();
      let uploadData;

      if (difference?.budget > difference?.realization) {
        formData.append("file", payload?.documents[0]);
        uploadData = await uploadFile.mutateAsync(formData);
      }

      const params = {
        minus: difference?.budget < difference?.realization,
        ...(difference?.budget > difference?.realization && {
          return_data: {
            ...payload,
          },
          documents: [
            {
              file_id: uploadData?.data?.id,
              file_name: uploadData?.name,
              file_size: uploadData?.data?.size,
              mime_type: uploadData?.data?.mimeType,
              path: uploadData?.data?.key,
            },
          ],
        }),
      };

      submitRealization.mutate(
        { id: detail?.id, payload: params },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  return (
    <FormHelperContext.Provider
      value={{
        detail,
        handleNext: () => {},
      }}
    >
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <ActivityDetail />
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold">
                  Pengembalian Selisih
                </div>
                <div
                  className={classNames(
                    "rounded-xl text-xs px-2 flex flex-row items-center gap-1 font-semibold w-28",
                    difference?.budget > difference?.realization
                      ? "bg-bluelight-50 text-bluelight-700 border-bluelight-50"
                      : "bg-[#FEF3F2] text-error-700 border-[#FEF3F2]"
                  )}
                >
                  <h1
                    className={classNames(
                      "text-2xl -mt-1",
                      difference?.budget > difference?.realization
                        ? " text-bluelight-700 "
                        : " text-error-700 "
                    )}
                  >
                    &#8226;
                  </h1>
                  {difference?.budget > difference?.realization
                    ? "Selisih Lebih"
                    : "Selisih Kurang"}
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <label className="flex gap-1 font-semibold text-[14px]">
                      <span className={`label-text`}>Uang Muka (Rp)</span>
                    </label>
                    <NumericFormat
                      value={difference?.budget}
                      placeholder="0"
                      className="w-full"
                      customInput={Input}
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      disabled
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="flex gap-1 font-semibold text-[14px]">
                      <span className={`label-text`}>Realisasi (Rp)</span>
                    </label>
                    <NumericFormat
                      value={difference?.realization}
                      placeholder="0"
                      className="w-full"
                      customInput={Input}
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      disabled
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="flex gap-1 font-semibold text-[14px]">
                      <span className={`label-text`}>Selisih (Rp)</span>
                    </label>
                    <NumericFormat
                      value={Math.abs(difference?.difference)}
                      placeholder="0"
                      className={classNames(
                        "w-full",
                        difference?.budget > difference?.realization
                          ? "!bg-blue-50"
                          : "!bg-[#FEF3F2]"
                      )}
                      customInput={Input}
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      disabled
                    />
                  </div>
                </div>
                {difference?.budget > difference?.realization && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <NumberInputForm
                        name="value"
                        label="Nilai Transfer"
                        className="w-full"
                        placeholder="Masukkan Nilai Transfer"
                      />
                      <InputForm
                        name="bank_account_number"
                        label="Nomor Rekening"
                        className="w-full"
                        placeholder="Masukkan Nomor Rekening"
                      />
                      <InputForm
                        name="bank_account_name"
                        label="Nama Pemilik Rekening"
                        className="w-full"
                        placeholder="Masukkan Nama Pemilik Rekening"
                      />
                      <InputForm
                        name="bank_name"
                        label="Bank"
                        className="w-full"
                        placeholder="Bank"
                      />
                    </div>
                    <InputForm
                      name="notes"
                      label="Keterangan"
                      className="w-full"
                      placeholder="Masukkan Keterangan"
                      type="textarea"
                    />
                    <FilePickerForm
                      name="documents"
                      label="Dokumen Bukti Pendukung"
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <Recap
                detail={detail}
                handleNext={() => {}}
                handlePrevious={() => {}}
                hideBottomForm
                hideDifference
              />
            </div>
          </div>
        </div>
        <div className="h-24" />
        <BottomFormAction
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={() => navigate(-1)}
          _submitButton={{
            label: "Submit",
          }}
          _cancelButton={{
            label: "Kembali",
          }}
        />
      </FormProvider>
    </FormHelperContext.Provider>
  );
};

export default SubmitAccountabilityApproval;
