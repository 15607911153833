import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs } from "components";
import {
  CustomDatepickerForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectBankAccountForm,
} from "components/HookForm";
import { useCreateDirectCashTransfer } from "hooks/cash";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";

const schema = yup.object().shape({
  value: yup.string().required("Wajib Diisi"),
  transaction_date: yup.string().required("Wajib diisi"),
  bank_account: yup.mixed().required("Wajib Diisi"),
  type: yup.string().required("Wajib Diisi"),
});

const CashFlowForm = () => {
  const navigate = useNavigate();
  const createDirectCashTransfer = useCreateDirectCashTransfer();
  const links = [
    {
      label: "Daftar Pemindahan Kas Tunai",
      path: "/bank-cash-flow",
    },
    {
      label: "Tambah Pemindahan Kategori Kas Tunai",
    },
  ];

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "",
      bank_account: null,
      value: "",
      transaction_date: "",
    },
  });

  const onSubmit = (payload) => {
    const params = {
      type: payload?.type,
      transaction_date: moment(payload.transaction_date).format("YYYY-MM-DD"),
      bank_account_id: payload?.bank_account?.id,
      is_draft: false,
      value: payload?.value,
      description: payload?.description,
    };

    createDirectCashTransfer.mutate(params, {
      onSuccess: (data) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        navigate(-1);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error?.message ?? "Error",
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                Tambah Pemindahan Kas Tunai
              </div>
              <RadioGroupForm
                label="Jenis Aktivitas"
                controllerName="type"
                values={[
                  {
                    label: "Setor",
                    value: "store",
                  },
                  {
                    label: "Tarik",
                    value: "withdraw",
                  },
                ]}
              />
              <CustomDatepickerForm
                name="transaction_date"
                label="Tanggal Permintaan"
                placeholder={"dd/mm/yyyy"}
              />
              <div className="grid grid-cols-2 gap-4">
                <SelectBankAccountForm
                  label="Nomor Rekening"
                  name="bank_account"
                  placeholder="Pilih Nomor Rekening"
                  required
                />
                <InputForm
                  name="branch"
                  label="Bank"
                  className="w-full"
                  disabled
                  value={methods.watch("bank_account")?.bank_name}
                />
              </div>
              <NumberInputForm label="Jumlah" name="value" className="w-full" />
              <InputForm
                name="description"
                label="Deskripsi"
                className="w-full"
                type="textarea"
              />
            </div>
          </div>
        </div>
        <div className="h-32"></div>
        <BottomFormAction
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={() => navigate(-1)}
        />
      </FormProvider>
    </>
  );
};

export default CashFlowForm;
