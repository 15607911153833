import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "components";
import { InputForm, NumberInputForm } from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { ConfirmationModal } from "components/Modal";
import { useUploadFile } from "hooks/externalService";
import {
  useApprovalUMO,
  useOperationalDetailQuery,
  useRealizationApprovalUMO,
  useRealizationUMO,
  useSubmitRealizationUMO,
} from "hooks/operationalAdvance";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import RejectionModal from "pages/DownPayment/rejection-modal";
import { getFileFromUrl } from "pages/DownPayment/util";
import { createContext, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import * as yup from "yup";
import Account from "./Account";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";

export const HelperContext = createContext({
  isRealizationCreated: false,
});

const OperationalAdvanceRealization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const uploadFile = useUploadFile();
  const submitRealization = useSubmitRealizationUMO();
  const approval = useRealizationApprovalUMO();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const { data: detail } = useOperationalDetailQuery([
    "umo-detail",
    params?.id,
  ]);
  const { data: realization } = useRealizationUMO([
    "umo-realization",
    params?.id,
  ]);

  const isRealizationCreated = detail?.realization_status === "created";

  const links = [
    {
      label: "Uang Muka Operasional",
      path: "/operational-advances",
    },
    {
      label: "Daftar Pengajuan UMO",
      path: "/operational-advances",
    },
    {
      label: "Pertanggung Jawaban",
    },
  ];

  const schema = yup.object().shape({
    accounts: yup.array().of(
      yup.object({
        activities: yup.array().of(
          yup.object({
            activity_detail: yup
              .object({
                label: yup.string().required("Wajib diisi"),
                value: yup.string().required("Wajib diisi"),
              })
              .typeError("Wajib diisi"),
            value: yup
              .number()
              .typeError("Wajib diisi")
              .required("Wajib diisi")
              .positive("Masukkan angka yang valid"),
            subtotal: yup
              .number()
              .typeError("Wajib diisi")
              .required("Wajib diisi")
              .positive("Masukkan angka yang valid"),
            taxes: yup.array().of(
              yup.object({
                billing_code: yup.string().typeError("Wajib diisi"),
                value: yup
                  .number()
                  .typeError("Wajib diisi")
                  .required("Wajib diisi")
                  .positive("Masukkan angka yang valid"),
              })
            ),
          })
        ),
        activity_date: yup.date().typeError("Wajib diisi").required(),
        account: yup
          .object({
            label: yup.string().required("Wajib diisi"),
            value: yup.string().required("Wajib diisi"),
          })
          .typeError("Wajib diisi"),
        component: yup
          .object({
            label: yup.string().required("Wajib diisi"),
            value: yup.string().required("Wajib diisi"),
          })
          .typeError("Wajib diisi"),
      })
    ),
    description: yup.string().optional(),
    total_value: yup
      .number()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .positive("Masukkan angka yang valid"),
    documents: yup.array().typeError("Wajib diisi").min(1, "Wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      accounts: [{ activities: [{}] }],
    },
  });

  const setDefaultValues = useCallback(async () => {
    const documentsFile = await getFileFromUrl(
      detail?.documents?.[0]?.url,
      detail?.documents?.[0]?.file_name
    );

    methods.reset({
      accounts: realization?.accounts?.map((account) => ({
        id: account?.id,
        account: {
          label: `${account?.account_code} - ${account?.account_name}`,
          name: account?.account_name,
          value: account?.account_code,
          id: account?.account_id,
        },
        component: {
          label: `${account?.component_code} - ${account?.component_name}`,
          name: account?.component_name,
          value: account?.component_code,
        },
        activity_date: account?.activity_date
          ? moment(account?.activity_date).toDate()
          : "",
        activities: account?.details?.map((accountDetail) => ({
          activity_detail: {
            id: accountDetail?.id,
            label: `${accountDetail?.detail_code} - ${accountDetail?.detail_name}`,
            name: accountDetail?.detail_name,
            value: accountDetail?.detail_code,
          },
          subtotal: accountDetail?.subtotal,
          value: accountDetail?.value,
          notes: accountDetail?.notes,
          tax_total: accountDetail?.value - accountDetail?.subtotal,
          taxes: accountDetail?.taxes?.map((tax) => ({
            tax: {
              label: tax?.tax_name,
              value: tax?.tax_id,
              amount: (tax?.value / accountDetail?.subtotal) * 100,
            },
            billing_code: tax?.billing_code,
            value: tax?.value,
          })),
        })),
      })),
      description: realization?.description,
      difference_value: realization?.difference || 0,
      total_value: realization?.total_value,
      submission_value: realization?.submission_value || detail?.total_value,
    });
  }, [detail]);

  useEffect(() => {
    if (detail) {
      setDefaultValues();
    }
  }, [detail]);

  const onCancel = () => {
    navigate(-1);
  };

  const calculateTotal = () => {
    const accounts = methods.watch("accounts");

    const total = accounts?.reduce(
      (acc, account) =>
        acc +
        account.activities?.reduce(
          (accActivities, activity) => accActivities + activity?.value,
          0
        ),
      0
    );

    return total;
  };

  const onSubmit = async (payload) => {
    try {
      const formDataDocument = new FormData();
      formDataDocument.append("file", payload?.documents[0]);

      const uploadData = await uploadFile.mutateAsync(formDataDocument);

      const params = {
        ...payload,
        documents: [
          {
            file_id: uploadData?.data?.id,
            file_name: uploadData?.data?.name,
            file_size: uploadData?.data?.size,
            mime_type: uploadData?.data?.mimeType,
            path: uploadData?.data?.key,
          },
        ],
        accounts: payload?.accounts?.map((account) => ({
          activity_date: moment(account?.activity_date).format("YYYY-MM-DD"),
          component_code: account?.component?.value,
          component_name: account?.component?.name,
          account_code: account?.account?.value,
          account_name: account?.account?.name,
          details: account?.activities?.map((activity) => ({
            detail_code: activity?.activity_detail?.value,
            detail_name: activity?.activity_detail?.name,
            currency: "IDR",
            value: activity?.value,
            subtotal: activity?.subtotal,
            taxes: activity?.taxes?.map((tax) => ({
              tax_id: tax?.tax?.value,
              tax_name: tax?.tax?.label,
              billing_code: tax?.billing_code,
              value: tax?.value,
            })),
            tax_total: activity?.value - activity?.subtotal,
            notes: activity?.notes,
          })),
          total_value: account.activities?.reduce(
            (acc, curr) => curr?.value + acc,
            0
          ),
        })),
      };

      submitRealization.mutate(
        { id: detail?.id, payload: params },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const handleApproval = (payload) => {
    approval.mutate(
      {
        id: detail?.id,
        payload: {
          approved: !payload?.reject_reason,
          reject_reason: payload?.reject_reason ?? "",
        },
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const renderModal = () => (
    <>
      <RejectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={handleApproval}
      />
      <ConfirmationModal
        open={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        onSubmit={handleApproval}
        title="Setujui Pengajuan?"
        message="Anda yakin akan menyetujui pengajuan ini?"
      />
    </>
  );

  return (
    <HelperContext.Provider
      value={{ isRealizationCreated: isRealizationCreated }}
    >
      {renderModal()}
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                Pertanggung Jawaban
              </div>
              <InputForm
                name={`description`}
                type="textarea"
                className={`border w-full rounded-md`}
                placeholder="Deskripsi"
                label={"Deskripsi"}
                required={true}
                disabled={isRealizationCreated}
              />
              <NumberInputForm
                name="submission_value"
                className="w-full"
                label="Nilai Pengajuan"
                placeholder="0"
                disabled
              />
              <NumberInputForm
                name="total_value"
                className="w-full"
                label="Nilai Realisasi"
                placeholder="0"
                disabled
                value={calculateTotal()}
              />
              <NumberInputForm
                name="difference_value"
                className="w-full"
                label="Selisih Lebih/Kurang"
                placeholder="0"
                disabled
                value={
                  methods.watch("total_value") -
                  methods.watch("submission_value")
                }
              />
              <Account />
            </div>
          </div>

          <Card>
            <CardHeader>Dokumen Bukti Pendukung</CardHeader>
            <CardBody>
              <FilePickerForm
                name="documents"
                label="Dokumen Bukti Pendukung"
                required
                readOnly={isRealizationCreated}
                file={isRealizationCreated ? detail?.documents[0] : undefined}
              />
            </CardBody>
          </Card>
          <div className="h-20" />
        </div>
        {isRealizationCreated ? (
          <BottomFormAction
            onCancel={onCancel}
            onSubmit={handleApproval}
            _submitButton={{
              label: "Selanjutnya",
            }}
            _cancelButton={{
              label: "Sebelumnya",
            }}
            renderAction={() => (
              <div className="grid grid-cols-2 gap-2">
                <Button
                  className="bg-error-600 border-error-600 gap-2"
                  startIcon={<RiCloseCircleLine size={18} />}
                  onClick={() => setIsOpen(true)}
                >
                  Tolak
                </Button>
                <Button
                  className="bg-success-500 border-success-500 gap-2"
                  startIcon={<RiCheckboxCircleLine size={18} />}
                  onClick={() => setIsOpenConfirmation(true)}
                >
                  Setujui
                </Button>
              </div>
            )}
          />
        ) : (
          <BottomFormAction
            onCancel={onCancel}
            onSubmit={methods.handleSubmit(onSubmit)}
          />
        )}
      </FormProvider>
    </HelperContext.Provider>
  );
};

export default OperationalAdvanceRealization;
