import { useMutation, useQuery } from "react-query";
import { RPP } from "services";

export const useRequestProjectPaymentsQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RPP.getRequestProjectPayments({ ...params }, signal);
    },
    ...options,
  });
};

export const useRequestProjectPaymentDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return RPP.getRequestProjectPaymentDetail(params, signal);
    },
    ...options,
  });
};

export const useRequestProjectPaymentPost = () => {
  return useMutation((payload) => RPP.postRequestProjectPayment(payload))
}

export const useRequestProjectPaymentPut = () => {
  return useMutation((payload) => RPP.putRequestProjectPayment(payload))
}
