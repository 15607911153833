import classNames from "classnames";
import {
  BottomFormAction,
  Card,
  CardBody,
  CardHeader,
  Input,
  Table,
} from "components";
import { Tab, Tabs } from "components/Tabs";
import {
  useRealizationDifference,
  useRealizationRecap,
} from "hooks/downPayment";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { convertToIDR } from "../util";

export const GeneralRecapTab = ({ detail, activityType, showMembers }) => {
  const { data } = useRealizationRecap([
    "dp-realization-recap",
    {
      id: detail?.id,
      activityTypeId: activityType?.id,
    },
  ]);

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "activity_detail",
      title: "Detail Kegiatan",
      dataIndex: "detail_code",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
            <span className="font-medium">{data?.name}</span>
            <span>{value}</span>
          </div>
        );
      },
    },
    {
      id: "quantity",
      title: "Qty",
      dataIndex: "realization_qty",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "0"}
          </div>
        );
      },
    },
    {
      id: "volume",
      title: "Volume",
      dataIndex: "realization_volume",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "0"}
          </div>
        );
      },
    },
    {
      id: "price",
      title: "Satuan Biaya",
      dataIndex: "realization_subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(
              data?.realization_subtotal /
                (data?.realization_volume ?? data?.qty)
            )}
          </div>
        );
      },
    },
    {
      id: "tax",
      title: "Pajak",
      dataIndex: "tax_subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(value)}
          </div>
        );
      },
    },
    {
      id: "subtotal",
      title: "Sub Total",
      dataIndex: "realization_subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(value + data?.tax_subtotal)}
          </div>
        );
      },
    },
  ];

  return (
    <div className="space-y-3">
      {showMembers && (
        <Card>
          <CardHeader>Peserta yang Diundang</CardHeader>
          <CardBody>
            <div className="grid grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Narasumber</span>
                </label>
                <Input value={data?.expert_total} className="w-full" disabled />
              </div>
              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Peserta Pegawai</span>
                </label>
                <Input value={data?.staff_total} className="w-full" disabled />
              </div>

              <div className="w-full space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Jumlah Undangan</span>
                </label>
                <Input value={data?.invite_total} className="w-full" disabled />
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardHeader>
          {" "}
          <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
            Total Nilai:{" "}
            <span className="font-bold">{convertToIDR(data?.total)}</span>
          </span>
        </CardHeader>
        <CardBody className="space-y-3">
          {data?.accounts?.map((account) => (
            <div
              className="rounded-lg border-[1px] border-gray-100"
              key={account?.id}
            >
              <div className="flex flex-row gap-4 items-center bg-[#E1F1D6] px-4 py-2 rounded-t-lg">
                <span className="text-primary-600 font-bold">
                  Akun Belanja:{" "}
                  <span className="font-medium">{account?.account_code}</span>
                </span>
                <span className="text-sm inline-block font-semibold bg-warning-50 text-warning-700 rounded-xl px-3 py-1 mix-blend-multiply">
                  Total Nilai Realisasi:{" "}
                  <span className="font-bold">
                    {convertToIDR(account?.realization_total)}
                  </span>
                </span>
              </div>
              <div className="p-4">
                <Table
                  bordered
                  stripped
                  layout="fixed"
                  className="mb-4 rounded-xl"
                  columns={tableColumns}
                  dataSource={account?.realizations}
                  pagination={{
                    page: 1,
                    limit: 20,
                    total: account?.realizations?.length,
                    previous_pages: false,
                    next_pages: false,
                  }}
                />
              </div>
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const DetailedRecapTab = ({ detail, activityType, recapType }) => {
  const { data } = useRealizationRecap([
    "dp-realization-recap-detail",
    {
      id: detail?.id,
      activityTypeId: activityType?.id,
      type: recapType,
    },
  ]);

  const activityAccounts = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => (
        <div className=" overflow-hidden text-ellipsis w-full">{index + 1}</div>
      ),
    },
    {
      id: "code",
      title: "Kode",
      dataIndex: "account_code",
      render: (value) => (
        <div className="w-full max-w-[350px] break-all whitespace-pre-wrap">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "name",
      title: "Uraian",
      dataIndex: "name",
      className: "overflow-hidden text-ellipsis",
      render: (value) => (
        <div className="w-full max-w-[350px] break-all whitespace-pre-wrap">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "pagu",
      title: "Pagu",
      dataIndex: "pagu",
      width: 100,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis">
          {convertToIDR(value)}
        </div>
      ),
    },
    {
      id: "realization",
      title: detail?.status?.step === 9 ? "RL Saat Ini" : "Realisasi",
      dataIndex: "current",
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis">
          {convertToIDR(
            detail?.status?.step === 9 ? value : value || 0 + data?.after || 0
          )}
        </div>
      ),
    },
    {
      ...(detail?.status?.step === 9 && {
        id: "rl-after",
        title: "RL Sesudah = 2 + SPP",
        dataIndex: "after",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis">
            {convertToIDR(value)}
          </div>
        ),
      }),
    },
    {
      id: "diff",
      title: detail?.status?.step === 9 ? "Sisa = 1 - 3" : "Sisa",
      dataIndex: "remaining",
      render: (value) => (
        <div className="overflow-hidden text-ellipsis">
          {convertToIDR(value)}
        </div>
      ),
    },
  ];

  const purchasingAccounts = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => (
        <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
          {index + 1}
        </div>
      ),
    },
    {
      id: "code",
      title: "Kode",
      dataIndex: "account_code",
      className: "overflow-hidden",
      render: (value) => (
        <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "name",
      title: "Akun Belanja",
      dataIndex: "name",
      className: "overflow-hidden",
      render: (value) => (
        <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "diff",
      title: "Nilai",
      dataIndex: "realization_total",
      className: "overflow-hidden",
      render: (value) => (
        <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
  ];

  const detailAccounts = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => (
        <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
          {index + 1}
        </div>
      ),
    },
    {
      id: "code",
      title: "Akun Belanja",
      dataIndex: "name",
      className: "overflow-hidden",
      render: (value) => (
        <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "name",
      title: "Detail",
      dataIndex: "detail_code",
      className: "overflow-hidden",
      width: 200,
      render: (value) => (
        <div className="w-full max-w-[300px] break-all whitespace-pre-wrap">
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: "notes",
      title: "Catatan",
      dataIndex: "notes",
      className: "overflow-hidden",
      width: 200,
      render: (value) => (
        <div className="w-full max-w-[300px] break-all whitespace-pre-wrap">
          {value}
        </div>
      ),
    },
    {
      id: "date",
      title: "Tanggal SPJ",
      dataIndex: "ddate",
      className: "overflow-hidden",
      render: (value) => (
        <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
          {moment(value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      id: "number",
      title: "No Kwitansi",
      dataIndex: "number",
      className: "overflow-hidden",
      render: () => {
        return (
          <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
            <span>K.8/3-II/2024</span>
          </div>
        );
      },
    },
    {
      id: "diff",
      title: "Nilai",
      dataIndex: "total",
      className: "overflow-hidden",
      render: (value) => (
        <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-3 w-[83vw]">
      <Card>
        <CardHeader>Daftar Akun Kegiatan</CardHeader>
        <CardBody>
          <Table
            bordered
            stripped
            layout="auto"
            className="mb-4 rounded-xl"
            columns={activityAccounts}
            dataSource={data?.pagu}
            pagination={{
              page: 1,
              limit: 20,
              total: data?.pagu?.length,
              previous_pages: false,
              next_pages: false,
            }}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Total Akun Belanja SPJ</CardHeader>
        <CardBody>
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4 rounded-xl"
            columns={purchasingAccounts}
            dataSource={data?.accounts}
            pagination={{
              page: 1,
              limit: 20,
              total: data?.accounts?.length,
              previous_pages: false,
              next_pages: false,
            }}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Detail Akun Belanja SPJ</CardHeader>
        <CardBody>
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4 rounded-xl w-full"
            columns={detailAccounts}
            dataSource={data?.details}
            pagination={{
              page: 1,
              limit: 20,
              total: data?.details?.length,
              previous_pages: false,
              next_pages: false,
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export const Recap = ({
  detail,
  handleNext,
  handlePrevious,
  hideBottomForm = false,
  hideDifference = false,
}) => {
  const { data: difference } = useRealizationDifference(
    [
      "dp-realization-difference",
      {
        id: detail?.id,
      },
    ],
    {
      enabled: !!detail?.id,
    }
  );

  return (
    <>
      <Tabs>
        {detail?.activity_types?.map((activityType) => (
          <Tab label={`Realisasi ${activityType?.name}`} key={activityType?.id}>
            {![9, 10].includes(detail?.status?.step) ? (
              <GeneralRecapTab activityType={activityType} detail={detail} />
            ) : (
              <DetailedRecapTab
                activityType={activityType}
                detail={detail}
                recapType={detail?.status?.step === 9 ? "ppk" : "ppspm"}
              />
            )}
          </Tab>
        ))}
        {!hideDifference && (
          <Tab label="Informasi Selisih">
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <Card>
                <CardHeader>Potensi Selisih</CardHeader>
                <CardBody className="space-y-3">
                  <div
                    className={classNames(
                      "rounded-xl text-xs px-2 flex flex-row items-center gap-1 font-semibold w-28",
                      difference?.budget > difference?.realization
                        ? "bg-bluelight-50 text-bluelight-700 border-bluelight-50"
                        : "bg-[#FEF3F2] text-error-700 border-[#FEF3F2]"
                    )}
                  >
                    <h1
                      className={classNames(
                        "text-2xl -mt-1",
                        difference?.budget > difference?.realization
                          ? " text-bluelight-700 "
                          : " text-error-700 "
                      )}
                    >
                      &#8226;
                    </h1>
                    {difference?.budget > difference?.realization
                      ? "Selisih Lebih"
                      : "Selisih Kurang"}
                  </div>
                  <div className="grid grid-cols-3 gap-2">
                    <div className="space-y-2">
                      <label className="flex gap-1 font-semibold text-[14px]">
                        <span className={`label-text`}>Uang Muka (Rp)</span>
                      </label>
                      <NumericFormat
                        value={difference?.budget}
                        placeholder="0"
                        className="w-full"
                        customInput={Input}
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        disabled
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="flex gap-1 font-semibold text-[14px]">
                        <span className={`label-text`}>Realisasi (Rp)</span>
                      </label>
                      <NumericFormat
                        value={difference?.realization}
                        placeholder="0"
                        className="w-full"
                        customInput={Input}
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        disabled
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="flex gap-1 font-semibold text-[14px]">
                        <span className={`label-text`}>Selisih (Rp)</span>
                      </label>
                      <NumericFormat
                        value={Math.abs(difference?.difference)}
                        placeholder="0"
                        className={classNames(
                          "w-full",
                          difference?.budget > difference?.realization
                            ? "!bg-blue-50"
                            : "!bg-[#FEF3F2]"
                        )}
                        customInput={Input}
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        disabled
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Tab>
        )}
      </Tabs>
      {!hideBottomForm && (
        <BottomFormAction
          onSubmit={handleNext}
          onCancel={handlePrevious}
          _submitButton={{
            label: "Selanjutnya",
          }}
          _cancelButton={{
            label: "Sebelumnya",
          }}
        />
      )}
    </>
  );
};
