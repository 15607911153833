import config from "app/config";
import axiosInstance from "app/interceptors";

export const getBanks = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/procurement/v1/common/banks`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const getBankAccounts = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/bank-account`, {
    params,
    signal,
  });
  return data;
};

export const createBankAccount = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/bank-account`,
    params
  );
  return data;
};

export const updateBankAccount = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/bank-account/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteBankAccount = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.BASE_URL}/bank-account/${params?.id}`
  );
  return data;
};

export const getDeposits = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/deposit`, {
    params,
    signal,
  });
  return data;
};

export const createDeposit = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/deposit`,
    params
  );
  return data;
};

export const updateDeposit = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/deposit/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteDeposit = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.BASE_URL}/deposit/${params?.id}`
  );
  return data;
};
