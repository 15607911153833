import React from "react";
import { InputForm, SelectBudgetListForm } from "components/HookForm";

const Form = () => {
  return (
    <div className="flex flex-col gap-4">
      <InputForm
        name={`transaction_type_name`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Jenis Transaksi"}
        placeholder={"Jenis Transaksi"}
      />
      <SelectBudgetListForm
        name={`debits_account_name`}
        label="Akun Debet"
      />
      <SelectBudgetListForm
        name={`credits_account_name`}
        label="Akun Kredit"
      />
    </div>
  );
};

export default Form;
