import React, { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { FiCheckCircle } from "react-icons/fi";
import { debounce } from "lodash";

import { useAccountGroup } from "hooks/accountGroup";

import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useLocation, useNavigate, useOutletContext } from "react-router";

const AccountGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);

  const links = [
    {
      label: "Daftar Kewenangan Akun",
    },
  ];

  const { data: list, isLoading } = useAccountGroup([
    "ag",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "directorate_name",
      title: "Direktorat",
      dataIndex: "directorate_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_accounts",
      title: "Jumlah Akun",
      dataIndex: "total_accounts",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value
            ? value
            : data?.accounts_account
            ? data?.accounts_account
            : "0"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis w-48",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-4 flex items-center w-full">
          {roleAccess?.canView && (
            <HiOutlineEye
              className="text-gray-600 cursor-pointer"
              size={20}
              onClick={() => navigate("/detail-authority", { state: data })}
            />
          )}
          {roleAccess?.canUpdate && (
            <Button
              onClick={() => navigate("/edit-authority", { state: data })}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-32 btn-sm"
            >
              Ubah
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={async () => {
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        }}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={() => setOpenPopupSuccessDelete(false)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Mapping Kewenangan Akun"}</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div> */}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountGroup;
