import { Breadcrumbs, Card, CardBody, CardHeader, Table } from "components";
import moment from "moment";
import React, { useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import RevisionHistory from "./revision-history";
import Detail from "./detail";
import { InformationModal } from "components/Modal";

const BudgetDetail = () => {
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const links = [
    {
      label: "Daftar Anggaran",
      path: "/budget",
    },
    {
      label: "Anggaran",
    },
  ];

  const onDetail = (data) => {
    setOpenDetail(true);
    setSelectedData(data);
  };

  const renderAllModal = () => {
    return (
      <>
        <InformationModal
          title="Informasi Detail Kegiatan"
          open={openDetail}
          onClose={() => setOpenDetail(false)}
        >
          <div className="space-y-4">
            <div className="text-sm">
              <label className="font-medium">Full Code</label>
              <p>{selectedData?.full_code ? selectedData?.full_code : "-"}</p>
            </div>
            <div className="text-sm">
              <label className="font-medium">Uraian</label>
              <p>
                {selectedData?.budget_name ? selectedData?.budget_name : "-"}
              </p>
            </div>
            <div className="text-sm">
              <label className="font-medium">Volum Kegiatan</label>
              <p>
                {selectedData?.activity_volume
                  ? selectedData?.activity_volume
                  : "-"}
              </p>
            </div>
            <div className="text-sm">
              <label className="font-medium">Harga Satuan</label>
              <p>{selectedData?.unit_price ? selectedData?.unit_price : "-"}</p>
            </div>
            <div className="text-sm">
              <label className="font-medium">Nilai Pagu</label>
              <p>
                {selectedData?.total_max_cost
                  ? selectedData?.total_max_cost
                  : "-"}
              </p>
            </div>
          </div>
        </InformationModal>
      </>
    );
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`w-full my-6 space-y-8`}>
        <Card>
          <CardHeader>
            <div>Detail</div>
          </CardHeader>
          <CardBody>
            <Detail onDetail={onDetail} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <div>Riwayat Revisi</div>
          </CardHeader>
          <CardBody>
            <RevisionHistory onDetail={onDetail} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default BudgetDetail;
