import config from 'app/config';
import axios from 'axios';

export const getFinanceReport = async (params, signal) => {
  // const { data } = await axios.get(`${config.BASE_URL}/manual-journal`, { params, signal });
  const data = new Promise((resolve) => {
    setTimeout(() => {
      if (params?.report_type === "neraca") {
        resolve({data: "neraca", message: "success"})
      } else {
        resolve({data: "laba-rugi", message: "error"})
      }
    }, 1000);
  })
  return data;
}