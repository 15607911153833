import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs, Button } from "components";
import Accordion from "components/Accordion";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectBankAccountForm,
} from "components/HookForm";
import { useCreatePayment, usePaymentDetailQuery } from "hooks/cash";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { convertToIDR } from "pages/DownPayment/util";
import { useCallback, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FiPlus, FiTrash } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
import * as yup from "yup";

const schema = yup.object().shape({
  paid_total: yup
    .number()
    .positive()
    .typeError("Wajib Diisi")
    .required("Wajib Diisi"),
  payment_date: yup.date().typeError("Wajib diisi").required("Wajib Diisi"),
  bank_account_name: yup
    .string()
    .typeError("Wajib Diisi")
    .required("Wajib Diisi"),
  bank_account_number: yup
    .string()
    .typeError("Wajib Diisi")
    .required("Wajib Diisi"),
  bank_name: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
  bank_branch_name: yup
    .string()
    .typeError("Wajib Diisi")
    .required("Wajib Diisi"),
  transactions: yup.object({
      bank_account: yup
        .object({
          label: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
          value: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
          name: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
          number: yup.string().typeError("Wajib Diisi").required("Wajib Diisi"),
        })
        .typeError("Wajib Diisi"),
      value: yup
        .string()
        .required("Wajib Diisi"),
    }),
  type: yup.string().typeError("Wajib Diisi").required(),
});

const PaymentRequestForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDetail = location.pathname.includes("detail");
  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Permintaan Pembayaran",
      path: "/payment-request",
    },
    {
      label: "Detail Pembayaran",
    },
  ];
  const createPayment = useCreatePayment();

  const { data: detail } = usePaymentDetailQuery([
    "direct-cash-transfer-detail",
    { id },
  ]);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });


  const setDefaultValues = useCallback(async () => {
    methods.reset({
      request_date: moment(detail?.created_at).toDate(),
      payment_date: detail?.payment_date
        ? moment(detail?.payment_date).toDate()
        : "",
      type: detail?.type,
      paid_total: detail?.value,
      bank_account_name: detail?.bank_account_name,
      bank_account_number: detail?.bank_account_number,
      bank_name: detail?.bank_name,
      bank_branch_name: detail?.bank_branch_name,
      notes: detail?.notes,
      transactions: {
        bank_account: {
          label: `${detail?.transactions?.[0]?.bank_account?.number} - ${detail?.transactions[0]?.bank_account?.bank_name} - ${detail?.transactions[0]?.bank_account?.name}` ,
          value: detail?.transactions?.[0]?.bank_account?.id,
          name: detail?.transactions?.[0]?.bank_account?.name 
        },
        value: detail?.transactions?.value
      }
    });
  }, [detail]);

  useEffect(() => {
    setDefaultValues();
  }, [detail]);

  useEffect(() => {
    if(methods.watch("paid_total")) methods.setValue(`transactions.value`, methods.watch("paid_total"))

  }, [methods.watch("paid_total")])

  const onSubmit = async (payload) => {
    // temp remove validation
    // if (
    //   payload?.paid_total !==
    //   payload?.transactions?.value
    // ) {
    //   return enqueueSnackbar({
    //     message:
    //       "Pembayaran tidak boleh lebih/kurang jumlah yang harus dibayar",
    //     variant: "error",
    //   });
    // }
    const params = {
      ...payload,
      request_date: undefined,
      payment_date: moment(payload?.payment_date).format("YYYY-MM-DD"),
      transactions: [{
        bank_account_id: payload?.transactions?.bank_account?.id,
        value: payload?.transactions?.value,
      }],
    };

    createPayment.mutate(
      { payload: params, id },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">Pembayaran</div>
              <CustomDatepickerForm
                name="request_date"
                label="Tanggal Permintaan"
                placeholder={"dd/mm/yyyy"}
                disabled
              />
              <InputForm
                label="Jenis Transaksi"
                name="type"
                className="w-full"
                disabled
              />
              <Accordion label="Rincian Pembayaran">
                <div className="space-y-4 w-full">
                  <NumberInputForm
                    name="paid_total"
                    label="Bayarlah Sejumlah"
                    className="w-full"
                    disabled
                  />
                  <p className="text-lg font-bold mt-4">Kepada</p>
                  <div className="grid grid-cols-2 gap-4">
                    <InputForm
                      name="bank_account_number"
                      label="Nomor Rekening"
                      className="w-full"
                      required
                      disabled={isDetail}
                    />
                    <InputForm
                      name="bank_account_name"
                      label="Nama Pemilik Rekening"
                      className="w-full"
                      required
                      disabled={isDetail}
                    />
                    <InputForm
                      name="bank_name"
                      label="Bank"
                      className="w-full"
                      required
                      disabled={isDetail}
                    />
                    <InputForm
                      name="bank_branch_name"
                      label="Cabang"
                      className="w-full"
                      required
                      disabled={isDetail}
                    />
                  </div>
                  <InputForm
                    name="notes"
                    label="Keterangan"
                    className="w-full"
                    type="textarea"
                    disabled={isDetail}
                  />
                </div>
              </Accordion>
              <Accordion
                label="Pembayaran"
                renderLeft={() => (
                  <div className="bg-warning-50 text-warning-700 rounded-3xl text-xs py-2 px-4 font-bold ml-4">
                    Nilai:{" "}
                    {convertToIDR(
                      methods.watch("transactions")?.value ?? 0
                    )}
                  </div>
                )}
              >
                  <div
                    className="border-b-[1px] pb-6 mb-6 space-y-4"
                  >
                    <CustomDatepickerForm
                      name="payment_date"
                      label="Tanggal Pembayaran"
                      placeholder={"dd/mm/yyyy"}
                      required
                      disabled={isDetail}
                    />
                    <div className="flex flex-row items-end justify-end gap-4">
                      <div className="grid grid-cols-3 gap-4 w-full">
                        <SelectBankAccountForm
                          label="Nomor Rekening"
                          name={`transactions.bank_account`}
                          placeholder="Nomor Rekening"
                          type="Pengeluaran"
                          required
                          isDisabled={isDetail}
                        />
                        <InputForm
                          name={`transactions.bank_account_name`}
                          label="Nama Pemilik Rekening"
                          className="w-full"
                          disabled
                          value={
                            methods.watch(`transactions.bank_account`)
                              ?.name
                          }
                          placeholder="Pilih Nomor Rekening"
                          required
                        />
                        <NumberInputForm
                          name={`transactions.value`}
                          label="Nilai Yang Dibayar"
                          className="w-full"
                          required
                          disabled
                        />
                      </div>
                    </div>
                  </div>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="h-32"></div>
        <BottomFormAction
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={() => navigate(-1)}
        />
      </FormProvider>
    </>
  );
};

export default PaymentRequestForm;
