import config from "app/config";
import axiosInstance from "app/interceptors";

const getMappedStatus = (status, role) => {
  let description, action;

  switch (role) {
    case "pjk":
      switch (status?.step) {
        case 5:
          description = "Perlu Pertanggung Jawaban";
          action = "realization";
          break;
        case 99:
          description = "Perlu Revisi";
          action = "revision";
          break;
        default:
          description = status?.description;
          action = status?.action;
      }
      break;
    case "staff ppk":
      switch (status?.step) {
        case 1:
          description = "Perlu Direview";
          action = "review";
          break;
        case 5:
          description = "Menunggu Pertanggungjawaban PJK";
          action = "view";
          break;
        case 6:
          description = "Perlu Pertanggungjawaban";
          action = "realization";
          break;
        default:
          description = status?.description;
          action = "view";
      }
      break;
    case "ppk":
      switch (status?.step) {
        case 2:
          description = "Perlu Direview";
          action = "review";
          break;
        case 5:
          description = "Perlu Pertanggung Jawaban PJK";
          action = "view";
          break;
        case 7:
          description = "Perlu Pertanggung Jawaban";
          action = "realization";
          break;
        default:
          description = status?.description;
          action = "view";
      }
      break;
    case "ppspm":
      switch (status?.step) {
        case 3:
          description = "Perlu Direview";
          action = "review";
          break;
        case 5:
          description = "Perlu Pertanggung Jawaban PJK";
          action = "view";
          break;
        case 8:
          description = "Perlu Pertanggung Jawaban";
          action = "realization";
          break;
        default:
          description = status?.description;
          action = "view";
      }
      break;
    default:
      description = status?.description;
      action = status?.action;
      break;
  }

  return {
    ...status,
    description,
    action,
  };
};

export const getOperational = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/umoactivity`, {
    params,
    signal,
  });

  let res = data;

  const result = res?.result?.map((op) => {
    return {
      ...op,
      status: getMappedStatus(op?.status, params?.role),
    };
  });

  res = {
    ...res,
    result,
  };

  return res;
};

export const getOperationalDetail = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/umoactivity/${id}`
  );
  return data?.result;
};

export const createUMO = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/umoactivity`,
    params
  );
  return data;
};

export const updateUMO = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/umoactivity/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteUMO = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.BASE_URL}/umoactivity/${params?.id}`
  );
  return data;
};

export const getActivityStatus = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity-status`,
    {
      signal,
    }
  );
  return data;
};

export const approveUMO = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/umoactivity/approval/${params?.id}`,
    params?.payload
  );
  return data;
};

export const submitRealizationUMO = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/umoactivity-realization/${params?.id}`,
    params?.payload
  );
  return data;
};

export const getRealizationUMO = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/umoactivity-realization/${id}`
  );
  return data?.result;
};

export const approveRealizationUMO = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/umoactivity-realization/approval/${params?.id}`,
    params?.payload
  );
  return data;
};
