import config from "app/config";
import axiosInstance from "app/interceptors";

const getMappedStatus = (activity, role) => {
  let description, action;

  switch (role) {
    case "pjk":
      switch (activity?.status?.step) {
        case 1:
          description = "Baru";
          action = "crud";
          break;
        case 2:
          description = "Perlu Approval";
          action = "verification";
          break;
        case 3:
          description = "Perlu Verifikasi Staf PPK";
          action = "view";
          break;
        case 6:
          if (activity?.realization_status === "draft_completed") {
            description = "Menunggu Approval Pertanggung Jawaban PUM";
            action = "view";
          } else {
            description = activity?.status?.description;
            action = activity?.status?.action;
          }
          break;
        case 7:
          description = "Perlu Approval Pertanggung Jawaban";
          action = "accountability_approval";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
      }
      break;
    case "pum":
      switch (activity?.status?.step) {
        case 1:
          description = "Perlu Rincian Kegiatan";
          action = "activity_detail";
          break;
        case 2:
          description = "Menunggu Approval PJK";
          action = "view";
          break;
        case 3:
          description = "Menunggu Approval Staff PPK";
          action = "view";
          break;
        case 6:
          if (activity?.realization_status === "draft_completed") {
            description = "Draft Pertanggung Jawaban Selesai";
            action = "submit_accountability";
          } else if (activity?.realization_status === "not_created") {
            description = "Pertanggung Jawaban";
            action = "accountability";
          }
          break;
        case 7:
          description = "Menunggu Approval Pertanggung Jawaban PJK";
          action = "view";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
      }
      break;
    case "staff ppk":
      switch (activity?.status?.step) {
        case 1:
          description = "Progress PUM";
          action = "view";
          break;
        case 2:
        case 3:
          description = "Perlu Approval";
          action = "verification";
          break;
        case 7:
          description = "Menunggu Approval Pertanggung Jawaban PJK";
          action = "view";
          break;
        case 8:
          description = "Perlu Approval Pertanggung Jawaban";
          action = "accountability_approval";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
      }
      break;
    case "ppk":
      switch (activity?.status?.step) {
        case 1:
          description = "Progress PUM";
          action = "view";
          break;
        case 2:
        case 3:
          description = "Menunggu Approval Staff PPK";
          action = "view";
          break;
        case 4:
          description = "Perlu Approval";
          action = "verification";
          break;
        case 7:
          description = "Menunggu Approval Pertanggung Jawaban PJK";
          action = "view";
          break;
        case 9:
          description = "Perlu Approval Pertanggung Jawaban";
          action = "accountability_approval";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
      }
      break;
    case "ppspm":
      switch (activity?.status?.step) {
        case 1:
          description = "Progress PUM";
          action = "view";
          break;
        case 2:
        case 3:
          description = "Menunggu Approval Staff PPK";
          action = "view";
          break;
        case 4:
          description = "Perlu Approval";
          action = "verification";
          break;
        case 7:
          description = "Menunggu Approval Pertanggung Jawaban PJK";
          action = "view";
          break;
        case 10:
          description = "Perlu Approval Pertanggung Jawaban";
          action = "accountability_approval";
          break;
        default:
          description = activity?.status?.description;
          action = activity?.status?.action;
      }
      break;
    default:
      description = "Baru";
      action = "crud";
      break;
  }

  return {
    status: activity?.status,
    description,
    action,
  };
};

export const getActivities = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/activity`, {
    params,
    signal,
  });

  let res = data;

  const result = res?.result?.map((activity) => {
    return {
      ...activity,
      status: getMappedStatus(activity, params?.role),
    };
  });

  res = {
    ...res,
    result,
  };

  return res;
};

export const getActivityStatus = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity-status`,
    {
      signal,
    }
  );
  return data;
};

export const getGeneralSettings = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/general-setting`,
    {
      params,
      signal,
    }
  );
  return data;
};

export const getActivityTypes = async (signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/activity-type`, {
    signal,
  });

  return data?.result;
};

export const getSbm = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/sbm`, {
    params,
    signal,
  });

  return data?.result;
};

export const createDownPayment = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/activity`,
    params
  );
  return data;
};

export const updateDownPayment = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}`,
    params?.payload
  );
  return data;
};

export const deleteActivity = async (params) => {
  const { data } = await axiosInstance.delete(
    `${config.BASE_URL}/activity/${params?.id}`
  );
  return data;
};

export const getActivityDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/activity/${id}`);
  return data?.result;
};

export const updateActivityStep1 = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/step1`,
    params?.payload
  );
  return data;
};

export const submitActivityDetail = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/submit`,
    params?.payload
  );
  return data;
};

export const approveActivityDetail = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/approval`,
    params?.payload
  );
  return data;
};

export const updateActivityType = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/detail/${params?.activityTypeId}`,
    params?.payload
  );
  return data;
};

export const getActivityTypeDetail = async ({ id, activityTypeId }) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity/${id}/detail/${activityTypeId}`
  );
  return data?.result;
};

export const getActivityRecap = async ({ id, activityTypeId }) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity/${id}/recap/${activityTypeId}`
  );
  return data?.result;
};

export const updateRealization = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/realization/${params?.activityTypeId}`,
    params?.payload
  );
  return data;
};

export const getRealizationDetail = async ({ id, activityTypeId }) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity/${id}/realization/${activityTypeId}`
  );
  return data?.result;
};

export const getEmployees = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.ROOT_BASE_URL}/user-management/v1/Employee/list`,
    {
      params,
      signal,
    }
  );

  const res = data?.data?.map((d) => ({
    ...d,
    agency: "PT Fiktif Makmur Sejahtera",
    bank_account_name: "Jane Doe",
    bank_account_number: "112233445566",
    bank_name: "Mandiri",
    class: "1",
    created_at: "2024-02-08T19:38:13.709Z",
    gender: "Wanita",
    nip: "0987654321",
    npwp: "9876543210",
    phone_number: "081234567890",
    position: "Komisaris",
    type: "invite",
    updated_at: "2024-02-08T19:38:13.709Z",
  }));

  return res;
};

export const updateParticipant = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/participant/${params?.activityTypeId}/add`,
    params?.payload
  );
  return data;
};

export const getRealizationRecap = async ({ id, activityTypeId, type }) => {
  const getTypeEndpoint = () => {
    switch (type) {
      case "ppk":
      case "ppspm":
        return "recap-ppspm";
      default:
        return "recap";
    }
  };

  const { data } = await axiosInstance.get(
    `${
      config.BASE_URL
    }/activity/${id}/realization/${activityTypeId}/${getTypeEndpoint()}`
  );
  return data?.result;
};

export const getRealizationDifference = async ({ id }) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity/${id}/realization-difference`
  );
  return data?.result;
};

export const getRealizationDocument = async ({ id }) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/activity/${id}/realization-document`
  );
  return data?.result;
};

export const uploadRealizationDocument = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/realization-document`,
    params?.payload
  );
  return data;
};

export const submitRealization = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/realization-submit`,
    params?.payload
  );
  return data;
};

export const approveRealization = async (params) => {
  const { data } = await axiosInstance.put(
    `${config.BASE_URL}/activity/${params?.id}/realization-approval`,
    params?.payload
  );
  return data;
};

export const validatePayment = async (params) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL}/activity/${params?.id}/validate-payment`,
    params
  );
  return data;
};
