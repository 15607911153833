import { Button, Input } from "components";
import Accordion from "components/Accordion";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectAccountForm,
} from "components/HookForm";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { NumericFormat } from "react-number-format";
import { getSubtotal } from "../util";

export const RABNeedsForm = ({
  fieldName,
  defaultValues,
  type,
  totalMultiplier = 1,
  volumeLabel = "Volume/Frekuensi",
}) => {
  const methods = useFormContext();
  const { fields, remove, append, replace } = useFieldArray({
    control: methods.control,
    name: fieldName,
  });

  useEffect(() => {
    if (fields?.length === 0) {
      replace(defaultValues);
    }
  }, []);

  return (
    <Accordion label="Daftar Item Kebutuhan">
      {fields.map((data, indexNeed) => {
        return (
          <div
            className="flex flex-row border-b-[1px] border-gray-200"
            key={data?.id}
          >
            <div className="space-y-3  py-4 flex-1">
              {type === "item" ? (
                <InputForm
                  name={`${fieldName}.${indexNeed}.item_name`}
                  label="Nama Barang"
                  className="w-full"
                  placeholder="Masukkan Nama Barang"
                />
              ) : (
                <CustomSelectForm
                  name={`${fieldName}.${indexNeed}.sbm`}
                  options={[]}
                  label="Item Kebutuhan"
                  isDisabled
                />
              )}
              <div className="grid grid-cols-3 gap-2">
                <NumberInputForm
                  name={`${fieldName}.${indexNeed}.volume`}
                  label={volumeLabel}
                  className="w-full"
                  placeholder="0"
                />
                <NumberInputForm
                  label={
                    type === "item" ? "Satuan Biaya (Rp)" : "Nilai SBM (Rp)"
                  }
                  name={`${fieldName}.${indexNeed}.value`}
                  disabled={type !== "item"}
                  className="w-full"
                  placeholder="0"
                />
                <div className="w-full space-y-2">
                  <label className="flex gap-1 font-semibold text-[14px]">
                    <span className={`label-text`}>Sub Total (Rp)</span>
                  </label>
                  <NumericFormat
                    value={getSubtotal(
                      methods.watch(`${fieldName}.${indexNeed}.volume`),
                      methods.watch(`${fieldName}.${indexNeed}.value`),
                      totalMultiplier
                    )}
                    placeholder="0"
                    className="w-full"
                    customInput={Input}
                    allowLeadingZeros
                    thousandSeparator="."
                    decimalSeparator=","
                    disabled
                  />
                </div>
              </div>
              <SelectAccountForm
                label="Akun Belanja"
                placeholder="Pilih Akun"
                name={`${fieldName}.${indexNeed}.account_code`}
                level="9"
              />
              <SelectAccountForm
                label="Detail Kegiatan"
                placeholder="Pilih Akun"
                name={`${fieldName}.${indexNeed}.detail_code`}
                level="11"
                parentId={
                  methods.watch(`${fieldName}.${indexNeed}.account_code`)?.id
                }
                isDisabled={
                  !methods.watch(`${fieldName}.${indexNeed}.account_code`)?.id
                }
              />
            </div>
            <Button
              startIcon={<BiTrash size={18} />}
              className="bg-error-500 border-error-500 px-2 self-end mx-4 h-12 mb-4"
              size="sm"
              onClick={() => remove(indexNeed)}
            />
          </div>
        );
      })}
      <div className="flex flex-1 mt-4 justify-end">
        <Button
          startIcon={<FiPlus />}
          className=" bg-white border-[1px] border-gray-200 rounded-md text-black hover:text-white"
          onClick={() => {
            append({
              sbm: "",
            });
          }}
        >
          Tambah Item Kebutuhan
        </Button>
      </div>
    </Accordion>
  );
};
