import React, { useCallback, useMemo, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { debounce, isString } from "lodash";

import { useBudget, useImportBudget, useUpdateStatus } from "hooks/budget";

import {
  ConfirmationModal,
  FormModal,
  InformationModal,
} from "components/Modal";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import moment from "moment";
import { HiOutlineEye } from "react-icons/hi";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import TemplateBudget from "app/budget-template.xlsx";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Budget = () => {
  const importRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const hierarchy = useMemo(() => oidc_user?.profile?.Hierarchy, [oidc_user]);

  const importBudget = useImportBudget();

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [importSuccessPopup, setImportSuccessPopup] = useState(false);
  const [importPopup, setImportPopup] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const links = [
    {
      label: "Anggaran",
    },
  ];

  const {
    data: list,
    isLoading,
    refetch,
  } = useBudget([
    "budget",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const updateStatus = useUpdateStatus();

  const methods = useForm({
    defaultValues: { file: null, status: null, reason: "" },
  });

  const onTriggerImport = () => {
    setImportPopup(true);
  };

  const onHandleImport = (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        file.type.includes("application/vnd.ms-excel")
      ) {
        methods.setValue("file", file);
      } else {
        enqueueSnackbar({
          message: "Ekstensi file tidak valid",
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Something went wrong", variant: "error" });
    }
  };

  const onImportUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data?.file);

    importBudget.mutate(formData, {
      onSuccess: (data, variables) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        setImportPopup(false);
        setTimeout(() => {
          refetch();
        }, 500);
      },
      onError: (error, variables) => {
        enqueueSnackbar({
          message:
            error?.response?.data && isString(error?.response?.data)
              ? error?.response?.data
              : "Something went wrong",
          variant: "error",
        });
      },
    });
  };

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onDetail = (data) => {
    navigate("/budget/detail", { state: data });
  };

  const onTriggerUpdateStatus = (data) => {
    methods.reset({ status: null, reason: "" });
    setSelectedData(data);
    setOpenModalStatus(true);
  };

  const onUpdateStatus = (payload) => {
    const params = {
      // status: payload?.status?.value ?? "",
      // reason: payload?.status?.value == 2 ? payload?.reason : "",
      status: parseInt(selectedData?.status) + 1,
      reason: "",
    };
    updateStatus.mutate(
      { id: selectedData.id, payload: params },
      {
        onSuccess: (data, variables) => {
          methods.reset({ status: null, reason: "" });
          setOpenModalStatus(false);
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          refetch();
        },
        onError: (data, variables) => {
          enqueueSnackbar({
            message: data?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis whitespace-normal">
            {no}
          </div>
        );
      },
    },
    {
      id: "year",
      title: "Tahun Anggaran",
      dataIndex: "year",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "start_date",
      title: "Tanggal Mulai",
      dataIndex: "start_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "end_date",
      title: "Tanggal Berakhir",
      dataIndex: "end_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "version",
      title: "Versi",
      dataIndex: "version",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_max_cost",
      title: "Jumlah Pagu",
      dataIndex: "total_max_cost",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "outstandingUM",
      title: "Outstanding UM",
      dataIndex: "outstandingUM",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "realization_value",
      title: "Nilai Realisasi",
      dataIndex: "realization_value",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "remaining_ceiling",
      title: "Sisa Pagu",
      dataIndex: "remaining_ceiling",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value
            ? value == 0
              ? "Draft"
              : value == 1
              ? "Menunggu Persetujuan"
              : value == 2
              ? "Ditetapkan"
              : "Reject"
            : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => <div className="text-center">Aksi</div>,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          {roleAccess?.canView && (
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onDetail(data)}
            />
          )}
          {data?.status == 1 && hierarchy === "Kepala Divisi" && (
            <Button
              onClick={() => onTriggerUpdateStatus(data)}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-32 btn-sm"
            >
              Approval
            </Button>
          )}
          {data?.status == 0 && (
            <Button
              onClick={() => onTriggerUpdateStatus(data)}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-32 btn-sm"
            >
              Ajukan
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <InformationModal open={importSuccessPopup} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-4 py-4">
          <IoIosCheckmarkCircleOutline className="text-[100px]" />
          <div className="text-center space-y-2 pb-4">
            <div className="font-bold text-2xl">Data berhasil Dihapus</div>
            <div className="text-sm">Data yang berhasil di upload 100 data</div>
          </div>
          <Button
            onClick={() => {
              setImportSuccessPopup(false);
              refetch();
            }}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 w-56"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <ConfirmationModal
        message={
          selectedData?.status == 0
            ? `Apakah anda yakin akan mengajukan anggaran ${selectedData?.year} ? Anggaran yang sudah diajukan tidak dapat edit kembali`
            : `Apakah anda yakin akan menyetujui anggaran ${selectedData?.year} ? Anggaran yang sudah disetujui tidak dapat edit kembali`
        }
        open={openModalStatus}
        className="max-w-md"
        onClose={() => {
          setSelectedData(null);
          setOpenModalStatus(false);
          methods.reset({ file: null, status: null, reason: "" });
        }}
        onSubmit={methods.handleSubmit(onUpdateStatus)}
        isLoading={updateStatus?.isLoading}
      />
      {/* <FormModal
        title="Update Status"
        open={openModalStatus}
        onClose={() => {
          setSelectedData(null);
          setOpenModalStatus(false);
          methods.reset({ file: null, status: null, reason: "" });
        }}
        bodyClassname={"min-h-[180px]"}
        onSubmit={methods.handleSubmit(onUpdateStatus)}
      >
        <FormProvider {...methods}>
          <div className="space-y-4">
            <CustomSelectForm
              name="status"
              label="Status"
              options={[
                { value: 1, label: "Ditetapkan" },
                { value: 2, label: "Reject" },
              ]}
            />
            {methods.watch("status")?.value === 2 && (
              <InputForm name="reason" label="Alasan" className="w-full" />
            )}
          </div>
        </FormProvider>
      </FormModal> */}
      <FormModal
        title="Import Data"
        open={importPopup}
        onClose={() => {
          setImportPopup(false);
          methods.reset({ file: null });
        }}
        onSubmit={methods.handleSubmit(onImportUpload)}
        isLoading={importBudget.isLoading}
      >
        <div className="space-y-1">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Upload Data</span>
          </label>
          <div
            className="flex items-center justify-center flex-col border border-dashed p-10 space-y-2 hover:cursor-pointer"
            onClick={() => importRef?.current?.click()}
          >
            <BiImageAdd className="text-[#8F9BB3] text-3xl" />
            <div className="flex items-center justify-center flex-col">
              <div className="font-medium text-sm">
                {methods?.watch("file")
                  ? methods?.watch("file")?.name
                  : "Upload"}
              </div>
              {!methods?.watch("file") ? (
                <div className="text-[#8F9BB3] text-sm">
                  .xls, .xlsx up to 10MB
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </FormModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Anggaran"}</TitleText>
        <div className="flex space-x-4 justify-end flex-1">
          <a
            href={TemplateBudget}
            download={`Daftar Anggaran Template - (Tahun Periode)`}
            target="_blank"
            className="btn capitalize text-white border-secondary-600 bg-secondary-600 hover:bg-secondary-700 hover:border-secondary-700"
            rel="noreferrer"
          >
            Download Template
          </a>
          <Button
            onClick={onTriggerImport}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Import Data
          </Button>
          <div className="hidden">
            <FormProvider {...methods}>
              <input
                ref={importRef}
                type="file"
                name="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={onHandleImport}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex items-center">
              <SearchInput
                className="flex-1"
                placeholder="Cari"
                onChange={onSearch}
              />
              <Input
                className="flex-1"
                placeholder="dd/mm/yyyy"
                onChange={onSearch}
              />
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Budget;
