import React, { useCallback, useState } from "react";
import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { useQuery } from "react-query";
import { useProjectAccountsQuery } from "hooks/projectAccount";
import { debounce } from "lodash";
// import { convertToIDR } from "helpers";
import { convertToIDR } from "pages/DownPayment/util";
import { HiOutlineEye } from "react-icons/hi";
import CustomDatepickerForm from "components/CustomDatepicker";
import moment from "moment";
import { convertToUSD } from "helpers";

const ProjectAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const [keyword, setKeyword] = useState("");
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState("");

  const links = [
    {
      label: "Daftar Rekening Proyek",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "account_number",
      title: "Nomor Rekening",
      dataIndex: "account_number",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "account_owner",
      title: "Nama Pemilik Rekening",
      dataIndex: "account_owner",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "bank_name",
      title: "Bank",
      dataIndex: "bank_name",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "branch",
      title: "Cabang",
      dataIndex: "branch",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "currency_name",
      title: "Mata Uang",
      dataIndex: "currency_name",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "balance_flow_in",
      title: "Total Uang Masuk",
      dataIndex: "balance_flow_in",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => {
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value
              ? data?.currency_name !== "USD"
                ? convertToIDR(+value)
                : convertToUSD(+value)
              : "-"}
          </div>
        );
      },
    },
    {
      id: "balance_flow_out",
      title: "Total Uang Keluar",
      dataIndex: "balance_flow_out",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value
            ? data?.currency_name !== "USD"
              ? convertToIDR(+value)
              : convertToUSD(+value)
            : "-"}
        </div>
      ),
    },
    {
      id: "balance",
      title: "Sisa Saldo",
      dataIndex: "balance",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value
            ? data?.currency_name !== "USD"
              ? convertToIDR(+value)
              : convertToUSD(+value)
            : "-"}
        </div>
      ),
    },
    // {
    //   id: "action",
    //   title: "Aksi",
    //   dataIndex: "action",
    //   width: 160,
    //   className: "overflow-hidden text-ellipsis right-[0px] block shadow-lg",
    //   columnClassName: "right-[0px] block shadow-lg",
    //   sortable: true,
    //   fixed: "right",
    //   render: (value, data) => (
    //     <div className="space-x-2 flex items-center w-[150px]">
    //       <HiOutlineEye
    //         className="text-gray-600 cursor-pointer flex-1"
    //         size={20}
    //         onClick={() => navigate(`/project-account/${data.id}`)}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const { data } = useProjectAccountsQuery([
    "project-accounts",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
      is_draft: statusFilter?.value,
      startDate: dateFilter
        ? moment(dateFilter).format("YYYY-MM-DD")
        : undefined,
      endDate: dateFilter ? moment(dateFilter).format("YYYY-MM-DD") : undefined,
    },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Rekening Proyek"}</TitleText>
        <div className="flex items-center space-x-4">
          <Button
            onClick={() => navigate(`/project-account/trx-history`)}
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Riwayat Transaksi
          </Button>
          <Button
            startIcon={<FiPlus />}
            onClick={() => navigate(`/project-account/trx-input`)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Input Transaksi
          </Button>
          {roleAccess?.canCreate && (
            <Button
              startIcon={<FiPlus />}
              onClick={() => navigate("/project-account/add")}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            >
              Tambah Rekening
            </Button>
          )}
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              {/* <CustomSelect
                value={statusFilter}
                optionsData={[
                  { value: "1", label: "Perlu Disetujui" },
                  { value: "2", label: "Telah Disetujui" },
                ]}
                label="Status"
                placeholder="Status"
                className="flex-1"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              />
              <div className="flex-1">
                <CustomDatepickerForm
                  name={`start_date`}
                  placeholder={"dd/mm/yyyy"}
                  onChange={setDateFilter}
                  value={dateFilter}
                />
              </div> */}
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={data?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: data?.currentPage,
                limit: data?.size,
                total: data?.totalItems,
                previous_pages: data?.currentPage > 1,
                next_pages: !(data?.currentPage === data?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectAccount;
