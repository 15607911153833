import classNames from "classnames";
import { Button } from "components";
import React from "react";

const BottomFormAction = ({
  onCancel,
  onSubmit,
  _submitButton,
  _cancelButton,
  renderAction,
  disabled = false,
  submitBtnDisabled = false,
  isLoading = false,
  hideCancelButton = false,
}) => {
  return (
    <div className="fixed bottom-0 right-0 w-full p-3 flex justify-center items-center sm:right-[-7.8rem] z-[11]">
      <div className="flex w-full bg-white rounded-3xl p-6 gap-4 border sm:w-[50%] md:w-[60%] lg:w-[70%] xl:w-[80%]">
        <div className="flex-1">
          {!hideCancelButton && (
            <Button
              onClick={onCancel}
              type="button"
              disabled={disabled}
              className={classNames(
                "p-4 border rounded-lg bg-gray-600 border-gray-600 w-40",
                _cancelButton?.className
              )}
            >
              <div className="flex items-center gap-2">
                {_cancelButton?.label ?? "Batal"}
              </div>
            </Button>
          )}
        </div>
        <div className="flex-1 flex justify-end">
          {renderAction?.() ? (
            <>{renderAction()}</>
          ) : (
            <Button
              onClick={onSubmit}
              type="submit"
              disabled={disabled || submitBtnDisabled}
              className={classNames(
                "p-4 border rounded-lg bg-primary-600 border-primary-600 w-40",
                _submitButton?.className
              )}
            >
              <div className="flex items-center gap-2">
                {isLoading
                  ? "Loading..."
                  : _submitButton?.label
                  ? _submitButton?.label
                  : "Submit"}
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomFormAction;
