import { yupResolver } from "@hookform/resolvers/yup";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

const REJECT_CATEGORY_OPTIONS = Array.from({ length: 3 }, (_, index) => ({
  label: `Kategori ${index + 1}`,
  value: `Kategori ${index + 1}`,
}));

const schema = yup.object().shape({
  category: yup
    .object({
      label: yup.string().required("Wajib diisi"),
      value: yup.string().required("Wajib diisi"),
    })
    .typeError("Wajib diisi"),
  reject_reason: yup.string().required("Wajib diisi"),
});

const RejectionModal = ({ isOpen, onClose, onSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      category: "",
      reject_reason: "",
    },
  });
  return (
    <FormModal
      title="Tolak"
      open={isOpen}
      onClose={onClose}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <div className="space-y-3 py-4">
          <CustomSelectForm
            name="category"
            label="Kategori Penolakan"
            options={REJECT_CATEGORY_OPTIONS}
            placeholder="Pilih Kategori"
          />
          <InputForm
            name="reject_reason"
            label="Alasan Penolakan"
            type="textarea"
            placeholder="Catatan"
            className="w-full"
          />
        </div>
      </FormProvider>
    </FormModal>
  );
};

export default RejectionModal;
